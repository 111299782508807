import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";

import { formatDate } from "@angular/common";
import { AppService } from "src/app/app.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserAddress } from "src/app/app.models";
@Component({
  selector: "app-schedule-checkbox",
  templateUrl: "./schedule-checkbox.component.html",
  styleUrls: ["./schedule-checkbox.component.css"],
})
export class ScheduleCheckboxComponent implements OnInit {
  public form!: FormGroup;
  submenulist: any;
  geocitylist: any;
  customerid: any;
  isLinear = true;
  formNameGroup!: FormGroup;
  formaddress!: FormGroup;
  formPasswordGroup !: FormGroup;
  formEmailGroup!: FormGroup;
  formPickupGroup!: FormGroup;
  totalss: number = 0
  totalss2: number = 0
  visiblity: boolean = false;
  cardvisiblity: boolean = false;
  fieldvisibility: boolean = false;
  matSteppervisibility: boolean = false;
  selectedItem: any;
  addressstatus: string = "old";
  constructor(
    public dialogRef: MatDialogRef<ScheduleCheckboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public appservice: AppService, public router: Router,
    public snackBar: MatSnackBar,

  ) {
    this.createForm();
  }
  ngOnInit() {
    console.log(this.data)
    this.getGeoCityList();
    this.totalss = (Number(this.data.total) + Number(this.data.delfee));
    console.log(this.totalss)

    if (this.data.formdata.ewallet_chk) {
      if (this.totalss <= this.data.formdata.eBal) {
        this.totalss2 = 0;
        console.log(this.totalss2)
      }
      else {
        this.totalss2 = 0;
        // this.totalss2 = (Number(this.totalss.toFixed(2)) - this.data.formdata.eBal)
      }
    }

    if (this.data.order.DeliveryType == "Curb Side Pickup") {
      this.fieldvisibility = false;
    }
    else {
      this.fieldvisibility = true;
    }

    if (this.data.order.DeliveryType == "Self-Pick Up") {
      this.matSteppervisibility = false;
    }
    else {
      this.matSteppervisibility = true;
    }

    // if (this.data.formdata.PaymentType == "credit")
    //   this.cardvisiblity = true;
    // else
    //   this.cardvisiblity = false;

  }

  getGeoCityList() {
    this.appservice.getGeoCityList().subscribe((reslist: any) => {
      console.log(reslist)
      this.geocitylist = reslist;
    })
  }

  createForm() {
    this.formNameGroup = this.fb.group({
      CustomerName: [null],
      CompanyName: [null],
      ContactNo: [null],
      StreetName: [null],
      POBoxNo: [null],
      GeoLocation: [null],
      Country: [null],
      BuildingName:'',
      OrderId: 0,//this.data.orderheader.result.id,
      AddressType: "Delivery Address",
    });

    this.formaddress = this.fb.group({
      Address: ''
    });

    this.formPasswordGroup = this.fb.group({

    });
    this.formEmailGroup = this.fb.group({

    });
    this.formPickupGroup = this.fb.group({
      DriverName: this.data.userdata.customer.driverName,
      DriverPhone: this.data.userdata.customer.driverPhoneNo,
      CarNumber: this.data.userdata.customer.carNumber,
      CarMake: this.data.userdata.customer.carMake,
      CarModel: this.data.userdata.customer.carModel,
      CarColor: this.data.userdata.customer.carColor,
    });
  }

  Changecity(args: any) {

    this.selectedItem = this.geocitylist.filter((item: { id: any; }) => item.id == args.target.value)[0]
    //this.formNameGroup.controls.Country.setValue(selectedItem.geoHead)
    this.formNameGroup.controls.Country.setValue("UAE")
  }

  changetype() {
    this.addressstatus = "old";
    this.visiblity = false;
  }


  showAddress() {
    this.addressstatus = "new";
    this.visiblity = true;
  }



  submit(stepper: any) {

    console.log(this.formNameGroup.value);

    if (this.addressstatus == "new" && this.data.order.DeliveryType != "Curb Side Pickup") {
      if (this.formNameGroup.controls.CustomerName.value != null && this.formNameGroup.controls.StreetName.value != null &&
        this.formNameGroup.controls.BuildingName.value != null && this.formNameGroup.controls.Country.value != null) {
        this.formNameGroup.controls.GeoLocation.setValue(this.selectedItem.geoName)

        // this.appservice.saveOrderAddress(this.formNameGroup.getRawValue()).subscribe((resadd: any) => {
        //   console.log(resadd)
        //   if (resadd.result === "Sucess") {
        //     if (this.data.userdata.address.length > 0 && this.addressstatus == "old") {
        //       this.customerid = this.data.userdata.address.id
        //     }
        //     else {
        //       this.customerid = 0;
        //     }
        //     let add = {
        //       Id: this.customerid,
        //       AddType: "Primary",
        //       StreetName: this.formNameGroup.controls.StreetName.value,
        //       POBoxNumber: this.formNameGroup.controls.POBoxNo.value,
        //       Location: this.selectedItem.geoName,
        //       Country: this.formNameGroup.controls.Country.value,
        //       ContactNumber: this.formNameGroup.controls.ContactNo.value,
        //       Latitude: this.selectedItem.geoLatitude,
        //       Longtitude: this.selectedItem.geoLongitude
        //     }
        //     this.appservice.updateUserAddress(add).subscribe((res) => {
        //       console.log(res)
        //       stepper.next()
        //     })

        //   }
        // })
        stepper.next();
      }
      else {
        this.snackBar.open("Please Fill details!!!", '×', { panelClass: 'danger', verticalPosition: 'top', horizontalPosition: 'center', duration: 5000 });
      }

    }
    else if (this.addressstatus == "old" && this.data.order.DeliveryType != "Curb Side Pickup") {
      if (this.formaddress.controls.Address.value != null && this.formaddress.controls.Address.value != '') {
        //   console.log(this.data.userdata.address)

        let selectaddr = this.data.userdata.address.filter((item: { id: any; }) => item.id == this.formaddress.controls.Address.value)




        this.formNameGroup.controls.CustomerName.setValue(this.data.userdata.customer.customerName);
        this.formNameGroup.controls.ContactNo.setValue(selectaddr.contactNumber);
        this.formNameGroup.controls.GeoLocation.setValue(selectaddr.country);
        this.formNameGroup.controls.StreetName.setValue(selectaddr.streetNamee);
        this.formNameGroup.controls.BuildingName.setValue(selectaddr.buildingName);
        this.formNameGroup.controls.Country.setValue(selectaddr.country);



        //   let orderadd = {
        //     CustomerName: this.data.userdata.customer.customerName,
        //     CompanyName: '',
        //     ContactNo: selectaddr.contactNumber,
        //     StreetName: selectaddr.streetName,
        //     POBoxNo: selectaddr.poBoxNumber,
        //     GeoLocation: selectaddr.location,
        //     Country: selectaddr.country,
        //     OrderId: this.data.orderheader.result.id,
        //     AddressType: selectaddr.addType
        //   }
        //   this.appservice.saveOrderAddress(orderadd).subscribe((resadd1: any) => {
        //     console.log(resadd1)
        //     if (resadd1.result === "Sucess") {
        //       console.log(stepper)
        //       stepper.next()
        //     }
        //   });
        console.log(this.formNameGroup.value)
        stepper.next();
      }
      else {
        this.snackBar.open("Please Select Address Details!!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
      }


    }
    else if (this.data.order.DeliveryType === "Curb Side Pickup") {
      // if (this.formPickupGroup.controls.VechicleNo.value != null && this.formPickupGroup.controls.DriverName.value != null && 
      //   this.formPickupGroup.controls.DriverPhone.value != null) {
      //     console.log(this.formPickupGroup.value)

      //     this.formNameGroup.controls.CustomerName.setValue("5554");
      //     this.formNameGroup.controls.ContactNo.setValue("5545");
      //     this.formNameGroup.controls.GeoLocation.setValue("c");
      //     this.formNameGroup.controls.StreetName.setValue("55454");
      //     this.formNameGroup.controls.POBoxNo.setValue("444");
      //     this.formNameGroup.controls.Country.setValue("755");

      //     console.log(this.formPickupGroup.controls.VechicleNo.value)

      //     this.data.order.VechicleNo=this.formPickupGroup.controls.VechicleNo.value;
      //     this.data.order.DriverName=this.formPickupGroup.controls.DriverName.value;
      //     this.data.order.DriverPhone=this.formPickupGroup.controls.DriverPhone.value;

      //     stepper.next();
      //   }
      //   else {
      //     this.snackBar.open("Please Fill details!!!", '×', { panelClass: 'danger', verticalPosition: 'top', horizontalPosition: 'center', duration: 5000 });
      //   }
      stepper.next();
    }

    // if (this.formNameGroup.valid || this.formaddress.valid) {
    //   if (this.addressstatus == "new") {
    //     this.formNameGroup.controls.GeoLocation.setValue(this.selectedItem.geoName)
    //     this.appservice.saveOrderAddress(this.formNameGroup.getRawValue()).subscribe((resadd: any) => {
    //         console.log(resadd)
    //         if (resadd.result === "Sucess") {
    //           if (this.data.userdata.address.length > 0 && this.addressstatus == "old") {
    //             this.customerid = this.data.userdata.address.id
    //           }
    //           else {
    //             this.customerid = 0;
    //           }
    //           let add = {
    //             Id: this.customerid,
    //             AddType: "Primary",
    //             StreetName: this.formNameGroup.controls.StreetName.value,
    //             POBoxNumber: this.formNameGroup.controls.POBoxNo.value,
    //             Location: this.selectedItem.geoName,
    //             Country: this.formNameGroup.controls.Country.value,
    //             ContactNumber: this.formNameGroup.controls.ContactNo.value,
    //             Latitude: this.selectedItem.geoLatitude,
    //             Longtitude: this.selectedItem.geoLongitude
    //           }
    //           this.appservice.updateUserAddress(add).subscribe((res) => {
    //             console.log(res)
    //             stepper.next()
    //           })

    //         }
    //       })
    //   }
    //   else if (this.addressstatus == "old") {
    //     if (this.formaddress.controls.Address.value != null) {
    //         console.log(this.data.userdata.address)



    //         let selectaddr=this.data.userdata.address.filter((item: { id: any; }) => item.id == this.formaddress.controls.Address.value)

    //         this.formNameGroup.controls.GeoLocation.setValue(selectaddr.location)
    //         this.formNameGroup.controls.StreetName.setValue(selectaddr.streetName)
    //         this.formNameGroup.controls.GeoLocation.setValue(selectaddr.location)
    //         this.formNameGroup.controls.GeoLocation.setValue(selectaddr.location)
    //         this.formNameGroup.controls.GeoLocation.setValue(selectaddr.location)
    //         this.formNameGroup.controls.GeoLocation.setValue(selectaddr.location)

    //         let orderadd = {
    //           CustomerName:this.data.userdata.customer.customerName,
    //           CompanyName: '',
    //           ContactNo: selectaddr.contactNumber,
    //           StreetName: selectaddr.streetName,
    //           POBoxNo: selectaddr.poBoxNumber,
    //           GeoLocation: selectaddr.location,
    //           Country: selectaddr.country,
    //           OrderId: this.data.orderheader.result.id,
    //           AddressType: selectaddr.addType
    //         }
    //         this.appservice.saveOrderAddress(orderadd).subscribe((resadd1: any) => {
    //           console.log(resadd1)
    //           if (resadd1.result === "Sucess") {
    //             console.log(stepper)

    //             stepper.next()
    //           }
    //         });
    //       }
    //   }

    // }

    else {
      this.snackBar.open("Please fill Address Details!!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
    }
    // if(this.formNameGroup.valid){
    //  // this.dialogRef.close(this.form.value);
    //  console.log(this.formNameGroup.value);
    // }
    // else{
    //   this.snackBar.open("Please Choose Item Portion!!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
    // }

  }


  paymentsubmit(stepper: any) {

    if (this.data.order.DeliveryType == "Curb Side Pickup") {
      this.data.order.CarNumber = this.data.userdata.customer.carNumber;
      this.data.order.DriverName = this.data.userdata.customer.driverName
      this.data.order.DriverPhone = this.data.userdata.customer.driverPhoneNo
      this.data.order.CarMake = this.data.userdata.customer.carMake
      this.data.order.CarModel = this.data.userdata.customer.carModel
      this.data.order.CarColor = this.data.userdata.customer.carColor
    }

    console.log(this.data.order)


    this.appservice.saveOrderSubcription(this.data.order).subscribe((orderheader: any) => {

      console.log(orderheader);

      if (orderheader.result != null) {

        for (let i = 0; i < this.data.cartArray.length; i++) {


          let mnd, tud, wd, th, frd, satd, snd;
          if (this.data.cartArray[i].mon == true) {
            mnd = "Yes"
          } else { mnd = "No" }
          if (this.data.cartArray[i].tue == true) {
            tud = "Yes"
          } else { tud = "No" }
          if (this.data.cartArray[i].wed == true) {
            wd = "Yes"
          } else { wd = "No" }
          if (this.data.cartArray[i].thu == true) {
            th = "Yes"
          } else { th = "No" }
          if (this.data.cartArray[i].fri == true) {
            frd = "Yes"
          } else { frd = "No" }
          if (this.data.cartArray[i].sat == true) {
            satd = "Yes"
          } else { satd = "No" }
          if (this.data.cartArray[i].sun == true) {
            snd = "Yes"
          } else { snd = "No" }

          let Items = {
            OrderId: orderheader.result.id,
            MenuId: this.data.cartArray[i].menuId,
            SubMenuId: this.data.cartArray[i].subItemId,
            MenuName: this.data.cartArray[i].menuName,
            Qty: this.data.cartArray[i].qty,
            "Mon": mnd,
            "Tue": tud,
            "Wed": wd,
            "Thu": th,
            "Fri": frd,
            "Sat": satd,
            "Sun": snd
          }
          console.log(Items)

          this.appservice.saveDetailsOrderSubcription(Items).subscribe((resorderdetails: any) => {
            console.log(resorderdetails)
            if (resorderdetails.result == "Sucess") {


              if (this.data.cartArray.length - 1 == i) {

                console.log(this.formNameGroup.value);
                this.formNameGroup.controls.OrderId.setValue = orderheader.result.id;

                if (this.addressstatus == "new" && this.data.order.DeliveryType != "Curb Side Pickup") {
                  let orderadd = {
                    CustomerName: this.formNameGroup.controls.CustomerName.value,
                    CompanyName: '',
                    ContactNo: this.formNameGroup.controls.ContactNo.value,
                    StreetName: this.formNameGroup.controls.StreetName.value,
                    BuildingName: this.formNameGroup.controls.BuildingName.value,
                    GeoLocation: this.formNameGroup.controls.GeoLocation.value,
                    Country: this.formNameGroup.controls.Country.value,
                    OrderId: orderheader.result.id,
                    AddressType: this.formNameGroup.controls.AddressType.value
                  }
                  console.log(orderadd)

                  this.appservice.SaveSubcriptionOrderAddress(orderadd).subscribe((resadd: any) => {
                    console.log(resadd)
                    if (resadd.result === "Sucess") {
                      if (this.data.userdata.address.length > 0 && this.addressstatus == "old") {
                        this.customerid = this.data.userdata.address.id
                      }
                      else {
                        this.customerid = 0;
                      }
                      let add = {
                        Id: this.customerid,
                        AddType: "Primary",
                        StreetName: this.formNameGroup.controls.StreetName.value,
                        BuildingName: this.formNameGroup.controls.BuildingName.value,
                        Location: this.selectedItem.geoName,
                        Country: this.formNameGroup.controls.Country.value,
                        ContactNumber: this.formNameGroup.controls.ContactNo.value,
                        Latitude: this.selectedItem.geoLatitude,
                        Longtitude: this.selectedItem.geoLongitude
                      }
                      console.log("--update add ---")
                      console.log(add)
                      this.appservice.updateUserAddress(add).subscribe((res) => {
                        console.log(res)

                        let pay = {
                          OrderId: orderheader.result.id,
                          PaymentType: this.data.formdata.PaymentType,
                          PaymentTransId: " ",
                          PaymentAmt: this.data.total,
                          PaymentCharges: 0,
                          PaymentStatus: "Success"
                        }
                        console.log(pay)

                        this.appservice.saveOrderPayment(pay).subscribe((respay: any) => {
                          console.log(respay)
                          if (respay.result === "Sucess") {
                            // stepper.next();
                            // setTimeout(() => {
                            //   setTimeout(() => {
                            //     this.dialogRef.close("1");
                            //     this.router.navigateByUrl("/home");
                            //   });
                            // }, 5000);

                            if (this.data.formdata.ewallet_chk) {
                              let wal_inv = {
                                "VendorId": 1,
                                "CustomerId": this.data.userdata.customer.id,
                                "VouType": "Invoice",
                                "VouSNo": 1,
                                "Ledger1": "TPoints",
                                "Ledger2": this.data.userdata.customer.customerName,
                                "Credit": 0,
                                "Debit": this.data.total,
                                "Notes": "as per online order : " + orderheader.result.id,
                                "RefNo": "as per online order : " + orderheader.result.id,
                                "PayType": "TPoints",
                                "PayRef": ""
                              }

                              this.appservice.EWallletOrder(wal_inv).subscribe((res: any) => {
                                console.log(res)
                                stepper.next();
                                setTimeout(() => {
                                  this.dialogRef.close("1");
                                  this.router.navigateByUrl("/home");
                                }, 5000);
                              })
                            }
                            else {
                              stepper.next();
                              setTimeout(() => {

                                this.dialogRef.close("1");
                                this.router.navigateByUrl("/home");
                              }, 5000);
                            }

                          }
                        })

                      })

                    }
                  })


                }
                else if (this.addressstatus == "old" && this.data.order.DeliveryType == "Home Delivery") {
                  console.log(this.data.userdata.address)

                  let selectaddr = this.data.userdata.address.filter((item: { id: any; }) => item.id == this.formaddress.controls.Address.value)

                  this.formNameGroup.controls.CustomerName.setValue(this.data.userdata.customer.customerName);
                  this.formNameGroup.controls.ContactNo.setValue(selectaddr.contactNumber);
                  this.formNameGroup.controls.GeoLocation.setValue(selectaddr.country);
                  this.formNameGroup.controls.StreetName.setValue(selectaddr.streetNamee);
                  this.formNameGroup.controls.BuildingName.setValue(selectaddr.buildingName);
                  this.formNameGroup.controls.Country.setValue(selectaddr.country);

                  console.log("---")
                  console.log(selectaddr)
                  console.log(selectaddr[0].contactNumber)
                  console.log(this.formaddress.controls.Address.value)

                  let orderadd = {
                    CustomerName: this.data.userdata.customer.customerName,
                    CompanyName: '',
                    ContactNo: selectaddr[0].contactNumber,
                    StreetName: selectaddr[0].streetName,
                    POBoxNo: selectaddr[0].poBoxNumber,
                    GeoLocation: selectaddr[0].location,
                    Country: selectaddr[0].country,
                    OrderId: orderheader.result.id,
                    AddressType: selectaddr[0].addType,
                    BuildingName:selectaddr[0].buildingName
                  }
                  console.log("---")
                  console.log(orderadd)
                  this.appservice.SaveSubcriptionOrderAddress(orderadd).subscribe((resadd1: any) => {
                    console.log(resadd1)
                    if (resadd1.result === "Sucess") {
                      console.log(stepper)

                      let pay = {
                        OrderId: orderheader.result.id,
                        PaymentType: this.data.formdata.PaymentType,
                        PaymentTransId: " ",
                        PaymentAmt: this.data.total,
                        PaymentCharges: 0,
                        PaymentStatus: "Success"
                      }

                      console.log(pay)

                      this.appservice.saveOrderPayment(pay).subscribe((respay: any) => {
                        console.log(respay)
                        if (respay.result === "Sucess") {


                          if (this.data.formdata.ewallet_chk) {
                            let wal_inv = {
                              "VendorId": 1,
                              "CustomerId": this.data.userdata.customer.id,
                              "VouType": "Invoice",
                              "VouSNo": 1,
                              "Ledger1": "TPoints",
                              "Ledger2": this.data.userdata.customer.customerName,
                              "Credit": 0,
                              "Debit": this.data.total,
                              "Notes": "as per online order : " + orderheader.result.id,
                              "RefNo": "as per online order : " + orderheader.result.id,
                              "PayType": "TPoints",
                              "PayRef": ""
                            }

                            this.appservice.EWallletOrder(wal_inv).subscribe((res: any) => {
                              console.log(res)
                              stepper.next();
                              setTimeout(() => {

                                this.dialogRef.close("1");
                                this.router.navigateByUrl("/home");

                              }, 5000);
                            })
                          }
                          else {
                            stepper.next();
                            setTimeout(() => {

                              this.dialogRef.close("1");
                              this.router.navigateByUrl("/home");

                            }, 5000);
                          }


                        }
                      })

                    }
                  });
                }
                else if (this.data.order.DeliveryType == "Self-Pick Up") {
                  let orderadd: any;
                  if (this.data.userdata.address.length > 0) {

                    orderadd = {
                      CustomerName: this.data.userdata.customer.customerName,
                      CompanyName: '',
                      ContactNo: this.data.userdata.address[0].contactNumber,
                      StreetName: this.data.userdata.address[0].streetName,
                      POBoxNo: this.data.userdata.address[0].poBoxNumber,
                      GeoLocation: this.data.userdata.address[0].location,
                      Country: this.data.userdata.address[0].country,
                      OrderId: orderheader.result.id,
                      AddressType: this.data.userdata.address[0].addType,
                      BuildingName:this.data.userdata.address[0].buildingName
                    }
                  }
                  else {

                    orderadd = {
                      CustomerName: this.data.userdata.customer.customerName,
                      CompanyName: '',
                      ContactNo: this.data.userdata.customer.mobileNo,
                      StreetName: '',
                      POBoxNo: '',
                      GeoLocation: '',
                      Country: '',
                      OrderId: orderheader.result.id,
                      AddressType: '',
                      BuildingName:''
                    }
                  }




                  console.log("---")
                  console.log(orderadd)
                  this.appservice.SaveSubcriptionOrderAddress(orderadd).subscribe((resadd1: any) => {
                    console.log(resadd1)
                    if (resadd1.result === "Sucess") {
                      console.log(stepper)

                      let pay = {
                        OrderId: orderheader.result.id,
                        PaymentType: this.data.formdata.PaymentType,
                        PaymentTransId: " ",
                        PaymentAmt: this.data.total,
                        PaymentCharges: 0,
                        PaymentStatus: "Success"
                      }

                      console.log(pay)

                      this.appservice.saveOrderPayment(pay).subscribe((respay: any) => {
                        console.log(respay)
                        if (respay.result === "Sucess") {


                          if (this.data.formdata.ewallet_chk) {
                            let wal_inv = {
                              "VendorId": 1,
                              "CustomerId": this.data.userdata.customer.id,
                              "VouType": "Invoice",
                              "VouSNo": 1,
                              "Ledger1": "TPoints",
                              "Ledger2": this.data.userdata.customer.customerName,
                              "Credit": 0,
                              "Debit": this.data.total,
                              "Notes": "as per online order : " + orderheader.result.id,
                              "RefNo": "as per online order : " + orderheader.result.id,
                              "PayType": "TPoints",
                              "PayRef": ""
                            }

                            this.appservice.EWallletOrder(wal_inv).subscribe((res: any) => {
                              console.log(res)
                              stepper.next();
                              setTimeout(() => {

                                this.dialogRef.close("1");
                                this.router.navigateByUrl("/home");

                              }, 5000);
                            })
                          }
                          else {
                            stepper.next();
                            setTimeout(() => {

                              this.dialogRef.close("1");
                              this.router.navigateByUrl("/home");

                            }, 5000);
                          }


                        }
                      })

                    }
                  });


                }
                else if (this.data.order.DeliveryType == "Curb Side Pickup") {
                  let orderadd: any;
                  if (this.data.userdata.address.length > 0) {

                    orderadd = {
                      CustomerName: this.data.userdata.customer.customerName,
                      CompanyName: '',
                      ContactNo: this.data.userdata.address[0].contactNumber,
                      StreetName: this.data.userdata.address[0].streetName,
                      POBoxNo: this.data.userdata.address[0].poBoxNumber,
                      GeoLocation: this.data.userdata.address[0].location,
                      Country: this.data.userdata.address[0].country,
                      OrderId: orderheader.result.id,
                      AddressType: this.data.userdata.address[0].addType,
                      BuildingName:this.data.userdata.address[0].buildingName
                    }
                  }
                  else {

                    orderadd = {
                      CustomerName: this.data.userdata.customer.customerName,
                      CompanyName: '',
                      ContactNo: this.data.userdata.customer.mobileNo,
                      StreetName: '',
                      POBoxNo: '',
                      GeoLocation: '',
                      Country: '',
                      OrderId: orderheader.result.id,
                      AddressType: '',
                      BuildingName:''
                    }
                  }




                  console.log("---")
                  console.log(orderadd)
                  this.appservice.SaveSubcriptionOrderAddress(orderadd).subscribe((resadd1: any) => {
                    console.log(resadd1)
                    if (resadd1.result === "Sucess") {
                      let pay = {
                        OrderId: orderheader.result.id,
                        PaymentType: this.data.formdata.PaymentType,
                        PaymentTransId: " ",
                        PaymentAmt: this.data.total,
                        PaymentCharges: 0,
                        PaymentStatus: "Success"
                      }
                      console.log(pay)
                      this.appservice.saveOrderPayment(pay).subscribe((respay: any) => {
                        console.log(respay)
                        if (respay.result === "Sucess") {
                          // stepper.next();
                          // setTimeout(() => {
                          //   setTimeout(() => {
                          //     this.dialogRef.close("1");
                          //     this.router.navigateByUrl("/home");
                          //   });
                          // }, 5000);

                          if (this.data.formdata.ewallet_chk) {

                            let wal_inv = {
                              "VendorId": 1,
                              "CustomerId": this.data.userdata.customer.id,
                              "VouType": "Invoice",
                              "VouSNo": 1,
                              "Ledger1": "TPoints",
                              "Ledger2": this.data.userdata.customer.customerName,
                              "Credit": 0,
                              "Debit": this.data.total,
                              "Notes": "as per online order : " + orderheader.result.id,
                              "RefNo": "as per online order : " + orderheader.result.id,
                              "PayType": "TPoints",
                              "PayRef": ""
                            }

                            this.appservice.EWallletOrder(wal_inv).subscribe((res: any) => {
                              console.log(res)
                              stepper.next();
                              setTimeout(() => {

                                this.dialogRef.close("1");
                                this.router.navigateByUrl("/home");

                              }, 5000);
                            })
                          }
                          else {
                            stepper.next();
                            setTimeout(() => {

                              this.dialogRef.close("1");
                              this.router.navigateByUrl("/home");

                            }, 5000);
                          }

                        }
                      })
                    }
                  });
                }


              }
            }


          },
            (err: HttpErrorResponse) => {
              // error code here
              console.log(err);
            });
        }

      }


    },
      (err: HttpErrorResponse) => {
        // error code here
        console.log(err);
      });

    //////////////////////////////////////

    //         }

    //       



    //     }
    //   }

    // },
    //   (err: HttpErrorResponse) => {
    //     // error code here
    //     console.log(err);
    //   });


    // let pay = {
    //   OrderId: this.data.orderheader.result.id,
    //   PaymentType: this.data.formdata.PaymentType,
    //   PaymentTransId: " ",
    //   PaymentAmt: this.data.total,
    //   PaymentCharges: 0,
    //   PaymentStatus: "Success"
    // }

    // this.appservice.saveOrderPayment(pay).subscribe((respay: any) => {
    //   console.log(respay)
    //   if (respay.result === "Sucess") {
    //     stepper.next();
    //     setTimeout(() => {
    //       setTimeout(() => {
    //         this.dialogRef.close("1");
    //         this.router.navigateByUrl("/home");
    //       });
    //     }, 5000);

    //   }
    // })
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
  public finish(): void {
    this.dialogRef.close("1");
  }
}
