import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";

import { formatDate } from "@angular/common";
import { AppService } from "src/app/app.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserAddress } from "src/app/app.models";
@Component({
  selector: "app-statement-dialog",
  templateUrl: "./statement-dialog.component.html",
  styleUrls: ["./statement-dialog.component.css"],
})
export class StatementDialogComponent implements OnInit {
  Ewalletlist:any;

  constructor(
    public dialogRef: MatDialogRef<StatementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public appservice: AppService, public router: Router,
    public snackBar: MatSnackBar,

  ) {

  }
  ngOnInit() {
    console.log(this.data)
   this.Ewalletlist=this.data.passdata.result;
  }




  onNoClick(): void {
    this.dialogRef.close();
  }
  public finish(): void {
      this.dialogRef.close();
  }
}
