import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SummaryCount, vendor } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { routes } from '../user/user.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  data: any;
  data2: any;
  errorMsg: string = '';
  isLoadingResult: boolean = false;
  keyword = 'location';
  keyword2 = 'vendorName';
  vendorsCount: number = 0;
  ordersCount: number = 1;
  regUsers: number = 0;
  top: any = '';
  vendorlist: any;
  vendorTypelist: any;
  CategoryImagebaseUrl: any = environment.BASE_API_URL + 'Catalog/';
  VendorImagebaseUrl: any =  environment.BASE_API_URL + 'vendor/';
  getres: any;
  getloc: any;
  userdata: any;
  testimonal_list: any;

  select_vend: any;
  select_loc: any;
  
  constructor(public fb: FormBuilder,public appservice: AppService, public router: Router, public snackBar: MatSnackBar,) { this.getSummaryCount(), this.getAllLocation(), this.getTopCategory(); }

  ngOnInit(): void {
    this.initDataLoad();
 
 }

  public initDataLoad() {
    this.getProfile();

    this.getVendorType();
    this.getTestimonials();

  }

  public getProfile() {
    if (localStorage.getItem("token")) {
      this.appservice.getUserProfile().subscribe((userdata: any) => {
        console.log(userdata)
        this.userdata = userdata
        this.getVendorList();
      });
    }
    else {
      this.getVendorList();
    }
  }


  public getAllLocation() {
    this.appservice.getGeoVendor().subscribe(loc => {
      this.appservice.Data.geovendorLocation = loc;
      //console.log(loc)
      this.data = loc;
    });

  }

  public getSummaryCount() {
    this.appservice.getSummaryCount().subscribe((count: any) => {
      this.vendorsCount = count.vendorsCount;
      this.ordersCount = count.ordersCount;
      this.regUsers = count.regUsers;

    })
  }

  public getTopCategory() {
    this.appservice.getTopCategory().subscribe(topdata => {
      //console.log(topdata)
      this.appservice.Data.topcategory = topdata;
      this.top = topdata;
    })
  }

  public getVendorList() {
    if (localStorage.getItem("token")) {
      let passdata = {
        "VendorType": "", "VendorNameSearch": "", "Location": "Dubai", "CustomerId": this.userdata.customer.id
      }
      this.appservice.getVendor(passdata).subscribe(vendata => {
        console.log(vendata)
        this.vendorlist = vendata
        this.data2 = vendata
      });
    }
    else {
      let passdata = {
        "VendorType": "", "VendorNameSearch": "", "Location": "Dubai", "CustomerId": 0
      }
      this.appservice.getVendorWithoutToken(passdata).subscribe(vendata => {
        console.log(vendata)
        this.vendorlist = vendata
        this.data2 = vendata

      });
    }
  }

  public getVendorType() {
    if (localStorage.getItem("token")) {
      this.appservice.getVendorType().subscribe(vend_type => {
        console.log(vend_type)
        this.vendorTypelist = vend_type;
      });
    } else {
      this.appservice.getVendorTypeWithoutToke().subscribe(vend_type => {
        console.log(vend_type)
        this.vendorTypelist = vend_type;
      });
    }
  }

  getTestimonials() {
    this.appservice.getTestimonials().subscribe((tets: any) => {
      console.log(tets)
      this.testimonal_list = tets
    })
  }

  ClickFav(id: number) {
    if (localStorage.getItem("token")) {
      let fav =
      {
        "CustomerId": this.userdata.customer.id,
        "VendorId": id,
      }

      this.appservice.myFavourite(fav).subscribe((tets: any) => {
        console.log(tets)
        this.getVendorList();
        if (tets.result == true) {
          this.snackBar.open(' Added as Favourite', '×', {
            panelClass: 'success',
            verticalPosition: 'top',
            duration: 3000,
          });
        }

      })
    } else {
      this.snackBar.open('Please Login to continue !!! ', '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000, });
    }
  }

  removefav(res: any) {
    if (localStorage.getItem("token")) {
      console.log(res)
      this.appservice.myRemoveFavourite(Number(res)).subscribe((re: any) => {
        console.log(re)
        this.getVendorList();
        if (re.result == true)
          this.snackBar.open(' Removed Favourite', '×', {
            panelClass: 'danger',
            verticalPosition: 'top',
            duration: 3000,
          });
      })

    } else {
      this.snackBar.open('Please Login to continue !!! ', '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000, });
    }
  }


  selectEvent(item: any) {
    // do something with selected item

    console.log(item)
    this.select_loc = item.location

  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e: any) {
    // do something when input is focused
  }



  selectEvent2(item: any) {
    // do something with selected item

    console.log(item)
    this.select_vend = item

  }

  onChangeSearch2(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused2(e: any) {
    // do something when input is focused
  }



  listing(val: any) {
    if (val != null) {
      localStorage.setItem("temp_loc", val);
      this.router.navigate(["/listings"]);
    }
  }

  listing2(val: any) {
    if (val != null) {
      localStorage.setItem("temp_type", val);
      this.router.navigate(["/listings"]);
    }
  }

  getvalue() {

    if (this.select_vend) {

      this.router.navigate(['/listing-details/', this.select_vend.vendorId])
      //this.router.navigate(['/listing-details/',3])
    }
    else if (this.select_loc) {
      localStorage.setItem("temp_loc", this.select_loc);
      this.router.navigate(["/listings"]);
    }
    else{
      this.router.navigate(["/listings"]);
    }
    //this.router.navigate(["/listings"]);
  }




}
