import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";

import { formatDate } from "@angular/common";
import { AppService } from "src/app/app.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-mealplan-dialog",
  templateUrl: "./mealplan-dialog.component.html",
  styleUrls: ["./mealplan-dialog.component.css"],
})
export class MealPlanDialogComponent implements OnInit {
  public form!: FormGroup;
  submenulist = [
    "Veg", "Non Veg"
  ]

  MealType = [
    "BreakFast", "Lunch", "Dinner"
  ]

  meal3visible: boolean=true;


  constructor(
    public dialogRef: MatDialogRef<MealPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public appservice: AppService, public router: Router,
    public snackBar: MatSnackBar
  ) {

  }
  ngOnInit() {
    this.form = this.fb.group({
      selecttypeId: [null, Validators.required],
      selectmealbreakfast: [null],
      selectmeallanch: [null],
      selectmealdinner: [null],
      Selectmealname:''
    });
    console.log(this.data)
    this.form.controls.Selectmealname.setValue(this.data.passdata)
    if(this.data.num==3){
      this.meal3visible=false;
      this.form.controls.selectmealbreakfast.setValue(true)
      this.form.controls.selectmealdinner.setValue(true)
      this.form.controls.selectmeallanch.setValue(true)
    }

  }


  selectMealTimeClick(event: any) {

    // console.log(event.target.value)
    // console.log(event.target.checked)
    // console.log(this.form.controls.selectmealbreakfast.value)
    // console.log(this.form.controls.selectmeallanch.value)
    // console.log(this.form.controls.selectmealdinner.value)



    switch (this.data.num) {
      case 1:
        if (event.target.value === "Breakfast") {
          this.form.controls.selectmeallanch.setValue(false)
          this.form.controls.selectmealdinner.setValue(false)
        }
        else if (event.target.value === "Lunch") {
          this.form.controls.selectmealbreakfast.setValue(false)
          this.form.controls.selectmealdinner.setValue(false)
        }
        else if (event.target.value === "Dinner") {
          this.form.controls.selectmeallanch.setValue(false)
          this.form.controls.selectmealbreakfast.setValue(false)
        }
        break;
      case 2:
        if (event.target.value === "Breakfast") {
          if ((this.form.controls.selectmeallanch.value == true) && (this.form.controls.selectmealdinner.value == true)) {
            this.form.controls.selectmealbreakfast.setValue(false)
            this.snackBar.open(' Please select only 2 Meal', '×', {
              panelClass: 'danger',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        }
        else if (event.target.value === "Lunch") {
          if ((this.form.controls.selectmealbreakfast.value == true) && (this.form.controls.selectmealdinner.value == true)) {
            this.form.controls.selectmeallanch.setValue(false)
            this.snackBar.open(' Please select only 2 Meal', '×', {
              panelClass: 'danger',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        }
        else if (event.target.value === "Dinner") {
          if ((this.form.controls.selectmealbreakfast.value == true) && (this.form.controls.selectmeallanch.value == true)) {
            this.form.controls.selectmealdinner.setValue(false)
            this.snackBar.open(' Please select only 2 Meal', '×', {
              panelClass: 'danger',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        }

        // else if(event.target.value==="Lunch"){
        //   this.form.controls.selectmealbreakfast.setValue(false)
        //   this.form.controls.selectmealdinner.setValue(false)
        // }
        // else if(event.target.value==="Dinner"){
        //   this.form.controls.selectmeallanch.setValue(false)
        //   this.form.controls.selectmealbreakfast.setValue(false)
        // }
        break;
    }

  }



  submit() {
    // emppty stuff
    if (this.form.valid) {
      if(this.data.num==1 || this.data.num==3){
        this.dialogRef.close(this.form.value);
      }
      else if(this.data.num==2){
        if ((this.form.controls.selectmeallanch.value == true) && (this.form.controls.selectmealdinner.value == true)) {
          this.dialogRef.close(this.form.value);
        }
        else if ((this.form.controls.selectmealbreakfast.value == true) && (this.form.controls.selectmealdinner.value == true)) {
          this.dialogRef.close(this.form.value);
        }
        else if ((this.form.controls.selectmealbreakfast.value == true) && (this.form.controls.selectmeallanch.value == true)) {
          this.dialogRef.close(this.form.value);
        }
        else{
           this.snackBar.open("Please Choose Two !!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
        }

      }
      
    }
    else {
      this.snackBar.open("Please Choose Item Portion!!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd(): void {

  }
}
