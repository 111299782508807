import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category, Customer, GeoVendorLocation, MenuItem, SummaryCount, TopCategory, vendor } from 'src/app/app.models';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

// import { MatBottomSheet } from '@angular/material/bottom-sheet';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { MatDialog } from '@angular/material/dialog';
// import { TranslateService } from '@ngx-translate/core';
// import { MenuItem, Order, Category,VendorBranch,RestaurantType,UserRoles } from 'src/app/app.models';
// import { vendor } from 'src/app/core/models/register'
// import { AppSettings } from 'src/app/app.settings';
// import { environment } from 'src/environments/environment';
// import { User } from 'src/app/admin/users/user.model'
// import {
//   ConfirmDialogComponent,
//   ConfirmDialogModel,
// } from './shared/confirm-dialog/confirm-dialog.component';
// import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
// import { map } from 'rxjs/operators';
// import { AuthService } from 'src/app/core/service/auth.service';


export class Data {
  constructor(
    public geovendorLocation: GeoVendorLocation[],
    public topcategory: TopCategory[],
    public totalPrice: number,
    public totalCartCount: number
  ) { }
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public Data = new Data(
    [],//GeoVedorLocation
    [],// topcategory
    0, // totalPrice
    0 //totalCartCount
  );


  //Login
  public _loginURL = environment.API_URL + "account/connect"
  public _registrationURL = environment.API_URL + "account/register-customer"
  public _forgotPassword = environment.API_URL + "account/reset-password-request";
  private _resetpassword =environment.API_URL+"account/reset-password";

  public _sendemaillink = environment.API_URL + "account/send-email-link";
  public _subscribeemail = environment.API_URL + "account/subscribe-email";

  //change Password
  private ChangePassword = environment.API_URL + "account/password-change";

  //UserProfile
  public _GetUserProfile = environment.API_URL + "master/customer/get-profile"

  //UserUpdateProfile
  public _UserprofileUpdate = environment.API_URL + "master/customer/update-customer"

  //UserUploadProfile
  public _UserprofileUpload = environment.API_URL +"master/customer/photo-upload"

  //UserUploadProfile
  public _UserDriverDetails = environment.API_URL +"master/customer/update-customer-car-info"


  //UserWalletBal
  public _GetUserWalletBal = environment.API_URL + "trans/ewallet/get-balance"

  //UserAddress save & update
  public _AddUserAddress = environment.API_URL + "master/customer/save-customeraddress";

  //UserAddress Remove
  public _RemoveUserAddress = environment.API_URL + "master/customer/remove-customeraddress/";

  //Get favourite
  public Get_Favourite =  environment.API_URL + "master/customer/get-Favoritevendor/"

  //favourite
  public _Favourite =  environment.API_URL + "master/customer/save-favouritevendor"

  //Remove favourite
  public Remove_Favourite =  environment.API_URL + "master/customer/remove-favouritevendor/"

   //Get Partner
   public Get_Partner =  environment.API_URL + "master/customer/get-partnervendor/"

   //Save Partner
   public _Partner =  environment.API_URL + "master/customer/save-partnervendor"
 
   //Remove Partner
   public Remove_Partner =  environment.API_URL + "master/customer/remove-partnervendor/"
 

  //Delivery Mode
  public _Delivery_Mode =  environment.API_URL + "account/get-deliverytype"

  //AllLocation without token
  public _AllLocation = environment.API_URL + "general/get-geo-vendor"

  //Summary without token
  public _SummaryCount = environment.API_URL + "general/get-Summary-count"

  //TopCategory without token
  public _TopCategory = environment.API_URL + "general/get-top-category"

  //Categorylist
  public _Category_List = environment.API_URL + "master/category/get-categorylist";

  //vendorMenuList
  private _Vendor_Menu_Category_List = environment.API_URL + "master/category/get-categorylist/";

  //menu
  private Menu_List_All = environment.API_URL + "master/menu/get-menulist/";
  private Menu_List_By_Category = environment.API_URL + "master/menu/get-by-category/";

  //Meal Plan
  private Get_Meal_Plan = environment.API_URL + "master/mealplan/get-mealplan/";
  private Save_Meal_Plan =environment.API_URL + "trans/mealplan/save-mealplan"
  private Save_Meal_Plan_Address =environment.API_URL + "trans/mealplan/save-mealplan-address"
  private getVendorMealPlanOrderInfo = environment.API_URL + "trans/mealplan/get-mealplan-byid/";
  private getVendorOrderTypeMealplanInfo = environment.API_URL + "trans/orders/get-mealorderinfo/";
  private getVendorOrderTypeSubcriptionplanInfo = environment.API_URL + "trans/orders/get-schorderinfo/";


  //VendorType
  private getVendor_Type = environment.API_URL + "master/vendor/get-vendortype";
 
  //VendorInfo
  private getVendor_Info = environment.API_URL + "master/vendor/get-vendorinfo/";

  //VendorType
  private getVendor_Type_without_Token = environment.API_URL + "account/get-vendortype";


  //Vendor
  private getVendorList = environment.API_URL + "master/vendor/query-vendor";

  //Vendor Without Token
  private getVendorList_without_token = environment.API_URL + "master/vendor/query-vendor-withoutkey";


  //submenuitems
  private SubMenu_Item = environment.API_URL + "master/menu/get-menu-subitems/";



  //order save Header
  private Save_Order_Header = environment.API_URL + "trans/orders/save-order";

  //Save Order Items
  private Save_Order_Items = environment.API_URL + "trans/orders/save-order-detail";

  //Save Order Address 
  private Save_Order_Address = environment.API_URL + "trans/orders/save-order-address";

  //Save Order Payment 
  private Save_Order_Payment = environment.API_URL + "trans/orderpayment/save-order-payment";

  //Save subcription Order  
  private Save_Subcription_Order = environment.API_URL + "trans/subscription/save-subscription-order";

  //Save subcription Order Details 
  private Save_Subcription_details_Order = environment.API_URL + "trans/subscription/save-subscriptionorder-detail";

  //save Subcription Address
  private Save_Subcription_Order_Address = environment.API_URL + "trans/subscription/save-subscription-address";


  //UserorderList
  private Order_List = environment.API_URL + "trans/orders/get-orders-bycustomer";

  //UserMealplanorderList
  private Mealplan_Order_List = environment.API_URL + "trans/mealplan/get-mealplan-bycustomer";


  //UserScheduleorderList
  private Schedule_Order_List = environment.API_URL + "trans/subscription/get-orders-bycustomer";

  //VendorScheduleOrder
  private getVendorScheduleOrderInfo = environment.API_URL + "trans/subscription/get-orderinfo/";



  //UserOrderInfo
  private Order_Info = environment.API_URL + "trans/orders/get-orderinfo/";

  //GeoCityList
  private Geo_City_List = environment.API_URL + "master/geo/get-city-list";

  //OrderVendorReview
  private Save_Vendor_Review = environment.API_URL + "trans/vendorreview/save-review";

  //ViewVendorReview
  private View_Vendor_Review = environment.API_URL + "trans/vendorreview/get-review-byvendor/";

  //CustomerOrderReview
  private Customer_order_Review = environment.API_URL + "trans/vendorreview/get-review-all-bycustomer/";


  //Cancel Order
  private Cancel_Order = environment.API_URL + "trans/orders/cancel-order/";

  //Ewallet Order
  private Ewallet_Order = environment.API_URL + "trans/ewallet/ewallet-invoice";

  
  //Ewallet Statement
  private Ewallet_Ledger = environment.API_URL + "trans/ewallet/get-Ledger";


  //get-testimonials
  private Get_Testimonials = environment.API_URL + "trans/testimonials/get-testimonials";







  constructor(
    public http: HttpClient,    // private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    // public dialog: MatDialog,
    // public appSettings: AppSettings,
    // public translateService: TranslateService,
    // private authenticationService: AuthService,
  ) { }

  showNotification(colorName: any, text: any, placementFrom: any, placementAlign: any) {
    //console.log(colorName)
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }


  login(username: string, password: string) {
    return this.http
      .post<any>(this._loginURL, {
        username,
        password,
      })
      .pipe(
        map((Customer) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (Customer.token !== null) {
            localStorage.setItem('User', JSON.stringify(Customer));
            localStorage.setItem('token', Customer.token);
            return Customer;
          }
          else {
            return Customer;
          }
        })
      );
  }


  Registration(Customer: any) {
    return this.http.post(this._registrationURL, Customer);
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('User');
    localStorage.removeItem('name');
    localStorage.removeItem('role');
    //this.currentUserSubject.next(null);
    return of({ success: false });
  }


  getToken() {
    return localStorage.getItem('token')
  }

  public createAuthorizationHeader(_HttpHeaders: HttpHeaders) {
    _HttpHeaders.set('Authorization', 'Bearer ' + this.getToken);
  }

  loggedIn() {
    return !!localStorage.getItem('token')
  }


  forgotPassword(data:any){
    return this.http.post(this._forgotPassword, data);
  }

  
  sendemaillink(data:any){
    return this.http.post(this._sendemaillink, data);
  }

  subscribeemail(data:any){
    return this.http.post(this._subscribeemail, data);
  }

  resetPassword(data:any){
    return this.http.post(this._resetpassword,data)
  }

  //Get User Profile
  getUserProfile() {
    return this.http.get(this._GetUserProfile, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //UserProfileUpdtae
  UserUpadteProfile(Customer: any) {
    return this.http.post(this._UserprofileUpdate, Customer, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

   //UserProfileUpload
   UserUploadProfile(Customer: any) {
    return this.http.post(this._UserprofileUpload, Customer, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //SaveUserCarInfo
  UserCarInfo(Customer: any) {
    return this.http.post(this._UserDriverDetails, Customer, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //changepassword
  passwordchange(data: any) {
    return this.http.post(this.ChangePassword, data, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }



  getUserWalletBAl(dat:any){
    console.log(dat)
    return this.http.post(this._GetUserWalletBal, dat, { headers: { Authorization: 'Bearer ' + this.getToken() } })

  }

  updateUserAddress(address: any) {
    return this.http.post(this._AddUserAddress, address, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  RemoveAddress(id:number){
    return this.http.post(this._RemoveUserAddress+ id, '', { headers: { Authorization: 'Bearer ' + this.getToken() } })

  }


  //All Location without token
  getGeoVendor(): Observable<GeoVendorLocation[]> {
    return this.http.get<GeoVendorLocation[]>(this._AllLocation);
  }

  //Summary without token
  getSummaryCount(): Observable<SummaryCount[]> {
    return this.http.get<SummaryCount[]>(this._SummaryCount);
  }

  //Top Category without token
  getTopCategory(): Observable<TopCategory[]> {
    return this.http.get<TopCategory[]>(this._TopCategory);
  }

  //Categorylist
  public getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(this._Category_List, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //vendorMenuList
  public getCategoriesMenuList(id: number): Observable<Category[]> {
    return this.http.get<Category[]>(this._Vendor_Menu_Category_List + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //All Menu List
  public getMenuItems(id: number): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.Menu_List_All + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //Menu List By Category
  public getMenuItemByCategoryId(id: number): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.Menu_List_By_Category + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //Meal Plan
  public getMealPlan(id: number): Observable<any[]> {
    return this.http.get<any[]>(this.Get_Meal_Plan + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //VendororderMealorder Info
  public getMealOrderInfo(id: number) {
    return this.http.get(this.getVendorMealPlanOrderInfo + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });

  }

   //getVendorOrderMelaplanTypeInfo
   public getVendorMealOrdersTypeInfo(ord:any) {
    return this.http.get<any[]>(this.getVendorOrderTypeMealplanInfo+ord, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //getVendorOrderSubcriptionOrdersTypeInfo
  public getVendorSubcriptionOrdersTypeInfo(ord:any) {
    return this.http.get<any[]>(this.getVendorOrderTypeSubcriptionplanInfo+ord, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //GetVendorType
  public getVendorType(): Observable<any[]> {
    return this.http.get<any[]>(this.getVendor_Type, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //GetVendorType without Token
  public getVendorTypeWithoutToke(): Observable<any[]> {
    return this.http.get<any[]>(this.getVendor_Type_without_Token, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //GetVendorInfo
  public getVendorInfo(id:number){
    return this.http.get(this.getVendor_Info + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });

  }

  //AllVendor
  public getVendor(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.getVendorList, data, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //AllVendor Without token
  public getVendorWithoutToken(data: any): Observable<any[]> {
    return this.http.post<any[]>(this.getVendorList_without_token, data, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //GetsubItems
  public getSubMenuItem(id: number): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(this.SubMenu_Item + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }


  //GeoCityList
  public getGeoCityList() {
    return this.http.get(this.Geo_City_List, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //Delivery MOde
  public deliveryType(){
    return this.http.get(this._Delivery_Mode, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  //GetFavourite
  public GetFavourite(fav:number){
    return this.http.get(this.Get_Favourite+fav,  { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  //Favourite
  public myFavourite(fav:any){
    return this.http.post(this._Favourite, fav, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

   //Remove Favourite
   public myRemoveFavourite(id:number){
    return this.http.post(this.Remove_Favourite+id, '', { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

   //GetPartner
   public GetPartner(par:number){
    return this.http.get(this.Get_Partner+par,  { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  //Favourite
  public SavePartner(par:any){
    return this.http.post(this._Partner, par, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

   //Remove Favourite
   public myRemovePartner(id:number){
    return this.http.post(this.Remove_Partner+id, '', { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }


  //order save Header
  public saveOrderHeader(order: any) {
    return this.http.post(this.Save_Order_Header, order, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }


  //order save Details
  public saveOrderDetails(order: any) {
    return this.http.post(this.Save_Order_Items, order, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }


  //order save address
  public saveOrderAddress(order: any) {
    return this.http.post(this.Save_Order_Address, order, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }


  //order save Payment
  public saveOrderPayment(order: any) {
    return this.http.post(this.Save_Order_Payment, order, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  //order subcription save 
  public saveOrderSubcription(order: any) {
    return this.http.post(this.Save_Subcription_Order, order, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  //Detislorder subcription save 
  public saveDetailsOrderSubcription(order: any) {
    return this.http.post(this.Save_Subcription_details_Order, order, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  //Order Save Subcription
  public SaveSubcriptionOrderAddress(order:any){
    return this.http.post(this.Save_Subcription_Order_Address, order, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }
  

  //Order Save Meal
  public SaveMealOrder(order:any){
    return this.http.post(this.Save_Meal_Plan, order, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  //Order Save Meal
  public SaveMealOrderAddress(order:any){
    return this.http.post(this.Save_Meal_Plan_Address, order, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  //order List
  public getOrderList() {
    return this.http.get(this.Order_List, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

   //Meal plan order List
   public getMealPlanOrderList() {
    return this.http.get(this.Mealplan_Order_List, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

   //Schedule order List
   public getScheduleOrderList() {
    return this.http.get(this.Schedule_Order_List, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //VendororderScheduleInfo
  public getScheduleOrderInfo(id: number) {
    return this.http.get(this.getVendorScheduleOrderInfo + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });

  }

  //order Info
  public getOrderInfo(id: number) {
    return this.http.get(this.Order_Info + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }
  

  //Save Vendor Review
  public saveVendorReview(review: any) {
    return this.http.post(this.Save_Vendor_Review, review, { headers: { Authorization: 'Bearer ' + this.getToken() } })
  }

  //View Vendor Review
  public getVendorReview(id: number) {
    return this.http.get(this.View_Vendor_Review + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //Customer Order Review
  public getCustomerOrderReview(id:number){
    return this.http.get(this.Customer_order_Review + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });

  }

  //Cancel Order
  public UserOrderCancel(id: number) {
    return this.http.post(this.Cancel_Order + id, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //Ewallet Order
  public EWallletOrder(wallet: any) {
    return this.http.post(this.Ewallet_Order, wallet, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //Ewallet Ledger
  public EWallletLedger(leg: any) {
    return this.http.post(this.Ewallet_Ledger, leg, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }

  //getTestimonials
  public getTestimonials(){
    return this.http.get(this.Get_Testimonials, { headers: { Authorization: 'Bearer ' + this.getToken() } });
  }



}
