<div class="sub-header align-center">
    <div class="subheader-holder" style=" background:url(assets/images/subheader-img2-1.jpg)  no-repeat ; background-size: cover; padding-top: 60px; padding-bottom: 30px; margin-top: 0px; margin-bottom: 0px; min-height: 186px !important;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="text-holder">
                        <div class="page-title ">
                            <h1 style="color:#ffffff !important;">Search Results : <span>Food</span>
                            </h1>
                        </div>
                        <p style="color:#ffffff !important;">A great restaurant website</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumbs align-left">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li class="active">Enter your keyword</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-section">
    <!-- Enter Your Keyword Page Start -->
    <div class="page-section nopadding cs-nomargin">
        <div class="container">
            <div class="row">
                <div class="section-fullwidth col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="page-content col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div class="blog blog-medium">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/"><img src="assets/images/blogs-food-bakery-02-180x135.jpg" alt="#"></a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail">Creating the world’s greatest food community with food bakery</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <a routerLink="/">Restaurant Marketing</a>
                                                        <span><a routerLink="/"> October 28, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <p> One of the best cities in India to experience a variety of dishes is apni Dilli aka Delhi. In this article, we take you through</p>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/"><img src="assets/images/blogs-food-bakery-07-180x135.jpg" alt="#"></a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail">Take away fans select celeb winners of Food Bakery</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <a routerLink="/">Cake delivery</a>
                                                        <span><a routerLink="/"> October 28, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <p> One of the best cities in India to experience a variety of dishes is apni Dilli aka Delhi. In this article, we take you through</p>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/"><img src="assets/images/blogs-food-bakery-08-180x135.jpg" alt="#"></a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail">Food Bakery strengthens Italian business with multi acquisition</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <a routerLink="/">malaysian food</a>
                                                        <span><a routerLink="/"> October 28, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <p> One of the best cities in India to experience a variety of dishes is apni Dilli aka Delhi. In this article, we take you through</p>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/"><img src="assets/images/blogs-food-bakery-10-180x135.jpg" alt="#"></a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail">Completion of Food Bakery for Placing and Open Offer</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <a routerLink="/">Pulled Pork Burger</a>
                                                        <span><a routerLink="/"> October 28, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <p> One of the best cities in India to experience a variety of dishes is apni Dilli aka Delhi. In this article, we take you through</p>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/"><img src="assets/images/blogs-food-bakery-12-180x135.jpg" alt="#"></a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail">We all love chinese food and dim sum achinese</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <a routerLink="/">Chicken with Green Vegetables</a>
                                                        <span><a routerLink="/"> October 28, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <p> One of the best cities in India to experience a variety of dishes is apni Dilli aka Delhi. In this article, we take you through</p>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/"><img src="assets/images/blogs-food-bakery-15-180x135.jpg" alt="#"></a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail">Food Is always of Britain’s most influential people</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <a routerLink="/">Delicious food</a>
                                                        <span><a routerLink="/"> July 28, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <p> One of the best cities in India to experience a variety of dishes is apni Dilli aka Delhi. In this article, we take you through</p>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/"><img src="assets/images/blogs-food-bakery-07-180x135.jpg" alt="#"></a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail">innovative collection technology with the foodbakery of Orogo</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <a routerLink="/">Spicy Food</a>
                                                        <span><a routerLink="/"> June 28, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <p> One of the best cities in India to experience a variety of dishes is apni Dilli aka Delhi. In this article, we take you through</p>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/"><img src="assets/images/blogs-food-bakery-01-180x135.jpg" alt="#"></a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail">Surge Fee And Food Delivery Everything</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <a routerLink="/">Restaurant Marketing</a>
                                                        <span><a routerLink="/"> March 28, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <p> One of the best cities in India to experience a variety of dishes is apni Dilli aka Delhi. In this article, we take you through</p>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail">Register User and Add Restaurant</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <span><a routerLink="/"> December 5, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="blog-post">
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h4><a routerLink="/blog-detail" title="Author">Author</a></h4>
                                                </div>
                                                <ul class="post-options">
                                                    <li>
                                                        <span><a routerLink="/"> November 30, 2016 </a>
                      </span>
                                                    </li>
                                                </ul>
                                                <a routerLink="/blog-detail" class="read-more text-color">Learn more<i class="icon-arrow-right22"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="page-sidebar right col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div class="widget-holder">
                                <div class="widget widget_archive">
                                    <div class="widget-title">
                                        <h5>archive dropdown</h5>
                                    </div>
                                    <label class="screen-reader-text">archive dropdown</label>
                                    <select>
              <option>Select Month</option>
              <option> October 2016 </option>
              <option> July 2016 </option>
              <option> June 2016 </option>
              <option> May 2016 </option>
              <option> April 2016 </option>
              <option> March 2016 </option>
              <option> February 2016 </option>
            </select>
                                </div>
                                <div class="widget widget_search">
                                    <div class="widget-title">
                                        <h5>Search</h5>
                                    </div>
                                    <form class="form-inline">
                                        <fieldset>
                                            <div class="input-group">
                                                <input type="text" class="form-control" placeholder="Search">
                                                <span class="input-group-btn">
                    <button type="submit" class="btn btn-default"><i class="icon-search"></i></button>
                  </span>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                                <div class="widget widget-related-post">
                                    <div class="widget widget-recent-blog-post">
                                        <div class="widget-title">
                                            <h5>Popular Posts</h5>
                                        </div>
                                        <ul>
                                            <li>
                                                <div class="img-holder">
                                                    <figure>
                                                        <a routerLink="/"><img src="assets/images/blogs-food-bakery-02-150x150.jpg" alt="#"></a>
                                                    </figure>
                                                </div>
                                                <div class="text-holder">
                                                    <div class="post-title">
                                                        <h6><a routerLink="/blog-detail">Creating the world’s greatest food community with food ...</a></h6>
                                                    </div>
                                                    <div class="post-options">
                                                        <span><i class=" icon-clock4"></i> October 28,
                        2016</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="img-holder">
                                                    <figure>
                                                        <a routerLink="/"><img src="assets/images/blogs-food-bakery-17-150x150.jpg" alt="#"></a>
                                                    </figure>
                                                </div>
                                                <div class="text-holder">
                                                    <div class="post-title">
                                                        <h6><a routerLink="/blog-detail">half year technology to update customers and restaurants</a></h6>
                                                    </div>
                                                    <div class="post-options">
                                                        <span><i class=" icon-clock4"></i> October 28,
                        2016</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="img-holder">
                                                    <figure>
                                                        <a routerLink="/"><img src="assets/images/blogs-food-bakery-07-150x150.jpg" alt="#"></a>
                                                    </figure>
                                                </div>
                                                <div class="text-holder">
                                                    <div class="post-title">
                                                        <h6><a routerLink="/blog-detail">Take away fans select celeb winners of Food...</a></h6>
                                                    </div>
                                                    <div class="post-options">
                                                        <span><i class=" icon-clock4"></i> October 28,
                        2016</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="img-holder">
                                                    <figure>
                                                        <a routerLink="/"><img src="assets/images/blogs-food-bakery-06-150x150.jpg" alt="#"></a>
                                                    </figure>
                                                </div>
                                                <div class="text-holder">
                                                    <div class="post-title">
                                                        <h6><a routerLink="/blog-detail">United Kingdom Fire Brigade launch have take away ...</a></h6>
                                                    </div>
                                                    <div class="post-options">
                                                        <span><i class=" icon-clock4"></i> October 28, 2016</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="widget widget_calendar">
                                    <div class="widget-title">
                                        <h5>Archives</h5>
                                    </div>
                                    <div id="calendar_wrap" class="calendar_wrap"></div>
                                </div>
                                <div class="widget widget_categories">
                                    <div class="widget-title">
                                        <h5>Categories</h5>
                                    </div>
                                    <ul>
                                        <li class="cat-item cat-item-22">
                                            <a routerLink="/">Cake delivery</a>(1)
                                        </li>
                                        <li class="cat-item cat-item-11">
                                            <a routerLink="/">Chicken with Green Vegetables</a>(2)
                                        </li>
                                        <li class="cat-item cat-item-18">
                                            <a routerLink="/">Delicious food</a>(1)
                                        </li>
                                        <li class="cat-item cat-item-21">
                                            <a routerLink="/">Halal food</a>(1)
                                        </li>
                                        <li class="cat-item cat-item-24">
                                            <a routerLink="/">malaysian food</a>(1)
                                        </li>
                                        <li class="cat-item cat-item-12">
                                            <a routerLink="/">Pulled Pork Burger</a>(2)
                                        </li>
                                        <li class="cat-item cat-item-4">
                                            <a routerLink="/">Restaurant Marketing</a>(5)
                                        </li>
                                        <li class="cat-item cat-item-15">
                                            <a routerLink="/">Spicy Food</a>(1)
                                        </li>
                                        <li class="cat-item cat-item-13">
                                            <a routerLink="/">Thai Home Crispy Beef</a>(2)
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget widget-gallery">
                                    <div class="widget-title">
                                        <h5>Photo Gallery</h5>
                                    </div>
                                    <ul>
                                        <li>
                                            <div class="cs-media">
                                                <figure>
                                                    <img alt="#" src="assets/images/15647274066_2ee48c3fe9_s.jpg">
                                                    <figcaption class="cs-bgcolor">
                                                        <a target="_blank" routerLink="/"></a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="cs-media">
                                                <figure>
                                                    <img alt="#" src="assets/images/15485436268_846ccca178_s.jpg">
                                                    <figcaption class="cs-bgcolor">
                                                        <a target="_blank" routerLink="/"></a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="cs-media">
                                                <figure>
                                                    <img alt="#" src="assets/images/15668911091_4ef20118b5_s.jpg">
                                                    <figcaption class="cs-bgcolor">
                                                        <a target="_blank" routerLink="/"></a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="cs-media">
                                                <figure>
                                                    <img alt="#" src="assets/images/15484954949_a4e97a9dc5_s.jpg">
                                                </figure>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget widget_tag_cloud">
                                    <div class="widget-title">
                                        <h5>Tags Cloud</h5>
                                    </div>
                                    <div class="tagcloud">
                                        <a routerLink="/" class="tag-link-10 tag-link-position-1">B.B.Q.</a>
                                        <a routerLink="/" class="tag-link-17 tag-link-position-2">Bendo Delight</a>
                                        <a routerLink="/" class="tag-link-19 tag-link-position-3">Curry Udon</a>
                                        <a routerLink="/" class="tag-link-16 tag-link-position-4">Donburi</a>
                                        <a routerLink="/" class="tag-link-8 tag-link-position-5">Fried Rice</a>
                                        <a routerLink="/" class="tag-link-5 tag-link-position-6">Green mango salad</a>
                                        <a routerLink="/" class="tag-link-6 tag-link-position-7">Miso Soup</a>
                                        <a routerLink="/" class="tag-link-7 tag-link-position-8">Roll</a>
                                        <a routerLink="/" class="tag-link-9 tag-link-position-9">Vegetable Udon</a>
                                    </div>
                                </div>
                                <div class="widget widget_text">
                                    <div class="widget-title">
                                        <h5>Food Bakery In Your Pocket!</h5>
                                    </div>
                                    <div class="textwidget">
                                        <span>Available on Google play for android &amp; on the App store for iOS. Download the app and drive hunger far, far away</span>
                                        <figure>
                                            <a routerLink="/"><img src="assets/images/app-img2-1.png" alt="#"></a>
                                        </figure>
                                        <figure>
                                            <a routerLink="/"><img src="assets/images/app-img1-1.png" alt="#"></a>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Enter Your Keyword Page End -->
</div>

