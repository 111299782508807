import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}

  home(event: any) {
    console.log(event);
    if (event === 'home') {
      this.router.navigate(['/']);
    } else if (event === 'about') {
      this.router.navigate(['/about-us']);
    } else if (event === 'contact') {
      this.router.navigate(['/contact-us']);
    }
  }

  redirt() {
    console.log('a');
  }
}
