import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-buyer-sidebar',
  templateUrl: './buyer-sidebar.component.html',
  styleUrls: ['./buyer-sidebar.component.css']
})
export class BuyerSidebarComponent implements OnInit {

  constructor(public appservice:AppService,public router:Router) { }

  ngOnInit(): void {
  }

  logout() {
    this.appservice.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/']);
      }
    });
  }

}
