<div class="main-section">
  <div
    class="page-section restaurant-detail-image-section"
    style="
      background: url(assets/images/cover-photo01.jpg) no-repeat scroll 0 0 /
        cover;
    "
  >
    <!-- Container Start -->
    <div class="container">
      <!-- Row Start -->
      <div class="row">
        <!-- Column Start -->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="company-info-detail">
            <div class="company-info">
              <div class="img-holder">
                <figure>
                  <img src="assets/images/listing-logo18.png" alt="" />
                </figure>
              </div>

              <div class="text-holder">
                <span class="restaurant-title">Restaurant Demo</span>
                <div class="text">
                  <i class="icon-local_pizza"></i>
                  <p>Apple Juice</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Column End -->
      </div>
      <!-- Row End -->
    </div>
    <!-- Container End -->
  </div>

  <div class="page-section account-header buyer-logged-in">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <div class="user-account-nav user-account-sidebar">
            <div class="user-nav-list">
              <ul>
                <li class="active">
                  <a routerLink="/restaurant-dashboard"
                    ><i class="icon-dashboard3"></i>Dashboard</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-restaurant"
                    ><i class="icon-building"></i>My Restaurant</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-menu-builder"
                    ><i class="icon-menu5"></i>Menu Builder</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-orders"
                    ><i class="icon-add_shopping_cart"></i>Orders</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-bookings"
                    ><i class="icon-file-text2"></i>Bookings</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-reviews"
                    ><i class="icon-comment2"></i>Reviews</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-memberships"
                    ><i class="icon-card_membership"></i>Memberships</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-withdrawals"
                    ><i class="icon-bill"></i>Withdrawals</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-earnings"
                    ><i class="icon-money"></i>Earnings</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-statement"
                    ><i class="icon-file-text22"></i>Statement</a
                  >
                </li>
                <li>
                  <a routerLink="/restaurant-team-management"
                    ><i class="icon-flow-tree"></i>Team Management</a
                  >
                </li>
                <li>
                  <a href="restaurant-change-password"
                    ><i class="icon-unlock-alt"></i>Change Password</a
                  >
                </li>
                <li>
                  <a id="profile_delete" href="/#"
                    ><i class="icon-delete"></i>Delete Profile</a
                  >
                </li>
                <li>
                  <a class="logout-btn" routerLink="/registration"
                    ><i class="icon-log-out"></i>Signout</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div class="user-dashboard loader-holder">
            <div class="user-holder">
              <div class="user-message" style="background-color: #1e73be">
                <a class="close" href="/#"><i class="icon-cross-out"></i></a>
                <h2>Welcome to your Restaurant.</h2>
                <p>
                  Restaurant Dashboard gives you quick access to settings and
                  tools for managing your Account like [Change address] and
                  [Change password] . You can [manage Restaurant] Build Menu ,
                  Manage Orders, Bookings, Reviews, Memberships, Withdrawals,
                  Earnings, Statements, Change Password, Location and if you are
                  you Restaurant Owner can also [Manage Team].
                </p>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="row">
                  <div class="element-title has-border right-filters-row">
                    <h5>Recent Orders</h5>
                    <div class="right-filters row pull-right">
                      <div class="col-lg-6 col-md-6 col-xs-6">
                        <div class="input-field">
                          <select class="chosen-select-no-single">
                            <option selected="selected" value="">
                              Select Orders Status
                            </option>
                            <option value="Processing">Processing</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Completed">Completed</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-6">
                        <div class="input-field">
                          <i class="icon-angle-down"></i>
                          <input
                            type="text"
                            data-id="daterange223"
                            id="daterange"
                            value=""
                            placeholder="Select Date Range"
                          />
                          <script>
                            $(function () {
                              $(
                                'input[data-id="daterange223"]'
                              ).daterangepicker(
                                {
                                  opens: "left",
                                },
                                function (start, end, label) {
                                  console.log(
                                    "A new date selection was made: " +
                                      start.format("YYYY-MM-DD") +
                                      " to " +
                                      end.format("YYYY-MM-DD")
                                  );
                                }
                              );
                            });
                          </script>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="user-orders-list">
                    <div class="responsive-table">
                      <ul class="table-generic">
                        <li class="order-heading-titles">
                          <div>Order id</div>
                          <div>Date</div>
                          <div>Total Price</div>
                          <div>Charges</div>
                          <div>Received</div>
                          <div>Status</div>
                          <div>Detail</div>
                        </li>
                        <li class="order-heading-titles">
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              >order-22606</a
                            >
                          </div>
                          <div>Apr 9,2019</div>
                          <div>£ 38.99</div>
                          <div>£ 3.90</div>
                          <div>£ 35.09</div>
                          <div>
                            <span
                              class="order-status"
                              style="background-color: #047a06"
                              >Completed</span
                            >
                          </div>
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              ><i class="icon-plus2 text-color"></i
                            ></a>
                          </div>
                        </li>
                        <li class="order-heading-titles">
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              >order-22594</a
                            >
                          </div>
                          <div>Apr 9,2019</div>
                          <div>£ 37.86</div>
                          <div>£ 3.79</div>
                          <div>£ 34.07</div>
                          <div>
                            <span
                              class="order-status"
                              style="background-color: #1e73be"
                              >Processing</span
                            >
                          </div>
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              ><i class="icon-plus2 text-color"></i
                            ></a>
                          </div>
                        </li>
                        <li class="order-heading-titles">
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              >order-22589</a
                            >
                          </div>
                          <div>Apr 9,2019</div>
                          <div>£ 26.22</div>
                          <div>£ 2.62</div>
                          <div>£ 23.60</div>
                          <div>
                            <span
                              class="order-status"
                              style="background-color: #047a06"
                              >Completed</span
                            >
                          </div>
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              ><i class="icon-plus2 text-color"></i
                            ></a>
                          </div>
                        </li>
                        <li class="order-heading-titles">
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              >order-22586</a
                            >
                          </div>
                          <div>Apr 9,2019</div>
                          <div>£ 26.22</div>
                          <div>£ 2.62</div>
                          <div>£ 23.60</div>
                          <div>
                            <span
                              class="order-status"
                              style="background-color: #047a06"
                              >Completed</span
                            >
                          </div>
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              ><i class="icon-plus2 text-color"></i
                            ></a>
                          </div>
                        </li>
                        <li class="order-heading-titles">
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              >order-22583</a
                            >
                          </div>
                          <div>Apr 9,2019</div>
                          <div>£ 26.22</div>
                          <div>£ 2.62</div>
                          <div>£ 23.60</div>
                          <div>
                            <span
                              class="order-status"
                              style="background-color: #1e73be"
                              >Processing</span
                            >
                          </div>
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              ><i class="icon-plus2 text-color"></i
                            ></a>
                          </div>
                        </li>
                        <li class="order-heading-titles">
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              >order-22493</a
                            >
                          </div>
                          <div>Apr 2,2019</div>
                          <div>£ 28.24</div>
                          <div>£ 2.82</div>
                          <div>£ 25.42</div>
                          <div>
                            <span
                              class="order-status"
                              style="background-color: #047a06"
                              >Completed</span
                            >
                          </div>
                          <div>
                            <a
                              href="/#"
                              data-toggle="modal"
                              data-target="#order-det-22606"
                              ><i class="icon-plus2 text-color"></i
                            ></a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="print-order-detail menu-order-detail order-detail"
                      style="display: none"
                    >
                      <div class="logo">
                        <img src="assets/images/main-logo.png" alt="" />
                      </div>
                      <h2>Order Detail</h2>
                      <div class="order-detail-inner">
                        <div class="description-holder">
                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div class="list-detail-options has-checkbox">
                                <h3>Restaurant Demo</h3>
                                <ul class="order-detail-options">
                                  <li class="order-number">
                                    <strong>Order ID:</strong>
                                    <span>22606</span>
                                  </li>
                                  <li class="req-delivery">
                                    <strong>Delivery Time:</strong>
                                    <span>10 Minutes </span>
                                  </li>
                                  <li class="created-date">
                                    <strong>Delivery Date:</strong>
                                    <span>Apr 9, 2019 06:38 AM </span>
                                  </li>
                                  <li class="order-type">
                                    <strong>Type:</strong>
                                    <span>order</span>
                                  </li>
                                  <li class="order-type">
                                    <strong>Payment Status:</strong>
                                    <span>Approved</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                              <div class="customer-detail-holder">
                                <h3>Customer Detail</h3>
                                <ul class="customer-detail">
                                  <li>
                                    <strong>Name :</strong>
                                    <span>Buyer Demo</span>
                                  </li>
                                  <li>
                                    <strong>Phone Number :</strong>
                                    <span>0123456789</span>
                                  </li>
                                  <li>
                                    <strong>Email :</strong>
                                    <span>dum4@chimpgroup.com</span>
                                  </li>
                                  <li>
                                    <strong>Address :</strong>
                                    <span>London</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="row">
                              <div
                                class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                              >
                                <div class="order-status-holder">
                                  <h3>Order Status:</h3>
                                  <div
                                    class="order-status-process order-status"
                                  >
                                    <p>Completed</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            >
                              <h2 class="heading">Food Menu</h2>
                              <div class="responsive-table">
                                <ul class="categories-order table-generic">
                                  <li class="order-heading-titles">
                                    <div>Products</div>
                                    <div>Price per</div>
                                  </li>
                                  <li class="order-heading-titles">
                                    <div>
                                      <h4>Pizzas</h4>
                                      <h5>Foodbakery Special 9" Deep Pan</h5>
                                    </div>
                                    <div>
                                      <span class="category-price">£4.80</span>
                                    </div>
                                  </li>
                                  <li class="order-heading-titles">
                                    <div>
                                      <h4>Pizzas</h4>
                                      <h5>Foodbakery Special 9" Deep Pan</h5>
                                    </div>
                                    <div>
                                      <span class="category-price">£4.80</span>
                                    </div>
                                  </li>
                                  <li class="order-heading-titles">
                                    <div>
                                      <h4>Pizzas</h4>
                                      <h5>Foodbakery Special 12" Deep Pan</h5>
                                    </div>
                                    <div>
                                      <span class="category-price">£3.90</span>
                                    </div>
                                  </li>
                                  <li class="order-heading-titles">
                                    <div>
                                      <h4>Garlic Bread</h4>
                                      <h5>Garlic Bread 12" Deep</h5>
                                    </div>
                                    <div>
                                      <span class="category-price">£3.50</span>
                                    </div>
                                  </li>
                                  <li class="order-heading-titles">
                                    <div>
                                      <h4>Kebabs</h4>
                                      <h5>Kebabs With Naan</h5>
                                    </div>
                                    <div>
                                      <span class="category-price">£4.50</span>
                                    </div>
                                  </li>
                                  <li class="order-heading-titles">
                                    <div>
                                      <h4>Burgers</h4>
                                      <h5>Quarter Pounder</h5>
                                    </div>
                                    <div>
                                      <span class="category-price">£3.00</span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            >
                              <div class="row">
                                <div
                                  class="col-lg-9 col-md-9 col-sm-9 col-xs-12"
                                >
                                  <h3>Order Total</h3>
                                </div>
                                <div
                                  class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                >
                                  <ul class="order-detail-options order-total">
                                    <li class="created-date">
                                      <strong>Subtotal:</strong>
                                      <span>£24.50</span>
                                    </li>
                                    <li class="order-type">
                                      <strong> Pick Up Fee: </strong>
                                      <span>£10.00</span>
                                    </li>
                                    <li class="order-type">
                                      <strong>VAT (13%)</strong>
                                      <span>£4.48</span>
                                    </li>
                                    <li class="order-type total-price">
                                      <strong>Total:</strong>
                                      <span>£38.99</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="modal fade menu-order-detail order-detail"
                      id="order-det-22606"
                      tabindex="-1"
                      role="dialog"
                      style="display: none"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                            <h2>Order Detail</h2>
                            <button class="btn-print">
                              <i class="icon-printer"></i><span>Receipt</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <div class="order-detail-inner">
                              <div class="description-holder">
                                <div class="row">
                                  <div
                                    class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                  >
                                    <div
                                      class="list-detail-options has-checkbox"
                                    >
                                      <h3>Restaurant Demo</h3>
                                      <ul class="order-detail-options">
                                        <li class="order-number">
                                          <strong>Order ID:</strong>
                                          <span>22606</span>
                                        </li>
                                        <li class="req-delivery">
                                          <strong>Delivery Time:</strong>
                                          <span>10 Minutes </span>
                                        </li>
                                        <li class="created-date">
                                          <strong>Delivery Date:</strong>
                                          <span> Apr 9, 2019 06:38 AM </span>
                                        </li>
                                        <li class="order-type">
                                          <strong>Type:</strong>
                                          <span>order</span>
                                        </li>
                                        <li class="order-type">
                                          <strong>Payment Status:</strong>
                                          <span>Approved</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                  >
                                    <div class="customer-detail-holder">
                                      <h3>Customer Detail</h3>
                                      <ul class="customer-detail">
                                        <li>
                                          <strong>Name :</strong>
                                          <span>Buyer Demo</span>
                                        </li>
                                        <li>
                                          <strong>Phone Number :</strong>
                                          <span>0123456789</span>
                                        </li>
                                        <li>
                                          <strong>Email :</strong>
                                          <span>dum4@chimpgroup.com</span>
                                        </li>
                                        <li>
                                          <strong>Address :</strong>
                                          <span>London</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                  >
                                    <div class="order-status-holder">
                                      <div class="row">
                                        <div
                                          class="col-lg-3 col-md-4 col-sm-4 col-xs-12"
                                        >
                                          <h3>Order Status</h3>
                                        </div>
                                        <div
                                          class="col-lg-9 col-md-8 col-sm-8 col-xs-12"
                                        >
                                          <div class="input-field">
                                            <select
                                              class="chosen-select-no-single"
                                            >
                                              <option value="Processing">
                                                Processing
                                              </option>
                                              <option value="Cancelled">
                                                Cancelled
                                              </option>
                                              <option
                                                selected="selected"
                                                value="Completed"
                                              >
                                                Completed
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                  >
                                    <h2 class="heading">Food Menu</h2>
                                    <div class="responsive-table">
                                      <ul
                                        class="categories-order table-generic"
                                      >
                                        <li class="order-heading-titles">
                                          <div>Products</div>
                                          <div>Price per</div>
                                        </li>
                                        <li class="order-heading-titles">
                                          <div>
                                            <h4>Pizzas</h4>
                                            <h5>
                                              Foodbakery Special 9" Deep Pan
                                            </h5>
                                          </div>
                                          <div>
                                            <span class="category-price"
                                              >£4.80</span
                                            >
                                          </div>
                                        </li>
                                        <li class="order-heading-titles">
                                          <div>
                                            <h4>Pizzas</h4>
                                            <h5>
                                              Foodbakery Special 9" Deep Pan
                                            </h5>
                                          </div>
                                          <div>
                                            <span class="category-price"
                                              >£4.80</span
                                            >
                                          </div>
                                        </li>
                                        <li class="order-heading-titles">
                                          <div>
                                            <h4>Pizzas</h4>
                                            <h5>
                                              Foodbakery Special 12" Deep Pan
                                            </h5>
                                          </div>
                                          <div>
                                            <span class="category-price"
                                              >£3.90</span
                                            >
                                          </div>
                                        </li>
                                        <li class="order-heading-titles">
                                          <div>
                                            <h4>Garlic Bread</h4>
                                            <h5>Garlic Bread 12" Deep</h5>
                                          </div>
                                          <div>
                                            <span class="category-price"
                                              >£3.50</span
                                            >
                                          </div>
                                        </li>
                                        <li class="order-heading-titles">
                                          <div>
                                            <h4>Kebabs</h4>
                                            <h5>Kebabs With Naan</h5>
                                          </div>
                                          <div>
                                            <span class="category-price"
                                              >£4.50</span
                                            >
                                          </div>
                                        </li>
                                        <li class="order-heading-titles">
                                          <div>
                                            <h4>Burgers</h4>
                                            <h5>Quarter Pounder</h5>
                                          </div>
                                          <div>
                                            <span class="category-price"
                                              >£3.00</span
                                            >
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div
                                    class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-lg-9 col-md-9 col-sm-9 col-xs-12"
                                      >
                                        <h3>Order Total</h3>
                                      </div>
                                      <div
                                        class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                      >
                                        <ul
                                          class="order-detail-options order-total"
                                        >
                                          <li class="created-date">
                                            <strong>Subtotal:</strong>
                                            <span>£24.50</span>
                                          </li>
                                          <li class="order-type">
                                            <strong> Pick Up Fee: </strong>
                                            <span>£10.00</span>
                                          </li>
                                          <li class="order-type">
                                            <strong>VAT (13%)</strong>
                                            <span>£4.48</span>
                                          </li>
                                          <li class="order-type total-price">
                                            <strong>Total:</strong>
                                            <span>£38.99</span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <script>
                      (function ($) {
                        $(document).ready(function () {
                          $(
                            ".order-detail .modal-dialog .modal-content"
                          ).mCustomScrollbar({
                            setHeight: 724,
                            theme: "minimal-dark",
                            mouseWheelPixels: 100,
                          });
                        });
                      })(jQuery);
                    </script>
                  </div>
                </div>
              </div>
              <ul class="pagination">
                <li class="active"><a>1</a></li>
                <li><a href="/#">2</a></li>
                <li><a href="/#">3</a></li>
                <li><span class="page-numbers dots">…</span></li>
                <li><a href="/#">5</a></li>
                <li><a href="/#">Next </a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Popup Start -->
<div
  class="modal fade menu-order-detail menu-order-info"
  id="booking-detail-22603"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
        <h2><a>Booking Detail</a></h2>
      </div>
      <div class="modal-body booking-modal-body">
        <div class="order-detail-inner">
          <h3>Restaurant Demo</h3>
          <ul class="order-detail-options">
            <li>
              <strong>Booking ID :</strong>
              <span>22603</span>
            </li>
            <li>
              <strong>Booking Date :</strong>
              <span>Apr 9,2019 6:40 AM </span>
            </li>
          </ul>
          <h3>Customer Deatil</h3>
          <ul class="order-detail-options">
            <li>
              <strong>First Name:</strong>
              <span>Mark</span>
            </li>
            <li>
              <strong>Last Name:</strong>
              <span>Jose</span>
            </li>
            <li>
              <strong>Email:</strong>
              <span>markjose@gmail.com</span>
            </li>
            <li>
              <span>4-guest</span>
            </li>
            <li>
              <span>Apr 30,2019 11:00 AM</span>
            </li>
            <li class="order-detail-message">
              <strong>Instructions:</strong>
              <span
                >I need to book a special table for my friends. Make it some
                special.!</span
              >
            </li>
          </ul>
          <div class="booking-status-holder">
            <div class="booking-status-process booking-status">
              <p style="background: #1e73be">Your booking is Processing.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="id_confrmdiv" style="display: none">
  <div class="cs-confirm-container">
    <i class="icon-sad"></i>
    <div class="message">You Want To Delete?</div>
    <a href="javascript:void(0);" id="id_truebtn">Yes, Delete</a>
    <a href="javascript:void(0);" id="id_falsebtn">No, Cancel</a>
  </div>
</div>
