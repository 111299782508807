<div
  class="inner-banner"
  style="background-image: url(assets/images/inner-bg.jpg)"
>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="company-info-detail">
          <div class="company-info">
            <div class="img-holder">
              <figure>
                <img src="{{ imageURL }}" alt="" />
              </figure>
            </div>
            <div class="text-holder">
              <span
                *ngIf="UserData && UserData.customer.customerName"
                class="restaurant-title"
                >{{ UserData.customer.customerName }}</span
              >
              <ul class="user-info-contact">
                <li *ngIf="UserData && UserData.customer.mobileNo" class="cell">
                  <i class="icon-phone"></i
                  ><a>{{ UserData.customer.mobileNo }}</a>
                </li>
                <li *ngIf="UserData && UserData.customer.emailId" class="email">
                  <i class="icon-mail5"></i
                  ><a>{{ UserData.customer.emailId }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
