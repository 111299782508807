
<mat-dialog-content>
  <div class="modal-header">
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h2 mat-dialog-title>Order Review</h2>
  </div>
  <div class="container1">
    <div class="order-detail-inner">
      <div class="reviews-holder">
        <div class="add-new-review-holder add-new-review-2067">
          <form  [formGroup]="formReviewGroup">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <h3>Rate and Write a Review
                </h3>
              </div>
              <div class="foodbakery-add-review-data">
                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="rating-restaurant">
                              <ul class="star-rating-list">
                                  <li>
                                      <span>Service</span>
                                      <div class="stars">
                                          <input id="star-1" type="radio" name="star0" class="star-1" checked="checked">
                                          <label class="star-1" for="star-1">1</label>
                                          <input id="star-2" type="radio" name="star0" class="star-2">
                                          <label class="star-2" for="star-2">2</label>
                                          <input id="star-3" type="radio" name="star0" class="star-3">
                                          <label class="star-3" for="star-3">3</label>
                                          <input id="star-4" type="radio" name="star0" class="star-4">
                                          <label class="star-4" for="star-4">4</label>
                                          <input id="star-5" type="radio" name="star0" class="star-5">
                                          <label class="star-5" for="star-5">5</label>
                                          <span style="width: 20%;"></span>
                                      </div>
                                  </li>
                                  <li>
                                      <span>Quality</span>
                                      <div class="stars">
                                          <input id="star-11" type="radio" name="star1" class="star-1" checked="checked">
                                          <label class="star-11" for="star-11">1</label>
                                          <input id="star-21" type="radio" name="star11" class="star-2">
                                          <label class="star-21" for="star-21">2</label>
                                          <input id="star-31" type="radio" name="star11" class="star-3">
                                          <label class="star-31" for="star-31">3</label>
                                          <input id="star-41" type="radio" name="star11" class="star-4">
                                          <label class="star-41" for="star-41">4</label>
                                          <input id="star-51" type="radio" name="star11" class="star-5">
                                          <label class="star-51" for="star-51">5</label>
                                          <span style="width: 20%;"></span>
                                      </div>
                                  </li>
                                  <li>
                                      <span>Value</span>
                                      <div class="stars">
                                          <input id="star-12" type="radio" name="star2" class="star-1" checked="checked">
                                          <label class="star-12" for="star-12">1</label>
                                          <input id="star-22" type="radio" name="star2" class="star-2">
                                          <label class="star-22" for="star-22">2</label>
                                          <input id="star-32" type="radio" name="star22" class="star-3">
                                          <label class="star-32" for="star-32">3</label>
                                          <input id="star-42" type="radio" name="star22" class="star-4">
                                          <label class="star-42" for="star-42">4</label>
                                          <input id="star-52" type="radio" name="star22" class="star-5">
                                          <label class="star-52" for="star-52">5</label>
                                          <span style="width: 20%;"></span>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div> -->
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="total-ratings user-rating-container overall-rating">
                    <h6>Overall rating
                    </h6>
                    <div class="rating-starz">
                      <ul class="list-inline rating-list" *ngFor="let star of stars; let i= index">
                        <li (click)="countStar(star)" [ngClass]="{'selected': (star <= selectedValue)}"
                          (mouseover)="addClass(star)" (mouseout)="removeClass()">
                          <span class="star" style="width: 20%;"><i class="fa fa-star" aria-hidden="true"></i>
                          </span>
                        </li>
                      </ul>


                      <!-- <input id="star-13" type="radio" name="star" class="star-1" checked="checked">
                                  <label class="star-13" for="star-13">1</label>
                                  <input id="star-23" type="radio" name="star" class="star-2">
                                  <label class="star-2" for="star-23">2</label>
                                  <input id="star-33" type="radio" name="star" class="star-3">
                                  <label class="star-3" for="star-33">3</label>
                                  <input id="star-43" type="radio" name="star" class="star-4">
                                  <label class="star-4" for="star-43">4</label>
                                  <input id="star-53" type="radio" name="star" class="star-5">
                                  <label class="star-5" for="star-53">5</label> -->
                      <!-- <span style="width: 20%;"></span> -->
                    </div>
                  </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="form-element">
                              <i class="icon-edit2"></i>
                              <input type="text" placeholder="Title of your review *" value="">
                          </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="form-element">
                              <i class="icon-user4"></i>
                              <input type="text" placeholder="Name *" value="">
                          </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="form-element">
                              <i class="icon-envelope3"></i>
                              <input type="text" placeholder="Email *" value="">
                          </div>
                      </div> -->
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-element">

                    <textarea placeholder="Tell about your experience or leave a tip for others" class="textarea" formControlName="ReviewContent" cols="30" rows="10"
                      maxlength="500"></textarea>
                  </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="form-element message-length">
                              <span>Min characters: 10</span>
                              <span>Max characters: 500</span>
                              <div>500 characters remaining</div>
                          </div>
                      </div> -->
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-element">
                    <input type="button" (click)="finish()" class="btn_review" value="Submit your Review">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
