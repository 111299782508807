import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";

import { formatDate } from "@angular/common";
import { AppService } from "src/app/app.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-menuextra-dialog",
  templateUrl: "./menuextra-dialog.component.html",
  styleUrls: ["./menuextra-dialog.component.css"],
})
export class MenuExtraDialogComponent implements OnInit {
  public form!: FormGroup;
  submenulist:any;
  
  constructor(
    public dialogRef: MatDialogRef<MenuExtraDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public appservice: AppService, public router: Router,
    public snackBar:MatSnackBar
  ) {
  
  }
  ngOnInit(){
    this.form = this.fb.group({
      selectportionId: [null, Validators.required],
    });
    console.log(this.data)
    if(this.data.listitem.length>0){
      this.submenulist=this.data.listitem;
    }
    // this.appservice.getSubMenuItem(this.data.passdata.id).subscribe((subitem:any) => {
    //   console.log(subitem)
    //   this.submenulist=subitem;
    // },
    //   (err: HttpErrorResponse) => {
    //     // error code here
    //     console.log(err);
    //   });
  }



  
 
 
  submit() {
    // emppty stuff
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
    else{
      this.snackBar.open("Please Choose Item Portion!!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd(): void {
  
  }
}
