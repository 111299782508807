import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetPasswordComponent implements OnInit {
  private param: any = 0;
  public resetForm!: FormGroup;
  public hide = false;
  public ismodal = "";
  errormsg: string = ''


  constructor(public fb: FormBuilder,
    public appservice: AppService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.param = params['id']+'=';
      console.log(this.param)
    });
    this.resetForm = this.fb.group({
      Token: this.param,
      Password: [null, Validators.required],
      ConfirmPassword: [null, Validators.required],
    });
    
  }


  public onResetPasswordFormSubmit(): void {
  // console.log(this.resetForm.value)
    this.hide = false;
    if (this.resetForm.valid && this.resetForm.controls.Password.value==this.resetForm.controls.ConfirmPassword.value) {
      if(this.resetForm.controls.Password.value==this.resetForm.controls.ConfirmPassword.value){
        this.appservice.resetPassword(this.resetForm.getRawValue())
        .subscribe(
          (res:any) => {
        //    console.log(res)
           if(res.result=="Successfully reset.."){
            this.appservice.showNotification("success",res.result+"Please Login to Continue","top","center");
            this.router.navigate(['/login']);
           }
          });
      }
      else{
        this.hide = true;
        this.errormsg = "Password Mismatch!!!";
        this.appservice.showNotification( "danger",this.errormsg,"top","center");
      }
      
    }
    else {
      console.log(this.resetForm.value)
      this.hide = true;
      this.errormsg = "All Fields is required";
      this.appservice.showNotification("danger",this.errormsg,"top","center");

    }
  }

}
