<div class="main-section">
    <div class="page-section nopadding cs-nomargin" style="margin-top: 0px;padding-top: 60px;padding-bottom: 60px;margin-bottom: 0px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                    <div class="user-dashboard loader-holder">
                        <div class="user-holder">
                            <ul class="restaurant-settings-nav progressbar-nav">
                                <li class="active processing"><a routerLink="/register-restaurant" class="cond-restaurant-settings1">Information</a></li>
                                <li><a routerLink="/register-select-package" class="cond-restaurant-settings1">Select Package</a></li>
                                <li><a routerLink="/register-payment-info" class="cond-restaurant-settings1">Payment Information</a></li>
                                <li><a routerLink="/register-activation" class="cond-restaurant-settings1">Activation</a></li>
                            </ul>
                            <div id="restaurant-sets-holder">
                                <form class="form-fields-set foodbakery-dev-restaurant-form" method="post" action="register-select-package">
                                    <ul class="register-add-restaurant-tab-container restaurant-information-tab-container">
                                        <li>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div class="row">
                                                        <ul class="has-seperator">
                                                            <li>
                                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                    <div class="field-holder">
                                                                        <label>Restaurant name *</label><input type="text" placeholder="i.e Pizza Hut" class="foodbakery-dev-req-field" id="foodbakery_restaurant_title_59054637" name="foodbakery_restaurant_title">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                    <div class="field-holder">
                                                                        <label>Restaurant phone</label><input type="text" placeholder="i.e +1 321 828 6662" id="foodbakery_restaurant_phone_59054637" name="foodbakery_restaurant_contact_phone">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                    <div class="field-holder">
                                                                        <label>Manager Name</label><input type="text" placeholder="i.e Alard Willaim" id="foodbakery_restaurant_manager_name_59054637" name="foodbakery_restaurant_manager_name">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                    <div class="field-holder">
                                                                        <label>Manager Contact
                                phone</label><input type="text" placeholder="i.e +1 321 828 6662" id="foodbakery_restaurant_manager_phone_59054637" name="foodbakery_restaurant_manager_phone">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                    <div class="field-holder">
                                                                        <label>Contact Email</label><input type="text" placeholder="i.e alard@example.com " id="foodbakery_restaurant_contact_email_59054637" name="foodbakery_restaurant_contact_email">
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="element-title">
                                                                        <h4>Location</h4>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="opt-conts">
                                                                        <div class="row">
                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                <div class="field-holder">
                                                                                    <label>Country *</label>
                                                                                    <div class="select-holder">
                                                                                        <span class="loader-country-restaurant"></span>
                                                                                        <select data-placeholder="Select Country" class="form-control chosen-select form-select-country dir-map-search foodbakery-dev-req-field " id="loc_country_restaurant" name="foodbakery_post_loc_country_restaurant">
                                        <option value="">
                                          Select
                                          Country
                                        </option>
                                        <option value="barry" data-name="">
                                          Barry
                                        </option>
                                        <option value="cardiff" data-name="">
                                          Cardiff
                                        </option>
                                        <option value="cowbridge" data-name="">
                                          Cowbridge
                                        </option>
                                        <option value="england" data-name="">
                                          England
                                        </option>
                                        <option value="llantwit-major" data-name="">
                                          Llantwit
                                          Major
                                        </option>
                                        <option value="northern-ireland" data-name="">
                                          Northern
                                          Ireland
                                        </option>
                                        <option value="penarth" data-name="">
                                          Penarth
                                        </option>
                                        <option value="scotland" data-name="">
                                          Scotland
                                        </option>
                                        <option value="wales" data-name="">
                                          Wales
                                        </option>
                                      </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                <div class="field-holder">
                                                                                    <label>State</label>
                                                                                    <div class="select-holder">
                                                                                        <span class="loader-state-restaurant"></span>
                                                                                        <select data-placeholder="Select State" class="form-control chosen-select form-select-state dir-map-search " id="loc_state_restaurant" name="foodbakery_post_loc_state_restaurant">
                                        <option value="">
                                          Select State
                                        </option>
                                      </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                <div class="field-holder">
                                                                                    <label>City</label>
                                                                                    <div class="select-holder">
                                                                                        <span class="loader-city-restaurant"></span>
                                                                                        <select data-placeholder="Select City" class="form-control chosen-select form-select-city dir-map-search " id="loc_city_restaurant" name="foodbakery_post_loc_city_restaurant">
                                        <option value="">
                                          Select
                                          City
                                        </option>
                                      </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                <div class="field-holder">
                                                                                    <label>Latitude</label>
                                                                                    <input type="text" placeholder="Latitude" class="form-control gllpLatitude" id="foodbakery_post_loc_latitude" name="foodbakery_post_loc_latitude_restaurant" value="51.5073509">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                <div class="field-holder">
                                                                                    <label>Longitude</label>
                                                                                    <input type="text" placeholder="Longitude" class="form-control gllpLongitude" id="foodbakery_post_loc_longitude" name="foodbakery_post_loc_longitude_restaurant" value="-0.12775829999998223">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                <input type="hidden" id="foodbakery__loc_bounds_rest" name="foodbakery_loc_bounds_rest_restaurant" value=""><input type="hidden" id="foodbakery_add_new_loc" class="gllpSearchField"
                                                                                    style="margin-bottom:10px;" name="foodbakery_add_new_loc_restaurant" value=""><input type="hidden" id="foodbakery_post_loc_zoom" class="gllpZoom" name="foodbakery_post_loc_zoom_restaurant"
                                                                                    value="9">
                                                                                <div class="field-holder">
                                                                                    <label>Find On Map</label>
                                                                                    <input type="text" onkeypress="foodbakery_gl_search_map(this.value)" placeholder="Type Your Address" class="foodbakery-search-location" id="loc_address" name="foodbakery_post_loc_address_restaurant" autocomplete="off">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                                                <div class="field-holder"></div>
                                                                                <div class="field-holder search-location-map input-button-loader">
                                                                                    <input type="button" class="acc-submit cs-section-update cs-color csborder-color gllpSearchButton" id="foodbakery_map_search_btn" name="foodbakery_map_search_btn" value="Search Location">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <!-- map start -->
                                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                                <div class="cs-map-section" style="float:left; width:100%; height:284px;">
                                                                                    <div class="gllpMap" id="cs-map-location-fe-id">
                                                                                        <img style="width:100%;height: 100%;" src="assets/images/map-location.png" alt="">
                                                                                    </div>
                                                                                </div>
                                                                                <!--<p> For the precise location, you can drag and drop the pin.-->
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="field-holder">
                                                                        <label>Delivery/Pickup</label>
                                                                        <select class="chosen-select" name="foodbakery_restaurant_pickup_delivery">
                                <option value="delivery">Delivery</option>
                                <option value="pickup">Pickup</option>
                                <option value="delivery_and_pickup">Delivery &amp; Pickup</option>
                              </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="foodbakery-dev-appended-cats12">
                                                                        <div class="field-holder">
                                                                            <label>Cuisines *</label>
                                                                            <select data-placeholder="Choose some Cuisines" class="multiple chosen-select foodbakery-dev-req-field" multiple="" id="foodbakery_foodbakery_restaurant_category" name="foodbakery_restaurant_category[]">
                                  <option value="apple-juice">Apple Juice</option>
                                  <option value="bb-q">BB.Q</option>
                                  <option value="beef-roast">Beef Roast</option>
                                  <option value="carrot-juice">Carrot Juice</option>
                                  <option value="cheese-burger">Cheese Burger</option>
                                  <option value="chicken-roast">Chicken Roast</option>
                                  <option value="chines-soup">Chines Soup</option>
                                  <option value="cold-coffee">Cold Coffee</option>
                                  <option value="cold-drink">Cold Drink</option>
                                  <option value="cupcake">Cupcake</option>
                                  <option value="doughnut">Doughnut</option>
                                  <option value="drum-stick">Drum Stick</option>
                                  <option value="egg-fry">Egg Fry</option>
                                  <option value="fish-fry">Fish Fry</option>
                                  <option value="fresh-juice">Fresh Juice</option>
                                  <option value="hot-dogs">Hot Dogs</option>
                                  <option value="ice-cream">Ice Cream</option>
                                  <option value="magi">Magi</option>
                                  <option value="noodles">Noodles</option>
                                  <option value="pastry">Pastry</option>
                                  <option value="pizza">Pizza</option>
                                  <option value="potato-fries">Potato Fries</option>
                                  <option value="prawns">Prawns</option>
                                  <option value="sham-pane">Sham pane</option>
                                  <option value="shawarma">Shawarma</option>
                                  <option value="soda-water">Soda water</option>
                                  <option value="stakes">Stakes</option>
                                  <option value="steam-roast">Steam Roast</option>
                                  <option value="tea">Tea</option>
                                </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="element-title">
                                                                        <h4>Signup Fields</h4>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                                    <div class="field-holder">
                                                                        <label>Username</label>
                                                                        <input type="text" placeholder="i.e alardwillaim" name="foodbakery_restaurant_username" class="foodbakery-dev-username foodbakery-dev-req-field">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                                    <div class="field-holder">
                                                                        <label>Email</label>
                                                                        <input type="text" placeholder="i.e alard@example.com" name="foodbakery_restaurant_user_email" class="foodbakery-dev-user-email foodbakery-dev-req-field">
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div class="field-holder">
                                                        <div class="payment-holder">
                                                            <div class="checkbox-area">
                                                                <input type="checkbox" id="terms-59054637" name="terms-59054637" class="foodbakery-dev-req-field">
                                                                <label for="terms-59054637">
                            By Registering You Confirm That You Accept The
                            <a target="_blank" routerLink="/contact-us">Terms &amp; conditions </a>
                            And <a target="_blank" routerLink="/price-plans">Privacy Policy </a>
                          </label>
                                                            </div>
                                                            <a routerLink="/register-select-package" class=" bgcolor btn-submit ">Next</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

