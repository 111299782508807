import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PartnerWithUsComponent } from './pages/partner-with-us/partner-with-us.component';
import { BlogDetailComponent } from './pages/blog/blog-detail/blog-detail.component';
import { BuyerAccountSettingComponent } from './pages/buyer/buyer-account-setting/buyer-account-setting.component';
import { BuyerAddressComponent } from './pages/buyer/buyer-address/buyer-address.component';
import { BuyerBookingsComponent } from './pages/buyer/buyer-bookings/buyer-bookings.component';
import { BuyerCarInfoComponent } from './pages/buyer/buyer-carinfo/buyer-carinfo.component';
import { BuyerChangePasswordComponent } from './pages/buyer/buyer-changepassword/buyer-changepassword.component';
import { BuyerDashboardComponent } from './pages/buyer/buyer-dashboard/buyer-dashboard.component';
import { BuyerMealPlanOrdersHistoryComponent } from './pages/buyer/buyer-mealplan-orders/buyer-mealplan-orders-history.component';
import { BuyerOrdersHistoryComponent } from './pages/buyer/buyer-orders-history/buyer-orders-history.component';
import { BuyerOrdersComponent } from './pages/buyer/buyer-orders/buyer-orders.component';
import { BuyerPartnerComponent } from './pages/buyer/buyer-partner/buyer-partner.component';
import { BuyerReviewsComponent } from './pages/buyer/buyer-reviews/buyer-reviews.component';
import { BuyerScheduleOrdersHistoryComponent } from './pages/buyer/buyer-schedule-orders/buyer-schedule-orders-history.component';
import { BuyerShortlistsComponent } from './pages/buyer/buyer-shortlists/buyer-shortlists.component';
import { BuyerStatementComponent } from './pages/buyer/buyer-statement/buyer-statement.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { ListingDetailComponent } from './pages/listing-detail/listing-detail.component';
import { ListingsComponent } from './pages/listings/listings.component';
import { LoginComponent } from './pages/login/login.component';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { RegisterActivationComponent } from './pages/register-activation/register-activation.component';
import { RegisterPaymentInfoComponent } from './pages/register-payment-info/register-payment-info.component';
import { RegisterRestaurantComponent } from './pages/register-restaurant/register-restaurant.component';
import { RegisterSelectPackageComponent } from './pages/register-select-package/register-select-package.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ResetPasswordComponent } from './pages/resetpassword/resetpassword.component';
import { SignupComponent } from './pages/signup/signup.component';
import { RestaurantDashboardComponent } from './pages/restaurant/restaurant-dashboard/restaurant-dashboard.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';
import { UserComponent } from './pages/user/user.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'listings', component: ListingsComponent },
  { path: 'listings/:loc/:type', component: ListingsComponent },
  { path: 'listing-details', component: ListingDetailComponent },
  { path: 'listing-details/:id', component: ListingDetailComponent },
  { path: 'buyer-dashboard',canActivate: [AuthGuard], component: BuyerDashboardComponent },
  { path: 'buyer-account-setting',canActivate: [AuthGuard], component: BuyerAccountSettingComponent },
  { path: 'buyer-bookings',canActivate: [AuthGuard], component: BuyerBookingsComponent },
  { path: 'buyer-orders',canActivate: [AuthGuard], component: BuyerOrdersComponent },
  { path: 'buyer-orders-history',canActivate: [AuthGuard], component: BuyerOrdersHistoryComponent },
  { path: 'buyer-mealplan-orders-history',canActivate: [AuthGuard], component: BuyerMealPlanOrdersHistoryComponent },
  { path: 'buyer-schedule-orders-history',canActivate: [AuthGuard], component: BuyerScheduleOrdersHistoryComponent },
  { path: 'buyer-reviews',canActivate: [AuthGuard], component: BuyerReviewsComponent },
  { path: 'buyer-shortlists',canActivate: [AuthGuard], component: BuyerShortlistsComponent },
  { path: 'buyer-partner',canActivate: [AuthGuard], component: BuyerPartnerComponent },
  { path: 'buyer-statement',canActivate: [AuthGuard], component: BuyerStatementComponent },
  { path: 'buyer-address',canActivate: [AuthGuard], component: BuyerAddressComponent },
  { path: 'buyer-carinfo',canActivate: [AuthGuard], component: BuyerCarInfoComponent },
  { path: 'buyer-changepassword',canActivate: [AuthGuard], component: BuyerChangePasswordComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register-restaurant', component: RegisterRestaurantComponent },
  {path:'resetpassword/:id',component:ResetPasswordComponent},
  { path: 'register-select-package', component: RegisterSelectPackageComponent },
  { path: 'register-payment-info', component: RegisterPaymentInfoComponent },
  { path: 'register-activation', component: RegisterActivationComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'restaurant-dashboard', component: RestaurantDashboardComponent },
  { path: 'search-result', component: SearchResultComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-condition', component: TermsConditionComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'partner-with-us', component: PartnerWithUsComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'blog-detail', component: BlogDetailComponent },
  { path: 'order-detail', component: OrderDetailComponent },
  { path: '**', component: PagenotfoundComponent },
  {path: 'user', component: UserComponent},
  
  //{ path: 'user',  canActivate: [AuthGuard],loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },

];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})//, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
