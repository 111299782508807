<div class="main-section">
    <app-buyer-header></app-buyer-header>
    <div class="page-section account-header buyer-logged-in">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <app-buyer-sidebar></app-buyer-sidebar>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div class="user-dashboard loader-holder">
                        <div class="user-holder">
                            <div class="element-title has-border right-filters-row">
                                <h5>T-Points Statements</h5>
                                <div class="right-filters row">
                                    <div class="col-lg-6 col-md-6 col-xs-6 pull-right">
                                        <div class="input-field">
                                            <i class="icon-angle-down"></i>
                                            <input type="text" data-id="daterange223" id="daterange" value="" placeholder="Select Date Range">
                                            <script>
                                                $(function() {
                                                    $('input[data-id="daterange223"]').daterangepicker({
                                                        opens: 'left'
                                                    }, function(start, end, label) {
                                                        console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
                                                    });
                                                });
                                            </script>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="responsive-table">
                                <ul class="table-generic">
                                    <li class="order-heading-titles">
                                        <div>Vendor Name</div>
                                        <div>Debit</div>
                                        <div>Credit</div>
                                        <div></div>
                                        
                                    </li>
                                    <li class="order-heading-titles" *ngFor="let res of EwalletBallist; let i=index;">
                                        <div>{{res.vendorName}}</div>
                                        <div>{{res.debit}}</div>
                                        <div>{{res.credit}}</div>
                                        <div><div class="list-option">
                                            <a  class="shortlist-btn" style="color: #39b44d !important;" (click)="OpenStatement(res)">
                                                <i class="icon-file-text"></i>
                                            </a>
                                        </div></div>
                                       
                                    </li>
                                    <!-- <li class="order-heading-titles">
                                        <div>Shop Name</div>
                                        <div>Transction Type</div>
                                        <div>Date</div>
                                        <div>Credit</div>
                                        <div>Debit</div>
                                    </li>
                                    <li class="order-heading-titles" *ngFor="let res of Ewalletlist; let i=index;">
                                        <div>{{res.vendorName}}</div>
                                        <div>{{res.vouType}}</div>
                                        <div>{{res.vouDate}}</div>
                                        <div>AED  {{res.credit}}</div>
                                        <div>AED  {{res.debit}}</div>
                                    </li> -->
                                    <!-- <li class="order-heading-titles">
                                        <div>#20204183</div>
                                        <div><a routerLink="/buyer-orders" class="orders-tab-link">22589</a></div>
                                        <div>Apr 9,2019</div>
                                        <div>Order - Restaurant Demo</div>
                                        <div>£26.22</div>
                                    </li>
                                    <li class="order-heading-titles">
                                        <div>#21617248</div>
                                        <div><a routerLink="/buyer-orders" class="orders-tab-link">22469</a></div>
                                        <div>Mar 31,2019</div>
                                        <div>Order - Restaurant Demo</div>
                                        <div>£66.25</div>
                                    </li>
                                    <li class="order-heading-titles">
                                        <div>#56656270</div>
                                        <div><a routerLink="/buyer-orders" class="orders-tab-link">22413</a></div>
                                        <div>Mar 27,2019</div>
                                        <div>Order - Restaurant Demo</div>
                                        <div>£25.43</div>
                                    </li>
                                    <li class="order-heading-titles">
                                        <div>#40728501</div>
                                        <div><a routerLink="/buyer-orders" class="orders-tab-link">22360</a></div>
                                        <div>Mar 26,2019</div>
                                        <div>Order - Restaurant Demo</div>
                                        <div>£29.38</div>
                                    </li> -->
                                </ul>
                            </div>
                            <!-- <ul class="pagination">
                                <li class="active"><a>1</a></li>
                                <li><a routerLink="/#">2</a></li>
                                <li><a routerLink="/#">3</a></li>
                                <li><span class="page-numbers dots">…</span></li>
                                <li><a routerLink="/#">15</a></li>
                                <li><a routerLink="/#">Next </a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
