import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';


@Injectable()
export class AppInterceptor implements HttpInterceptor {
    constructor(private appService: AppService) {}
  
    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

            const token = localStorage.getItem("token"); // your auth token
          if (this.appService.getToken()) { 
            req = req.clone({
              headers:req.headers.set('Authorization',`Bearer ${token}`)
                        .set('Access-Control-Allow-Origin', '*')
                        .set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
                        .set("Access-Control-Allow-Headers", "Content-Type, Authorization, X-Requested-With"),
              
            });
            
          }

        

        // console.log(`Request for ${req.urlWithParams} started...`);

        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // console.log(`Request for ${req.urlWithParams} completed...`);
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            const started = Date.now();            
            const elapsed = Date.now() - started;
            console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
           // debugger;
            return throwError(error);
          })
        );

    }  
}