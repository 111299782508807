import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";

import { formatDate } from "@angular/common";
import { AppService } from "src/app/app.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-schedule-dialog",
  templateUrl: "./schedule-dialog.component.html",
  styleUrls: ["./schedule-dialog.component.css"],
})
export class ScheduleDialogComponent implements OnInit {
  public form!: FormGroup;
 



  constructor(
    public dialogRef: MatDialogRef<ScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public appservice: AppService, public router: Router,
    public snackBar: MatSnackBar
  ) {

  }
  ngOnInit() {
    console.log(this.data)
    this.form = this.fb.group({
      selectmenuid: this.data.passdata.id,
      select_mon: false,
      select_tue: false,
      select_wed: false,
      select_thu:false,
      select_fri:false,
      select_sat:false,
      select_sun:false

    });
    console.log(this.data)
    
  }

  submit() {
    // emppty stuff
    
    if(this.form.controls.select_mon.value==true || this.form.controls.select_tue.value==true || this.form.controls.select_wed.value==true || this.form.controls.select_thu.value==true || this.form.controls.select_fri.value==true || 
      this.form.controls.select_sat.value==true || this.form.controls.select_sun.value==true  ){
        this.dialogRef.close(this.form.value)
      }
      else {
        this.snackBar.open("Please Choose Item Portion!!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
      }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd(): void {

  }
}
