<div class="main-section">
  <app-buyer-header></app-buyer-header>
  <div class="page-section account-header buyer-logged-in">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <app-buyer-sidebar></app-buyer-sidebar>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div class="user-dashboard loader-holder">
            <div class="user-holder">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="row">
                  <div class="element-title has-border right-filters-row">
                    <h5>Schedule Orders</h5>
                    <div class="right-filters row pull-right">
                      <!-- <div class="col-lg-6 col-md-6 col-xs-6">
                        <div class="input-field">
                          <select class="chosen-select-no-single">
                            <option selected="selected" value="">
                              Select Orders Status
                            </option>
                            <option value="Processing">Processing</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Completed">Completed</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-6">
                        <div class="input-field">
                          <i class="icon-angle-down"></i>
                          <input
                            type="text"
                            data-id="daterange223"
                            id="daterange"
                            value=""
                            placeholder="Select Date Range"
                          />
                          <script>
                            $(function () {
                              $(
                                'input[data-id="daterange223"]'
                              ).daterangepicker(
                                {
                                  opens: "left",
                                },
                                function (start, end, label) {
                                  console.log(
                                    "A new date selection was made: " +
                                      start.format("YYYY-MM-DD") +
                                      " to " +
                                      end.format("YYYY-MM-DD")
                                  );
                                }
                              );
                            });
                          </script>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="responsive-table">
                    <ul class="table-generic">
                        <li class="order-heading-titles">
                            <div>Vendor Name</div>
                            <div>order Date</div>
                            <div>Start Date</div>
                            <div>End Date</div>
                            <div>Time</div>
                            <div>Status</div>
                            <div></div>
                            
                        </li>
                        <li class="order-heading-titles" *ngFor="let res of Orderlist | paginate: { itemsPerPage: 10, currentPage: p }; let i=index;">
                          <div><a [routerLink]="['/listing-details/',res.vendorId]">
                            <strong style="color: #39b44d !important;" >{{res.vendorName}}</strong></a></div>
                            <div>{{res.orderDate | date : "dd-MM-yyyy HH:mm"}}</div>
                           
                            <div>{{res.orderStartDate | date : "dd-MM-yyyy"}}</div>
                            <div>{{res.orderEndDate | date : "dd-MM-yyyy"}}</div>
                            <div>{{res.deliveryTime}}</div>
                            <div>{{res.activeStatus}}</div>
                            <div>
                              <div class="list-option">
                                <a  class="shortlist-btn"  data-toggle="modal"
                                data-target="#order_detail" style="color: #39b44d !important;"  (click)="orderdetail(res)">
                                    <i class="icon-file-text"></i>
                                </a>
                            </div>
                          </div>                           
                        </li>                       
                    </ul>
                </div>
                <div
                            class="modal fade menu-order-detail order-detail"
                            id="order_detail"
                            tabindex="-1"
                            role="dialog"
                          >
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <h2>Order Detail</h2>
                                </div>
                                <div class="modal-body">
                                  <div class="order-detail-inner">
                                    <div class="description-holder">
                                      <div class="row">
                                        <div
                                          class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                        >
                                          <div
                                            *ngIf="Orderlistdet"
                                            class="list-detail-options has-checkbox"
                                          >
                                            <h3>Restaurant Demo</h3>
                                            <ul class="order-detail-options">
                                              <li class="order-number">
                                                <strong>Order ID:</strong>
                                                <span>#{{
                                                  Orderlistdet.subsOrderNo
                                                }}</span>
                                              </li>
                                              <li class="req-delivery">
                                                <strong>Delivery Time:</strong>
                                                <span>{{ Orderlistdet.deliveryTime }} </span>
                                              </li>
                                              <li class="created-date">
                                                <strong>Delivery Date:</strong>
                                                <span
                                                  >{{
                                                    Orderlistdet.orderDate
                                                      | date: "dd-MM-yyyy"
                                                  }}
                                                </span>
                                              </li>
                                              <li class="order-type">
                                                <strong>Delivey Type:</strong>
                                                <span>{{
                                                  Orderlistdet.deliveryType
                                                }}</span>
                                              </li>
                                              <li class="order-type">
                                                <strong>Payment Status:</strong>
                                                <span
                                                  *ngIf="
                                                    Orderlistdet.paymentType ==
                                                    'Cash'
                                                  "
                                                  >Pending (Cash on
                                                  Delivery)</span
                                                >
                                                <span
                                                  *ngIf="
                                                    Orderlistdet.paymentType !=
                                                    'Cash'
                                                  "
                                                  >{{
                                                    Orderlistdet.paymentType
                                                  }}</span
                                                >
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                        >
                                          <div class="customer-detail-holder">
                                            <h3>Customer Detail</h3>
                                            <ul
                                              class="customer-detail"
                                              *ngIf="Orderlistdet"
                                            >
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Self-Pick Up'
                                                "
                                              >
                                                <li>
                                                  <strong>Name :</strong>
                                                  <!-- <span>{{lis.customerName}}</span> -->
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .customerName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong
                                                  >
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .contactNo
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .streetName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Location :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .geoLocation
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0].country
                                                    }}</span
                                                  >
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Home Delivery'
                                                "
                                              >
                                                <li>
                                                  <strong>Name :</strong>
                                                  <!-- <span>{{lis.customerName}}</span> -->
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .customerName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong
                                                  >
                                                  <span *ngIf="Orderdetailss">{{
                                                    Orderdetailss
                                                      .orderAddress[0].contactNo
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span *ngIf="Orderdetailss">{{
                                                    Orderdetailss
                                                      .orderAddress[0]
                                                      .streetName
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span *ngIf="Orderdetailss">{{
                                                    Orderdetailss
                                                      .orderAddress[0].country
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Curb Side Pickup'
                                                "
                                              >
                                                <li>
                                                  <strong>Driver Name :</strong>
                                                  <!-- <span>{{lis.customerName}}</span> -->
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.driverName
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong
                                                    >Driver contactNo :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.driverPhone
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong
                                                    >Vechnicle no :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.vechicleNo
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                        >
                                          <div
                                            class="order-status-holder"
                                            *ngIf="Orderdetailss"
                                          >
                                            <div
                                              class="order-status-process order-status"
                                            >
                                              <p
                                                *ngIf="
                                                  Orderdetailss.trnsSubscriptionOrder
                                                    .activeStatus !=
                                                    'Completed' &&
                                                  Orderdetailss.trnsSubscriptionOrder
                                                    .activeStatus != 'Cancel'
                                                "
                                                style="background: #1e73be"
                                              >
                                                Your order is Inprocessing
                                              </p>
                                              <p
                                                *ngIf="
                                                  Orderdetailss.trnsSubscriptionOrder
                                                    .activeStatus ===
                                                  'Completed'
                                                "
                                                style="background: #047a06"
                                              >
                                                Your order is Completed
                                              </p>
                                              <p
                                                *ngIf="
                                                  Orderdetailss.trnsSubscriptionOrder
                                                    .activeStatus == 'Cancel'
                                                "
                                                style="background: #fc2004"
                                              >
                                                Your order is Cancelled
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                        >
                                          <h2 class="heading">Food Menu</h2>
                                          <div class="responsive-table">
                                            <ul
                                              *ngIf="Orderdetailss"
                                              class="categories-order table-generic"
                                            >
                                              <li class="order-heading-titles">
                                                <div>Products</div>
                                                <div>Price per</div>
                                              </li>
                                              <li
                                                class="order-heading-titles"
                                                *ngFor="
                                                  let res of Orderdetailss.trnsSubscriptionItem;
                                                  let i = index
                                                "
                                              >
                                                <div>
                                                  <h4>{{ res.menuName }}</h4>
                                                  <h5>
                                                    <li>
                                                      <ul>
                                                        <li *ngIf="res.mon=='Yes'">Monday</li>
                                                        <li *ngIf="res.tue=='Yes'">Tuesday</li>
                                                        <li *ngIf="res.wed=='Yes'">Wednesday</li>
                                                        <li *ngIf="res.thu=='Yes'">Thursday</li>
                                                        <li *ngIf="res.fri=='Yes'">Friday</li>
                                                        <li *ngIf="res.sat=='Yes'">Saturday</li>
                                                        <li *ngIf="res.sun=='Yes'">Sunday</li>
                                                      </ul>
                                                  </li>
                                                </h5>
                                                  <!-- <h5>Foodbakery
                                                                                                        Special 9" Deep
                                                                                                        Pan</h5> -->
                                                </div>
                                                <div>
                                                  <!-- <span class="category-price"
                                                    >AED
                                                    {{ res.itemValue }}</span -->
                                                  >
                                                </div>
                                              </li>

                                              <!--                                                                                            
                                                                                            <li
                                                                                                class="order-heading-titles">
                                                                                                <div>
                                                                                                    <h4>Burgers</h4>
                                                                                                    <h5>Quarter Pounder
                                                                                                    </h5>
                                                                                                </div>
                                                                                                <div><span
                                                                                                        class="category-price">£3.00</span>
                                                                                                </div>
                                                                                            </li> -->
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                        >
                                          <div class="row">
                                            <!-- <div
                                              class="col-lg-9 col-md-9 col-sm-9 col-xs-12"
                                            >
                                              <h3>Order Total</h3>
                                            </div> -->
                                            <div
                                              class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                            >
                                              <!-- <ul
                                                *ngIf="Orderdetailss"
                                                class="order-detail-options order-total"
                                              >
                                                <li class="created-date">
                                                  <strong>Subtotal:</strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss.trnsOrder
                                                        .grossValue
                                                    }}</span
                                                  >
                                                </li>
                                                <li
                                                  class="order-type"
                                                  *ngIf="
                                                    Orderdetailss.trnsOrder
                                                      .deliverycharges > 0
                                                  "
                                                >
                                                  <strong>
                                                    Delivery Fee:
                                                  </strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss.trnsOrder
                                                        .deliverycharges
                                                    }}</span
                                                  >
                                                </li>
                                                <li class="order-type">
                                                  <strong>VAT (5%)</strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss.trnsOrder
                                                        .tax
                                                    }}</span
                                                  >
                                                </li>
                                                <li
                                                  class="order-type total-price"
                                                >
                                                  <strong>Total:</strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss.trnsOrder
                                                        .netAmount
                                                    }}</span
                                                  >
                                                </li>
                                              </ul> -->
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                </div>
              </div>
              <ul class="pagination">
                <pagination-controls (pageChange)="p = $event" class=""></pagination-controls>
                                <!-- <li class="active"><a>1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><span class="page-numbers dots">…</span></li>
                                <li><a href="#">24</a></li>
                                <li><a href="#">Next </a></li> -->
                            </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

