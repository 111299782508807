import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";

import { formatDate } from "@angular/common";
import { AppService } from "src/app/app.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserAddress } from "src/app/app.models";
@Component({
  selector: "app-vendor-review-dialog",
  templateUrl: "./vendor-review-dialog.component.html",
  styleUrls: ["./vendor-review-dialog.component.css"],
})
export class VendorReviewDialogComponent implements OnInit {
  public formReviewGroup!: FormGroup;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  isMouseover = true;

  constructor(
    public dialogRef: MatDialogRef<VendorReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public appservice: AppService, public router: Router,
    public snackBar: MatSnackBar,

  ) {

  }
  ngOnInit() {
    console.log(this.data)
    this.formReviewGroup = this.fb.group({
      VendorId:this.data.passdata.vendorId,
      Mark:0,
      ReviewContent:'',
      OrderId:this.data.passdata.id
    });
  }

  countStar(star: number) {
    this.isMouseover = false;
    this.selectedValue = star;
  }

  addClass(star: number) {
    if (this.isMouseover) {
      this.selectedValue = star;
    }
  }

  removeClass() {
    if (this.isMouseover) {
      this.selectedValue = 0;
    }
  }



  onNoClick(): void {
    this.dialogRef.close();
  }
  public finish(): void {

    this.formReviewGroup.controls.Mark.setValue(this.selectedValue);
    console.log(this.formReviewGroup.getRawValue())
    this.appservice.saveVendorReview(this.formReviewGroup.getRawValue()).subscribe((res)=>{
      //console.log(res)
      this.dialogRef.close(res);
    })
    //this.dialogRef.close("1");
  }
}
