
<mat-dialog-content>
  <div class="modal-header">
    <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
    <h2 mat-dialog-title>T-Points Statement</h2>
  </div>
  <div class="container1">
    <div class="order-detail-inner">
      <div class="reviews-holder">
        <div class="add-new-review-holder add-new-review-2067">
        
            <div class="row">
              
            
                
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="responsive-table">
                    <ul class="table-generic">
                      <li class="order-heading-titles">
                        <!-- <div>Shop Name</div> -->
                        <div>Transction Type</div>
                        <div>Date</div>
                        <div>Credit</div>
                        <div>Debit</div>
                    </li>
                    <li class="order-heading-titles" *ngFor="let res of Ewalletlist; let i=index;">
                        <!-- <div>{{res.vendorName}}</div> -->
                        <div>{{res.vouType}}</div>
                        <div>{{res.vouDate | date:'dd-MM-yyyy HH:mm ' }}</div>
                        <div>{{res.credit}}</div>
                        <div>{{res.debit}}</div>
                    </li>

                    </ul>
                  </div>
                </div>
                
              
            </div>
          
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
