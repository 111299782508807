<div class="sub-header align-center">
    <div class="subheader-holder" style=" background:url(assets/images/subheader-img2-1.jpg)  no-repeat ; background-size: cover; padding-top: 60px; padding-bottom: 30px; margin-top: 0px; margin-bottom: 0px; min-height: 186px !important;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 87px;">
                    <div class="text-holder">
                        <div class="page-title ">
                            <h1 style="color:#ffffff !important;">Privacy policy</h1>
                        </div>
                        <p style="color:#ffffff !important;">A great restaurant website</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumbs align-left">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ul>
                        <li><a [routerLink]="['/home']">Home</a></li>
                        <li class="active">Privacy policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-section ">
    <div class="page-content-fullwidth">
        <div class="col-xs-12">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="cs-rich-editor">
                            <h2>Privacy Policy</h2>
                            <!-- <p>Our website address is: </p>
                            <h2>What personal data we collect and why we collect it</h2>
                            <h3>Comments</h3> -->
                            <p>At all times, TKaff Portal will attempt to treat the information about you, which we receive on the Internet with utmost care deemed reasonable under the circumstances and ensure that the information you submit is used/disclosed only for the purposes and towards the means it is intended for..</p>
                            <p>TKaff Portal uses information provided by you (if any) to measure the use of its site and to improve the contents of its site.</p>
                            <p>The personal information we may collect is used only by us to respond to your inquiry, process an order or allow you to access specific account information.</p>
                            <p>Occasionally, we may make use of the e-mail addresses, of those who provide information, available to other TKaff App/Portal companies which have equal or better privacy policy than we do. At times cookies may be used to provide you with certain information. 
                                A cookie is a tiny element of data that an App/web site can send to your browser, which may then be stored on your hard drive so we can recognize you when you return. You may set your browser to notify you when you receive a cookie.</p>
                            <p>TKaff Portal does not wish to receive any confidential, secret or proprietary information and materials from its site, Facebook page/other social media web pages, or in any other way.</p>
                            <p>Any information or materials (the "Materials") submitted or sent to TKaff Portal will be deemed not to be confidential or secret. 
                                By submitting or sending Materials to TKaff Portal you (1) warrant that you have no right of any kind to the Materials and that to the best of your knowledge no other party has any right to those Materials; (2) grant TKaff Portal an unrestricted and irrevocable license to use, reproduce, modify, transmit, distribute the Materials, </p>
                            <br>
                            <h3>Credit/Debit Card Information</h3>
                            <p>We reserve the right to refuse to accept the Credit/Debit Card or, in such case the transaction is accepted, cancel the transaction, at our sole discretion, as part of our own fraud detection program.</p>
                            <p>The Credit/Debit card details provided by you for benefiting from the services on the App/Website will be truthful, valid and accurate and you shall use the Credit/Debit card which is lawfully owned by you, i.e. in a Credit/Debit card transaction, 
                                you must use your own Credit/Debit card. The said information will not be utilized and shared by us with any of the third parties unless required for fraud verifications or by law, regulation or court order. We will not be liable for any Credit/Debit card fraud. 
                                The liability for use of a card fraudulently will be on you and the responsibility to prove otherwise shall be exclusively on you.</p>
                            <br>
                            <h3>Copyright & Trademark</h3>
                            <p>Unless otherwise noted, all contents of the App/Website are copyrights, trademarks, trade dress and/or other intellectual property owned, controlled or licensed by <b>TKaff Portal</b> or one of its affiliates. </p>
                            <p>We expressly reserve all intellectual property rights in all text, programs, products, processes, technology, content and other materials, which appear on this App/Website.
                                 Access to this App/Website does not confer and shall not be considered as conferring upon anyone any license under any of our or any third party's intellectual property rights. 
                                 All rights, including copyright, in this App/Website are owned by or licensed to us/our affiliates. Any use of this App/Website or its contents, including copying or storing it in part or whole, other than for your own personal, non-commercial use is prohibited without our permission. You may not modify, distribute or re-post anything on this App/Website for any purpose which will infringe our intellectual property.</p>
                            <p>Our names and logos and all other related products and service names, design marks and slogans are the trademarks or service marks of <b>TKaff Portal</b>. No trademark or service mark license is granted in connection with the materials contained on this App/Website.
                                 The mere Access to this App/Website does not authorize anyone to use any name, logo or mark in any manner whatsoever.</p>
                            <br>
                            <h3>Liability release and indemnification</h3>
                            <p>You hereby release and discharge and agree to indemnify: 
                                1) <b>TKaff  Portal</b> and any related body corporate, 
                                2) <b>TKaff  Portal</b> directors, officers, employees and agents; for any personal injury, illness or death, claims, liabilities or expenses of any kind, including reasonable attorney’s fees (and whether accruing to you, your heirs or personal representatives) that are caused or alleged to be caused in whole or in part by reason of an act or omission of any Released Party that arises out of or in connection with your use of our App/Website or participation in any of our promotions, 
                                or resulting from any violation of this agreement or any activity related to your account (including infringement of third parties’ worldwide intellectual property rights or negligent or wrongful conduct) by you.</p>
                            <p>Further you agree to defend, indemnify and hold all our directors, managers and employees, harmless from and against any and all claims, damages, costs and expenses, including attorneys' fees, arising from or related to your use of the App/Website by entering into the contest or any breach by you of these Terms and Conditions and/or the applicable laws.</p>
                            <br>
                            <h3>Personal information</h3>
                            <p>We at <b>TKaff Portal</b> are dedicated to protecting the privacy and confidentiality of your personal information. Your data is stored securely on our database and will only be disclosed to our trusted partners. We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent and we only use your information as described in the Privacy Policy. 
                                We consider/view protection of your privacy as a very important community principle. 
                                We understand clearly that you and the Information is one of our most paramount assets. 
                                We store and process the Information on computers that are protected by physical as well as technological security devices. We use third parties to verify and certify our privacy principles.</p>
                            <br>
                            <h3>Force majeure</h3>
                            <p>TKaff Portal shall not be held liable for any breach of obligations where there is hindrance or prevention from carrying out the contest obligations by any cause outside their reasonable control, 
                                including but not limited to lightning, fire, flood, extreme or severe weather, strike, lock-out, labour dispute, act of God, Global Pandemic, war, riot, civil commotion, malicious damage, failure of any telecommunications or computer system, compliance with any law, accident (or by any damage caused by any of such events). </p>
                            <br>
                            <h3>The customer shall agree and confirm on the following:</h3>
                            <p>1. There may be or will be optional extended warranty on products sold by us. Should there be any problems with the product, it is the choice of the customer to contact the manufacturer's local agent in the UAE.</p>
                            <p>2. If we have shipped any electronic items they are designed to work on 220-240V. If you do not have the required facilities to operate on this electricity voltage, then use of the equipment is at your own risk. We will not accept back any products that have been damaged due to use with faulty electrical sources from your end. You are advised to check the owners’ manual before the product is used to see if the product meets the local requirements required for safe operation.</p>
                            <p>3. It is pertinent to mention that correct and proper address at which delivery of the product ordered by you shall be provided by you. If the provided address is found to be inaccurate/incomplete or incorrect, we may attempt to find the correct address to complete the delivery, If in an event of wherein the provided address is incorrect, or if delivery can't be completed, then a surcharge for extra cost will be imposed to the customer.</p>
                            <br>
                            <h3>Governing Law and Jurisdiction</h3>
                            <p>The Terms and Conditions shall be construed in accordance with the federal laws of the United Arab Emirates and the local laws and regulations of the Emirate of Dubai. The Courts at Dubai shall have exclusive jurisdiction in any proceedings arising out of the Terms and Conditions.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
