<div class="main-section">
  <app-buyer-header></app-buyer-header>
  <div class="page-section account-header buyer-logged-in">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <app-buyer-sidebar></app-buyer-sidebar>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div class="user-dashboard loader-holder">
            <div class="user-holder">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div
                    class="element-title has-border reviews-header right-filters-row"
                  >
                    <h5>
                      <span>Reviews Given</span>
                      <span class="element-slogan">(2)</span>
                    </h5>
                    <div class="right-filters row">
                      <div class="col-lg-6 col-md-6 col-xs-6">
                        <div class="sort-by">
                          <ul class="reviews-sortby">
                            <li>
                              <small>Sort by:</small>
                              <span
                                ><strong class="active-sort"
                                  >Newest Reviews
                                </strong></span
                              >
                              <div class="reviews-sort-dropdown">
                                <form>
                                  <div class="input-reviews">
                                    <div class="radio-field">
                                      <input
                                        name="review"
                                        id="check-1"
                                        type="radio"
                                        value="newest"
                                        checked="checked"
                                      />
                                      <label for="check-1"
                                        >Newest Reviews</label
                                      >
                                    </div>
                                    <div class="radio-field">
                                      <input
                                        name="review"
                                        id="check-2"
                                        type="radio"
                                        value="highest"
                                      />
                                      <label for="check-2"
                                        >Highest Rating</label
                                      >
                                    </div>
                                    <div class="radio-field">
                                      <input
                                        name="review"
                                        id="check-3"
                                        type="radio"
                                        value="lowest"
                                      />
                                      <label for="check-3">Lowest Rating</label>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-6">
                        <div class="input-field">
                          <i class="icon-angle-down"></i>
                          <input
                            type="text"
                            data-id="daterange223"
                            id="daterange"
                            value=""
                            placeholder="Select Date Range"
                          />
                          <script>
                            $(function () {
                              $(
                                'input[data-id="daterange223"]'
                              ).daterangepicker(
                                {
                                  opens: "left",
                                },
                                function (start, end, label) {
                                  console.log(
                                    "A new date selection was made: " +
                                      start.format("YYYY-MM-DD") +
                                      " to " +
                                      end.format("YYYY-MM-DD")
                                  );
                                }
                              );
                            });
                          </script>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="user-reviews-list">
                    <div class="review-listing">
                      <ul>
                        <li
                          class="alert"
                          *ngFor="let rev of VendorReviewlist; let i = index"
                        >
                          <div class="list-holder">
                            <div class="review-text">
                              <div class="review-title">
                                <h6>
                                  <a
                                    [routerLink]="[
                                      '/listing-details/',
                                      rev.vendorId
                                    ]"
                                    >{{ rev.vendorName }} :
                                    {{ rev.reviewContent }}
                                  </a>
                                </h6>
                                <div class="rating-holder">
                                  <div class="rating-star">
                                    <span
                                      class="rating-box"
                                      style="width: 100%"
                                    ></span>
                                    <!-- <ul class="list-inline rating-list" *ngFor="let star of stars; let i= index">
                                                                            <li  [ngClass]="{'selected': (star <= rev.mark)}">
                                                                              <span class="star" style="width: 20%;"><i class="fa fa-star" aria-hidden="true"></i>
                                                                              </span>
                                                                            </li>
                                                                          </ul> -->
                                  </div>
                                </div>
                              </div>
                              <em class="review-date"
                                >{{ rev.reviewDate | date: "dd-MM-yyyy HH:mm" }}
                              </em>
                              <!-- <p class="more">It has all the bells and whistles I you are looking for in a Foodbakery directory theme. </p> -->
                            </div>
                            <!-- <a routerLink="/#" class="delete-this-user-review close"><i class="icon-close2"></i></a> -->
                          </div>
                        </li>
                        <!-- <li class="alert ">
                                                    <div class="list-holder">
                                                        <div class="review-text">
                                                            <div class="review-title">
                                                                <h6><a routerLink="/# "> Restaurant Demo: Delicious and Wealthy </a></h6>
                                                                <div class="rating-holder">
                                                                    <div class="rating-star">
                                                                        <span class="rating-box" style="width: 100%;"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <em class="review-date">21 mins Ago </em>
                                                            <p class="more">A+++ support from developers. Super system, all integrated very well documented and great support, this is great. </p>
                                                        </div>
                                                        <a routerLink="/#" class="delete-this-user-review close"><i class="icon-close2"></i></a>
                                                    </div>
                                                </li> -->
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <!-- <ul class="pagination">
                                        <li class="active"><a>1</a></li>
                                        <li><a routerLink="/#">2</a></li>
                                        <li><a routerLink="/#">3</a></li>
                                        <li><span class="page-numbers dots">…</span></li>
                                        <li><a routerLink="/#">24</a></li>
                                        <li><a routerLink="/#">Next </a></li>
                                    </ul> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
