<div class="user-account-nav user-account-sidebar">
  <button
    type="button"
    class="navbar-toggle collapsed"
    data-toggle="collapse"
    data-target="#sidebuyermenu"
    aria-expanded="false"
  >
    <i class="bi bi-list"></i>
  </button>
  <div class="collapse navbar-collapse" id="sidebuyermenu">
    <div class="user-nav-list">
      <ul>
        <li routerLinkActive="active">
          <a routerLink="/buyer-dashboard"
            ><i class="icon-dashboard3"></i>Dashboard</a
          >
        </li>
        <li routerLinkActive="active">
          <a routerLink="/buyer-orders-history" class="btn-edit-profile"
            ><i class="icon-add_shopping_cart"></i>My Orders</a
          >
        </li>
        <li routerLinkActive="active">
          <a
            routerLink="/buyer-mealplan-orders-history"
            class="btn-edit-profile"
            ><i class="icon-add_shopping_cart"></i>My Meal Plan Orders</a
          >
        </li>
        <li routerLinkActive="active">
          <a
            routerLink="/buyer-schedule-orders-history"
            class="btn-edit-profile"
            ><i class="icon-add_shopping_cart"></i>My Schedule Orders</a
          >
        </li>
        <!-- <li routerLinkActive="active"><a routerLink="/buyer-orders" class="btn-edit-profile"><i class="icon-add_shopping_cart"></i>My Orders</a></li> -->
        <!-- <li routerLinkActive="active"><a routerLink="/buyer-bookings" class="btn-edit-profile"><i class="icon-file-text2"></i>My Bookings</a></li> -->
        <li routerLinkActive="active">
          <a routerLink="/buyer-reviews" class="btn-edit-profile"
            ><i class="icon-comment2"></i>My Reviews</a
          >
        </li>
        <li routerLinkActive="active">
          <a routerLink="/buyer-shortlists"
            ><i class="icon-heart"></i>Favourite</a
          >
        </li>
        <li routerLinkActive="active">
          <a routerLink="/buyer-partner"><i class="icon-heart"></i>Partner</a>
        </li>
        <li routerLinkActive="active">
          <a routerLink="/buyer-statement"
            ><i class="icon-file-text22"></i>T-Points</a
          >
        </li>
        <li routerLinkActive="active">
          <a routerLink="/buyer-account-setting"
            ><i class="icon-build"></i>Account Settings</a
          >
        </li>
        <li routerLinkActive="active">
          <a routerLink="/buyer-address"
            ><i class="icon-book"></i>Address Book</a
          >
        </li>
        <li routerLinkActive="active">
          <a routerLink="/buyer-carinfo"
            ><i class="icon-car"></i>Vehicle Detail</a
          >
        </li>
        <li routerLinkActive="active">
          <a routerLink="/buyer-changepassword"
            ><i class="icon-lock"></i>Change Password</a
          >
        </li>
        <!-- <li><a id="profile_delete" href="#"><i class="icon-delete"></i>Delete Profile</a></li> -->
        <li>
          <a (click)="logout()"><i class="icon-log-out"></i>Sign out</a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div id="id_confrmdiv" style="display: none">
  <div class="cs-confirm-container">
    <i class="icon-sad"></i>
    <div class="message">You Want To Delete?</div>
    <a href="javascript:void(0);" id="id_truebtn">Yes, Delete</a>
    <a href="javascript:void(0);" id="id_falsebtn">No, Cancel</a>
  </div>
</div>
