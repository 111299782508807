<div class="inner-banner" style="background-image: url(assets/images/inner-bg.jpg)">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>Partner with us</h3>
                <div class="breadcrumbs">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Partner with us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-section">
    <div class="page-section cs-page-sec-667423 nopadding cs-nomargin" style="
      margin-top: 0px;
      padding-top: 60px;
      padding-bottom: 30px;
      margin-bottom: 0px;
    ">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="faqs">
                            <div class="element-title">
                                <h2>Partner with us</h2>
                            </div>
                            <div class="rich-editor-text">
                                <span class="description">TKAFF.ae, the easiest, simple and economical way to order
                                    food is here. Choose from a wide variety of restaurants across
                                    UAE from any place you're in. Without any haste of memorizing
                                    restaurant names, menus or their contact details. No more
                                    calls on hold, busy tones, wrong deliveries due to
                                    miscommunication. For us in TKAFF, your convenience is
                                    paramount .If you think your order has to be delivered at
                                    curbside, we let you choose "Curb-Side Pick-Up" option. Many
                                    more of such options to give a new customer experience. We
                                    have a fully automated model with no or very less human
                                    intervention guaranteeing a pleasant ordering
                                    experience.</span>
                            </div>
                            <br />
                            <div class="text-center">
                                <h4>ALL EAZE AT NO EXTRA CHARGE!</h4>
                            </div>
                            <br />
                            <div class="title-area">
                                <h2>WHY USE TKAFF APP?</h2>
                                <span class="description">Pocket TKAFF with the TKAFF mobile app. Download our free
                                    mobile app and order food from 1000+ restaurants in the UAE.
                                    Connect to hundreds of restaurants and order from a variety of
                                    cuisines and food through our mobile app. Get exciting offers,
                                    view food images, choose your delivery option, place advance
                                    order and what not.</span>
                                <br />
                                <span class="description">An app as good as our site. Go ahead and pocket it</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>