<div
  class="inner-banner"
  style="background-image: url(assets/images/inner-bg.jpg)"
>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>About Us</h3>
        <div class="breadcrumbs">
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li>About Us</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="inner-container">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6 mb-4">
        <h2><span>About</span> Tkaff</h2>
        <p>
          TKAFF.ae, the easiest, simple and economical way to order food is
          here. Choose from a wide variety of restaurants across UAE from any
          place you're in. Without any haste of memorizing restaurant names,
          menus or their contact details. No more calls on hold, busy tones,
          wrong deliveries due to miscommunication. For us in TKAFF, your
          convenience is paramount .If you think your order has to be delivered
          at curbside, we let you choose "Curb-Side Pick-Up" option. Many more
          of such options to give a new customer experience. We have a fully
          automated model with no or very less human intervention guaranteeing a
          pleasant ordering experience.
        </p>
      </div>
      <div class="col-md-6 mb-4">
        <img src="assets/images/about-img.jpg" class="img-fluid" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="better-than">
          <div class="row align-items-center">
            <div class="col-md-12 col-lg-4">
              <h3>Why we are Better than others</h3>
            </div>
            <div class="col-md-12 col-lg-8">
              <div class="better-icon">
                <div class="boxes">
                  <div class="icons">
                    <img src="assets/images/fast-delivery.svg" />
                  </div>
                  <h5>Fast Delivery</h5>
                </div>
                <div class="boxes">
                  <div class="icons">
                    <img src="assets/images/easy-payment.svg" />
                  </div>
                  <h5>Easy Payment</h5>
                </div>
                <div class="boxes">
                  <div class="icons">
                    <img src="assets/images/maximum-quality.svg" />
                  </div>
                  <h5>Maximum Quality</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="why-tkaff">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6 mb-4">
        <h2>Why use <span>TKAFF</span> app?</h2>
        <p>
          Pocket TKAFF with the TKAFF mobile app. Download our free mobile app
          and order food from 1000+ restaurants in the UAE. Connect to hundreds
          of restaurants and order from a variety of cuisines and food through
          our mobile app. Get exciting offers, view food images, choose your
          delivery option, place advance order and what not.
        </p>
        <p>An app as good as our site. Go ahead and pocket it</p>
        <div class="steps">
          <div class="box">
            <span>1</span>
            <h4>Find your favorite food</h4>
          </div>
          <div class="box">
            <span>2</span>
            <h4>Hot delivery to home</h4>
          </div>
          <div class="box">
            <span>3</span>
            <h4>Receive the great food</h4>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <img src="assets/images/why-tkaff.jpg" class="img-fluid" />
      </div>
    </div>
  </div>
</section>
