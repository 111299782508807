import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buyer-shortlists',
  templateUrl: './buyer-shortlists.component.html',
  styleUrls: ['./buyer-shortlists.component.css']
})
export class BuyerShortlistsComponent implements OnInit {
  userdata:any;
  id:any;
  VendorImagebaseUrl: any =  environment.BASE_API_URL + 'vendor/';


  constructor(
    public appservice: AppService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,) { }

  ngOnInit(): void {

    this.appservice.getUserProfile().subscribe((userdata: any) => {
      console.log(userdata)
      this.id=userdata.customer.id;
      this.getFavourite();
    })
  }

  getFavourite(){
    this.appservice.GetFavourite(this.id).subscribe((fav:any)=>{
      console.log(fav)
      this.userdata=fav;
    })
  }

  remove(res:any){
    console.log(res)
    this.appservice.myRemoveFavourite(res.id).subscribe((re:any)=>{
      console.log(re)
      this.getFavourite();
    })
  }

}
