<div
  class="inner-banner"
  style="background-image: url(assets/images/inner-bg.jpg)"
>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>Reset Password</h3>
        <div class="breadcrumbs">
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Reset Password</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Main Section Start -->
<div class="main-section">
  <div
    class="page-section nopadding cs-nomargin"
    style="
      margin-top: 0px;
      padding-top: 60px;
      padding-bottom: 30px;
      margin-bottom: 0px;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="signup-form login-form">
            <div class="tab-content">
              <div id="login_box" class="tab-pane fade in active">
                <div class="scetion-title">
                  <h2>Reset Password</h2>
                </div>
                <form
                  [formGroup]="resetForm"
                  (ngSubmit)="onResetPasswordFormSubmit()"
                  class="wp-user-form webkit"
                >
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <input matInput type="password" formControlName="Password" placeholder="Password"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <input matInput  type="password" formControlName="ConfirmPassword"  placeholder="Confirm Password"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                      <div
                        class="shortcode-ajax-login-button input-button-loader"
                      >
                        <input
                          type="submit"
                          class="cs-bgcolor user-submit backcolr acc-submit"
                          id="user-submit"
                          value="Submit"
                        />
                      </div>
                    
                    </div>
                   
                  </div>
                </form>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
