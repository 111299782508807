<div
  class="inner-banner"
  style="background-image: url(assets/images/inner-bg.jpg)"
>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>Register</h3>
        <div class="breadcrumbs">
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Register</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Main Section Start -->
<div class="main-section">
  <div
    class="page-section nopadding cs-nomargin"
    style="
      margin-top: 0px;
      padding-top: 60px;
      padding-bottom: 30px;
      margin-bottom: 0px;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="signup-form login-form">
            <div class="tab-content">
              <div id="login_box" class="tab-pane fade">
                <div class="scetion-title">
                  <h2>User Login</h2>
                </div>
                <form class="wp-user-form webkit">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <input type="text" value="Username" />
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <input
                          type="password"
                          name="user_pass"
                          value="Password"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                      <div
                        class="shortcode-ajax-login-button input-button-loader"
                      >
                        <input
                          type="button"
                          class="cs-bgcolor user-submit backcolr acc-submit"
                          id="user-submit"
                          value="Log in"
                        />
                      </div>
                      <a
                        href="#forgot_box"
                        data-toggle="tab"
                        class="user-forgot-password-page"
                      >
                        Forgot Password?</a
                      >
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                      <div class="login-section">
                        <i class="icon-user-add"></i>New Here?
                        <a
                          href="#signup_box"
                          data-toggle="tab"
                          class="register-link-page"
                          >Signup</a
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div id="signup_box" class="tab-pane fade in active">
                <div class="scetion-title">
                  <h2 class="text-center mar-b-20">Sign Up</h2>
                </div>
                <div class="input-info">
                  <div class="row">
                    <form
                      [formGroup]="registrationForm"
                      (ngSubmit)="onRegistrationFormSubmit()"
                      class="wp-user-form webkit"
                    >
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="field-holder">
                          <div class="form-group">
                            <label class="col-form-label">Customer Name:</label>
                            <input
                              formControlName="CustomerName"
                              type="text"
                              size="20"
                              class="form-control"
                              placeholder="Customer Name"
                            />
                            <small
                              *ngIf="
                                f.CustomerName?.invalid &&
                                f.CustomerName?.touched
                              "
                              class="text-danger"
                              >Customer Name is Required</small
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="field-holder">
                          <div class="form-group">
                            <label class="col-form-label"
                              >MobileNo (Username):</label
                            >
                            <input
                              formControlName="MobileNo"
                              type="text"
                              size="20"
                              maxlength="10"
                              class="form-control"
                              placeholder="05XXXXXXXX"
                            />

                            <small
                              *ngIf="f.MobileNo.touched && f.MobileNo.invalid"
                              class="text-danger"
                            >
                              <div *ngIf="f.MobileNo.errors?.required">
                                Mobile Number is required
                              </div>
                              <div *ngIf="f.MobileNo.errors?.pattern">
                                Please enter valid 10 digit Mobile
                                Number(05XXXXXXXX).
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="field-holder">
                          <div class="form-group">
                            <label class="col-form-label"
                              >Alternate MobileNo:</label
                            >
                            <input
                              formControlName="AltMobileNo"
                              type="text"
                              size="20"
                              class="form-control"
                              placeholder="05XXXXXXXX"
                            />
                            <small
                              *ngIf="
                                f.AltMobileNo.touched && f.AltMobileNo.invalid
                              "
                              class="text-danger"
                            >
                              <div *ngIf="f.AltMobileNo.errors?.required">
                                Mobile Number is required
                              </div>
                              <div *ngIf="f.AltMobileNo.errors?.pattern">
                                Please enter valid 10 digit Mobile
                                Number(05XXXXXXXX).
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="field-holder">
                          <div class="form-group">
                            <label class="col-form-label">Email Id:</label>
                            <input
                              formControlName="EmailId"
                              type="text"
                              size="20"
                              class="form-control"
                              placeholder="Email Id"
                            />
                            <small
                              *ngIf="f.EmailId.touched && f.EmailId.invalid"
                              class="text-danger"
                            >
                              <div *ngIf="f.EmailId.errors?.required">
                                Email Id is required
                              </div>
                              <div *ngIf="f.EmailId.errors?.pattern">
                                Please enter a valid Email Id.
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="field-holder">
                          <div class="form-group">
                            <label class="col-form-label">Password:</label>
                            <input
                              formControlName="Password"
                              type="password"
                              size="20"
                              class="form-control"
                              placeholder="Password"
                              maxlength="12"
                            />
                            <!-- <small *ngIf="f.Password?.invalid && f.Password?.touched" class="text-danger">Password is
    Required</small> -->

                            <small
                              *ngIf="f.Password.touched && f.Password.invalid"
                              class="text-danger"
                            >
                              <div *ngIf="f.Password.errors?.required">
                                Password is required
                              </div>
                              <div *ngIf="f.Password.errors?.pattern">
                                Password must contain a uppercase, lowercase,
                                digit
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="field-holder">
                          <div class="form-group">
                            <label class="col-form-label"
                              >Confirm Password:</label
                            >
                            <input
                              formControlName="ConfirmPassword"
                              type="password"
                              size="20"
                              class="form-control"
                              placeholder="Password"
                              maxlength="12"
                            />

                            <small
                              *ngIf="
                                f.ConfirmPassword.touched &&
                                f.ConfirmPassword.invalid
                              "
                              class="text-danger"
                            >
                              <div *ngIf="f.ConfirmPassword.errors?.required">
                                Confirm Password is required
                              </div>
                              <div *ngIf="f.ConfirmPassword.errors?.pattern">
                                Confirm Password must contain a uppercase,
                                lowercase, digit
                              </div>
                            </small>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="checkbox-area">
                          <input
                            type="checkbox"
                            formControlName="accept"
                            id="terms-check"
                            class="foodbakery-dev-req-field"
                          />
                          <label for="terms-check">
                            By Registering You Confirm That You Accept The
                            <a
                              target="_blank"
                              routerLink="/terms-condition"
                              class="text-primary"
                              >Terms &amp; conditions </a
                            >And
                            <a
                              target="_blank"
                              routerLink="/privacy-policy"
                              class="text-primary"
                              >Privacy Policy</a
                            >
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div
                          class="shortcode-ajax-signup-button input-button-loader"
                          style="margin-top: 20px"
                        >
                          <input
                            type="submit"
                            class="cs-bgcolor user-submit acc-submit"
                            value="Create Account"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div class="login-section" style="margin-top: 10px">
                          <i class="icon-user-add"></i> Already have an account?
                          <br />
                          <!-- <a href="#login_box" data-toggle="tab" class="login-link-page">Login here</a> -->
                          <a routerLink="/login" class="login-link-page"
                            >Login here</a
                          >
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- <div class="row">
                                    <div class="form-bg">
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                            <div class="footer-element">
                                                <div class="social-media">
                                                    <h6><span>Login with</span></h6>
                                                    <ul>
                                                        <li>
                                                            <a href="#" title="Facebook" data-original-title="Facebook" class="social_login_login_facebook facebook"><i class="icon-facebook"></i>Sign In With Facebook</a>
                                                        </li>
                                                        <li>
                                                            <a href="#" title="Twitter" data-original-title="twitter" class="social_login_login_twitter twitter"><i class="icon-twitter3"></i>Sign In With Twitter</a>
                                                        </li>
                                                        <li>
                                                            <a href="#" rel="nofollow" title="google-plus" data-original-title="google+" class="social_login_login_google gplus"><i class="icon-google4"></i>Sign In With Google</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
              </div>
              <div id="forgot_box" class="tab-pane fade">
                <div class="scetion-title">
                  <h4>Forgot Password</h4>
                </div>
                <form class="user_form">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-filed">
                        <i class="icon-email"></i>
                        <input
                          type="text"
                          placeholder="Enter email address..."
                          name="user_input"
                        />
                      </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                      <div
                        class="shortcode-ajax-forgot-button input-button-loader"
                      >
                        <input
                          type="submit"
                          class="reset_password user-submit backcolr cs-bgcolor acc-submit"
                          name="submit"
                          value="Submit"
                        />
                      </div>
                    </div>
                    <div class="col-mlg-7 col-md-7 col-sm-12 col-xs-12">
                      <div class="login-here-seaction login-section">
                        <a
                          href="#login_box"
                          data-toggle="tab"
                          class="login-link-page"
                          >Login Here</a
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
