import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-buyer-address',
  templateUrl: './buyer-address.component.html',
  styleUrls: ['./buyer-address.component.css']
})
export class BuyerAddressComponent implements OnInit {

  formAddressGroup!: FormGroup;
  UserData:any;
  geocitylist: any;

  constructor(public appservice: AppService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog, private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.getUserProfile();
    this.formAddressGroup = this.fb.group({
      Id:0,
      ContactNumber: [null],
      StreetName: [null],
      POBoxNumber: [null],
      Location: [null],
      Country: [null],
      Latitude: [null],
      BuildingName:null,
      Longtitude: [null],
      AddType: "Delivery Address"

    });
  }

  public getUserProfile() {
    this.getGeoCityList();
    this.appservice.getUserProfile().subscribe((userdata: any) => {
      console.log(userdata);
      this.UserData = userdata;

    });
  }

  getGeoCityList() {
    this.appservice.getGeoCityList().subscribe((reslist: any) => {
      console.log(reslist)
      this.geocitylist = reslist;
    })
  }

  Changecity(args: any) {

    let selectedItem = this.geocitylist.filter((item: { geoName: any; }) => item.geoName == args.target.value)[0]
    console.log(selectedItem)
    //this.formNameGroup.controls.Country.setValue(selectedItem.geoHead)
    this.formAddressGroup.controls.Latitude.setValue(selectedItem.geoLatitude);
    this.formAddressGroup.controls.Longtitude.setValue(selectedItem.geoLongitude);
    this.formAddressGroup.controls.Country.setValue("UAE")
  }

  EditAddress(add:any){
    console.log(add)
    if(add!=null){
      this.formAddressGroup.controls.Id.setValue(add.id);
      this.formAddressGroup.controls.AddType.setValue(add.addType);
      this.formAddressGroup.controls.ContactNumber.setValue(add.contactNumber);
      this.formAddressGroup.controls.StreetName.setValue(add.streetName);
      this.formAddressGroup.controls.Location.setValue(add.location);
      this.formAddressGroup.controls.POBoxNumber.setValue(add.poBoxNumber);
      this.formAddressGroup.controls.Country.setValue(add.country);
      this.formAddressGroup.controls.Latitude.setValue(add.latitude);
      this.formAddressGroup.controls.Longtitude.setValue(add.longtitude);
      this.formAddressGroup.controls.BuildingName.setValue(add.buildingName);
    }
  }
  RemoveAddress(event:any){
    console.log(event)
    if(event!=null){
      this.appservice.RemoveAddress(event.id).subscribe((res:any)=>{
        console.log(res)
        if(res.result){
          this.appservice.showNotification("success","Delete Successfully","top","center");
        }
      })
    }
  }

  onSubmit(){
    console.log(this.formAddressGroup.value)
    if(this.formAddressGroup.controls.AddType.value != null && this.formAddressGroup.controls.StreetName.value != null &&
      this.formAddressGroup.controls.BuildingName.value != null && this.formAddressGroup.controls.Country.value != null){
            console.log(this.formAddressGroup.value)
            this.appservice.updateUserAddress(this.formAddressGroup.getRawValue()).subscribe((res:any)=>{
              console.log(res);
              this.formAddressGroup.reset();
               this.appservice.showNotification("success","Successfully Updated","top","center");
               this.getUserProfile();
            })
    }
    else{
      this.appservice.showNotification("danger","Please Fill details...","top","center")
 
    }
  }

}
