<div
  class="inner-banner"
  style="background-image: url(assets/images/inner-bg.jpg)"
>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>FAQ</h3>
        <div class="breadcrumbs">
          <ul>
            <li><a href="index.html">Home</a></li>
            <li>FAQ</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="inner-container">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-10 col-lg-8 mb-4">
        <div class="faq-bg">
          <div class="faq">
            <div class="faqitem">
              <div class="header">
                <h4>Why do we use it?</h4>
                <i class="bi bi-plus-lg"></i>
                <i class="bi bi-dash-lg"></i>
              </div>
              <div class="content">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </div>
            </div>
            <div class="faqitem">
              <div class="header">
                <h4>Where does it come from?</h4>
                <i class="bi bi-plus-lg"></i>
                <i class="bi bi-dash-lg"></i>
              </div>
              <div class="content">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32. The standard chunk of Lorem Ipsum used since
                the 1500s is reproduced below for those interested. Sections
                1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by
                Cicero are also reproduced in their exact original form,
                accompanied by English versions from the 1914 translation by H.
                Rackham.
              </div>
            </div>
            <div class="faqitem">
              <div class="header">
                <h4>Where can I get some?</h4>
                <i class="bi bi-plus-lg"></i>
                <i class="bi bi-dash-lg"></i>
              </div>
              <div class="content">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet. It uses a
                dictionary of over 200 Latin words, combined with a handful of
                model sentence structures, to generate Lorem Ipsum which looks
                reasonable. The generated Lorem Ipsum is therefore always free
                from repetition, injected humour, or non-characteristic words
                etc.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
