import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Customer } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {


  public sendLinkForm!: FormGroup;
  public subscribeForm!: FormGroup;

  public loginForm!: FormGroup;
  public hide = false;
  public ismodal="";
  errormsg :string=''
  currentYear: number = new Date().getFullYear();


  constructor(public fb: FormBuilder,
    public router: Router,
    public appservice:AppService,
    private snackBar: MatSnackBar,
    ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: [null,Validators.required],
      password: [null,Validators.required]
    });

      this.sendLinkForm = this.fb.group({
      txtSendLink: [null, [Validators.required,Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });

  this.subscribeForm = this.fb.group({
      txtSubscribe: [null, [Validators.required,Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });


  }

  
  public onLoginFormSubmit(): void {
    console.log(this.loginForm.value)
    this.hide=false;
    if (this.loginForm.valid ) {
      this.appservice
      .login(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe(
        (res) => {
          console.log(res)
          if (res.token !== null) {

            //console.log(res)
            localStorage.setItem("role", res.role.type)
            localStorage.setItem("name", res.user.name)
            this.router.navigate(['/buyer-dashboard']);
            
          }
          else {
            console.log(res)
            //  res.errorMsgs[0];
            //  this.hide=true;
            //  this.errormsg=res.errorMsgs[0];
            this.appservice.showNotification(
              "snackbar-danger",
              res.errorMsgs[0],
              "bottom",
              "center"
            );
          }
        });
    }
    else{
      console.log(this.loginForm.value)
      this.hide=true;
      this.errormsg= "All Fields is required";
      this.appservice.showNotification(
        "snackbar-danger",
        this.errormsg,
        "bottom",
        "center"
      );
      
    }
  }




 onsendLinkFormSubmit(){

    if(this.sendLinkForm.controls.txtSendLink.value!=null){
      //console.log(this.forgotForm.value)
      this.appservice.subscribeemail(this.sendLinkForm.getRawValue()).subscribe((res:any)=>{
       // console.log(res)
        if(res.result!="Fail"){
          this.sendLinkForm.controls.txtSendLink.setValue("");
          this.appservice.showNotification(
            "success",
            "Thanks for subscribing.",
            "top",
            "center"
          );
        }
        else{
          this.appservice.showNotification(
            "danger",
            res.errorMsgs[0],
            "top",
            "center"
          );
        }
      })
    }
    else{
      this.appservice.showNotification(
        "danger",
        "Enter valid Email Id!!!",
        "top",
        "center"
      );
    }
  }


  onsubscribeFormSubmit(){
    if(this.subscribeForm.controls.txtSubscribe.value!=null){
      //console.log(this.forgotForm.value)
      this.appservice.subscribeemail(this.subscribeForm.getRawValue()).subscribe((res:any)=>{
       // console.log(res)
        if(res.result!="Fail"){
          this.subscribeForm.controls.txtSubscribe.setValue("");
          this.appservice.showNotification(
            "success",
            "Thanks for subscribing.",
            "top",
            "center"
          );
        }
        else{
          this.appservice.showNotification(
            "danger",
            res.errorMsgs[0],
            "top",
            "center"
          );
        }
      })
    }
    else{
      this.appservice.showNotification(
        "danger",
        "Enter valid Email Id!!!",
        "top",
        "center"
      );
    }
  }



  get ff(){
    return this.sendLinkForm.controls;
  }

  get fs(){
    return this.subscribeForm.controls;
  }

}
