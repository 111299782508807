import { formatDate } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';

export class Customer {
    constructor(
        public id: number,
        public customerName: string,
        public mobileNo: string,
        public altMobileNo: string,
        public emailId: string,
        public registerDate: string,
        public customerType: string,
        public favouriteDelivery: string,
        public favouritePayment: string,
        public isActive: number,
        public password: string
    ) { }
}


export class GeoVendorLocation {
    constructor(
        public locationId: number,
        public location: string,
        public vendorsCount: number
    ) {

    }
}

export class SummaryCount {
    constructor(public vendorsCount: number,
        public ordersCount: number,
        public regUsers: number) {
    }
}


export class TopCategory {
    constructor(public vendorId: number,
        public categoryName: string,
        public imageFile: string,
        public availTimeStart: string,
        public availTimeEnd: string,
        public isactive: number,
        public id: number) { }
}


export class Category {
    constructor(public id: number,
        public vendorId: number,
        public categoryName: string,
        public imageFile: string,
        public availTimeStart: any,
        public availTimeEnd: any,
        public isactive: number) { }
}


export class MenuItem {
    constructor(public id: number,
        public vendorId: number,
        public menuName: string,
        public description: string,
        public menuGroup: string,
        public rate: number,
        public weekSubscriptionRate: number,
        public monthlySubscriptionRate: number,
        public availTimeStart: any,
        public AvailTimeStart: any,
        public availTimeEnd: any,
        public AvailTimeEnd: any,
        public menuType: string,
        public cateId: number,
        public isactive: number,
        public price: number,
        public discount: number,
        public weight: number,
        public ratingsCount: number,
        public ratingsValue: number,
        public availibilityCount: number,
        public cartCount: number,
        public isVegetarian: boolean,
        public categoryId: number,
        public name: string,
    ) { }
}

export class vendor {
    VendorName: string = '';
    'BusinessName': string = '';
    URLName: string = '';
    RestaurantType: number = 0;
    TradeLicense: string = '';
    FSSAILicense: string = '';
    LocationLicense: string = '';
    SubscriptionCode: string = '';
    Description: string = '';
    ContactNo: string = '';
    CustomerCareNo: string = '';
    EmailId: string = '';
    MinOrderValue: number = 0;
    Validity: string = formatDate(new Date(), "yyyy-MM-dd", "en");
    ActiveStatus: string = '';
    Password: string = '';
}

export class AddToCart {
    public id: number = 0;
    public vendorId: number = 0;
    public menuName: string = '';
    public menuGroup: string = '';
    public rate: number = 0;
    public qty: number = 0;
    public menutax: number = 0;
    public cateId: number = 0;
    public menuId: number = 0;
    public subItemName: string = '';
    public subItemId: number = 0;
    public isSubcription:number = 0
}

//meal plan
export class MealPlanCart{
    public id:number=0;
    public menuname : string='';
    public type : string='';
    public mealrate : number=0;
    public breakfast : boolean = false;
    public lunch : boolean = false;
    public dinner : boolean = false;
    // public mon : boolean=false;
    // public tue : boolean=false;
    // public wed : boolean=false;
    // public thu : boolean=false;
    // public fri : boolean=false;
    // public sat : boolean=false;
    // public sun : boolean=false;
}

export class ScheduleCart {
    public id: number = 0;
    public vendorId: number = 0;
    public menuName: string = '';
    public menuGroup: string = '';
    public rate: number = 0;
    public qty: number = 0;
    public menutax: number = 0;
    public cateId: number = 0;
    public menuId: number = 0;
    public subItemName: string = '';
    public subItemId: number = 0;
    public isSubcription:number = 0
    public mon : boolean=false;
    public tue :boolean=false;
    public wed : boolean=false;
    public thu : boolean=false;
    public fri : boolean=false;
    public sat : boolean=false;
    public sun : boolean=false;
}

export class UserAddress {
    public id:number=0;
    public streetname:string='0';
    public Location:string='0';
    public Country:string='0';

}

export class order {
    constructor(
        public orderNo:number,
        public vendorId:number,
        public vbId:number,
        public orderDate:string,
        public customerId:number,
        public deliverySchDate:string,
        public grossValue:number,
        public discount:number,
        public tax:number,
        public deliverycharges:number,
        public netAmount:number,
        public paymentType:string,
        public deliveryType:string,
        public activeStatus:string,
        public isActive:number,
        public vendorName:string,
        public branchName:string,
        public customerName:string,
        public id:number
    ) {
        
    }
}