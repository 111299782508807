<div
  class="inner-banner"
  style="background-image: url(assets/images/inner-bg.jpg)"
>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>Login</h3>
        <div class="breadcrumbs">
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Login</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Main Section Start -->
<div class="main-section">
  <div
    class="page-section nopadding cs-nomargin"
    style="
      margin-top: 0px;
      padding-top: 60px;
      padding-bottom: 30px;
      margin-bottom: 0px;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="signup-form login-form">
            <div class="tab-content">
              <div id="login_box" class="tab-pane fade in active">
                <div class="scetion-title">
                  <h2 class="text-center mar-b-20">User Login</h2>
                </div>
                <form
                  [formGroup]="loginForm"
                  (ngSubmit)="onLoginFormSubmit()"
                  class="wp-user-form webkit"
                >
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <div class="form-group">
                          <label class="col-form-label"
                            >Username (MobileNo)</label
                          >
                          <input
                            formControlName="username"
                            type="text"
                            size="20"
                            maxlength="10"
                            class="form-control"
                            placeholder="05XXXXXXXX"
                          />

                          <small
                            *ngIf="fl.username.touched && fl.username.invalid"
                            class="text-danger"
                          >
                            <div *ngIf="fl.username.errors?.required">
                              Username is required
                            </div>
                            <div *ngIf="fl.username.errors?.pattern">
                              Please enter valid 10 digit Mobile
                              Number(05XXXXXXXX).
                            </div>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <div class="form-group">
                          <label class="col-form-label">Password</label>
                          <input
                            formControlName="password"
                            type="text"
                            size="20"
                            maxlength="12"
                            type="password"
                            class="form-control"
                            placeholder="Password"
                          />

                          <small
                            *ngIf="fl.password?.invalid && fl.password?.touched"
                            class="text-danger"
                            >Password is Required</small
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                      <div
                        class="shortcode-ajax-login-button input-button-loader"
                      >
                        <input
                          [disabled]="loginForm.invalid"
                          type="submit"
                          class="cs-bgcolor user-submit backcolr acc-submit"
                          id="user-submit"
                          value="Log in"
                        />
                      </div>
                      <a
                        href="#forgot_box"
                        data-toggle="tab"
                        class="user-forgot-password-page"
                      >
                        Forgot Password?</a
                      >
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                      <div class="login-section">
                        <i class="icon-user-add"></i>New Here?
                        <a routerLink="/registration" class="register-link-page"
                          >Signup</a
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div id="forgot_box" class="tab-pane">
                <div class="scetion-title">
                  <h4 class="text-center mar-b-20">Forgot Password</h4>
                </div>
                <form
                  class="user_form"
                  [formGroup]="forgotForm"
                  (ngSubmit)="onForgotFormSubmit()"
                >
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <div class="form-group">
                          <label class="col-form-label">Email Id</label>

                          <input
                            formControlName="EmailId"
                            type="text"
                            size="20"
                            class="form-control"
                            placeholder="Enter email address"
                          />

                          <small
                            *ngIf="ff.EmailId.touched && ff.EmailId.invalid"
                            class="text-danger"
                          >
                            <div *ngIf="ff.EmailId.errors?.required">
                              Email Id is required
                            </div>
                            <div *ngIf="ff.EmailId.errors?.pattern">
                              Please enter a valid Email Id.
                            </div>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                      <div
                        class="shortcode-ajax-forgot-button input-button-loader"
                      >
                        <input
                          [disabled]="forgotForm.invalid"
                          type="submit"
                          class="reset_password user-submit backcolr cs-bgcolor acc-submit"
                          name="submit"
                          value="Submit"
                        />
                      </div>
                    </div>
                    <div class="col-mlg-7 col-md-7 col-sm-12 col-xs-12">
                      <div class="login-here-seaction login-section">
                        <a
                          href="#login_box"
                          data-toggle="tab"
                          class="login-link-page"
                          >Login Here</a
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
