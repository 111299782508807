import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MenuExtraDialogComponent } from './menuextra-dialog/menuextra-dialog.component';
import { AddToCart, MealPlanCart, ScheduleCart } from 'src/app/app.models';
import { SelectAddressDialogComponent } from './selectaddress-dialog/menuextra-dialog/selectaddress-dialog.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { formatDate } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { MealPlanDialogComponent } from './mealplan-dialog/mealplan-dialog.component';
import { MealPlanCheckoutComponent } from './mealplan-checkout/mealplan-checkout.component';
import { ScheduleDialogComponent } from './schedule-dialog/schedule-dialog.component';
import { ScheduleCheckboxComponent } from './schedule-checkout/schedule-checkbox.component';

@Component({
  selector: 'app-listing-detail',
  templateUrl: './listing-detail.component.html',
  styleUrls: ['./listing-detail.component.css'],
})
export class ListingDetailComponent implements OnInit {
  private param: any = 0;
  selectedValue: number = 0;
  isMouseover = true;

  public form!: FormGroup;
  //public formmenu!: FormGroup;
  VendorInfoDet: any;
  Categorylist: any;
  Categorylist2: any;
  Menulist: any;
  Menulist2: any;
  Meallist: any;
  VendorReviewlist: any;
  del_type_list: any;
  VendorImagebaseUrl: any = 'assets/images/listing-logo18.png'; //environment.BASE_API_URL + 'vendor/';
  CategoryImagebaseUrl: any = environment.BASE_API_URL + 'Catalog/';
  MenuImagebaseUrl: any = environment.BASE_API_URL + 'Catalog/';
  MenuNoImagebaseUrl: any = environment.BASE_API_URL + 'img/tkaffnoimage.png';
  dateset = new Date(new Date().setFullYear(new Date().getFullYear()));
  active = '';
  cartArray: Array<AddToCart> = [];
  MealArray: Array<MealPlanCart> = [];
  ScheduleArray: Array<ScheduleCart> = [];
  subtotal: number = 0;
  vendor_vat: number = 2;
  total: number = 0;
  tax: number = 0;
  delfee: number = 0;
  setdate: any;
  UserData: any;
  UserBal: any;
  stars: number[] = [1, 2, 3, 4, 5];
  public formReviewGroup!: FormGroup;
  formmenu = new FormGroup({
    searchmenu: new FormControl(),
  });

  //Visibility cart
  ordernowvisibility: boolean = true;
  schedulevisibility: boolean = false;
  mealvisibility: boolean = false;

  mealplanrate: number = 0;
  mealplantax: number = 0;
  mealplanttot: number = 0;

  scheduleordersubtotal: number = 0;
  scheduleordertax: number = 0;
  scheduleordertotal: number = 0;

  constructor(
    public appservice: AppService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public fb: FormBuilder
  ) {
    this.form = this.fb.group({
      delivery_date: [null, Validators.required],
      PaymentType: [null, Validators.required],
      order_fee_type1: [null, Validators.required],
      ewallet_chk: '',
      eBal: 0,
      delivery_time: '',
    });
  }

  ngOnInit(): void {
    this.setdate = formatDate(this.dateset, 'MM-dd-yyyy hh:mm t', 'en');
    this.activatedRoute.params.subscribe((params) => {
      this.param = params['id'];
    });
    //console.log(this.param)
    if (!localStorage.getItem('token')) {
      localStorage.setItem('tempurl', this.router.url);

      this.router.navigate(['/login']);

      this.appservice.showNotification(
        'danger',
        'Please Login to continue !!!',
        'bottom',
        'center'
      );
    } else {
      if (localStorage.getItem('temp_ord')) {
        this.getReorderItem();
      }

      this.getDeliveryType();
      this.getVendorInfo(this.param);
      this.getUserProfile();
      this.getCategoryList();
      this.getAllMenu();
      this.getMealPlan();
      this.getVendorReview(1);
      this.searchItems();
    }
    this.formReviewGroup = this.fb.group({
      VendorId: this.param,
      Mark: 0,
      ReviewContent: '',
    });
  }

  public getVendorInfo(id: number) {
    this.appservice.getVendorInfo(id).subscribe((info: any) => {
      this.VendorInfoDet = info;
      console.log(info);
      if (info.config) this.vendor_vat = info.config.vat;

      this.MenuImagebaseUrl += info.vendor.identifier + '/';
      this.CategoryImagebaseUrl += info.vendor.identifier + '/';
      if (info.vendor.imageFile) {
        this.VendorImagebaseUrl =
          environment.BASE_API_URL +
          'vendor/' +
          info.vendor.identifier +
          '/Logo/' +
          info.vendor.imageFile;
      }
    });
  }

  public getUserProfile() {
    this.appservice.getUserProfile().subscribe((userdata: any) => {
      console.log(userdata);
      this.UserData = userdata;
      setTimeout(() => {
        this.getUserWalletBal();
      }, 100);
    });
  }

  public getUserWalletBal() {
    let wall = {
      VendorId: this.VendorInfoDet.vendor.id,
      CustomerId: this.UserData.customer.id,
    };
    this.appservice.getUserWalletBAl(wall).subscribe((bal: any) => {
      console.log(bal);
      this.UserBal = bal;
      if (this.UserBal != null && this.UserBal.result.length > 0) {
        this.form.controls.eBal.setValue(this.UserBal.result[0].credit);
      }
    });
  }

  public getCategoryList() {
    this.appservice.getCategoriesMenuList(this.param).subscribe(
      (cate: any) => {
        this.Categorylist = cate;
        this.Categorylist2 = cate;
      },
      (err: HttpErrorResponse) => {
        // error code here
        console.log(err);
      }
    );
  }

  public getAllMenu() {
    this.appservice.getMenuItems(this.param).subscribe((menures: any) => {
      this.Menulist = menures;
      this.Menulist2 = menures;
      //console.log(menures)
    });
  }

  public getMealPlan() {
    this.appservice.getMealPlan(this.param).subscribe((mealres: any) => {
      this.Meallist = mealres;
      console.log(mealres);
    });
  }

  public getVendorReview(id: any) {
    this.appservice.getVendorReview(this.param).subscribe((review: any) => {
      // console.log(review)
      this.VendorReviewlist = review;
    });
  }

  public getDeliveryType() {
    this.appservice.deliveryType().subscribe((delv: any) => {
      console.log(delv);
      this.del_type_list = delv;
    });
  }

  /// Reorder //
  public getReorderItem() {
    let ord_id: any = localStorage.getItem('temp_ord');
    console.log(ord_id);

    this.appservice.getOrderInfo(ord_id).subscribe((detail: any) => {
      console.log(detail);
      //this.Orderdetailss=detail;
      if (detail.orderItem.length > 0) {
        for (let i = 0; i < detail.orderItem.length; i++) {
          let customObj = new AddToCart();
          console.log(detail.orderItem[i].menuName);
          customObj.vendorId = detail.trnsOrder.vendorId;

          customObj.menuName = detail.orderItem[i].menuName;
          customObj.menuId = detail.orderItem[i].menuId;
          customObj.rate = detail.orderItem[i].rate;
          customObj.menutax = detail.orderItem[i].tax;
          customObj.qty = detail.orderItem[i].qty;
          customObj.subItemId = detail.orderItem[i].subMenuId;
          customObj.subItemName = detail.orderItem[i].subMenuName;
          this.cartArray.push(customObj);
          console.log(customObj);
        }

        this.caluateRate();
        this.snackBar.open(' Item add to cart :)', '×', {
          panelClass: 'success',
          verticalPosition: 'top',
          duration: 5000,
        });
      }
    });

    // customObj.vendorId = selectmenu.vendorId;
    // customObj.cateId = selectmenu.cateId;
    // customObj.menuName = selectmenu.menuName;
    // customObj.menuId = selectmenu.id;
    // customObj.rate = selectedPortion[0].rate;
    // customObj.menutax = selectmenu.tax;
    // customObj.subItemId = selectedPortion[0].id;
    // customObj.subItemName = selectedPortion[0].subItemName;
    // customObj.isSubcription = selectmenu.isSubscription;
    //this.cartArray.push(customObj);
    //this.caluateRate();
    localStorage.removeItem('temp_ord');
  }

  category(event: any) {
    //console.log(event)
    //this.active="active"
    if (event != 0) {
      this.Menulist = '';
      this.Categorylist2 = '';
      this.appservice
        .getMenuItemByCategoryId(event)
        .subscribe((menures: any) => {
          this.Menulist = menures;
          this.Categorylist2 = this.Categorylist.filter(
            (item: { id: any }) => item.id == event
          );
          //console.log(menures)
        });
    } else {
      this.Categorylist2 = this.Categorylist;
      this.Menulist = this.Menulist2;
    }
  }

  private searchItems() {
    // let filteredOptions=this.formmenu.controls.searchmenu.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value))
    // );
    // console.log(filteredOptions)
    this.formmenu.controls['searchmenu'].valueChanges.subscribe((value) => {
      console.log(value);
      if (value != '') {
        this.Menulist = this.Menulist2;
        this.Menulist = this.Menulist.filter((option: { menuName: string }) =>
          option?.menuName?.toLowerCase().includes(value)
        );
        for (let i = 0; i < this.Menulist.length; i++) {
          console.log(this.Menulist[i].cateId);
        }
      } else {
        this.Menulist = this.Menulist2;
        this.Categorylist2 = this.Categorylist;
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    console.log(filterValue);
    return this.Menulist.filter((option: { menuName: string }) =>
      option?.menuName?.toLowerCase().includes(filterValue)
    );
  }

  addItem(selectmenu: any) {
    //console.log(selectmenu)
    let customObj = new AddToCart();
    let select= this.cartArray.find((item: any) => item.menuId == selectmenu.id)
    //console.log(select)
    //console.log(this.cartArray)
    if(!select){
      this.appservice.getSubMenuItem(selectmenu.id).subscribe(
        (subitem: any) => {
          if (subitem.length > 0) {
            const dialogRef = this.dialog.open(MenuExtraDialogComponent, {
              data: { passdata: selectmenu, listitem: subitem },
            });
            dialogRef.afterClosed().subscribe((cat) => {
              if (cat) {
                let selectedPortion = subitem.filter(
                  (item: { id: any }) => item.id == cat.selectportionId
                );

                customObj.vendorId = selectmenu.vendorId;
                customObj.cateId = selectmenu.cateId;
                customObj.menuName = selectmenu.menuName;
                customObj.menuId = selectmenu.id;
                customObj.rate = selectedPortion[0].rate;
                customObj.menutax = selectmenu.tax;
                customObj.qty = 1;
                customObj.subItemId = selectedPortion[0].id;
                customObj.subItemName = selectedPortion[0].subItemName;
                customObj.isSubcription = selectmenu.isSubscription;
                this.cartArray.push(customObj);
                this.caluateRate();
                this.snackBar.open(
                  selectmenu.menuName + ' item add to cart :)',
                  '×',
                  {
                    panelClass: 'success',
                    verticalPosition: 'top',
                    duration: 5000,
                  }
                );
              }
              //console.log(this.cartArray)
            });
          } else {

                  customObj.vendorId = selectmenu.vendorId;
                  customObj.cateId = selectmenu.cateId;
                  customObj.menuName = selectmenu.menuName;
                  customObj.menuId = selectmenu.id;
                  customObj.rate = selectmenu.rate;
                  customObj.qty = 1;
                  customObj.menutax = selectmenu.tax;
                  customObj.isSubcription = selectmenu.isSubscription;
                  this.cartArray.push(customObj);
                  //console.log(this.cartArray)
                  this.caluateRate();
                  this.snackBar.open(
                    selectmenu.menuName + ' item add to cart :)',
                    '×',
                    {
                      panelClass: 'success',
                      verticalPosition: 'top',
                      horizontalPosition: 'center',
                      duration: 5000,
                    }
                  );


        }
        },
        (err: HttpErrorResponse) => {
          // error code here
          console.log(err);
        }
      );
    }
    else{
      let findindex=this.cartArray.findIndex((item: any) => item.menuId == selectmenu.id)
      this.cartArray[findindex].qty=this.cartArray[findindex].qty+1;
      this.caluateRate();
      this.snackBar.open(this.cartArray[findindex].menuName + ' item qty '+this.cartArray[findindex].qty, '×', {
        panelClass: 'success',
        verticalPosition: 'top',
        duration: 5000,
      });
    }
  }

  removeItem(i: any, getarr: any) {
    this.snackBar.open(getarr[0].menuName + ' item removed !!!', '×', {
      panelClass: 'danger',
      verticalPosition: 'top',
      duration: 5000,
    });
    this.cartArray.splice(i, 1);
    this.caluateRate();
  }
    //QtyIncreaseRegularOrder
    Qty_decrese(i:any,getarr:any){
      if(this.cartArray[i].qty>1){
        this.cartArray[i].qty=this.cartArray[i].qty-1;
        this.caluateRate();
        this.snackBar.open(this.cartArray[i].menuName + ' item qty '+this.cartArray[i].qty, '×', {
          panelClass: 'danger',
          verticalPosition: 'top',
          duration: 5000,
        });
      }
  
    }

  caluateRate() {
    if (this.cartArray.length > 0) {
      this.subtotal = 0;
      this.total = 0;
      this.delfee = 0;
      // if (this.form.controls.order_fee_type1.value == "1") {
      //   this.delfee=20;
      // }
      // else{
      //   this.delfee=30;
      // }
      for (let i = 0; i < this.cartArray.length; i++) {
        this.subtotal += Number(this.cartArray[i].rate*this.cartArray[i].qty);
        // this.tax =(Number(this.cartArray[i].menutax) * Number(this.cartArray[i].rate)) / 100;
        //console.log(this.cartArray[i].menutax)
      }
      //console.log(this.tax)
     // console.log(this.vendor_vat)

      this.tax = (this.vendor_vat * this.subtotal) / 100;

      this.total = this.subtotal + this.tax;
    }
  }

  deltype(args: any) {
    this.caluateRate();
  }

  selectAddress() {
    console.log(this.form.value);
    if (this.cartArray.length > 0) {
      if (this.form.controls.order_fee_type1.value != null) {
        if (this.form.controls.PaymentType.value != null) {
          if (this.form.controls.PaymentType.value == 'TPoints') {
            this.form.controls.ewallet_chk.setValue('1');
            if (this.form.controls.ewallet_chk.value) {
              if (this.UserBal) {
                if (this.UserBal.result[0].credit > 0) {
                  if (
                    Number(
                      this.total.toFixed(2) <= this.UserBal.result[0].credit
                    )
                  ) {
                    let deltype;
                    let delfee: any = 0;

                    // if (this.form.controls.order_fee_type1.value == '1') {
                    //   //this.delfee = 20
                    //   //delfee = "20";
                    //   deltype = 'Takeaway';
                    // } else if (
                    //   this.form.controls.order_fee_type1.value == '2'
                    // ) {
                    //   //this.delfee = 30
                    //   //delfee = "30";
                    //   deltype = 'Home Delivery';
                    // } else if (
                    //   this.form.controls.order_fee_type1.value == '3'
                    // ) {
                    //   // this.delfee = 25
                    //   // delfee = "25";
                    //   deltype = 'Curbside Pick Up';
                    // }

                    deltype = this.form.controls.order_fee_type1.value;

                    console.log(this.delfee);

                    let order = {
                      VendorId: this.cartArray[0].vendorId,
                      VBId: 0,
                      DeliverySchDate: formatDate(
                        this.dateset,
                        'yyyy-MM-dd',
                        'en'
                      ),
                      GrossValue: this.subtotal.toFixed(2),
                      Discount: 0,
                      TaxAmount: this.tax.toFixed(2),
                      Tax: this.vendor_vat,
                      Deliverycharges: delfee,
                      NetAmount: Number(this.total + this.delfee).toFixed(2),
                      PaymentType: this.form.controls.PaymentType.value,
                      DeliveryType: deltype,
                      OrderType:'RegularOrder',
                      VechicleNo: null,
                      DriverName: null,
                      DriverPhone: null,
                      ActiveStatus: 'New',
                    };

                    const dialogRef = this.dialog.open(
                      SelectAddressDialogComponent,
                      {
                        data: {
                          cartArray: this.cartArray,
                          subtotal: this.subtotal,
                          tax: this.tax,
                          total: this.total,
                          delfee: this.delfee,
                          formdata: this.form.value,
                          userdata: this.UserData,
                          order: order,
                        },
                        disableClose: true,
                      }
                    );

                    dialogRef.afterClosed().subscribe((datas) => {
                      console.log(datas);
                      if (datas === '1') {
                        this.snackBar.open(' Thank you for Ordered :)', '×', {
                          panelClass: 'success',
                          verticalPosition: 'top',
                          duration: 3000,
                        });
                        this.router.navigate(['/home']);
                      }
                    });
                  } else {
                    this.snackBar.open(
                      ' Your E-Wallet Balances less than Order Amount !!!',
                      '×',
                      {
                        panelClass: 'danger',
                        verticalPosition: 'top',
                        duration: 3000,
                      }
                    );
                  }
                } else {
                  this.snackBar.open(' Your E-Wallet Balances is 0 !!!', '×', {
                    panelClass: 'danger',
                    verticalPosition: 'top',
                    duration: 3000,
                  });
                }
              } else {
                this.snackBar.open(' Please Register E-Wallet !!!', '×', {
                  panelClass: 'danger',
                  verticalPosition: 'top',
                  duration: 3000,
                });
              }
            } else {
              this.snackBar.open(
                ' Please select Ewallet Balance / Please Register E-Wallet !!!',
                '×',
                {
                  panelClass: 'danger',
                  verticalPosition: 'top',
                  duration: 3000,
                }
              );
            }
          } else if (
            this.form.controls.PaymentType.value == 'Cash' ||
            this.form.controls.PaymentType.value == 'Card'
          ) {
            this.form.controls.ewallet_chk.setValue('');
            if (this.form.controls.ewallet_chk.value) {
              // if (this.UserBal.result[0].credit > 0) {
              //   let deltype;
              //   let delfee: any = 0;

              //   if (this.form.controls.order_fee_type1.value == "1") {
              //     //this.delfee = 20
              //     //delfee = "20";
              //     deltype = "Takeaway"
              //   }
              //   else if (this.form.controls.order_fee_type1.value == "2") {
              //     //this.delfee = 30
              //     //delfee = "30";
              //     deltype = "Door Delivery"
              //   }
              //   else if (this.form.controls.order_fee_type1.value == "3") {
              //    // this.delfee = 25
              //     // delfee = "25";
              //     deltype = "Cafe Side Pickup"
              //   }

              //   let order = {
              //     VendorId: this.cartArray[0].vendorId,
              //     VBId: 0,
              //     DeliverySchDate: formatDate(this.dateset, "yyyy-MM-dd", "en"),
              //     GrossValue: this.subtotal.toFixed(2),
              //     Discount: 0,
              //     Tax: this.tax.toFixed(2),
              //     Deliverycharges: delfee,
              //     NetAmount: Number(this.total + this.delfee).toFixed(2),
              //     PaymentType: this.form.controls.PaymentType.value,
              //     DeliveryType: deltype,
              //     VechicleNo: null,
              //     DriverName: null,
              //     DriverPhone: null,
              //     ActiveStatus: "New"
              //   }

              //   const dialogRef = this.dialog.open(SelectAddressDialogComponent, {
              //     data: {
              //       cartArray: this.cartArray, subtotal: this.subtotal,
              //       tax: this.tax, total: this.total, delfee: this.delfee,
              //       formdata: this.form.value, userdata: this.UserData, order: order
              //     },
              //     disableClose: true
              //   });

              //   dialogRef.afterClosed().subscribe(datas => {
              //     console.log(datas)
              //     if (datas === "1") {
              //       this.router.navigate(["/home"]);
              //     }
              //   });
              // }
              // else {
              //   this.snackBar.open(" Your E-Wallet Balances is 0 !!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
              // }

              this.snackBar.open(
                ' Please select Ewallet Payment Type !!!',
                '×',
                {
                  panelClass: 'danger',
                  verticalPosition: 'top',
                  duration: 3000,
                }
              );
            } else {
              let deltype;
              let delfee: any = 0;

              deltype = this.form.controls.order_fee_type1.value;

              let order = {
                VendorId: this.cartArray[0].vendorId,
                VBId: 0,
                DeliverySchDate: formatDate(this.dateset, 'yyyy-MM-dd', 'en'),
                GrossValue: this.subtotal.toFixed(2),
                Discount: 0,
                TaxAmount: this.tax.toFixed(2),
                Tax: this.vendor_vat,
                Deliverycharges: delfee,
                NetAmount: Number(this.total + this.delfee).toFixed(2),
                PaymentType: this.form.controls.PaymentType.value,
                DeliveryType: deltype,
                OrderType:'RegularOrder',
                VechicleNo: null,
                DriverName: null,
                DriverPhone: null,
                ActiveStatus: 'New',
              };

              const dialogRef = this.dialog.open(SelectAddressDialogComponent, {
                data: {
                  cartArray: this.cartArray,
                  subtotal: this.subtotal,
                  tax: this.tax,
                  total: this.total,
                  delfee: this.delfee,
                  formdata: this.form.value,
                  userdata: this.UserData,
                  order: order,
                },
                disableClose: true,
              });

              dialogRef.afterClosed().subscribe((datas) => {
                console.log(datas);
                if (datas === '1') {
                  this.snackBar.open(' Thank you for Ordered :)', '×', {
                    panelClass: 'success',
                    verticalPosition: 'top',
                    duration: 3000,
                  });
                  this.router.navigate(['/home']);
                }
              });
            }
          }

          // console.log(order)

          // this.appservice.saveOrderHeader(order).subscribe((orderheader: any) => {

          //   console.log(orderheader)
          //   if (orderheader.result != null) {

          //     for (let i = 0; i < this.cartArray.length; i++) {

          //       let itemtax = (13)
          //       let itemtaxvalue = (13 * this.cartArray[i].rate)

          //       let Items = {
          //         OrderId: orderheader.result.id,
          //         MenuId: this.cartArray[i].menuId,
          //         SubMenuId: this.cartArray[i].subItemId,
          //         MenuName: this.cartArray[i].menuName,
          //         Qty: 1.0,
          //         Rate: this.cartArray[i].rate,
          //         ItemValue: this.cartArray[i].rate,
          //         ExtraAdded: "",
          //         ExtraAddValue: 0,
          //         IsGiftPack: 0,
          //         GiftPackCharge: 0,
          //         Discount: 0.0,
          //         Tax: itemtax,
          //         TaxValue: itemtaxvalue,
          //         itemStatus: ""
          //       }
          //       console.log(Items)

          //       this.appservice.saveOrderDetails(Items).subscribe((resorderdetails: any) => {
          //         console.log(resorderdetails)
          //         if (resorderdetails.result == "Sucess") {
          //           if (this.cartArray.length - 1 == i) {

          //               const dialogRef = this.dialog.open(SelectAddressDialogComponent, {
          //                 data: { cartdata: this.cartArray,subtotal:this.subtotal,
          //                   tax:this.tax,total:this.total, delfee:this.delfee,
          //                   formdata:this.form.value , userdata:this.UserData, orderheader:orderheader },
          //                 disableClose: true
          //               });

          //               dialogRef.afterClosed().subscribe(datas => {
          //                 console.log(datas)
          //                   if(datas==="1"){
          //                       this.router.navigate(["/home"]);
          //                   }
          //               });
          //           }
          //         }

          //       },
          //         (err: HttpErrorResponse) => {
          //           // error code here
          //           console.log(err);
          //         });

          //     }
          //   }

          // },
          //   (err: HttpErrorResponse) => {
          //     // error code here
          //     console.log(err);
          //   });
        } else {
          this.snackBar.open(' Please select Payment type !!!', '×', {
            panelClass: 'danger',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      } else {
        this.snackBar.open(' Please select delivery type !!!', '×', {
          panelClass: 'danger',
          verticalPosition: 'top',
          duration: 3000,
        });
      }
    } else {
      this.snackBar.open(' Please Add Item to cart!!!', '×', {
        panelClass: 'danger',
        verticalPosition: 'top',
        duration: 3000,
      });
    }
  }


  countStar(star: number) {
    this.isMouseover = false;
    this.selectedValue = star;
  }

  addClass(star: number) {
    if (this.isMouseover) {
      this.selectedValue = star;
    }
  }

  removeClass() {
    if (this.isMouseover) {
      this.selectedValue = 0;
    }
  }

  public finish(): void {
    this.formReviewGroup.controls.Mark.setValue(this.selectedValue);
    console.log(this.formReviewGroup.getRawValue());
    this.appservice
      .saveVendorReview(this.formReviewGroup.getRawValue())
      .subscribe((res) => {
        this.getVendorReview(1);
        this.snackBar.open(' Thank you review :)', '×', {
          panelClass: 'success',
          verticalPosition: 'top',
          duration: 5000,
        });
      });
  }

  TabVisibility(event: any) {
    if (event === 'order') {
      this.ordernowvisibility = true;
      this.schedulevisibility = false;
      this.mealvisibility = false;
    } else if (event === 'schedule') {
      this.ordernowvisibility = false;
      this.schedulevisibility = true;
      this.mealvisibility = false;
    } else if (event === 'meal') {
      this.ordernowvisibility = false;
      this.schedulevisibility = false;
      this.mealvisibility = true;
    }
  }

  //Meal Plan
  addMealItem(select: any, num: number) {
    let mealarr = new MealPlanCart();
    //let mealplanrate:number=0;

    if (this.MealArray.length == 0) {
      const dialogRef = this.dialog.open(MealPlanDialogComponent, {
        data: { passdata: select, num: num },
      });

      dialogRef.afterClosed().subscribe((datas) => {
        console.log(datas);
        if (datas != null) {
          mealarr.menuname = datas.Selectmealname;
          mealarr.type = datas.selecttypeId;
          mealarr.breakfast = datas.selectmealbreakfast;
          mealarr.lunch = datas.selectmeallanch;
          mealarr.dinner = datas.selectmealdinner;
          if (datas.selecttypeId == 'Veg') {
            if (datas.selectmealbreakfast == true) {
              this.mealplanrate = this.Meallist.vegBreakFast;
            }
            if (datas.selectmeallanch == true) {
              this.mealplanrate += this.Meallist.vegLunch;
            }
            if (datas.selectmealdinner == true) {
              this.mealplanrate += this.Meallist.vegDinner;
            }
          } else if (datas.selecttypeId == 'Non Veg') {
            if (datas.selectmealbreakfast == true) {
              this.mealplanrate = this.Meallist.nVegBreakFast;
            }
            if (datas.selectmeallanch == true) {
              this.mealplanrate += this.Meallist.nVegLunch;
            }
            if (datas.selectmealdinner == true) {
              this.mealplanrate += this.Meallist.nVegDinner;
            }
          }
          mealarr.mealrate = this.mealplanrate;
          this.mealplantax = (this.mealplanrate * this.vendor_vat) / 100;
          this.mealplanttot = this.mealplanrate + this.mealplantax;
          this.MealArray.push(mealarr);
          this.snackBar.open(num + ' Meal item add to cart :)', '×', {
            panelClass: 'success',
            verticalPosition: 'top',
            duration: 5000,
          });
        }
      });
    } else {
      this.snackBar.open('Allowed only one !!!', '×', {
        panelClass: 'danger',
        verticalPosition: 'top',
        duration: 5000,
      });
    }
  }

  removeMealItem(id: number, getarr: any) {
    this.snackBar.open(getarr[0].menuname + ' item removed !!!', '×', {
      panelClass: 'danger',
      verticalPosition: 'top',
      duration: 5000,
    });
    this.MealArray.splice(id, 1);
  }

  CheckoutMealPlan() {
    console.log(this.form.value);
    if (this.MealArray.length > 0) {
      if (this.form.controls.order_fee_type1.value != null) {
        if (this.form.controls.PaymentType.value != null) {
          if (this.form.controls.PaymentType.value == 'TPoints') {
            this.form.controls.ewallet_chk.setValue('1');
            if (this.form.controls.ewallet_chk.value) {
              if (this.UserBal) {
                if (this.UserBal.result[0].credit > 0) {
                  if (
                    Number(
                      this.total.toFixed(2) <= this.UserBal.result[0].credit
                    )
                  ) {
                    let deltype;
                    let delfee: any = 0;

                    deltype = this.form.controls.order_fee_type1.value;

                    console.log(this.delfee);

                    let order = {
                      VendorId: this.param,
                      VBId: 0,
                      DeliverySchDate: formatDate(
                        this.dateset,
                        'yyyy-MM-dd',
                        'en'
                      ),
                      GrossValue: this.mealplanrate.toFixed(2),
                      Discount: 0,
                      TaxAmount: this.mealplantax.toFixed(2),
                      Tax: this.vendor_vat,
                      Deliverycharges: delfee,
                      NetAmount: Number(
                        this.mealplanttot + this.delfee
                      ).toFixed(2),
                      PaymentType: this.form.controls.PaymentType.value,
                      DeliveryType: deltype,
                      OrderType:'MealPlan',
                      VechicleNo: null,
                      DriverName: null,
                      DriverPhone: null,
                      ActiveStatus: 'New',
                    };

                    const dialogRef = this.dialog.open(
                      MealPlanCheckoutComponent,
                      {
                        data: {
                          cartArray: this.MealArray,
                          subtotal: this.mealplanrate,
                          tax: this.mealplantax,
                          total: this.mealplanttot,
                          delfee: this.delfee,
                          formdata: this.form.value,
                          userdata: this.UserData,
                          order: order,
                        },
                        disableClose: true,
                      }
                    );

                    dialogRef.afterClosed().subscribe((datas) => {
                      console.log(datas);
                      if (datas === '1') {
                        this.snackBar.open(' Thank you for Ordered :)', '×', {
                          panelClass: 'success',
                          verticalPosition: 'top',
                          duration: 3000,
                        });
                        this.router.navigate(['/home']);
                      }
                    });
                  } else {
                    this.snackBar.open(
                      ' Your E-Wallet Balances less than Order Amount !!!',
                      '×',
                      {
                        panelClass: 'danger',
                        verticalPosition: 'top',
                        duration: 3000,
                      }
                    );
                  }
                } else {
                  this.snackBar.open(' Your E-Wallet Balances is 0 !!!', '×', {
                    panelClass: 'danger',
                    verticalPosition: 'top',
                    duration: 3000,
                  });
                }
              } else {
                this.snackBar.open(' Please Register E-Wallet !!!', '×', {
                  panelClass: 'danger',
                  verticalPosition: 'top',
                  duration: 3000,
                });
              }
            } else {
              this.snackBar.open(
                ' Please select Ewallet Balance / Please Register E-Wallet !!!',
                '×',
                {
                  panelClass: 'danger',
                  verticalPosition: 'top',
                  duration: 3000,
                }
              );
            }
          } else if (this.form.controls.PaymentType.value == 'Cash') {
            this.form.controls.ewallet_chk.setValue('');
            if (this.form.controls.ewallet_chk.value) {
              // if (this.UserBal.result[0].credit > 0) {
              //   let deltype;
              //   let delfee: any = 0;

              //   if (this.form.controls.order_fee_type1.value == "1") {
              //     //this.delfee = 20
              //     //delfee = "20";
              //     deltype = "Takeaway"
              //   }
              //   else if (this.form.controls.order_fee_type1.value == "2") {
              //     //this.delfee = 30
              //     //delfee = "30";
              //     deltype = "Door Delivery"
              //   }
              //   else if (this.form.controls.order_fee_type1.value == "3") {
              //    // this.delfee = 25
              //     // delfee = "25";
              //     deltype = "Cafe Side Pickup"
              //   }

              //   let order = {
              //     VendorId: this.cartArray[0].vendorId,
              //     VBId: 0,
              //     DeliverySchDate: formatDate(this.dateset, "yyyy-MM-dd", "en"),
              //     GrossValue: this.subtotal.toFixed(2),
              //     Discount: 0,
              //     Tax: this.tax.toFixed(2),
              //     Deliverycharges: delfee,
              //     NetAmount: Number(this.total + this.delfee).toFixed(2),
              //     PaymentType: this.form.controls.PaymentType.value,
              //     DeliveryType: deltype,
              //     VechicleNo: null,
              //     DriverName: null,
              //     DriverPhone: null,
              //     ActiveStatus: "New"
              //   }

              //   const dialogRef = this.dialog.open(SelectAddressDialogComponent, {
              //     data: {
              //       cartArray: this.cartArray, subtotal: this.subtotal,
              //       tax: this.tax, total: this.total, delfee: this.delfee,
              //       formdata: this.form.value, userdata: this.UserData, order: order
              //     },
              //     disableClose: true
              //   });

              //   dialogRef.afterClosed().subscribe(datas => {
              //     console.log(datas)
              //     if (datas === "1") {
              //       this.router.navigate(["/home"]);
              //     }
              //   });
              // }
              // else {
              //   this.snackBar.open(" Your E-Wallet Balances is 0 !!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
              // }

              this.snackBar.open(
                ' Please select Ewallet Payment Type !!!',
                '×',
                {
                  panelClass: 'danger',
                  verticalPosition: 'top',
                  duration: 3000,
                }
              );
            } else {
              let deltype;
              let delfee: any = 0;

              deltype = this.form.controls.order_fee_type1.value;

              let order = {
                VendorId: this.param,
                VBId: 0,
                DeliverySchDate: formatDate(this.dateset, 'yyyy-MM-dd', 'en'),
                GrossValue: this.mealplanrate.toFixed(2),
                Discount: 0,
                TaxAmount: this.mealplantax.toFixed(2),
                Tax: this.vendor_vat,
                Deliverycharges: delfee,
                NetAmount: Number(this.mealplanttot + this.delfee).toFixed(2),
                PaymentType: this.form.controls.PaymentType.value,
                DeliveryType: deltype,
                OrderType:'MealPlan',
                VechicleNo: null,
                DriverName: null,
                DriverPhone: null,
                ActiveStatus: 'New',
              };

              const dialogRef = this.dialog.open(MealPlanCheckoutComponent, {
                data: {
                  cartArray: this.MealArray,
                  subtotal: this.mealplanrate,
                  tax: this.mealplantax,
                  total: this.mealplanttot,
                  delfee: this.delfee,
                  formdata: this.form.value,
                  userdata: this.UserData,
                  order: order,
                },
                disableClose: true,
              });

              dialogRef.afterClosed().subscribe((datas) => {
                console.log(datas);
                if (datas === '1') {
                  this.snackBar.open(' Thank you for Ordered :)', '×', {
                    panelClass: 'success',
                    verticalPosition: 'top',
                    duration: 3000,
                  });
                  this.router.navigate(['/home']);
                }
              });
            }
          } else if (this.form.controls.PaymentType.value == 'Card') {
            let deltype;
            let delfee: any = 0;

            deltype = this.form.controls.order_fee_type1.value;

            let order = {
              VendorId: this.param,
              VBId: 0,
              DeliverySchDate: formatDate(this.dateset, 'yyyy-MM-dd', 'en'),
              GrossValue: this.mealplanrate.toFixed(2),
              Discount: 0,
              TaxAmount: this.mealplantax.toFixed(2),
              Tax: this.vendor_vat,
              Deliverycharges: delfee,
              NetAmount: Number(this.mealplanttot + this.delfee).toFixed(2),
              PaymentType: this.form.controls.PaymentType.value,
              DeliveryType: deltype,
              OrderType:'MealPlan',
              VechicleNo: null,
              DriverName: null,
              DriverPhone: null,
              ActiveStatus: 'New',
            };

            const dialogRef = this.dialog.open(MealPlanCheckoutComponent, {
              data: {
                cartArray: this.MealArray,
                subtotal: this.mealplanrate,
                tax: this.mealplantax,
                total: this.mealplanttot,
                delfee: this.delfee,
                formdata: this.form.value,
                userdata: this.UserData,
                order: order,
              },
              disableClose: true,
            });

            dialogRef.afterClosed().subscribe((datas) => {
              console.log(datas);
              if (datas === '1') {
                this.snackBar.open(' Thank you for Ordered :)', '×', {
                  panelClass: 'success',
                  verticalPosition: 'top',
                  duration: 3000,
                });
                this.router.navigate(['/home']);
              }
            });
          }

          // console.log(order)

          // this.appservice.saveOrderHeader(order).subscribe((orderheader: any) => {

          //   console.log(orderheader)
          //   if (orderheader.result != null) {

          //     for (let i = 0; i < this.cartArray.length; i++) {

          //       let itemtax = (13)
          //       let itemtaxvalue = (13 * this.cartArray[i].rate)

          //       let Items = {
          //         OrderId: orderheader.result.id,
          //         MenuId: this.cartArray[i].menuId,
          //         SubMenuId: this.cartArray[i].subItemId,
          //         MenuName: this.cartArray[i].menuName,
          //         Qty: 1.0,
          //         Rate: this.cartArray[i].rate,
          //         ItemValue: this.cartArray[i].rate,
          //         ExtraAdded: "",
          //         ExtraAddValue: 0,
          //         IsGiftPack: 0,
          //         GiftPackCharge: 0,
          //         Discount: 0.0,
          //         Tax: itemtax,
          //         TaxValue: itemtaxvalue,
          //         itemStatus: ""
          //       }
          //       console.log(Items)

          //       this.appservice.saveOrderDetails(Items).subscribe((resorderdetails: any) => {
          //         console.log(resorderdetails)
          //         if (resorderdetails.result == "Sucess") {
          //           if (this.cartArray.length - 1 == i) {

          //               const dialogRef = this.dialog.open(SelectAddressDialogComponent, {
          //                 data: { cartdata: this.cartArray,subtotal:this.subtotal,
          //                   tax:this.tax,total:this.total, delfee:this.delfee,
          //                   formdata:this.form.value , userdata:this.UserData, orderheader:orderheader },
          //                 disableClose: true
          //               });

          //               dialogRef.afterClosed().subscribe(datas => {
          //                 console.log(datas)
          //                   if(datas==="1"){
          //                       this.router.navigate(["/home"]);
          //                   }
          //               });
          //           }
          //         }

          //       },
          //         (err: HttpErrorResponse) => {
          //           // error code here
          //           console.log(err);
          //         });

          //     }
          //   }

          // },
          //   (err: HttpErrorResponse) => {
          //     // error code here
          //     console.log(err);
          //   });
        } else {
          this.snackBar.open(' Please select Payment type !!!', '×', {
            panelClass: 'danger',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      } else {
        this.snackBar.open(' Please select delivery type !!!', '×', {
          panelClass: 'danger',
          verticalPosition: 'top',
          duration: 3000,
        });
      }
    } else {
      this.snackBar.open(' Please Add Item to cart!!!', '×', {
        panelClass: 'danger',
        verticalPosition: 'top',
        duration: 3000,
      });
    }
  }
  //Schedule

  addscheduleItem(event: any) {
    let scharr = new ScheduleCart();
    let select= this.ScheduleArray.find((item: any) => item.menuId == event.id)
    if(!select){
    this.appservice.getSubMenuItem(event.id).subscribe(
      (subitem: any) => {
        if (subitem.length > 0) {
          const dialogRef = this.dialog.open(MenuExtraDialogComponent, {
            data: { passdata: event, listitem: subitem },
          });
          dialogRef.afterClosed().subscribe((cat) => {
            if (cat) {
              let selectedPortion = subitem.filter(
                (item: { id: any }) => item.id == cat.selectportionId
              );

              const dialogRef2 = this.dialog.open(ScheduleDialogComponent, {
                data: { passdata: event },
              });

              dialogRef2.afterClosed().subscribe((datas) => {
                console.log(datas);
                if (datas) {
                  scharr.vendorId = event.vendorId;
                  scharr.cateId = event.cateId;
                  scharr.menuName = event.menuName;
                  scharr.menuId = event.id;
                  scharr.rate = selectedPortion[0].rate;
                  scharr.qty = 1;
                  scharr.menutax = event.tax;
                  scharr.subItemId = selectedPortion[0].id;
                  scharr.subItemName = selectedPortion[0].subItemName;
                  scharr.mon = datas.select_mon;
                  scharr.tue = datas.select_tue;
                  scharr.wed = datas.select_wed;
                  scharr.thu = datas.select_thu;
                  scharr.fri = datas.select_fri;
                  scharr.sat = datas.select_sat;
                  scharr.sun = datas.select_sun;
                  this.ScheduleArray.push(scharr);
                  this.caluateScheduleRate();
                  this.snackBar.open(
                    event.menuName + ' item add to cart :)',
                    '×',
                    {
                      panelClass: 'success',
                      verticalPosition: 'top',
                      duration: 5000,
                    }
                  );
                }
              });
            }
            //console.log(this.cartArray)
          });
        } else {
          const dialogRef = this.dialog.open(ScheduleDialogComponent, {
            data: { passdata: event },
          });

          dialogRef.afterClosed().subscribe((datas) => {
            console.log(datas);
            if (datas) {
              scharr.vendorId = event.vendorId;
              scharr.cateId = event.cateId;
              scharr.menuName = event.menuName;
              scharr.menuId = event.id;
              scharr.rate = event.rate;
              scharr.qty = 1;
              scharr.menutax = event.tax;
              scharr.mon = datas.select_mon;
              scharr.tue = datas.select_tue;
              scharr.wed = datas.select_wed;
              scharr.thu = datas.select_thu;
              scharr.fri = datas.select_fri;
              scharr.sat = datas.select_sat;
              scharr.sun = datas.select_sun;
              this.ScheduleArray.push(scharr);
              this.caluateScheduleRate();
              this.snackBar.open(event.menuName + ' item add to cart :)', '×', {
                panelClass: 'success',
                verticalPosition: 'top',
                duration: 5000,
              });
            }
          });
        }
      },
      (err: HttpErrorResponse) => {
        // error code here
        console.log(err);
      }
    );
    }
    else{

      let findindex=this.ScheduleArray.findIndex((item: any) => item.menuId == event.id)
      this.ScheduleArray[findindex].qty=this.ScheduleArray[findindex].qty+1;
      this.caluateScheduleRate();
      this.snackBar.open(this.ScheduleArray[findindex].menuName + ' item qty '+this.ScheduleArray[findindex].qty, '×', {
        panelClass: 'success',
        verticalPosition: 'top',
        duration: 5000,
      });    
    }
  }

  
  //QtyIncreaseScheduleOrder
  Schedule_Qty_decrese(i:any,getarr:any){
    if(this.ScheduleArray[i].qty>1){
      this.ScheduleArray[i].qty=this.ScheduleArray[i].qty-1;
      this.caluateScheduleRate();
      this.snackBar.open(this.ScheduleArray[i].menuName + ' item qty '+this.ScheduleArray[i].qty, '×', {
        panelClass: 'danger',
        verticalPosition: 'top',
        duration: 5000,
      });
    }
  }

  removeScheduleItem(id: number, getarr: any) {
    this.snackBar.open(getarr[0].menuName + ' item removed !!!', '×', {
      panelClass: 'danger',
      verticalPosition: 'top',
      duration: 5000,
    });
    this.ScheduleArray.splice(id, 1);
    this.caluateScheduleRate();
  }

  caluateScheduleRate() {
    if (this.ScheduleArray.length > 0) {
      this.scheduleordersubtotal = 0;
      this.scheduleordertotal = 0;
      this.delfee = 0;
      // if (this.form.controls.order_fee_type1.value == "1") {
      //   this.delfee=20;
      // }
      // else{
      //   this.delfee=30;
      // }
      for (let i = 0; i < this.ScheduleArray.length; i++) {
        this.scheduleordersubtotal += Number(this.ScheduleArray[i].rate * this.ScheduleArray[i].qty);
        this.scheduleordertax =
          (Number(this.vendor_vat) * Number(this.ScheduleArray[i].rate * this.ScheduleArray[i].qty)) / 100;
        //console.log(this.cartArray[i].menutax)
      }
      //console.log(this.tax)

      //this.tax = (13 * this.subtotal) / 100;

      this.scheduleordertotal =
        this.scheduleordersubtotal + this.scheduleordertax;
    }
  }

  CheckoutSchedule() {
    console.log(this.form.value);
    if (this.ScheduleArray.length > 0) {
      if (this.form.controls.order_fee_type1.value != null) {
        if (this.form.controls.delivery_time.value != '') {
          if (this.form.controls.PaymentType.value != null) {
            if (this.form.controls.PaymentType.value == 'TPoints') {
              this.form.controls.ewallet_chk.setValue('1');
              if (this.form.controls.ewallet_chk.value) {
                if (this.UserBal) {
                  if (this.UserBal.result[0].credit > 0) {
                    if (
                      Number(
                        this.total.toFixed(2) <= this.UserBal.result[0].credit
                      )
                    ) {
                      let deltype;
                      let delfee: any = 0;

                      deltype = this.form.controls.order_fee_type1.value;

                      console.log(this.delfee);

                      let order = {
                        VendorId: this.ScheduleArray[0].vendorId,
                        VBId: 0,
                        DeliverySchDate: formatDate(
                          this.dateset,
                          'yyyy-MM-dd',
                          'en'
                        ),
                        GrossValue: this.scheduleordersubtotal.toFixed(2),
                        Discount: 0,
                        TaxAmount: this.scheduleordertax.toFixed(2),
                        Tax: this.vendor_vat,
                        Deliverycharges: delfee,
                        NetAmount: Number(
                          this.scheduleordertotal + this.delfee
                        ).toFixed(2),
                        PaymentType: this.form.controls.PaymentType.value,
                        DeliveryType: deltype,
                        OrderType:'Subscripton',
                        VechicleNo: null,
                        DriverName: null,
                        DriverPhone: null,
                        ActiveStatus: 'New',
                      };

                      const dialogRef = this.dialog.open(
                        ScheduleCheckboxComponent,
                        {
                          data: {
                            cartArray: this.ScheduleArray,
                            subtotal: this.scheduleordersubtotal,
                            tax: this.scheduleordertax,
                            total: this.scheduleordertotal,
                            delfee: this.delfee,
                            formdata: this.form.value,
                            userdata: this.UserData,
                            order: order,
                          },
                          disableClose: true,
                        }
                      );

                      dialogRef.afterClosed().subscribe((datas) => {
                        console.log(datas);
                        if (datas === '1') {
                          this.snackBar.open(' Thank you for Ordered :)', '×', {
                            panelClass: 'success',
                            verticalPosition: 'top',
                            duration: 3000,
                          });
                          this.router.navigate(['/home']);
                        }
                      });
                    } else {
                      this.snackBar.open(
                        ' Your E-Wallet Balances less than Order Amount !!!',
                        '×',
                        {
                          panelClass: 'danger',
                          verticalPosition: 'top',
                          duration: 3000,
                        }
                      );
                    }
                  } else {
                    this.snackBar.open(
                      ' Your E-Wallet Balances is 0 !!!',
                      '×',
                      {
                        panelClass: 'danger',
                        verticalPosition: 'top',
                        duration: 3000,
                      }
                    );
                  }
                } else {
                  this.snackBar.open(' Please Register E-Wallet !!!', '×', {
                    panelClass: 'danger',
                    verticalPosition: 'top',
                    duration: 3000,
                  });
                }
              } else {
                this.snackBar.open(
                  ' Please select Ewallet Balance / Please Register E-Wallet !!!',
                  '×',
                  {
                    panelClass: 'danger',
                    verticalPosition: 'top',
                    duration: 3000,
                  }
                );
              }
            } else if (this.form.controls.PaymentType.value == 'Cash') {
              this.form.controls.ewallet_chk.setValue('');
              if (this.form.controls.ewallet_chk.value) {
                // if (this.UserBal.result[0].credit > 0) {
                //   let deltype;
                //   let delfee: any = 0;

                //   if (this.form.controls.order_fee_type1.value == "1") {
                //     //this.delfee = 20
                //     //delfee = "20";
                //     deltype = "Takeaway"
                //   }
                //   else if (this.form.controls.order_fee_type1.value == "2") {
                //     //this.delfee = 30
                //     //delfee = "30";
                //     deltype = "Door Delivery"
                //   }
                //   else if (this.form.controls.order_fee_type1.value == "3") {
                //    // this.delfee = 25
                //     // delfee = "25";
                //     deltype = "Cafe Side Pickup"
                //   }

                //   let order = {
                //     VendorId: this.cartArray[0].vendorId,
                //     VBId: 0,
                //     DeliverySchDate: formatDate(this.dateset, "yyyy-MM-dd", "en"),
                //     GrossValue: this.subtotal.toFixed(2),
                //     Discount: 0,
                //     Tax: this.tax.toFixed(2),
                //     Deliverycharges: delfee,
                //     NetAmount: Number(this.total + this.delfee).toFixed(2),
                //     PaymentType: this.form.controls.PaymentType.value,
                //     DeliveryType: deltype,
                //     VechicleNo: null,
                //     DriverName: null,
                //     DriverPhone: null,
                //     ActiveStatus: "New"
                //   }

                //   const dialogRef = this.dialog.open(SelectAddressDialogComponent, {
                //     data: {
                //       cartArray: this.cartArray, subtotal: this.subtotal,
                //       tax: this.tax, total: this.total, delfee: this.delfee,
                //       formdata: this.form.value, userdata: this.UserData, order: order
                //     },
                //     disableClose: true
                //   });

                //   dialogRef.afterClosed().subscribe(datas => {
                //     console.log(datas)
                //     if (datas === "1") {
                //       this.router.navigate(["/home"]);
                //     }
                //   });
                // }
                // else {
                //   this.snackBar.open(" Your E-Wallet Balances is 0 !!!", '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
                // }

                this.snackBar.open(
                  ' Please select Ewallet Payment Type !!!',
                  '×',
                  {
                    panelClass: 'danger',
                    verticalPosition: 'top',
                    duration: 3000,
                  }
                );
              } else {
                let deltype;
                let delfee: any = 0;

                deltype = this.form.controls.order_fee_type1.value;

                let order = {
                  VendorId: this.ScheduleArray[0].vendorId,
                  VBId: 0,
                  DeliverySchDate: formatDate(this.dateset, 'yyyy-MM-dd', 'en'),
                  GrossValue: this.scheduleordersubtotal.toFixed(2),
                  Discount: 0,
                  TaxAmount: this.scheduleordertax.toFixed(2),
                  Tax: this.vendor_vat,
                  Deliverycharges: delfee,
                  NetAmount: Number(
                    this.scheduleordertotal + this.delfee
                  ).toFixed(2),
                  PaymentType: this.form.controls.PaymentType.value,
                  DeliveryType: deltype,
                  OrderType:'Subscripton',
                  VechicleNo: null,
                  DriverName: null,
                  DriverPhone: null,
                  ActiveStatus: 'New',
                };

                const dialogRef = this.dialog.open(ScheduleCheckboxComponent, {
                  data: {
                    cartArray: this.ScheduleArray,
                    subtotal: this.scheduleordersubtotal,
                    tax: this.scheduleordertax,
                    total: this.scheduleordertotal,
                    delfee: this.delfee,
                    formdata: this.form.value,
                    userdata: this.UserData,
                    order: order,
                  },
                  disableClose: true,
                });

                dialogRef.afterClosed().subscribe((datas) => {
                  console.log(datas);
                  if (datas === '1') {
                    this.snackBar.open(' Thank you for Ordered :)', '×', {
                      panelClass: 'success',
                      verticalPosition: 'top',
                      duration: 3000,
                    });
                    this.router.navigate(['/home']);
                  }
                });
              }
            } else if (
              this.form.controls.PaymentType.value == 'Card'
            ) {
              let deltype;
              let delfee: any = 0;

              deltype = this.form.controls.order_fee_type1.value;

              console.log(this.delfee);

              const date = new Date();
              var sub = date.getDay() > 0 ? 1 : -6;
              var monday = new Date(
                date.setDate(date.getDate() - date.getDay() + sub)
              );
              var sunday = new Date(
                date.setDate(date.getDate() - date.getDay() + 7)
              );
              console.log(monday);
              console.log(sunday);
              let order = {
                VendorId: this.ScheduleArray[0].vendorId,
                CustomerId: this.UserData.customer.id,
                SubscriptonName: 'Subscripton',
                OrderStartDate: formatDate(monday, 'yyyy-MM-dd', 'en'),
                OrderEndDate: formatDate(sunday, 'yyyy-MM-dd', 'en'),
                PaymentDetails: '',
                DeliveryTime: this.form.controls.delivery_time.value,

                PaymentType: 'Card',
                DeliveryType: deltype,
                VechicleNo: null,
                DriverName: null,
                DriverPhone: null,
                CarMake: '',
                CarModel: '',
                CarColor: '',
                ActiveStatus: 'Active',
              };

              const dialogRef = this.dialog.open(ScheduleCheckboxComponent, {
                data: {
                  cartArray: this.ScheduleArray,
                  subtotal: this.scheduleordersubtotal,
                  tax: this.scheduleordertax,
                  total: this.scheduleordertotal,
                  delfee: this.delfee,
                  formdata: this.form.value,
                  userdata: this.UserData,
                  order: order,
                },
                disableClose: true,
              });

              dialogRef.afterClosed().subscribe((datas) => {
                console.log(datas);
                if (datas === '1') {
                  this.snackBar.open(' Thank you for Ordered :)', '×', {
                    panelClass: 'success',
                    verticalPosition: 'top',
                    duration: 3000,
                  });
                  this.router.navigate(['/home']);
                }
              });
            }

            // console.log(order)

            // this.appservice.saveOrderHeader(order).subscribe((orderheader: any) => {

            //   console.log(orderheader)
            //   if (orderheader.result != null) {

            //     for (let i = 0; i < this.cartArray.length; i++) {

            //       let itemtax = (13)
            //       let itemtaxvalue = (13 * this.cartArray[i].rate)

            //       let Items = {
            //         OrderId: orderheader.result.id,
            //         MenuId: this.cartArray[i].menuId,
            //         SubMenuId: this.cartArray[i].subItemId,
            //         MenuName: this.cartArray[i].menuName,
            //         Qty: 1.0,
            //         Rate: this.cartArray[i].rate,
            //         ItemValue: this.cartArray[i].rate,
            //         ExtraAdded: "",
            //         ExtraAddValue: 0,
            //         IsGiftPack: 0,
            //         GiftPackCharge: 0,
            //         Discount: 0.0,
            //         Tax: itemtax,
            //         TaxValue: itemtaxvalue,
            //         itemStatus: ""
            //       }
            //       console.log(Items)

            //       this.appservice.saveOrderDetails(Items).subscribe((resorderdetails: any) => {
            //         console.log(resorderdetails)
            //         if (resorderdetails.result == "Sucess") {
            //           if (this.cartArray.length - 1 == i) {

            //               const dialogRef = this.dialog.open(SelectAddressDialogComponent, {
            //                 data: { cartdata: this.cartArray,subtotal:this.subtotal,
            //                   tax:this.tax,total:this.total, delfee:this.delfee,
            //                   formdata:this.form.value , userdata:this.UserData, orderheader:orderheader },
            //                 disableClose: true
            //               });

            //               dialogRef.afterClosed().subscribe(datas => {
            //                 console.log(datas)
            //                   if(datas==="1"){
            //                       this.router.navigate(["/home"]);
            //                   }
            //               });
            //           }
            //         }

            //       },
            //         (err: HttpErrorResponse) => {
            //           // error code here
            //           console.log(err);
            //         });

            //     }
            //   }

            // },
            //   (err: HttpErrorResponse) => {
            //     // error code here
            //     console.log(err);
            //   });
          } else {
            this.snackBar.open(' Please select Payment type !!!', '×', {
              panelClass: 'danger',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        } else {
          this.snackBar.open(' Please select Delivery Time !!!', '×', {
            panelClass: 'danger',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      } else {
        this.snackBar.open(' Please select delivery type !!!', '×', {
          panelClass: 'danger',
          verticalPosition: 'top',
          duration: 3000,
        });
      }
    } else {
      this.snackBar.open(' Please Add Item to cart!!!', '×', {
        panelClass: 'danger',
        verticalPosition: 'top',
        duration: 3000,
      });
    }
  }

  //////////////////
}
