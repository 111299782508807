<div class="main-section">
    <app-buyer-header></app-buyer-header>
    <div class="page-section account-header buyer-logged-in">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <app-buyer-sidebar></app-buyer-sidebar>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div class="user-dashboard loader-holder">
                        <div class="user-holder">
                            <div class="user-profile">
                                
                                <div class="element-title has-border">
                                    <h5>Address Details</h5>
                                </div>
                                <form [formGroup]="formAddressGroup" >
                                  <div class="row">
                                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          <div class="payment-summary-fields">
                                            <div class="row">
                                              <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                  <label>Address Type</label>
                                                  <select formControlName="AddType">
                                                    <option value="Primary Address">Primary Address</option>
                                                    <option value="Secondary Address">Secondary Address</option>
                                                    <option value="Billing Address">Billing Address</option>
                                                  </select>
                                                </div>
                                              </div> -->
                                              
                          
                                              
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                  <label>Building Name</label>
                                                  <input type="text" class="foodbakery-dev-req-field foodbakery-email-field" name="BuildingName"
                                                     formControlName="BuildingName"  value="" placeholder="Building Name">
                                                </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                  <label>Street Name</label>
                                                  <!-- <textarea class="foodbakery-dev-req-field" name="trans_address"></textarea> -->
                                                  <input type="text" class="foodbakery-dev-req-field foodbakery-number-field"
                                                  formControlName="StreetName" name="StreetName" placeholder="Street Name" value="">
                                                </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                  <label>Location</label>
                                                  <!-- <textarea class="foodbakery-dev-req-field" name="trans_address"></textarea> -->
                                                  <!-- <input type="text" class="foodbakery-dev-req-field foodbakery-number-field"
                                                    name="trans_phone_number" placeholder="Location" value=""> -->
                                                  <select (change)="Changecity($event)" name="Location" formControlName="Location">
                                                    <option selected="selected" value="">Select Location
                                                    </option>
                                                    <option [value]="option.geoName" *ngFor="let option of geocitylist">{{option.geoName}}</option>
                          
                                                  </select>
                                                </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                  <label>Country</label>
                                                  <!-- <textarea class="foodbakery-dev-req-field" name="trans_address"></textarea> -->
                                                  <input type="text" class="foodbakery-dev-req-field foodbakery-number-field"
                                                    formControlName="Country" name="Country" placeholder="Country" value="">
                                                </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                  <label>Phone Number</label>
                                                  <input type="text" class="foodbakery-dev-req-field foodbakery-number-field" name="ContactNumber"
                                                    formControlName="ContactNumber" placeholder="Phone Number" value="">
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          <div class="field-holder">
                                            <div class="payment-holder">
                                              <div class="payment-section">
                          
                                              </div><button id="register-restaurant-order" class="submit btn-submit" 
                                                type="submit" (click)="onSubmit()">Save</button>
                                            </div>
                                          </div>
                                        </div>
                                  </div>
                              </form>
                              <div class="row">
                                <div class="responsive-table">
                                  <ul class="table-generic">
                                      <li class="order-heading-titles">
                                          
                                          <div>Building Name</div>
                                          <div>StreetName</div>
                                          <div>Location</div>
                                          <div>Country</div>
                                          <div>Contact no</div>
                                          <div></div>
                                          <div></div>
                                      </li>
                                      <ng-container *ngIf="UserData">
                                        <li class="order-heading-titles" *ngFor="let res of UserData.address; let i=index;">
                                          
                                          <div>{{res.buildingName}}</div>
                                          <div>{{res.streetName}}</div>
                                          <div>{{res.location}}</div>
                                          <div>{{res.country}}</div>
                                          <div>{{res.contactNumber}}</div>
                                          <div> <div class="list-option">
                                            <a  class="shortlist-btn" style="color: #39b44d !important;" (click)="EditAddress(res)">
                                                <i class="icon-edit2"></i>
                                            </a>
                                        </div> </div>
                                        <div> <div class="list-option">
                                         
                                          <a  class="shortlist-btn" style="color: #f8640e !important;" (click)="RemoveAddress(res)">
                                            <i class="icon-trash"></i>
                                        </a>
                                      </div> </div>
                                      </li>
                                  </ng-container>
                                  </ul>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
