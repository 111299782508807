<div class="main-section">
    <app-buyer-header></app-buyer-header>
    <div class="page-section account-header buyer-logged-in">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <app-buyer-sidebar></app-buyer-sidebar>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div class="user-dashboard loader-holder">
                        <div class="user-holder">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="element-title has-border right-filters-row">
                                        <h5>My Bookings</h5>
                                        <div class="right-filters row pull-right">
                                            <div class="col-lg-6 col-md-6 col-xs-6">
                                                <div class="input-field">
                                                    <select class="chosen-select-no-single">
                      <option selected="selected" value="">Select Booking Status</option>
                      <option value="Processing">Processing</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Completed">Completed</option>
                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-xs-6">
                                                <div class="input-field">
                                                    <i class="icon-angle-down"></i>
                                                    <input type="text" data-id="daterange223" id="daterange" value="" placeholder="Select Date Range">
                                                    <script>
                                                        $(function() {
                                                            $('input[data-id="daterange223"]').daterangepicker({
                                                                opens: 'left'
                                                            }, function(start, end, label) {
                                                                console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
                                                            });
                                                        });
                                                    </script>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="user-orders-list responsive-table">
                                        <ul class="table-generic" id="portfolio">
                                            <li>
                                                <div class="orders-title">Restaurant Name</div>
                                                <div class="orders-date">Date</div>
                                                <div class="orders-type">Status</div>
                                                <div class="orders-price">Detail</div>
                                            </li>
                                            <li>
                                                <div class="orders-title">
                                                    <h6 class="order-title">
                                                        <a href="/#" data-toggle="modal" data-target="#booking-detail-22603">Restaurant Demo </a>
                                                        <span>( #22603 )</span>
                                                    </h6>
                                                </div>
                                                <div class="orders-date">
                                                    <span>Apr 9,2019</span>
                                                </div>
                                                <div class="orders-status">
                                                    <span class="booking-status" style="background-color: #1e73be;">Processing</span>
                                                </div>
                                                <div class="orders-price">
                                                    <a href="/#" data-toggle="modal" data-target="#booking-detail-22603"><i class="icon-plus2 text-color"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="orders-title">
                                                    <h6 class="order-title">
                                                        <a href="/#" data-toggle="modal" data-target="#booking-detail-22603">Restaurant Demo </a>
                                                        <span>( #22600 )</span>
                                                    </h6>
                                                </div>
                                                <div class="orders-date">
                                                    <span>Apr 9,2019</span>
                                                </div>
                                                <div class="orders-status">
                                                    <span class="booking-status" style="background-color: #1e73be;">Processing</span>
                                                </div>
                                                <div class="orders-price">
                                                    <a href="/#" data-toggle="modal" data-target="#booking-detail-22603"><i class="icon-plus2 text-color"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="orders-title">
                                                    <h6 class="order-title">
                                                        <a href="/javascript:void(0);" data-toggle="modal" data-target="#booking-detail-22603">Restaurant Demo </a>
                                                        <span>( #22445 )</span>
                                                    </h6>
                                                </div>
                                                <div class="orders-date">
                                                    <span>Mar 30,2019</span>
                                                </div>
                                                <div class="orders-status">
                                                    <span class="booking-status" style="background-color: #047a06;">Completed</span>
                                                </div>
                                                <div class="orders-price">
                                                    <a href="/javascript:void(0);" data-toggle="modal" data-target="#booking-detail-22603"><i class="icon-plus2 text-color"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="orders-title">
                                                    <h6 class="order-title">
                                                        <a href="/#" data-toggle="modal" data-target="#booking-detail-22603">Restaurant Demo </a>
                                                        <span>( #22438 )</span>
                                                    </h6>
                                                </div>
                                                <div class="orders-date">
                                                    <span>Mar 30,2019</span>
                                                </div>
                                                <div class="orders-status">
                                                    <span class="booking-status" style="background-color: #1e73be;">Processing</span>
                                                </div>
                                                <div class="orders-price">
                                                    <a href="/#" data-toggle="modal" data-target="#booking-detail-22603"><i class="icon-plus2 text-color"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="orders-title">
                                                    <h6 class="order-title">
                                                        <a href="/#" data-toggle="modal" data-target="#booking-detail-22603">Dragon Express </a>
                                                        <span>( #22405 )</span>
                                                    </h6>
                                                </div>
                                                <div class="orders-date">
                                                    <span>Mar 27,2019</span>
                                                </div>
                                                <div class="orders-status">
                                                    <span class="booking-status" style="background-color: #1e73be;">Processing</span>
                                                </div>
                                                <div class="orders-price">
                                                    <a href="/#" data-toggle="modal" data-target="#booking-detail-22603"><i class="icon-plus2 text-color"></i></a>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="orders-title">
                                                    <h6 class="order-title">
                                                        <a href="/#" data-toggle="modal" data-target="#booking-detail-22603">Restaurant Demo </a>
                                                        <span>( #22149 )</span>
                                                    </h6>
                                                </div>
                                                <div class="orders-date">
                                                    <span>Mar 15,2019</span>
                                                </div>
                                                <div class="orders-status">
                                                    <span class="booking-status" style="background-color: #047a06;">Completed</span>
                                                </div>
                                                <div class="orders-price">
                                                    <a href="/#" data-toggle="modal" data-target="#booking-detail-22603"><i class="icon-plus2 text-color"></i></a>
                                                </div>
                                            </li>
                                            <script>
                                                (function($) {
                                                    $(document).ready(function() {
                                                        $(".menu-order-info .modal-dialog .modal-content").mCustomScrollbar({
                                                            setHeight: 467,
                                                            theme: "minimal-dark",
                                                            mouseWheelPixels: 100
                                                        });
                                                    });
                                                })(jQuery);
                                            </script>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <ul class="pagination">
                                <li class="active"><a>1</a></li>
                                <li><a routerLink="/#">2</a></li>
                                <li><a routerLink="/#">3</a></li>
                                <li><span class="page-numbers dots">…</span></li>
                                <li><a routerLink="/#">24</a></li>
                                <li><a routerLink="/#">Next </a></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Popup Start -->
<div class="modal fade menu-order-detail menu-order-info" id="booking-detail-22603" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <h2><a>Booking Detail</a></h2>
            </div>
            <div class="modal-body booking-modal-body">
                <div class="order-detail-inner">
                    <h3>Restaurant Demo </h3>
                    <ul class="order-detail-options">
                        <li>
                            <strong>Booking ID :</strong>
                            <span>22603</span>
                        </li>
                        <li>
                            <strong>Booking Date :</strong>
                            <span>Apr 9,2019 6:40 AM </span>
                        </li>
                    </ul>
                    <h3>Customer Deatil</h3>
                    <ul class="order-detail-options">
                        <li>
                            <strong>First Name:</strong>
                            <span>Mark</span>
                        </li>
                        <li>
                            <strong>Last Name:</strong>
                            <span>Jose</span>
                        </li>
                        <li>
                            <strong>Email:</strong>
                            <span>markjose@gmail.com</span>
                        </li>
                        <li>
                            <span>4-guest</span>
                        </li>
                        <li>
                            <span>Apr 30,2019 11:00 AM</span>
                        </li>
                        <li class="order-detail-message">
                            <strong>Instructions:</strong>
                            <span>I need to book a special table for my friends. Make it some special.!</span>
                        </li>
                    </ul>
                    <div class="booking-status-holder">
                        <div class="booking-status-process booking-status">
                            <p style="background:#1e73be;">Your booking is Processing.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
