<div class="main-section">
  <app-buyer-header></app-buyer-header>
  <div class="page-section account-header buyer-logged-in">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <app-buyer-sidebar></app-buyer-sidebar>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div class="user-dashboard loader-holder">
            <div class="user-holder">
              <div class="user-profile">
                <div class="element-title has-border">
                  <h5>Account Settings</h5>
                </div>
                <div class="row">
                  <form
                    [formGroup]="formNameGroup"
                    id="publisher_profile"
                    (ngSubmit)="onSubmit()"
                  >
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="field-holder">
                            <label>Full Name*</label>
                            <input
                              type="text"
                              class="foodbakery-dev-req-field"
                              formControlName="CustomerName"
                              name="CustomerName"
                              value=""
                              required
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="field-holder">
                            <label>Email Address *</label>
                            <input
                              type="text"
                              class="foodbakery-dev-req-field"
                              formControlName="EmailId"
                              name="EmailId"
                              value=""
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="field-holder">
                            <label>Phone Number *</label>
                            <input
                              type="text"
                              class="foodbakery-dev-req-field"
                              formControlName="MobileNo"
                              name="MobileNo"
                              value=""
                              required readonly
                            />
                          </div>
                        </div>
                        <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="field-holder">
                            <label>T-Coins</label>
                            <input
                              type="text"
                              class="foodbakery-dev-req-field"
                              formControlName="ewallet"
                              value="0"
                              readonly
                            />
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                      <div class="user-profile-images">
                        <div class="current-img">
                          <div class="row mt">
                            <div
                              id="cropContainerModal"
                              data-def-img="assets/images/team-medium-img1.jpg"
                              data-img-type="default"
                            >
                              <figure>
                                <a>
                                  <img id="img-upload"  [src]="imageURL "   alt=""  />
                              </a>
                              </figure>
                            </div>
                          </div>
                          <br>
                        </div>
                        <div class="">
                          <label class="fileContainer">
                            Upload Picture
                            <input type="file" (change)="uploadimg($event)"/>
                          </label>
                          <!-- <input id="file" type="file" class="btn-primary"> -->

                          <!-- <button #file type="button" id="imgInp">
                            <span>Upload Picture</span>
                            <input id="file" type="file" class="hide" />
                          </button> -->
                        </div>
                        <!-- <div class="upload-file">
                          <input id="file-1" type="file" class="hide" />
                          <button type="button" id="imgInp">
                            <span>Upload Picture</span>
                          </button>
                        </div> -->

                        <!-- test -->
                        <!-- <div class="container col-md-6">
                          <div class="mb-5">
                            <label for="Image" class="form-label"
                              >Bootstrap 5 image Upload with Preview</label
                            >
                            <input
                              class="form-control"
                              type="file"
                              id="formFile"
                            
                            />
                            <button
                             
                              class="btn btn-primary mt-3"
                            >
                              Click me
                            </button>
                          </div>
                          <img id="frame" src="" class="img-fluid" />
                        </div> -->
                        <!-- <ul class="uploaded-img">
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2570" src="assets/images/icon1.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2571" src="assets/images/icon2.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2572" src="assets/images/icon3.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2573" src="assets/images/icon4.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2574" src="assets/images/icon5.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2575" src="assets/images/icon6.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2576" src="assets/images/icon7.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2577" src="assets/images/icon8.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2578" src="assets/images/icon9.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2579" src="assets/images/icon10.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2580" src="assets/images/icon11.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2581" src="assets/images/icon12.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2582" src="assets/images/icon13.png" alt="">
                                                        </figure>
                                                    </li>
                                                    <li>
                                                        <figure>
                                                            <img data-attachment_id="2583" src="assets/images/icon14.png" alt="">
                                                        </figure>
                                                    </li>
                                                </ul> -->
                      </div>
                    </div>
                    <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="opt-conts">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="field-holder">
                                                            <label>Country *</label>
                                                            <div class="select-holder">
                                                                <select data-placeholder="Select Country" class="form-control chosen-select form-select-country dir-map-search foodbakery-dev-req-field" id="loc_country_publisher" name="foodbakery_post_loc_country_publisher">
                            <option value="">Select Country</option>
                            <option value="barry" data-name="">Barry</option>
                            <option value="cardiff" data-name="">Cardiff</option>
                            <option value="cowbridge" data-name="">Cowbridge</option>
                            <option value="england" data-name="">England</option>
                            <option value="llantwit-major" data-name="">Llantwit Major</option>
                            <option value="northern-ireland" data-name="">Northern Ireland</option>
                            <option value="penarth" data-name="">Penarth</option>
                            <option value="scotland" data-name="">Scotland</option>
                            <option value="wales" data-name="">Wales</option>
                          </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="field-holder">
                                                            <label>State</label>
                                                            <div class="select-holder">
                                                                <select data-placeholder="Select State" class="chosen-select">
                            <option value="">Select State</option>
                          </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="field-holder">
                                                            <label>City</label>
                                                            <div class="select-holder">
                                                                <select data-placeholder="Select City" class="chosen-select">
                            <option value="">Select City</option>
                          </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="field-holder">
                                                            <label>Latitude</label>
                                                            <input type="text" placeholder="Latitude" class="form-control gllpLatitude" value="">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="field-holder">
                                                            <label>Longitude</label>
                                                            <input type="text" placeholder="Longitude" class="form-control gllpLongitude" value="">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="field-holder">
                                                            <label>Find On Map</label>
                                                            <input type="text" placeholder="Type Your Address" class="foodbakery-search-location">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <div class="field-holder"></div>
                                                        <div class="field-holder search-location-map input-button-loader">
                                                            <input type="button" class="acc-submit cs-section-update cs-color gllpSearchButton" value="Search Location">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="cs-map-section">
                                                            <img style="width:100%;height: 100%;" src="assets/images/map-location.png" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                    <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="element-title has-border">
                                                <h5>Change Password</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="field-holder">
                                                <label> Current Password*</label>
                                                <input type="password">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="field-holder">
                                                <label> New Password*</label>
                                                <input type="password">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="field-holder">
                                                <label> Confirm New Password* </label>
                                                <input type="password">
                                            </div>
                                        </div> -->
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <input type="submit" class="btn-submit" value="Save" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
