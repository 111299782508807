<div
  class="inner-banner"
  style="background-image: url(assets/images/inner-bg.jpg)"
>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>Contact Us</h3>
        <div class="breadcrumbs">
          <ul>
            <li><a routerLink="/">Home</a></li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main-section">
  <div
    class="page-section cs-page-sec-667423 nopadding cs-nomargin"
    style="
      margin-top: 0px;
      padding-top: 60px;
      padding-bottom: 30px;
      margin-bottom: 0px;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="faqs">
                <div class="element-title">
                  <h2>Frequently Asked Questions.</h2>
                </div>
                <div class="faq panel-group" id="faq_573980">
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse84771"
                          aria-expanded="false"
                          >What is Tkaff?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapse84771"
                      role="tabpanel"
                      class="panel-collapse collapse"
                      aria-expanded="false"
                      style="height: 0px"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse48228"
                          aria-expanded="false"
                          >How does it work?</a
                        >
                      </h4>
                    </div>
                    <div
                      id="collapse48228"
                      role="tabpanel"
                      class="panel-collapse collapse"
                      aria-expanded="false"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse86232"
                          aria-expanded="false"
                          >How can my restaurant benefit from tkaff?</a
                        >
                      </h4>
                    </div>
                    <div
                      id="collapse86232"
                      role="tabpanel"
                      class="panel-collapse collapse"
                      aria-expanded="false"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse32316"
                          aria-expanded="false"
                          >What are the benefits for people who use the app?</a
                        >
                      </h4>
                    </div>
                    <div
                      id="collapse32316"
                      role="tabpanel"
                      class="panel-collapse collapse"
                      aria-expanded="false"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse41290"
                          aria-expanded="false"
                          >How much do I pay for Food Bakery service?</a
                        >
                      </h4>
                    </div>
                    <div
                      id="collapse41290"
                      role="tabpanel"
                      class="panel-collapse collapse"
                      aria-expanded="false"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse99514"
                          aria-expanded="false"
                          >Do I have to pay commission for upsales at
                          restaurant?</a
                        >
                      </h4>
                    </div>
                    <div
                      id="collapse99514"
                      role="tabpanel"
                      class="panel-collapse collapse"
                      aria-expanded="false"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse76754"
                          aria-expanded="false"
                          >What are the terms and conditions of the
                          contracts?</a
                        >
                      </h4>
                    </div>
                    <div
                      id="collapse76754"
                      role="tabpanel"
                      class="panel-collapse collapse"
                      aria-expanded="false"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse61772"
                          aria-expanded="false"
                          >I already use a reservation online ordering
                          service?</a
                        >
                      </h4>
                    </div>
                    <div
                      id="collapse61772"
                      role="tabpanel"
                      class="panel-collapse collapse"
                      aria-expanded="false"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse26921"
                          aria-expanded="false"
                          >Does Tkaff promote in-store marketing?</a
                        >
                      </h4>
                    </div>
                    <div
                      id="collapse26921"
                      role="tabpanel"
                      class="panel-collapse collapse"
                      aria-expanded="false"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a
                          class=""
                          data-toggle="collapse"
                          data-parent="#faq_573980"
                          href="#collapse26831"
                          aria-expanded="true"
                          >How do I accept a new order?</a
                        >
                      </h4>
                    </div>
                    <div
                      id="collapse26831"
                      role="tabpanel"
                      class="panel-collapse collapse in"
                      aria-expanded="true"
                    >
                      <div class="panel-body">
                        is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="contact-info">
                <div class="element-title">
                  <h3>Contact Tkaff</h3>
                </div>
                <ul>
                  <li>
                    <div class="text-holder">
                      <span class="title">Call us</span
                      ><strong>+9714 10254</strong>
                    </div>
                  </li>
                  <li>
                    <div class="text-holder">
                      <span class="title">Email For sales</span
                      ><strong>info@tkaff.ae</strong>
                    </div>
                    <div class="text-holder">
                      <span class="title">Email For support</span
                      ><strong>support@tkaff.ae</strong>
                    </div>
                  </li>
                  <li>
                    <div class="text-holder">
                      <span class="title">Address</span
                      ><strong
                        >SIT Towers, Dubai Silicon Oasis (DSO) Dubai,
                        UAE.</strong
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <div class="contact-form">
                <div class="element-title">
                  <h4>Get In touch</h4>
                </div>
                <div class="form-holder row" id="ul_frm5005">
                  <form
                    name="frm5005"
                    id="frm5005"
                    action="javascript:foodbakery_var_contact_frm_submit(5005)"
                  >
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <strong>First Name *</strong
                        ><input
                          name="contact_name"
                          type="text"
                          placeholder="First Name"
                          required=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <strong>Last Name *</strong
                        ><input
                          name="contact_name_last"
                          type="text"
                          placeholder="Last Name"
                          required=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <strong>Email Address *</strong
                        ><input
                          name="contact_email"
                          type="text"
                          placeholder="Email"
                          required=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <strong>Phone number</strong
                        ><input
                          name="contact_number"
                          type="text"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="field-holder">
                        <strong>Message</strong
                        ><textarea
                          name="contact_msg"
                          placeholder="Text here.."
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="input-btn">
                        <div class="contact-ajax-button input-button-loader">
                          <input
                            type="submit"
                            value="Submit message"
                            class="bgcolor"
                          />
                        </div>
                        <div id="loading_div5005"></div>
                        <div id="message22" class="status status-message"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
