import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.css']
})
export class HeaderUserComponent implements OnInit {

  user : any;
  imageURL: any | undefined;

  constructor(public appservice:AppService,
    public router:Router) { }

  ngOnInit(): void {
    //this.name = localStorage.getItem('name')
    this.imageURL = "assets/images/team-medium-img1.jpg";
    this.appservice.getUserProfile().subscribe((res:any)=>{
      console.log(res)
      this.user=res
      if(this.user.customer.customerphoto){
        this.imageURL = environment.BASE_API_URL+"CustomerPhoto/"+this.user.customer.id+"/"+this.user.customer.customerphoto
      }
    })
  }

  home(event:any)
  {
    console.log(event)
    if(event==="home"){
      this.router.navigate(['/']);
    } else if(event==="about"){
      this.router.navigate(['/about-us']);

    } else if(event==="contact"){
      this.router.navigate(['/contact-us']);

    }

  }

  logout() {
    this.appservice.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/']);
      }
    });
  }

}
