import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buyer-account-setting',
  templateUrl: './buyer-account-setting.component.html',
  styleUrls: ['./buyer-account-setting.component.css'],
})
export class BuyerAccountSettingComponent implements OnInit {
  formNameGroup!: FormGroup;
  UserData: any;
  imageURL: any | undefined;
  NoimageURL: any | undefined;
  ProfileURL: any | undefined;
  public UserformData = new FormData();

  constructor(
    public appservice: AppService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit(): void {
    this.imageURL = "assets/images/team-medium-img1.jpg";
    this.ProfileURL = environment.BASE_API_URL+"";
    this.getUserProfile();
    this.formNameGroup = this.fb.group({
      id: 0,
      CustomerName: [null],
      MobileNo: [null],
      AltMobileNo: [null],
      EmailId: [null],
     
     
    });
  }

  public getUserProfile() {
    this.appservice.getUserProfile().subscribe((userdata: any) => {
      console.log(userdata);
      this.UserData = userdata;
      this.formNameGroup.controls.id.setValue(this.UserData.customer.id);
      this.formNameGroup.controls.CustomerName.setValue(
        this.UserData.customer.customerName
      );
      this.formNameGroup.controls.MobileNo.setValue(
        this.UserData.customer.mobileNo
      );
      this.formNameGroup.controls.EmailId.setValue(
        this.UserData.customer.emailId
      );

      if(this.UserData.customer.customerphoto){
        this.imageURL = environment.BASE_API_URL+"CustomerPhoto/"+this.UserData.customer.id+"/"+this.UserData.customer.customerphoto
      }
    
     
    });
  }

  uploadimg(event: any) {
    console.log(event.target.files[0])

    if (event.target.files && event.target.files[0]) {
      this.UserformData.delete('File');
      this.UserformData.append('File', event.target.files[0]);
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = () => { // called once readAsDataURL is completed
        // this.url = event.target.result;
       // console.log(reader.result)
        this.imageURL = reader.result;

      }
    }
  }

  onSubmit() {
    if (this.formNameGroup.valid) {
      console.log(this.formNameGroup.value);
      this.appservice
        .UserUpadteProfile(this.formNameGroup.getRawValue())
        .subscribe((res: any) => {
          if (this.UserformData.getAll('File').length > 0) {
            this.UserformData.append('Id', this.formNameGroup.controls.id.value);
            this.UserformData.append('FileType', "CustomerPhoto");
            console.log(this.UserformData.getAll('File'))
            console.log(this.UserformData.getAll('Id'))
            console.log(this.UserformData.getAll('FileType'))
            this.appservice.UserUploadProfile(this.UserformData).subscribe((ress: any) => {
              console.log(ress)
              this.getUserProfile();
              this.appservice.showNotification(
                'success',
                'Successfully Updated',
                'top',
                'center'
              );
             })
          }
          else {
            console.log(res);
            this.appservice.showNotification(
              'success',
              'Successfully Updated',
              'top',
              'center'
            );
          }
         // this.getUserProfile();
        });
    } else {
      this.appservice.showNotification(
        'danger',
        'Please Fill details...',
        'top',
        'center'
      );
    }
  }
}
