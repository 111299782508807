
<mat-dialog-content>
  
  <div class="container1">
    <div class="order-detail-inner">
      <div class="reviews-holder">
        <div class="add-new-review-holder add-new-review-2067">
         
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p>Are you want delete?
                </p>
              </div>
              <div class="foodbakery-add-review-data">
              
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <div class="form-element">
                    <input type="button" (click)="dismiss()" class="btn_review" value="cancel">
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <div class="form-element">
                    <input type="button" (click)="accept()" class="ok-confirm" value="ok">
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
