import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-buyer-carinfo',
  templateUrl: './buyer-carinfo.component.html',
  styleUrls: ['./buyer-carinfo.component.css']
})
export class BuyerCarInfoComponent implements OnInit {

  formAddressGroup!: FormGroup;
  UserData:any;
  geocitylist: any;

  constructor(public appservice: AppService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog, private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.getUserProfile();
    this.formAddressGroup = this.fb.group({
      Id:0,
      DriverName: [null],
      DriverPhoneNo: [null],
      CarNumber: [null],
      CarMake: [null],
      CarModel: [null],
      CarColor: [null],

    });
  }

  public getUserProfile() {
   
    this.appservice.getUserProfile().subscribe((userdata: any) => {
      console.log(userdata);
      this.UserData = userdata;
      this.formAddressGroup.controls.Id.setValue(userdata.customer.id )
      this.formAddressGroup.controls.DriverName.setValue(userdata.customer.driverName);
      this.formAddressGroup.controls.DriverPhoneNo.setValue(userdata.customer.driverPhoneNo);
      this.formAddressGroup.controls.CarNumber.setValue(userdata.customer.carNumber);
      this.formAddressGroup.controls.CarMake.setValue(userdata.customer.carModel);
      this.formAddressGroup.controls.CarModel.setValue(userdata.customer.carMake);
      this.formAddressGroup.controls.CarColor.setValue(userdata.customer.carColor);
    });
  }

  
  onSubmit(){
    console.log(this.formAddressGroup.value)//this.formAddressGroup.controls.DriverName.value != null && this.formAddressGroup.controls.DriverPhoneNo.value != null &&
    if(
      this.formAddressGroup.controls.CarNumber.value != null && this.formAddressGroup.controls.CarMake.value != null 
      && this.formAddressGroup.controls.CarModel.value != null && this.formAddressGroup.controls.CarColor.value != null
      ){
            console.log(this.formAddressGroup.value)
            this.appservice.UserCarInfo(this.formAddressGroup.getRawValue()).subscribe((res:any)=>{
              console.log(res);
              this.formAddressGroup.reset();
               this.appservice.showNotification("success","Successfully Updated","top","center");
               this.getUserProfile();
            })
    }
    else{
      this.appservice.showNotification("danger","Please Fill details...","top","center")
 
    }
  }

}
