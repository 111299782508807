<div class="sub-header align-center">
    <div class="subheader-holder" style=" background:url(assets/images/subheader-img2-1.jpg)  no-repeat ; background-size: cover; padding-top: 60px; padding-bottom: 30px; margin-top: 0px; margin-bottom: 0px; min-height: 186px !important;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 87px;">
                    <div class="text-holder">
                        <div class="page-title ">
                            <h1 style="color:#ffffff !important;">Terms and Condition</h1>
                        </div>
                        <!-- <p style="color:#ffffff !important;">A great restaurant website</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumbs align-left">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ul>
                        <li><a [routerLink]="['/home']">Home</a></li>
                        <li class="active">Terms and Condition</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-section ">
    <div class="page-content-fullwidth">
        <div class="col-xs-12">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="cs-rich-editor">
                            <h2>Terms and Condition</h2>
                            <p>We from “<b>TKaff Portal</b>” request the “User” to read the ‘Terms and Conditions” (T & C) set forth hereunder for accepting the same and providing the consent to proceed further before using the App.</p>
                            <p>We at “<b>TKaff Portal</b>” (“Company” registered in UAE License No:1035910) reserve the right to change the Terms and Conditions at any time. And any such changes shall take effect when posted on our App/Website.</p>
                            <p>It shall be your sole responsibility to read the Terms and Conditions on each occasion you use this App/Website and your continued use of the App/Website shall imply/signify your acceptance to be bound by the latest Terms and Conditions.</p>
                            <p>If you do not agree to (or cannot comply with) any of the Terms and Conditions, you are advised to refrain from using this App/Website or participating in any kind of the promotions which may be carried out by the “Company".</p>
                            <p>All the Information provided by the shopper/individual must be accurate. Providing any inaccurate Information shall constitutes a breach of these Terms and Conditions.</p>
                            <p>Please note that your use of the App/Website service constitutes your unconditional and irrevocable agreement to follow and be bound by these terms and conditions (the “Terms and Conditions”).</p>
                            <p>If you do not agree to (or cannot comply with) any of the Terms and Conditions, we advise you not to use this App/Website.</p>
                            <br>
                            <h3>Amendment of the Terms and Conditions</h3>
                            <p>We at <b>TKaff Portal</b> may at any time modify the Terms and Conditions of the App/Website without any prior notification to you. You can access the latest version of the Terms and Conditions at any given time on the App/Website. 
                                In the event the modified Terms and Conditions are not acceptable to you, you should refrain/discontinue using the service of the App/Website. However, if in the event you continue to use the service you shall be deemed to have agreed to accept and abide by the modified Terms & Conditions of this App/Website.</p>
                            <br>
                            <h3>Cancellation</h3>
                            <p>Please note that there may be certain orders that we may reject or cancel. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason.</p>
                            <p>For your convenience, you will not be charged until your payment method is authorized; the order information is verified for accuracy.</p>
                            <p>Some situations that may result in your order being cancelled due to limitations on quantities available for purchase, inaccuracies or errors in the product or pricing information, or problems identified by our relevant department.</p>
                            <p>We may also require additional verifications or information before accepting any order. We will contact you if all or any portion of your order is cancelled or if additional information is required to accept your order. 
                                If your order is cancelled after your credit/Debit card has been charged, we will issue a credit to your credit card in the amount of the charge, the time required to credit the charge back to your card may take up to 15(Fifteen) days depending on the Bank guidelines and policies.</p>
                            <br>
                            <h3>App Security</h3>
                            <p>You are prohibited from violating the security of the App/Website, including, without limitation, to the herein below set forth conditions</p>
                            <p>I.	accessing the data not intended for such user or logging onto a server or an account which the user is not authorized to access;</p>
                            <p>II.	attempting to interfere with service to any user, host or network, including, without limitation, via means of submitting a virus to the App/Website, overloading, flooding, spamming, mail bombing or crashing.</p>
                            <p>III.	Sending unsolicited email, including promotions and/or advertising of products or services.</p>
                            <p>Violations of system or network security may result in civil or criminal liability.
                                 We shall have the absolute rights to investigate such violations and prosecute users who are involved in such violations. 
                                 You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this App/Website or any activity being conducted on this App/Website. 
                                    You unconditionally covenant/agree, further, not to use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search this Website other than the search engine and search agents available from us on this App/Website.</p>
                            <p>Access to the App may be occasionally restricted to allow for repair, system maintenance/upgradation or the introduction of new facilities or services.</p>
                            <br>
                            <h3>Registration Process</h3>
                            <p>The information (the “Information”) provided from your end will be protected in accordance with our Privacy Policy Terms. 
                                If you use the App, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your Mobile Phone/computer, and you agree to accept responsibility for all activities that occur under your account or password, once your registration process is completed from your end.</p>
                            <p>We assume no liability to any person for any loss or damage which may arise as a result of any failure by you in protecting your password or account. Any suspicious activities on your account shall be notified to us by contacting us immediately through email. 
                                If we have reason to believe that there is likely to be a breach of security or misuse of the App, we may suggest/require you to change your password or we may suspend your account without any liability on/to us.</p>
                            <br>
                            <h3>Product’s Pricing</h3>
                            <p>In the event that a product is listed at an incorrect price or with incorrect information due to an error in pricing or product information, we shall have the right, at our sole discretion, to either contact you for instructions or cancel your order and notify you of such cancellation, which shall be done through a Message/Phone Call or Email.</p>
                            <p>In the event wherein our App accepts your order, the same shall be debited to your credit/debit card account and duly notified to you by email/Text message or Email. The payment may be processed prior to our dispatch of the product that you have ordered. If we have to cancel the order after we have processed the payment, the said amount will be reversed back to your credit/debit card account. No cash disbursement shall be made under any condition whatsoever.</p>
                            <p>Sometimes a price online does not match the price in a store, store pricing will sometimes differ from online prices. Prices and availability are subject to change without any prior notice. Further, description/performance of products stated against each are based on the catalogue and technical literature printed by the manufacturers/agent. Therefore, the write-up provided against each product is not that of ours and subject to change without prior notice.</p>
                            <p>All customers will be charged in UAE Dirham and differences between approximation in any foreign currency and actual billing price on the customer's credit card bill is probably attributable to different credit card companies charging different exchange rates in accordance with the policy guidelines of the Card issuing Bank.</p>
                            <br>
                            <h3>Order Acceptance</h3>
                            <p>We reserve the right, at its sole discretion, to refuse or cancel any order for any reason whatsoever. Limitations on quantities available for purchase inaccuracies or errors in product or pricing information, or problems identified by our credit and fraud are some situations that may result in the order being cancelled, additional verifications or information may be requested before accepting any order.
                                We will contact you if all or any portion of your order is cancelled or if additional information is required to accept your order. If your order is cancelled after your credit/debit card has been charged, the said amount will be reversed back in your credit/debit card account. No cash disbursement shall be made under any condition whatsoever.</p>
                            <br>
                            <h3>Cancellations from the Customer</h3>
                            <p>If in an event of cancellation of order/notice from your side is duly received by us and the order has not been processed/approved by us, we shall cancel the order and refund the entire amount to your credit/debit card.</p>
                            <p>Orders which are cancelled after they have been shipped are subject to a AED 25 cancellation fee to be deducted from your refund towards the shipping and processing charges. 
                                We shall have the full right to demonstrate whether an order has been shipped or not. The customer agrees not to dispute the decision made by us and accept our decision regarding the cancellation.</p>
                            <br>
                            <h3>Privacy Policy</h3>
                            <p>At all times, TKaff Portal will attempt to treat the information about you, which we receive on the Internet with utmost care deemed reasonable under the circumstances and ensure that the information you submit is used/disclosed only for the purposes and towards the means it is intended for.</p>
                            <p>TKaff Portal uses information provided by you (if any) to measure the use of its site and to improve the contents of its site.</p>
                            <p>The personal information we may collect is used only by us to respond to your inquiry, process an order or allow you to access specific account information.</p>
                            <p>Occasionally, we may make use of the e-mail addresses, of those who provide information, available to other TKaff App/Portal companies which have equal or better privacy policy than we do. At times cookies may be used to provide you with certain information. A cookie is a tiny element of data that an App/web site can send to your browser, which may then be stored on your hard drive so we can recognize you when you return. You may set your browser to notify you when you receive a cookie.</p>
                            <p>TKaff Portal does not wish to receive any confidential, secret or proprietary information and materials from its site, Facebook page/other social media web pages, or in any other way.</p>
                            <p>Any information or materials (the "Materials") submitted or sent to TKaff Portal will be deemed not to be confidential or secret. By submitting or sending Materials to TKaff Portal you (1) warrant that you have no right of any kind to the Materials and that to the best of your knowledge no other party has any right to those Materials; (2) grant TKaff Portal an unrestricted and irrevocable license to use, reproduce, modify, transmit, distribute the Materials, </p>
                            <br>
                            <h3>Credit/Debit Card Information</h3>
                            <p>We reserve the right to refuse to accept the Credit/Debit Card or, in such case the transaction is accepted, cancel the transaction, at our sole discretion, as part of our own fraud detection program.</p>
                            <p>The Credit/Debit card details provided by you for benefiting from the services on the App/Website will be truthful, valid and accurate and you shall use the Credit/Debit card which is lawfully owned by you, i.e. in a Credit/Debit card transaction, you must use your own Credit/Debit card. The said information will not be utilized and shared by us with any of the third parties unless required for fraud verifications or by law, regulation or court order. We will not be liable for any Credit/Debit card fraud. The liability for use of a card fraudulently will be on you and the responsibility to prove otherwise shall be exclusively on you.</p>
                            <br>
                            <h3>Copyright & Trademark</h3>
                            <p>Unless otherwise noted, all contents of the App/Website are copyrights, trademarks, trade dress and/or other intellectual property owned, controlled or licensed by <b>TKaff Portal</b> or one of its affiliates. </p>
                            <p>We expressly reserve all intellectual property rights in all text, programs, products, processes, technology, content and other materials, which appear on this App/Website. Access to this App/Website does not confer and shall not be considered as conferring upon anyone any license under any of our or any third party's intellectual property rights. All rights, including copyright, in this App/Website are owned by or licensed to us/our affiliates. Any use of this App/Website or its contents, including copying or storing it in part or whole, other than for your own personal, non-commercial use is prohibited without our permission. You may not modify, distribute or re-post anything on this App/Website for any purpose which will infringe our intellectual property.</p>
                            <p>Our names and logos and all other related products and service names, design marks and slogans are the trademarks or service marks of <b>TKaff Portal</b>. No trademark or service mark license is granted in connection with the materials contained on this App/Website. The mere Access to this App/Website does not authorize anyone to use any name, logo or mark in any manner whatsoever.</p>
                            <br>
                            <h3>Liability release and indemnification</h3>
                            <p>You hereby release and discharge and agree to indemnify: 1) <b>TKaff Portal</b> and any related body corporate, 2) <b>TKaff Portal</b> directors, officers, employees and agents; for any personal injury, illness or death, claims, liabilities or expenses of any kind, including reasonable attorney’s fees (and whether accruing to you, your heirs or personal representatives) that are caused or alleged to be caused in whole or in part by reason of an act or omission of any Released Party that arises out of or in connection with your use of our App/Website or participation in any of our promotions, or resulting from any violation of this agreement or any activity related to your account (including infringement of third parties’ worldwide intellectual property rights or negligent or wrongful conduct) by you.</p>
                            <p>Further you agree to defend, indemnify and hold all our directors, managers and employees, harmless from and against any and all claims, damages, costs and expenses, including attorneys' fees, arising from or related to your use of the App/Website by entering into the contest or any breach by you of these Terms and Conditions and/or the applicable laws.</p>
                            <br>
                            <h3>Personal information</h3>
                            <p>We at <b>TKaff Portal</b> are dedicated to protecting the privacy and confidentiality of your personal information. Your data is stored securely on our database and will only be disclosed to our trusted partners. We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent and we only use your information as described in the Privacy Policy. We consider/view protection of your privacy as a very important community principle. We understand clearly that you and the Information is one of our most paramount assets. We store and process the Information on computers that are protected by physical as well as technological security devices. We use third parties to verify and certify our privacy principles.</p>
                            <br>
                            <h3>Force majeure</h3>
                            <p><b>TKaff Portal</b> shall not be held liable for any breach of obligations where there is hindrance or prevention from carrying out the contest obligations by any cause outside their reasonable control, including but not limited to lightning, fire, flood, extreme or severe weather, strike, lock-out, labour dispute, act of God, Global Pandemic, war, riot, civil commotion, malicious damage, failure of any telecommunications or computer system, compliance with any law, accident (or by any damage caused by any of such events). </p>
                            <br>
                            <h3>The customer shall agree and confirm on the following</h3>
                            <p>1. There may be or will be optional extended warranty on products sold by us. Should there be any problems with the product, it is the choice of the customer to contact the manufacturer's local agent in the UAE.</p>
                            <p>2. If we have shipped any electronic items they are designed to work on 220-240V. If you do not have the required facilities to operate on this electricity voltage, then use of the equipment is at your own risk. We will not accept back any products that have been damaged due to use with faulty electrical sources from your end. You are advised to check the owners’ manual before the product is used to see if the product meets the local requirements required for safe operation.</p>
                            <p>3. It is pertinent to mention that correct and proper address at which delivery of the product ordered by you shall be provided by you. If the provided address is found to be inaccurate/incomplete or incorrect, we may attempt to find the correct address to complete the delivery, If in an event of wherein the provided address is incorrect, or if delivery can't be completed, then a surcharge for extra cost will be imposed to the customer.</p>
                            <br>
                            <h3>Governing Law and Jurisdiction</h3>
                            <p>The Terms and Conditions shall be construed in accordance with the federal laws of the United Arab Emirates and the local laws and regulations of the Emirate of Dubai. The Courts at Dubai shall have exclusive jurisdiction in any proceedings arising out of the Terms and Conditions.</p>



                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
