import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-select-package',
  templateUrl: './register-select-package.component.html',
  styleUrls: ['./register-select-package.component.css']
})
export class RegisterSelectPackageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
