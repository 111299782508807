import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Customer } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public forgotForm!: FormGroup;
  public hide = false;
  public ismodal = "";
  errormsg: string = ''


  constructor(public fb: FormBuilder,
    public router: Router,
    public appservice: AppService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required, Validators.pattern("^((\\05-?)|0)?[0-9]{10}$")]],
      password: [null, Validators.required]
    });
    this.forgotForm = this.fb.group({
      "UserType" :"Customer",
      "EmailId" :[null,[Validators.required,Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    })
  }


  public onLoginFormSubmit(): void {
   // console.log(this.loginForm.value)
    this.hide = false;
    if (this.loginForm.valid) {
      this.appservice
        .login(this.loginForm.value.username, this.loginForm.value.password)
        .subscribe(
          (res) => {
            console.log(res)
            if (res.token !== null ) {

              if(res.role.type=="Customer"){
                 //console.log(res)
                localStorage.setItem("role", res.role.type)
                // localStorage.setItem("name", res.user.name)
                // localStorage.setItem("mobileNo", res.user.mobileNo)
                // localStorage.setItem("email", res.user.email)

                  if (localStorage.getItem("tempurl")) {
                    let tempurl = localStorage.getItem("tempurl")
                    localStorage.removeItem("tempurl")
                    this.router.navigate([tempurl])
                  } else {
                    this.router.navigate(['/']);
                  }
                  this.appservice.showNotification(
                    "success",
                   "Welcome to Tkaff :)",
                    "top",
                    "center"
                  );
              }
              else{
                this.appservice.showNotification(
                  "danger",
                 "Invalid Login !!!",
                  "top",
                  "center"
                );
              }
             

            }
            else {
              console.log(res)
              //  res.errorMsgs[0];
              //  this.hide=true;
              //  this.errormsg=res.errorMsgs[0];
              this.appservice.showNotification(
                "danger",
                res.errorMsgs[0],
                "top",
                "center"
              );
            }
          });
    }
    else {
      console.log(this.loginForm.value)
      this.hide = true;
      this.errormsg = "All Fields is required";
      this.appservice.showNotification(
        "danger",
        this.errormsg,
        "top",
        "center"
      );

    }
  }

  onForgotFormSubmit(){
    if(this.forgotForm.controls.EmailId.value!=null){
      //console.log(this.forgotForm.value)
      this.appservice.forgotPassword(this.forgotForm.getRawValue()).subscribe((res:any)=>{
       // console.log(res)
        if(res.result!="Fail"){
          this.forgotForm.controls.EmailId.setValue("");
          this.appservice.showNotification(
            "success",
            "Please Check email to reset Password",
            "top",
            "center"
          );
        }
        else{
          this.appservice.showNotification(
            "danger",
            res.errorMsgs[0],
            "top",
            "center"
          );
        }
      })
    }
    else{
      this.appservice.showNotification(
        "danger",
        "Enter Register Email Id!!!",
        "top",
        "center"
      );
    }
  }



  get fl(){
    return this.loginForm.controls;
  }

  get ff(){
    return this.forgotForm.controls;
  }

}
