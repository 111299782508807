// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // API_URL:'http://148.72.214.78/tkaff/api/'
//  API_URL:'http://103.155.84.59:1101/api/',
//  BASE_API_URL:'http://103.155.84.59:1101/'
  // API_URL:'http://localhost/tkaffapi/api/',
  // BASE_API_URL:'http://localhost/tkaffapi/api/',
 // API_URL:'http://148.72.214.78:1200/api/',
 // BASE_API_URL:'http://148.72.214.78:1200/',
 // API_URL:'https://localhost:5001/api/',
 // BASE_API_URL:'https://localhost:5001/',
 API_URL:'http://148.72.206.209:267/api/',
 BASE_API_URL:'http://148.72.206.209:267/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
