import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { StatementDialogComponent } from './statement-dialog/vendor-review-dialog/statement-dialog-dialog.component';

@Component({
  selector: 'app-buyer-statement',
  templateUrl: './buyer-statement.component.html',
  styleUrls: ['./buyer-statement.component.css']
})
export class BuyerStatementComponent implements OnInit {

  constructor(public appservice:AppService,public router:Router,public dialog: MatDialog,) { }

  Ewalletlist:any;
  EwalletBallist:any;
  ngOnInit(): void {
    this.appservice.getUserProfile().subscribe((res:any)=>{
      console.log(res)
      //this.getEwalletLedger(res.customer.id)  
      this.getBalances(res.customer.id)
    })
  }

  getEwalletLedger(id:number){
    let wa={
      "VendorId" : 0,"CustomerId" : id
    }
    this.appservice.EWallletLedger(wa).subscribe((wal:any)=>{
      console.log(wal)
      this.Ewalletlist=wal.result;
    })
  }

  getBalances(id:number){
    let bal ={  "VendorId" : 0,"CustomerId" : id }
    this.appservice.getUserWalletBAl(bal).subscribe((res:any)=>{
      console.log(res);
      this.EwalletBallist=res.result;
    })
  }

  OpenStatement(event:any){
    console.log(event)

    let wa={
      "VendorId" : event.vendorId,"CustomerId" : event.customerId
    }
        this.appservice.EWallletLedger(wa).subscribe((wal:any)=>{

        const dialogRef = this.dialog.open(StatementDialogComponent
          , {
          data: { passdata: wal }
        });
        dialogRef.afterClosed().subscribe(cat => {
          console.log(cat)
        });

      })
  }

}
