<div class="menuextracontainer"  tabindex="-1" role="dialog">
  <div class="" role="document">
    <div class="">
      <div class="modal-header">
        <button type="button" class="close"  mat-dialog-close (click)="onNoClick()"><span aria-hidden="true">×</span></button>
        <h2><a>{{data.passdata.menuName}}</a></h2>
      </div>
      <div class="modal-body">
        <div class="menu-selection-container">
          <form [formGroup]="form" (ngSubmit)="submit()">
         
          <div class="extras-detail-main" >
            <h3>Choose Meal Day</h3>
            <div class="extras-detail-options">
              <div class="extras-detail-att" >
                <input type="checkbox" id="Monday" formControlName="select_mon" 
                 name="select_mon" >
                <label for="Monday">
                  <span class="extra-title">Monday</span>
                </label>
              </div>
              <div class="extras-detail-att" >
                <input type="checkbox" id="Tuesday" formControlName="select_tue" 
                 name="select_tue" >
                <label for="Tuesday">
                  <span class="extra-title">Tuesday</span>
                </label>
              </div>
              <div class="extras-detail-att" >
                <input type="checkbox" id="Wednesday" formControlName="select_wed" 
                 name="select_wed" >
                <label for="Wednesday">
                  <span class="extra-title">Wednesday</span>
                </label>
              </div>
              <div class="extras-detail-att" >
                <input type="checkbox" id="Thursday" formControlName="select_thu" 
                 name="select_thu" >
                <label for="Thursday">
                  <span class="extra-title">Thursday</span>
                </label>
              </div>
              <div class="extras-detail-att" >
                <input type="checkbox" id="Friday" formControlName="select_fri" 
                 name="select_fri" >
                <label for="Friday">
                  <span class="extra-title">Friday</span>
                </label>
              </div>
              <div class="extras-detail-att" >
                <input type="checkbox" id="Saturday" formControlName="select_sat" 
                 name="select_sat" >
                <label for="Saturday">
                  <span class="extra-title">Saturday</span>
                </label>
              </div>
              <div class="extras-detail-att" >
                <input type="checkbox" id="Sunday" formControlName="select_sun" 
                 name="select_sun" >
                <label for="Sunday">
                  <span class="extra-title">Sunday</span>
                </label>
              </div>
              <!-- <div class="extras-detail-att">
                <input type="checkbox" id="extra-1-0-12" data-ind="1" data-menucat-id="0" data-menu-id="1" name="extra-0-15">
                <label for="extra-1-0-12">
                  <span class="extra-title">Tequila Lime Sauce</span>
                  <span class="extra-price">£2.50</span>
                </label>
              </div>
              <div class="extras-detail-att">
                <input type="checkbox" id="extra-2-0-13" data-ind="2" data-menucat-id="0" data-menu-id="1" name="extra-0-15">
                <label for="extra-2-0-13">
                  <span class="extra-title">Soy Sauce</span>
                  <span class="extra-price">£3.50</span>
                </label>
              </div>
              <div class="extras-detail-att">
                <input type="checkbox" id="extra-2-0-14" data-ind="2" data-menucat-id="0" data-menu-id="1" name="extra-0-15">
                <label for="extra-2-0-14">
                  <span class="extra-title">Garlic Sauce</span>
                  <span class="extra-price">£2.50</span>
                </label>
              </div> -->
            </div>
          </div>
          <div class="extras-btns-holderss">
            <button  class="add-extra-menu-btn input-button-loader editing-menu">Submit</button>
            <!-- <a href="#" class="reset-menu-fields btn">Reset Fields</a> -->
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>
</div>