import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-buyer-changepassword',
  templateUrl: './buyer-changepassword.component.html',
  styleUrls: ['./buyer-changepassword.component.css']
})
export class BuyerChangePasswordComponent implements OnInit {

  formAddressGroup!: FormGroup;
  UserData:any;
  geocitylist: any;

  constructor(public appservice: AppService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog, private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.getUserProfile();
    this.formAddressGroup = this.fb.group({
      UserName: '',
      Password: ['', Validators.required],
      Newpassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required],

    });
  }

  public getUserProfile() {
   
    this.appservice.getUserProfile().subscribe((userdata: any) => {
      console.log(userdata);
      this.UserData = userdata;
      this.formAddressGroup.controls.UserName.setValue(userdata.customer.mobileNo )
      
    });
  }

  
  onSubmit(){
    console.log(this.formAddressGroup.value)//this.formAddressGroup.controls.DriverName.value != null && this.formAddressGroup.controls.DriverPhoneNo.value != null &&
    if(this.formAddressGroup.controls.Password.value != null && this.formAddressGroup.controls.Newpassword.value != null 
      && this.formAddressGroup.controls.confirmNewPassword.value != null ){
        if(this.formAddressGroup.controls.Newpassword.value==this.formAddressGroup.controls.confirmNewPassword.value)
        {
          console.log(this.formAddressGroup.value)
          this.appservice.passwordchange(this.formAddressGroup.getRawValue()).subscribe((res:any)=>{
            console.log(res);
            this.formAddressGroup.reset();
            this.getUserProfile();
             this.appservice.showNotification("success","Successfully Updated","top","center");
          })
        }
        else{
          this.appservice.showNotification("danger","Password Mismatch!!!!","top","center")
        }
    }
    else{
      this.appservice.showNotification("danger","Please Fill details...","top","center")
 
    }
  }

}
