import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";

import { formatDate } from "@angular/common";
import { AppService } from "src/app/app.service";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserAddress } from "src/app/app.models";
@Component({
  selector: "app-confirm-alert-dialog",
  templateUrl: "./confirm-alert-dialog.component.html",
  styleUrls: ["./confirm-alert-dialog.component.css"],
})
export class ConfirmAlertDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<ConfirmAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public appservice: AppService, public router: Router,
    public snackBar: MatSnackBar,

  ) {

  }
  ngOnInit() {
    console.log(this.data)
    
  }




  public accept() {
    this.appservice.UserOrderCancel(this.data.passdata.id).subscribe((data:any)=>{
      console.log(data)
      if(data.errorMsgs!=null){
        this.dialogRef.close(data.result);
      }
      
    });

    
  }

  public dismiss() {
    this.dialogRef.close();
  }
}
