<div class="main-section">
  <app-buyer-header></app-buyer-header>
  <div class="page-section account-header buyer-logged-in">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <app-buyer-sidebar></app-buyer-sidebar>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div class="user-dashboard loader-holder">
            <div class="user-holder">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="row">
                  <div class="element-title has-border right-filters-row">
                    <h5>My Orders</h5>
                    <div class="right-filters row pull-right">
                      <!-- <div class="col-lg-6 col-md-6 col-xs-6">
                        <div class="input-field">
                          <select class="chosen-select-no-single">
                            <option selected="selected" value="">
                              Select Orders Status
                            </option>
                            <option value="Processing">Processing</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Completed">Completed</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-xs-6">
                        <div class="input-field">
                          <i class="icon-angle-down"></i>
                          <input
                            type="text"
                            data-id="daterange223"
                            id="daterange"
                            value=""
                            placeholder="Select Date Range"
                          />
                          <script>
                            $(function () {
                              $(
                                'input[data-id="daterange223"]'
                              ).daterangepicker(
                                {
                                  opens: "left",
                                },
                                function (start, end, label) {
                                  console.log(
                                    "A new date selection was made: " +
                                      start.format("YYYY-MM-DD") +
                                      " to " +
                                      end.format("YYYY-MM-DD")
                                  );
                                }
                              );
                            });
                          </script>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="responsive-table">
                    <ul class="table-generic">
                        <li class="order-heading-titles">
                            <div>Order No</div>
                            <div>Vendor Name</div>
                            <div>order Date</div>
                            <div>Amount</div>
                            <div>Order Type</div>
                            <div>payment Type</div>
                            <div>Delivery Type</div>
                            <div>Status</div>
                            <div></div>
                            
                        </li>
                        <li class="order-heading-titles" *ngFor="let res of Orderlist | paginate: { itemsPerPage: 10, currentPage: p }; let i=index;">
                            <div>#{{res.orderNo}}</div>
                            <div><a [routerLink]="['/listing-details/',res.vendorId]">
                              <strong style="color: #39b44d !important;" >{{res.vendorName}}</strong></a></div>
                            <div>{{res.orderDate | date : "dd-MM-yyyy HH:mm"}}</div>
                            <div>AED {{res.netAmount}}</div>
                            <div>{{res.orderType}}</div>
                            <div>{{res.paymentType}}</div>
                            <div>{{res.deliveryType}}</div>
                            <div>{{res.activeStatus}}</div>
                            <div><div class="list-option">
                                <a  class="shortlist-btn"  
                                    data-toggle="modal"
                                  data-target="#order_detail" 
                                  style="color: #39b44d !important;" 
                                   (click)="orderdetail(res)">
                                    <i class="icon-file-text"></i>
                                </a>
                                <!-- <a  class="shortlist-btn"  *ngIf="res.orderType=='MealPlan'"
                                data-toggle="modal"
                              data-target="#order_meal" 
                              style="color: #39b44d !important;" 
                               (click)="orderdetail(res)">
                                <i class="icon-file-text"></i>
                            </a>
                            <a  class="shortlist-btn"  *ngIf="res.orderType=='Subscripton'"
                            data-toggle="modal"
                          data-target="#order_sub" 
                          style="color: #39b44d !important;" 
                           (click)="orderdetail(res)">
                            <i class="icon-file-text"></i>
                        </a> -->
                            </div></div>
                        </li>
                    </ul>
                </div>
                <!-- Regular Order -->
                <div 
                            class="modal fade menu-order-detail order-detail"
                            id="order_detail"
                            tabindex="-1"
                            role="dialog"
                          >
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <h2>Order Detail</h2>
                                </div>
                                <div class="modal-body">
                                  <div class="order-detail-inner">
                                    <div class="description-holder">
                                      <div class="row">
                                        <div
                                          class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                        >
                                          <div
                                            *ngIf="Orderlistdet"
                                            class="list-detail-options has-checkbox"
                                          >
                                            <h3>{{Orderlistdet.vendorName}}</h3>
                                            <ul class="order-detail-options">
                                              <li class="order-number">
                                                <strong>Order ID:</strong>
                                                <span>{{
                                                  Orderlistdet.orderNo
                                                }}</span>
                                              </li>
                                              <li class="req-delivery">
                                                <strong>Delivery Time:</strong>
                                                <span>10 Minutes </span>
                                              </li>
                                              <li class="created-date">
                                                <strong>Delivery Date:</strong>
                                                <span
                                                  >{{
                                                    Orderlistdet.deliverySchDate
                                                      | date: "dd-MM-yyyy"
                                                  }}
                                                </span>
                                              </li>
                                              <li class="order-type">
                                                <strong>Delivey Type:</strong>
                                                <span>{{
                                                  Orderlistdet.deliveryType
                                                }}</span>
                                              </li>
                                              <li class="order-type">
                                                <strong>Payment Status:</strong>
                                                <span
                                                  *ngIf="
                                                    Orderlistdet.paymentType ==
                                                    'Cash'
                                                  "
                                                  >Cash on
                                                  Delivery</span
                                                >
                                                <span
                                                  *ngIf="
                                                    Orderlistdet.paymentType !=
                                                    'Cash'
                                                  "
                                                  >{{
                                                    Orderlistdet.paymentType
                                                  }}</span
                                                >
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                        >
                                          <div class="customer-detail-holder">
                                            <h3>Customer Detail</h3>
                                            <!--Regular ORder-->
                                            <ul
                                              class="customer-detail"
                                              *ngIf="Orderlistdet && Orderlistdet.orderType=='RegularOrder'"
                                            >
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Self-Pick Up'
                                                "
                                              >
                                                <li>
                                                  <strong>Name :</strong>
                                                  <!-- <span>{{lis.customerName}}</span> -->
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .customerName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong
                                                  >
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .contactNo
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .streetName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Location :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .geoLocation
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0].country
                                                    }}</span
                                                  >
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Home Delivery'
                                                "
                                              >
                                                <li>
                                                  <strong>Name :</strong>
                                                  <!-- <span>{{lis.customerName}}</span> -->
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss
                                                        .orderAddress[0]
                                                        .customerName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong
                                                  >
                                                  <span *ngIf="Orderdetailss">{{
                                                    Orderdetailss
                                                      .orderAddress[0].contactNo
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span *ngIf="Orderdetailss">{{
                                                    Orderdetailss
                                                      .orderAddress[0]
                                                      .streetName
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span *ngIf="Orderdetailss">{{
                                                    Orderdetailss
                                                      .orderAddress[0].country
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Curb Side Pickup'
                                                "
                                              >
                                                
                                                <li>
                                                  <strong
                                                    >Vechnicle no :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carNumber
                                                  }}</span>
                                                </li>
                                                <!-- <li>
                                                  <strong>Car Brand :</strong>
                                                  
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carMake
                                                  }}</span>
                                                </li> -->
                                                <li>
                                                  <strong
                                                    >Car Model :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carModel
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong
                                                    >Car Color :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carColor
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                            </ul>
                                            <!-- Subscription-->
                                            <ul
                                              class="customer-detail"
                                              *ngIf="Orderlistdet && Orderlistdet.orderType=='Subscription'"
                                            >
                                              <ng-container  *ngIf="Orderlistdet.deliveryType ==='Self-Pick Up'">
                                                <li>
                                                  <strong>Name :</strong>
                                                  <!-- <span>{{lis.customerName}}</span> -->
                                                  <span *ngIf="Orderdetailss2.orderAddress">
                                                    {{Orderdetailss2.orderAddress[0].customerName}}
                                                    </span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong>
                                                  <span *ngIf="Orderdetailss2.orderAddress">
                                                    {{Orderdetailss2.orderAddress[0].contactNo}}
                                                    </span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span *ngIf="Orderdetailss2.orderAddress">
                                                    {{Orderdetailss2.orderAddress[0].streetName}}
                                                  </span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Location :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss2.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss2
                                                        .orderAddress[0]
                                                        .geoLocation
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss2.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss2
                                                        .orderAddress[0].country
                                                    }}</span
                                                  >
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Home Delivery'
                                                "
                                              >
                                                <li>
                                                  <strong>Name :</strong>
                                                  <!-- <span>{{lis.customerName}}</span> -->
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss2.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss2
                                                        .orderAddress[0]
                                                        .customerName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong
                                                  >
                                                  <span *ngIf="Orderdetailss2">{{
                                                    Orderdetailss2
                                                      .orderAddress[0].contactNo
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span *ngIf="Orderdetailss2">{{
                                                    Orderdetailss2
                                                      .orderAddress[0]
                                                      .streetName
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span *ngIf="Orderdetailss2">{{
                                                    Orderdetailss2
                                                      .orderAddress[0].country
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Curb Side Pickup'
                                                "
                                              >
                                                
                                                <li>
                                                  <strong
                                                    >Vechnicle no :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carNumber
                                                  }}</span>
                                                </li>
                                                <!-- <li>
                                                  <strong>Car Brand :</strong>
                                                
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carMake
                                                  }}</span>
                                                </li> -->
                                                <li>
                                                  <strong
                                                    >Car Model :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carModel
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong
                                                    >Car Color :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carColor
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                            </ul>
                                            <!--Meal plan-->
                                            <ul
                                              class="customer-detail"
                                              *ngIf="Orderlistdet && Orderlistdet.orderType=='MealPlan'"
                                            >
                                              <ng-container  *ngIf="Orderlistdet.deliveryType ==='Self-Pick Up'">
                                                <li>
                                                  <strong>Name :</strong>
                                                  <!-- <span>{{lis.customerName}}</span> -->
                                                  <span *ngIf="Orderdetailss1.orderAddress">
                                                    {{Orderdetailss1.orderAddress[0].customerName}}
                                                    </span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong>
                                                  <span *ngIf="Orderdetailss1.orderAddress">
                                                    {{Orderdetailss1.orderAddress[0].contactNo}}
                                                    </span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span *ngIf="Orderdetailss1.orderAddress">
                                                    {{Orderdetailss1.orderAddress[0].streetName}}
                                                  </span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Location :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss1.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss1
                                                        .orderAddress[0]
                                                        .geoLocation
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss1.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss1
                                                        .orderAddress[0].country
                                                    }}</span
                                                  >
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Home Delivery'
                                                "
                                              >
                                                <li>
                                                  <strong>Name :</strong>
                                                  <!-- <span>{{lis.customerName}}</span> -->
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss1.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss1
                                                        .orderAddress[0]
                                                        .customerName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong
                                                  >
                                                  <span *ngIf="Orderdetailss1">{{
                                                    Orderdetailss1
                                                      .orderAddress[0].contactNo
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span *ngIf="Orderdetailss1">{{
                                                    Orderdetailss1
                                                      .orderAddress[0]
                                                      .streetName
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span *ngIf="Orderdetailss1">{{
                                                    Orderdetailss1
                                                      .orderAddress[0].country
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Curb Side Pickup'
                                                "
                                              >
                                                
                                                <li>
                                                  <strong
                                                    >Vechnicle no :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carNumber
                                                  }}</span>
                                                </li>
                                                <!-- <li>
                                                  <strong>Car Brand :</strong>
                                                
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carMake
                                                  }}</span>
                                                </li> -->
                                                <li>
                                                  <strong
                                                    >Car Model :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carModel
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong
                                                    >Car Color :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carColor
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                            </ul>
                                            <!---->
                                          </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          <!--Regular Order-->
                                          <div
                                            class="order-status-holder"
                                            *ngIf="Orderdetailss && Orderlistdet && Orderlistdet.orderType=='RegularOrder'"
                                          >
                                            <div
                                              class="order-status-process order-status"
                                            >
                                              <p
                                                *ngIf="
                                                  Orderdetailss.trnsOrder
                                                    .activeStatus !=
                                                    'Done' &&
                                                  Orderdetailss.trnsOrder
                                                    .activeStatus != 'Cancel'
                                                "
                                                style="background: #1e73be"
                                              >
                                                Your order is In process
                                              </p>
                                              <p
                                                *ngIf="
                                                  Orderdetailss.trnsOrder
                                                    .activeStatus ===
                                                  'Done'
                                                "
                                                style="background: #047a06"
                                              >
                                                Your order is Completed
                                              </p>
                                              <p
                                                *ngIf="
                                                  Orderdetailss.trnsOrder
                                                    .activeStatus == 'Cancel'
                                                "
                                                style="background: #fc2004"
                                              >
                                                Your order is Cancelled
                                              </p>
                                            </div>
                                          </div>
                                          <!--Meal Plan-->
                                          <div
                                            class="order-status-holder"
                                            *ngIf="Orderdetailss1 && Orderlistdet && Orderlistdet.orderType=='MealPlan'"
                                          >
                                            <div
                                              class="order-status-process order-status"
                                            >
                                              <p
                                                *ngIf="
                                                  Orderdetailss1.trnsOrder
                                                    .activeStatus !=
                                                    'Done' &&
                                                  Orderdetailss1.trnsOrder
                                                    .activeStatus != 'Cancel'
                                                "
                                                style="background: #1e73be"
                                              >
                                                Your order is In process
                                              </p>
                                              <p
                                                *ngIf="
                                                  Orderdetailss1.trnsOrder
                                                    .activeStatus ===
                                                  'Done'
                                                "
                                                style="background: #047a06"
                                              >
                                                Your order is Completed
                                              </p>
                                              <p
                                                *ngIf="
                                                  Orderdetailss1.trnsOrder
                                                    .activeStatus == 'Cancel'
                                                "
                                                style="background: #fc2004"
                                              >
                                                Your order is Cancelled
                                              </p>
                                            </div>
                                          </div>
                                          <!--Subcription-->
                                          <div
                                            class="order-status-holder"
                                            *ngIf="Orderdetailss2 && Orderlistdet && Orderlistdet.orderType=='Subscription'"
                                          >
                                            <div
                                              class="order-status-process order-status"
                                            >
                                              <p
                                                *ngIf="
                                                  Orderdetailss2.trnsOrder
                                                    .activeStatus !=
                                                    'Done' &&
                                                  Orderdetailss2.trnsOrder
                                                    .activeStatus != 'Cancel'
                                                "
                                                style="background: #1e73be"
                                              >
                                                Your order is In process
                                              </p>
                                              <p
                                                *ngIf="
                                                  Orderdetailss2.trnsOrder
                                                    .activeStatus ===
                                                  'Done'
                                                "
                                                style="background: #047a06"
                                              >
                                                Your order is Completed
                                              </p>
                                              <p
                                                *ngIf="
                                                  Orderdetailss2.trnsOrder
                                                    .activeStatus == 'Cancel'
                                                "
                                                style="background: #fc2004"
                                              >
                                                Your order is Cancelled
                                              </p>
                                            </div>
                                          </div>
                                          <!-- -->
                                        </div>
                                        <div
                                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                        >
                                          <h2 class="heading">Food Menu</h2>
                                          <!--Regular Order-->
                                          <div class="responsive-table" *ngIf="Orderlistdet && Orderlistdet.orderType=='RegularOrder'">
                                            <ul
                                              *ngIf="Orderdetailss"
                                              class="categories-order table-generic"
                                            >
                                              <li class="order-heading-titles">
                                                <div>Products</div>
                                                <div>Price</div>
                                              </li>
                                              <li
                                                class="order-heading-titles"
                                                *ngFor="
                                                  let res of Orderdetailss.orderItem;
                                                  let i = index
                                                "
                                              >
                                                <div>
                                                  <h4>{{ res.menuName }}  x {{res.qty}}</h4>
                                                  <h5 *ngIf="res.subMenuName" >{{res.subMenuName}}</h5>
                                                  <!-- <h5>Foodbakery
                                                                                                        Special 9" Deep
                                                                                                        Pan</h5> -->
                                                </div>
                                                <div>
                                                  <span class="category-price"
                                                    >AED
                                                    {{ res.itemValue * res.qty  | number : '2.2' }}</span
                                                  >
                                                </div>
                                              </li>

                                              <!--                                                                                            
                                                                                            <li
                                                                                                class="order-heading-titles">
                                                                                                <div>
                                                                                                    <h4>Burgers</h4>
                                                                                                    <h5>Quarter Pounder
                                                                                                    </h5>
                                                                                                </div>
                                                                                                <div><span
                                                                                                        class="category-price">£3.00</span>
                                                                                                </div>
                                                                                            </li> -->
                                            </ul>
                                          </div>
                                          <!--MealPlan-->
                                          <div class="responsive-table" *ngIf="Orderlistdet && Orderlistdet.orderType=='MealPlan'">
                                            <ul
                                              *ngIf="Orderdetailss1"
                                              class="categories-order table-generic"
                                            >
                                              <li class="order-heading-titles">
                                                <div>Meal Plan Type</div>
                                                <div></div>
                                                
                                              </li>
                                              <li
                                                class="order-heading-titles">
                                                <div>
                                                  <h4>{{ Orderdetailss1.trnsOrder.mealPlanType }}</h4>
                                                 


                                                  
                                                </div>
                                               
                                              </li>

                                              <!--
                                                                                            <li
                                                                                                class="order-heading-titles">
                                                                                                <div>
                                                                                                    <h4>Burgers</h4>
                                                                                                    <h5>Quarter Pounder
                                                                                                    </h5>
                                                                                                </div>
                                                                                                <div><span
                                                                                                        class="category-price">£3.00</span>
                                                                                                </div>
                                                                                            </li> -->
                                            </ul>
                                          </div>
                                          <!---->
                                          <div class="responsive-table" *ngIf="Orderlistdet && Orderlistdet.orderType=='Subscription'">
                                            <ul
                                              *ngIf="Orderdetailss2"
                                              class="categories-order table-generic"
                                            >
                                              <li class="order-heading-titles">
                                                <div>Products</div>
                                                <div>Price per</div>
                                              </li>
                                              <li
                                                class="order-heading-titles"
                                                *ngFor="
                                                  let res of Orderdetailss2.orderItem;
                                                  let i = index
                                                "
                                              >
                                                <div>
                                                  <h4>{{ res.menuName }} x {{res.qty}}</h4>
                                                  <h5 *ngIf="res.subMenuName" >{{res.subMenuName}}</h5>
                                                  <!-- <h5>
                                                    <li>
                                                      <ul>
                                                        <li *ngIf="res.mon=='Yes'">Monday</li>
                                                        <li *ngIf="res.tue=='Yes'">Tuesday</li>
                                                        <li *ngIf="res.wed=='Yes'">Wednesday</li>
                                                        <li *ngIf="res.thu=='Yes'">Thursday</li>
                                                        <li *ngIf="res.fri=='Yes'">Friday</li>
                                                        <li *ngIf="res.sat=='Yes'">Saturday</li>
                                                        <li *ngIf="res.sun=='Yes'">Sunday</li>
                                                      </ul>
                                                  </li>
                                                </h5> -->
                                                  <!-- <h5>Foodbakery
                                                                                                        Special 9" Deep
                                                                                                        Pan</h5> -->
                                                </div>
                                                <div>
                                                  <span class="category-price"
                                                    >AED
                                                    {{ res.itemValue * res.qty  | number : '2.2' }}</span
                                                  >
                                                </div>
                                              </li>

                                              <!--                                                                                            
                                                                                            <li
                                                                                                class="order-heading-titles">
                                                                                                <div>
                                                                                                    <h4>Burgers</h4>
                                                                                                    <h5>Quarter Pounder
                                                                                                    </h5>
                                                                                                </div>
                                                                                                <div><span
                                                                                                        class="category-price">£3.00</span>
                                                                                                </div>
                                                                                            </li> -->
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                        >
                                          <div class="row">
                                            <div class="col-lg-7 col-md-9 col-sm-9 col-xs-12" >
                                              
                                              <a  class="reorder" *ngIf="Orderlistdet && Orderlistdet.orderType=='RegularOrder'" (click)="reorder(Orderlistdet)" data-dismiss="modal">Repeat Order</a
                                            >
                                              <!-- <ng-container *ngIf="Orderlistdet && Orderlistdet.deliveryType ==='Home Delivery'"> 
                                                <h3>Delivery Details</h3>
                                                <ul>
                                                  <li>
                                                    <strong></strong>

                                                  </li>

                                                </ul>
                                              </ng-container> -->
                                              
                                            </div>
                                             <!--Regular Order-->
                                            <div 
                                              class="col-lg-5 col-md-3 col-sm-3 col-xs-12"
                                            >
                                           
                                              <ul *ngIf="Orderdetailss && Orderlistdet.orderType=='RegularOrder'"
                                                
                                                class="order-detail-options order-total"
                                              >
                                                <li class="created-date">
                                                  <strong>Subtotal:</strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss.trnsOrder
                                                        .grossValue | number : '2.2'
                                                    }}</span
                                                  >
                                                </li>
                                                <li
                                                  class="order-type"
                                                  *ngIf="
                                                    Orderdetailss.trnsOrder
                                                      .deliverycharges > 0
                                                  "
                                                >
                                                  <strong>
                                                    Delivery Fee:
                                                  </strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss.trnsOrder
                                                        .deliverycharges
                                                    }}</span
                                                  >
                                                </li>
                                                <li class="order-type">
                                                  <strong>VAT ({{Orderdetailss.trnsOrder
                                                    .tax}}%)</strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss.trnsOrder
                                                        .taxAmount | number : '2.2'
                                                    }}</span
                                                  >
                                                </li>
                                                <li
                                                  class="order-type total-price"
                                                >
                                                  <strong>Total:</strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss.trnsOrder
                                                        .netAmount | number : '2.2'
                                                    }}</span
                                                  >
                                                </li>
                                              </ul>
                                            <!--Subscription-->
                                              <ul
                                                *ngIf="Orderdetailss2 && Orderlistdet.orderType=='Subscription'"
                                                class="order-detail-options order-total"
                                              >
                                                <li class="created-date">
                                                  <strong>Subtotal:</strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss2.trnsOrder
                                                        .grossValue | number : '2.2'
                                                    }}</span
                                                  >
                                                </li>
                                                <li
                                                  class="order-type"
                                                  *ngIf="
                                                    Orderdetailss2.trnsOrder
                                                      .deliverycharges > 0
                                                  "
                                                >
                                                  <strong>
                                                    Delivery Fee:
                                                  </strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss2.trnsOrder
                                                        .deliverycharges
                                                    }}</span
                                                  >
                                                </li>
                                                <li class="order-type">
                                                  <strong>VAT ({{Orderdetailss2.trnsOrder
                                                    .tax}}%)</strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss2.trnsOrder
                                                        .taxAmount | number : '2.2'
                                                    }}</span
                                                  >
                                                </li>
                                                <li
                                                  class="order-type total-price"
                                                >
                                                  <strong>Total:</strong>
                                                  <span
                                                    >AED
                                                    {{
                                                      Orderdetailss2.trnsOrder
                                                        .netAmount | number : '2.2'
                                                    }}</span
                                                  >
                                                </li>
                                              </ul>
                                              <!---->
                                            </div>
                                            
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                 </div>
                 <!-- Meal Plan -->
                 <!-- <div *ngIf="Orderlistdet && Orderlistdet.orderType=='MealPlan'"
                            class="modal2 fade menu-order-detail order-detail"
                            id="order_meal"
                            tabindex="-1"
                            role="dialog"
                          >
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <h2>Order Detail</h2>
                                </div>
                                <div class="modal-body">
                                  <div class="order-detail-inner">
                                    <div class="description-holder">
                                      <div class="row">
                                        <div
                                          class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                        >
                                          <div
                                            *ngIf="Orderlistdet"
                                            class="list-detail-options has-checkbox"
                                          >
                                            <h3>Restaurant Demo</h3>
                                            <ul class="order-detail-options">
                                              
                                              <li class="req-delivery">
                                                <strong>Delivery Time:</strong>
                                                <span>10 Minutes </span>
                                              </li>
                                              <li class="created-date">
                                                <strong>Ordered Date:</strong>
                                                <span
                                                  >{{
                                                    Orderlistdet.orderDate
                                                      | date: "dd-MM-yyyy"
                                                  }}
                                                </span>
                                              </li>
                                              <li class="order-type">
                                                <strong>Delivey Type:</strong>
                                                <span>{{
                                                  Orderlistdet.deliveryType
                                                }}</span>
                                              </li>
                                              <li class="order-type">
                                                <strong>Payment Status:</strong>
                                                <span
                                                  *ngIf="
                                                    Orderlistdet.paymentType ==
                                                    'Cash'
                                                  "
                                                  >Pending (Cash on
                                                  Delivery)</span
                                                >
                                                <span
                                                  *ngIf="
                                                    Orderlistdet.paymentType !=
                                                    'Cash'
                                                  "
                                                  >{{
                                                    Orderlistdet.paymentType
                                                  }}</span
                                                >
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                        >
                                          <div class="customer-detail-holder">
                                            <h3>Customer Detail</h3>
                                            <ul
                                              class="customer-detail"
                                              *ngIf="Orderlistdet"
                                            >
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Self-Pick Up'
                                                "
                                              >
                                                <li>
                                                  <strong>Name :</strong>
                                                  
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss1.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss1
                                                        .orderAddress[0]
                                                        .customerName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong
                                                  >
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss1.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss1
                                                        .orderAddress[0]
                                                        .contactNo
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss1.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss1
                                                        .orderAddress[0]
                                                        .streetName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Location :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss1.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss1
                                                        .orderAddress[0]
                                                        .geoLocation
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss1.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss1
                                                        .orderAddress[0].country
                                                    }}</span
                                                  >
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Home Delivery'
                                                "
                                              >
                                                <li>
                                                  <strong>Name :</strong>
                                                
                                                  <span
                                                    *ngIf="
                                                      Orderdetailss1.orderAddress
                                                    "
                                                    >{{
                                                      Orderdetailss1
                                                        .orderAddress[0]
                                                        .customerName
                                                    }}</span
                                                  >
                                                </li>
                                                <li>
                                                  <strong
                                                    >Phone Number :</strong
                                                  >
                                                  <span *ngIf="Orderdetailss1">{{
                                                    Orderdetailss1
                                                      .orderAddress[0].contactNo
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Street name :</strong>
                                                  <span *ngIf="Orderdetailss1">{{
                                                    Orderdetailss1
                                                      .orderAddress[0]
                                                      .streetName
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Address :</strong>
                                                  <span *ngIf="Orderdetailss1">{{
                                                    Orderdetailss1
                                                      .orderAddress[0].country
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                              <ng-container
                                                *ngIf="
                                                  Orderlistdet.deliveryType ===
                                                  'Curb Side Pickup'
                                                "
                                              >
                                                
                                                <li>
                                                  <strong
                                                    >Vechnicle no :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carNumber
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong>Car Brand :</strong>
                                                
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carMake
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong
                                                    >Car Model :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carModel
                                                  }}</span>
                                                </li>
                                                <li>
                                                  <strong
                                                    >Car Color :</strong
                                                  >
                                                  <span *ngIf="Orderlistdet">{{
                                                    Orderlistdet.carColor
                                                  }}</span>
                                                </li>
                                              </ng-container>
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                        >
                                          <div
                                            class="order-status-holder"
                                            *ngIf="Orderlistdet"
                                          >
                                            <div
                                              class="order-status-process order-status"
                                            >
                                           
                                              <p
                                                *ngIf="Orderlistdet.activeStatus !='Done' &&
                                                Orderlistdet.activeStatus != 'Cancel'"
                                                style="background: #1e73be"
                                              >
                                                Your order is In process
                                              </p>
                                              <p
                                                *ngIf="
                                                Orderlistdet
                                                    .activeStatus ===
                                                  'Done'
                                                "
                                                style="background: #047a06"
                                              >
                                                Your order is Completed
                                              </p>
                                              <p
                                                *ngIf="
                                                Orderlistdet
                                                    .activeStatus == 'Cancel'
                                                "
                                                style="background: #fc2004"
                                              >
                                                Your order is Cancelled
                                              </p>
                                           

                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                        >
                                          <h2 class="heading">Food Menu</h2>
                                          <div class="responsive-table">
                                            <ul
                                              *ngIf="Orderlistdet"
                                              class="categories-order table-generic"
                                            >
                                              <li class="order-heading-titles">
                                                <div>Plan</div>
                                                <div>Type</div>
                                              </li>
                                              <li
                                                class="order-heading-titles">
                                                <div>
                                                  <h4>{{ Orderlistdet.mealPlanName }}</h4>
                                                  <h5>
                                                    <ul>
                                                      <li *ngIf="Orderlistdet.breakFast=='Yes'">BreakFast</li>
                                                      <li *ngIf="Orderlistdet.lunch=='Yes'">Lunch</li>
                                                      <li *ngIf="Orderlistdet.dinner=='Yes'">Dinner</li>
                                                    </ul>

                                                  </h5>


                                                 
                                                </div>
                                                <div>
                                                  <span class="category-price">
                                                    {{ Orderlistdet.mealPlanType }}</span
                                                  >
                                                </div>
                                              </li>

                                             
                                            </ul>
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-lg-8 col-md-9 col-sm-9 col-xs-12"
                                            >
                                              <h3>Order Details</h3>
                                              <ul class="order-detail-options">
                                                <li class="created-date">
                                                  <strong>Start Date:</strong>
                                                  <span *ngIf="Orderlistdet" >{{Orderlistdet.startDate |  date: "dd-MM-yyyy"}}</span>
                                                  </li>
                                                  <li class="created-date">
                                                    <strong>End Date:</strong>
                                                    <span *ngIf="Orderlistdet" >{{Orderlistdet.endDate |  date: "dd-MM-yyyy"}}</span>
                                                    </li>
                                              </ul>
                                            </div>
                                            <div
                                              class="col-lg-4 col-md-3 col-sm-3 col-xs-12"
                                            >
                                              <ul
                                              *ngIf="Orderlistdet"
                                                class="order-detail-options order-total"
                                              >
                                                <li class="created-date">
                                                  <strong>Subtotal:</strong>
                                                  <span>AED {{ Orderlistdet.amount | number :'2.2' }}</span>
                                                </li>
                                              
                                                <li class="order-type">
                                                  <strong>VAT ({{Orderlistdet.tax}}%)</strong>
                                                  <span
                                                    >AED
                                                    {{ Orderlistdet.taxAmount | number :'2.2' }}</span
                                                  >
                                                </li>
                                                <li
                                                  class="order-type total-price"
                                                >
                                                  <strong>Total:</strong>
                                                  <span
                                                    >AED
                                                    {{ Orderlistdet.netAmount | number :'2.2'}}</span
                                                  >
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                  </div> -->
                  <!-- Scbcrioption Order -->
                  <!-- <div *ngIf="Orderlistdet && Orderlistdet.orderType=='Subscripton'"
                  class="modal fade menu-order-detail order-detail"
                  id="order_sub"
                  tabindex="-1"
                  role="dialog"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                        <h2>Order Detail</h2>
                      </div>
                      <div class="modal-body">
                        <div class="order-detail-inner">
                          <div class="description-holder">
                            <div class="row">
                              <div
                                class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                              >
                                <div
                                  *ngIf="Orderlistdet"
                                  class="list-detail-options has-checkbox"
                                >
                                  <h3>Restaurant Demo</h3>
                                  <ul class="order-detail-options">
                                    <li class="order-number">
                                      <strong>Order ID:</strong>
                                      <span>#{{
                                        Orderlistdet.subsOrderNo
                                      }}</span>
                                    </li>
                                    <li class="req-delivery">
                                      <strong>Delivery Time:</strong>
                                      <span>{{ Orderlistdet.deliveryTime }} </span>
                                    </li>
                                    <li class="created-date">
                                      <strong>Delivery Date:</strong>
                                      <span
                                        >{{
                                          Orderlistdet.orderDate
                                            | date: "dd-MM-yyyy"
                                        }}
                                      </span>
                                    </li>
                                    <li class="order-type">
                                      <strong>Delivey Type:</strong>
                                      <span>{{
                                        Orderlistdet.deliveryType
                                      }}</span>
                                    </li>
                                    <li class="order-type">
                                      <strong>Payment Status:</strong>
                                      <span
                                        *ngIf="
                                          Orderlistdet.paymentType ==
                                          'Cash'
                                        "
                                        >Pending (Cash on
                                        Delivery)</span
                                      >
                                      <span
                                        *ngIf="
                                          Orderlistdet.paymentType !=
                                          'Cash'
                                        "
                                        >{{
                                          Orderlistdet.paymentType
                                        }}</span
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                              >
                                <div class="customer-detail-holder">
                                  <h3>Customer Detail</h3>
                                  <ul
                                    class="customer-detail"
                                    *ngIf="Orderlistdet"
                                  >
                                    <ng-container
                                      *ngIf="
                                        Orderlistdet.deliveryType ===
                                        'Self-Pick Up'
                                      "
                                    >
                                      <li>
                                        <strong>Name :</strong>
                               
                                        <span
                                          *ngIf="
                                            Orderdetailss2.orderAddress
                                          "
                                          >{{
                                            Orderdetailss2
                                              .orderAddress[0]
                                              .customerName
                                          }}</span
                                        >
                                      </li>
                                      <li>
                                        <strong
                                          >Phone Number :</strong
                                        >
                                        <span
                                          *ngIf="
                                            Orderdetailss2.orderAddress
                                          "
                                          >{{
                                            Orderdetailss2
                                              .orderAddress[0]
                                              .contactNo
                                          }}</span
                                        >
                                      </li>
                                      <li>
                                        <strong>Street name :</strong>
                                        <span
                                          *ngIf="
                                            Orderdetailss2.orderAddress
                                          "
                                          >{{
                                            Orderdetailss2
                                              .orderAddress[0]
                                              .streetName
                                          }}</span
                                        >
                                      </li>
                                      <li>
                                        <strong>Location :</strong>
                                        <span
                                          *ngIf="
                                            Orderdetailss2.orderAddress
                                          "
                                          >{{
                                            Orderdetailss2
                                              .orderAddress[0]
                                              .geoLocation
                                          }}</span
                                        >
                                      </li>
                                      <li>
                                        <strong>Address :</strong>
                                        <span
                                          *ngIf="
                                            Orderdetailss2.orderAddress
                                          "
                                          >{{
                                            Orderdetailss2
                                              .orderAddress[0].country
                                          }}</span
                                        >
                                      </li>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="
                                        Orderlistdet.deliveryType ===
                                        'Home Delivery'
                                      "
                                    >
                                      <li>
                                        <strong>Name :</strong>
                                       
                                        <span
                                          *ngIf="
                                            Orderdetailss2.orderAddress
                                          "
                                          >{{
                                            Orderdetailss2
                                              .orderAddress[0]
                                              .customerName
                                          }}</span
                                        >
                                      </li>
                                      <li>
                                        <strong
                                          >Phone Number :</strong
                                        >
                                        <span *ngIf="Orderdetailss2">{{
                                          Orderdetailss2
                                            .orderAddress[0].contactNo
                                        }}</span>
                                      </li>
                                      <li>
                                        <strong>Street name :</strong>
                                        <span *ngIf="Orderdetailss2">{{
                                          Orderdetailss2
                                            .orderAddress[0]
                                            .streetName
                                        }}</span>
                                      </li>
                                      <li>
                                        <strong>Address :</strong>
                                        <span *ngIf="Orderdetailss2">{{
                                          Orderdetailss2
                                            .orderAddress[0].country
                                        }}</span>
                                      </li>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="
                                        Orderlistdet.deliveryType ===
                                        'Curb Side Pickup'
                                      "
                                    >
                                      <li>
                                        <strong>Driver Name :</strong>
                                        
                                        <span *ngIf="Orderlistdet">{{
                                          Orderlistdet.driverName
                                        }}</span>
                                      </li>
                                      <li>
                                        <strong
                                          >Driver contactNo :</strong
                                        >
                                        <span *ngIf="Orderlistdet">{{
                                          Orderlistdet.driverPhone
                                        }}</span>
                                      </li>
                                      <li>
                                        <strong
                                          >Vechnicle no :</strong
                                        >
                                        <span *ngIf="Orderlistdet">{{
                                          Orderlistdet.vechicleNo
                                        }}</span>
                                      </li>
                                    </ng-container>
                                  </ul>
                                </div>
                              </div>
                              <div
                                class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                              >
                                <div
                                  class="order-status-holder"
                                  *ngIf="Orderdetailss2"
                                >
                                  <div
                                    class="order-status-process order-status"
                                  >
                                    <p
                                      *ngIf="
                                        Orderdetailss2.trnsSubscriptionOrder
                                          .activeStatus !=
                                          'Done' &&
                                        Orderdetailss2.trnsSubscriptionOrder
                                          .activeStatus != 'Cancel'
                                      "
                                      style="background: #1e73be"
                                    >
                                      Your order is Inprocessing
                                    </p>
                                    <p
                                      *ngIf="
                                        Orderdetailss2.trnsSubscriptionOrder
                                          .activeStatus ===
                                        'Done'
                                      "
                                      style="background: #047a06"
                                    >
                                      Your order is Completed
                                    </p>
                                    <p
                                      *ngIf="
                                        Orderdetailss2.trnsSubscriptionOrder
                                          .activeStatus == 'Cancel'
                                      "
                                      style="background: #fc2004"
                                    >
                                      Your order is Cancelled
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                              >
                                <h2 class="heading">Food Menu</h2>
                                <div class="responsive-table">
                                  <ul
                                    *ngIf="Orderdetailss"
                                    class="categories-order table-generic"
                                  >
                                    <li class="order-heading-titles">
                                      <div>Products</div>
                                      <div>Price per</div>
                                    </li>
                                    <li
                                      class="order-heading-titles"
                                      *ngFor="
                                        let res of Orderdetailss2.trnsSubscriptionItem;
                                        let i = index
                                      "
                                    >
                                      <div>
                                        <h4>{{ res.menuName }}</h4>
                                        <h5>
                                          <li>
                                            <ul>
                                              <li *ngIf="res.mon=='Yes'">Monday</li>
                                              <li *ngIf="res.tue=='Yes'">Tuesday</li>
                                              <li *ngIf="res.wed=='Yes'">Wednesday</li>
                                              <li *ngIf="res.thu=='Yes'">Thursday</li>
                                              <li *ngIf="res.fri=='Yes'">Friday</li>
                                              <li *ngIf="res.sat=='Yes'">Saturday</li>
                                              <li *ngIf="res.sun=='Yes'">Sunday</li>
                                            </ul>
                                        </li>
                                      </h5>

                                      </div>
                                      <div>
                                        
                                        >
                                      </div>
                                    </li>

                                  </ul>
                                </div>
                              </div>
                              <div
                                class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                              >
                                <div class="row">
                                  
                                  <div
                                    class="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                  >
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                  <!----->
                </div>
              </div>
              <ul class="pagination">
                <pagination-controls (pageChange)="p = $event" class=""></pagination-controls>                  
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

