import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buyer-header',
  templateUrl: './buyer-header.component.html',
  styleUrls: ['./buyer-header.component.css']
})
export class BuyerHeaderComponent implements OnInit {

  UserData: any;
  imageURL: any | undefined;

  constructor(public appservice: AppService,) { }

  ngOnInit(): void {
    this.imageURL = "assets/images/team-medium-img1.jpg";
    this.appservice.getUserProfile().subscribe((res:any)=>{
      this.UserData=res
      if(this.UserData.customer.customerphoto){
        this.imageURL = environment.BASE_API_URL+"CustomerPhoto/"+this.UserData.customer.id+"/"+this.UserData.customer.customerphoto
      }
    })
  }

}
