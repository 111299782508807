import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationStart  } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'tkaf';
  loggedHead: boolean = false;
  commonHead: boolean = true;
  constructor(private router: Router) {
    // on route change to '/login', set the variable loggedHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
      
        if ( localStorage.getItem('token')) {
          this.loggedHead = true;
          this.commonHead = false;
        } else {
          // console.log("NU")
          this.loggedHead = false;
          this.commonHead = true;
        }
      }
    });
   }

    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
    }
}
