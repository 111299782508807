import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-payment-info',
  templateUrl: './register-payment-info.component.html',
  styleUrls: ['./register-payment-info.component.css']
})
export class RegisterPaymentInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
