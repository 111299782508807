import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Customer } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

   registrationForm= new FormGroup({
    CustomerName:new FormControl(),
    MobileNo:new FormControl(),
    AltMobileNo:new FormControl(),
    EmailId:new FormControl(),
    RegisterDate:new FormControl(),
    CustomerType:new FormControl(),
    FavouriteDelivery:new FormControl(),
    FavouritePayment:new FormControl(),
    Password:new FormControl(),
    ConfirmPassword:new FormControl(),
  });
  dateset = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  

  constructor(public fb: FormBuilder,
    public router: Router,
    public appservice:AppService,
    private snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    formatDate(this.dateset, "yyyy-MM-dd", "en")
    this.registrationForm=this.fb.group({
      CustomerName:['',Validators.required],
      MobileNo: ['', [Validators.required, Validators.pattern("^((\\05-?)|0)?[0-9]{10}$")]],
      AltMobileNo:['',[Validators.pattern("^((\\05-?)|0)?[0-9]{10}$")]],
      EmailId:['',[Validators.required,Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      RegisterDate:[formatDate(this.dateset, "yyyy-MM-dd", "en")],
      CustomerType:'',
      FavouriteDelivery:'',
      FavouritePayment:'',
      Password:['',[Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{6,}$")]],
      ConfirmPassword:['',[Validators.required, Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{6,}$")]],
      accept:['',Validators.required]
    },{
     
    })
  }

  public onRegistrationFormSubmit(): void {
    console.log(this.registrationForm.value)

    if(this.registrationForm.valid){
      if(this.registrationForm.controls.Password.value===this.registrationForm.controls.ConfirmPassword.value){
        if(this.registrationForm.controls.accept.value===true){
          this.appservice.Registration(this.registrationForm.getRawValue()).subscribe((data: any) => {
            console.log(data)
            if (data.token !== null) {
              //localStorage.setItem('token', data.token);
              this.router.navigate(['/login'])
              this.snackBar.open("Register Successfully.. Please Login to Continue :)", '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            }
            else{
              this.snackBar.open(data.errorMsgs[0], '×', { panelClass: 'danger', verticalPosition: 'top', duration: 3000 });
            }
          })
        }
        else{
          this.appservice.showNotification("danger",
         "Please accept terms and conditions !!!!",
         "bottom",
         "center")
        }
      }else{
        this.appservice.showNotification("danger",
         "Password Mismatched!!!!",
         "bottom",
         "center")
      }
     
    }
    
    else{
      this.appservice.showNotification("danger",
         "Please fill details...",
         "bottom",
         "center")
    }
  }

   clicksub() {
    console.log(this.registrationForm.value);
    this.registrationForm.reset();
  }

  get f(){
    return this.registrationForm.controls;
  }

}

