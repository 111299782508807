<div class="main-section">
  <div class="inner-banner" style="
      background-image: url(assets/images/inner-bg.jpg);
      margin-bottom: 60px;
    ">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="company-info-detail">
            <div class="company-info">
              <div class="img-holder">
                <figure>
                  <img src="{{ VendorImagebaseUrl }}" alt="#" />
                </figure>
              </div>
              <div class="text-holder">
                <div class="rating-star" *ngIf="VendorInfoDet">
                  <span class="rating-box" *ngIf="VendorInfoDet.review.length > 0"
                    style="width: {{ VendorInfoDet.review[0].mark * 20 }}%"></span>
                </div>

                <span *ngIf="VendorInfoDet" class="restaurant-title">{{
                  VendorInfoDet.vendor.vendorName
                  }}</span>

                <span class="reviews">
                  <div class="text">
                    <a data-toggle="tab" href="/#menu1" style="color: white">
                      <i class="icon- icon-textsms"></i>Reviews</a>
                  </div>
                </span>
              </div>
            </div>
            <div class="delivery-timing reviews-sortby">
              <div class="text addresslisthead">
                <i class="icon-motorcycle"></i>
                <p *ngIf="VendorInfoDet">
                  <span>Address :
                    {{
                    VendorInfoDet.address[0].streetName +
                    ", " +
                    VendorInfoDet.address[0].location
                    }}</span>
                  <span>Contact No :
                    {{ VendorInfoDet.address[0].contactNumber }}</span>
                </p>
              </div>
              <ul></ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-3 col-xs-12 sticky-sidebar">
          <div class="filter-toggle">
            <span class="filter-toggle-text">Categories By</span><i class="icon-chevron-down"></i>
          </div>
          <div class="filter-wrapper">
            <div class="categories-menu">
              <h6><i class="icon-restaurant_menu"></i>Categories</h6>
              <ul class="menu-list">
                <li *ngIf="Categorylist">
                  <a (click)="category(0)" class="menu-category-link"> All </a>
                </li>
                <div *ngFor="let res of Categorylist; let i = index">
                  <li [class]="active">
                    <a (click)="category(res.id)" class="menu-category-link"><img *ngIf="res.imageFile"
                        class="categoryimage" src="{{
                          CategoryImagebaseUrl + 'category_' + res.id + '/'
                        }}{{ res.imageFile }}" alt="#" />
                      {{ res.categoryName }}
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-9 col-xs-12">
          <div class="tabs-holder horizontal">
            <ul class="stickynav-tabs nav nav-tabs">
              <li role="presentation">
                <a data-toggle="tab" href="#home" role="tab" class="active" aria-controls="home"
                  (click)="TabVisibility('order')"><i class="icon- icon-room_service"></i>Online Order</a>
              </li>
              <li role="presentation">
                <a data-toggle="tab" href="#schedule" role="tab" aria-controls="schedule"
                  (click)="TabVisibility('schedule')"><i class="icon- icon-calendar"></i>Schedule Order</a>
              </li>
              <li role="presentation">
                <a data-toggle="tab" href="#meal" role="tab" aria-controls="meal" (click)="TabVisibility('meal')"><i
                    class="icon- icon-calendar"></i>Meal Plan</a>
              </li>
            </ul>
            <div class="tab-content">
              <div id="home" class="tab-pane active" role="tabpanel">
                <div class="menu-itam-holder">
                  <form [formGroup]="formmenu">
                    <div class="field-holder sticky-search">
                      <input id="menu-srch-6272" data-id="6272" formControlName="searchmenu"
                        class="input-field dev-menu-search-field" type="text" placeholder="Search food item" />
                    </div>
                    <div id="menu-item-list-6272" class="menu-itam-list"
                      *ngFor="let res of Categorylist2; let i = index">
                      <div class="element-title" id="menu-category-{{ res.id }}">
                        <h5 class="text-color">
                          <img *ngIf="res.imageFile" class="categoryimage" src="{{
                              CategoryImagebaseUrl + 'category_' + res.id + '/'
                            }}{{ res.imageFile }}" alt="#" />{{ res.categoryName }}
                        </h5>
                      </div>

                      <ul *ngFor="let menures of Menulist">
                        <li *ngIf="menures.cateId === res.id">
                          <div class="image-holder">
                            <a><img *ngIf="menures.imageFile" src="{{
                                  MenuImagebaseUrl +
                                    'Menu_' +
                                    menures.id +
                                    '/' +
                                    menures.imageFile
                                }}" alt="#" /></a>
                            <a><img *ngIf="!menures.imageFile" src="{{ MenuNoImagebaseUrl }}" alt="#" /></a>
                          </div>
                          <div class="text-holder">
                            <h6>{{ menures.menuName }}</h6>
                            <span *ngIf="menures.stockAvailable == 0" class="stock">Out of Stock</span>
                            <!-- <span>{{menures.description}}</span> -->
                            <ul class="nutri-icons">
                              <li>
                                <!-- <a data-toggle="tooltip" title="Contain Bnana"><img src="assets/images/natural-item03.png" alt="#"></a> -->
                                {{ menures.menuGroup }}
                              </li>
                            </ul>
                          </div>
                          <!-- price aed -->
                          <div class="price-holder">
                            <span class="price">AED {{ menures.rate | number: "2.2" }}</span>
                            <a *ngIf="menures.stockAvailable == 1" data-target="#extras-0-1" (click)="addItem(menures)"
                              class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>

                            <span id="add-menu-loader-0"></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
              <div id="schedule" class="tab-pane" role="tabpanel">
                <div class="menu-itam-holder">
                  <form [formGroup]="formmenu">
                    <div class="field-holder sticky-search">
                      <input id="menu-srch-6272" data-id="6272" formControlName="searchmenu"
                        class="input-field dev-menu-search-field" type="text" placeholder="Search food item" />
                    </div>
                    <div id="menu-item-list-6272" class="menu-itam-list"
                      *ngFor="let res of Categorylist2; let i = index">
                      <div class="element-title" id="menu-category-{{ res.id }}">
                        <h5 class="text-color">
                          <img *ngIf="res.imageFile" class="categoryimage" src="{{
                              CategoryImagebaseUrl + 'category_' + res.id + '/'
                            }}{{ res.imageFile }}" alt="#" />{{ res.categoryName }}
                        </h5>
                        <!-- <span>Choose your cut: Triangular, square, fingers or Un cut on any size pizza.</span> -->
                      </div>

                      <ul *ngFor="let menures of Menulist">
                        <li *ngIf="menures.cateId === res.id">
                          <div class="image-holder">
                            <a><img *ngIf="menures.imageFile" src="{{
                                  MenuImagebaseUrl +
                                    'Menu_' +
                                    menures.id +
                                    '/' +
                                    menures.imageFile
                                }}" alt="#" /></a>
                            <a><img *ngIf="!menures.imageFile" src="{{ MenuNoImagebaseUrl }}" alt="#" /></a>
                          </div>
                          <div class="text-holder">
                            <h6>{{ menures.menuName }}</h6>
                            <span *ngIf="menures.stockAvailable == 0" class="stock">Out of Stock</span>
                            <!-- <span>{{menures.description}}</span> -->
                            <ul class="nutri-icons">
                              <li>
                                <!-- <a data-toggle="tooltip" title="Contain Bnana"><img src="assets/images/natural-item03.png" alt="#"></a> -->
                                {{ menures.menuGroup }}
                              </li>
                            </ul>
                          </div>
                          <!-- price aed -->
                          <div class="price-holder">
                            <span class="price">AED {{ menures.rate | number: "2.2" }}</span>
                            <a *ngIf="menures.stockAvailable == 1" data-target="#extras-0-1"
                              (click)="addscheduleItem(menures)" class="dev-adding-menu-btn"><i
                                class="icon-plus4 text-color"></i></a>

                            <span id="add-menu-loader-0"></span>

                            <!-- <div class="" style="width: 100px; margin-top: 5px">
                              <div class="input-group">
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-number btn-sm"
                                    data-type="minus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-minus"
                                    ></span>
                                  </button>
                                </span>
                                <input
                                  style="
                                    height: 30px;
                                    padding: 5px;
                                    text-align: center;
                                  "
                                  type="text"
                                  name="quant[2]"
                                  class="form-control input-number"
                                  value="10"
                                  min="1"
                                  max="100"
                                />
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-success btn-number btn-sm"
                                    data-type="plus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-plus"
                                    ></span>
                                  </button>
                                </span>
                              </div>
                            </div> -->
                          </div>
                        </li>
                        <!-- <li>
                                                <div class="image-holder">
                                                    <a routerLink="/listings"><img src="assets/images/cover-photo23-150x150.jpg" alt="#"></a>
                                                </div>
                                                <div class="text-holder">
                                                    <h6>Chicken Tandoori Special 09" Deep Pan</h6>
                                                    <span>Cheese, tomatoes, tuna fish, sweetcorn and italian herbs </span>
                                                </div>
                                                <div class="price-holder">
                                                    <span class="price">£6.50</span>
                                                    <a routerLink="/#." data-toggle="modal" data-target="#extras-0-1" class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>
                                                    <span id="add-menu-loader-1"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="image-holder">
                                                    <a routerLink="/listings"><img src="assets/images/cover-photo21-150x150.jpg" alt="#"></a>
                                                </div>
                                                <div class="text-holder">
                                                    <h6>Margherita 12" Deep Pan</h6>
                                                    <span>heese, tomatoes and italian herbs</span>
                                                </div>
                                                <div class="price-holder">
                                                    <span class="price">£5.50</span>
                                                    <a routerLink="/#." data-toggle="modal" data-target="#extras-0-1" class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>
                                                    <span id="add-menu-loader-2"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="image-holder">
                                                    <a routerLink="/listings"><img src="assets/images/blogs-food-bakery-05-150x150.jpg" alt="#"></a>
                                                </div>
                                                <div class="text-holder">
                                                    <h6> Margherita 9" Deep Pan</h6>
                                                    <span>Cheese, tomatoes, shrimps, garlic, mussels, cockles,
                        olives and
                        italian herbs</span>
                                                </div>
                                                <div class="price-holder">
                                                    <span class="price">£4.50</span>
                                                    <a routerLink="/#." data-toggle="modal" data-target="#extras-0-1" class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>
                                                    <span id="add-menu-loader-3"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="image-holder">
                                                    <a routerLink="/listings"><img src="assets/images/cover-photo19-150x150.jpg" alt="#"></a>
                                                </div>
                                                <div class="text-holder">
                                                    <h6> Margherita 9" Deep Pan</h6>
                                                    <span>Cheese, tomatoes and italian herbs</span>
                                                    <ul class="nutri-icons">
                                                        <li>
                                                            <a data-toggle="tooltip" title="Onion"><img src="assets/images/natural-item02.png" alt="#"></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="price-holder">
                                                    <span class="price">£3.50</span>
                                                    <a routerLink="/#." data-toggle="modal" data-target="#extras-0-1" class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>
                                                    <span id="add-menu-loader-4"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="image-holder">
                                                    <a routerLink="/listings"><img src="assets/images/cover-photo18-150x150.jpg" alt="#"></a>
                                                </div>
                                                <div class="text-holder">
                                                    <h6>Vegetarian 12" Deep Pan</h6>
                                                    <span>Cheese, tomatoes, shrimps, garlic, mussels, cockles,
                        olives and
                        italian herbs</span>
                                                </div>
                                                <div class="price-holder">
                                                    <span class="price">£3.50</span>
                                                    <a routerLink="/#." data-toggle="modal" data-target="#extras-0-1" class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>
                                                    <span id="add-menu-loader-5"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="image-holder">
                                                    <a routerLink="/listings"><img src="assets/images/cover-photo16-150x150.jpg" alt="#"></a>
                                                </div>
                                                <div class="text-holder">
                                                    <h6>Foodbakery Special 12" Deep Pan</h6>
                                                    <span>Cheese, tomatoes, mushrooms and italian herbs</span>
                                                </div>
                                                <div class="price-holder">
                                                    <span class="price">£3.90</span>
                                                    <a routerLink="/#." data-toggle="modal" data-target="#extras-0-1" class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>
                                                    <span id="add-menu-loader-6"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="image-holder">
                                                    <a routerLink="/listings"><img src="assets/images/cover-photo15-150x150.jpg" alt="#"></a>
                                                </div>
                                                <div class="text-holder">
                                                    <h6>Foodbakery Special 9" Deep Pan</h6>
                                                    <span>Cheese, tomatoes, shrimps, garlic, mussels, cockles,
                        olives and
                        italian herbs</span>
                                                    <ul class="nutri-icons">
                                                        <li>
                                                            <a data-toggle="tooltip" title="Vegitable"><img src="assets/images/natural-item04.png" alt="#"></a>
                                                        </li>
                                                        <li>
                                                            <a data-toggle="tooltip" title="Chille"><img src="assets/images/natural-item05.png" alt="#"></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="price-holder">
                                                    <span class="price">£4.80</span>
                                                    <a routerLink="/#." data-toggle="modal" data-target="#extras-0-1" class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>
                                                    <span id="add-menu-loader-7"></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="image-holder">
                                                    <a routerLink="/listings"><img src="assets/images/cover-photo14-150x150.jpg" alt="#"></a>
                                                </div>
                                                <div class="text-holder">
                                                    <h6>Foodbakery Special 9" Deep Pan</h6>
                                                    <span>Cheese, tomatoes, mushrooms and italian herbs</span>
                                                </div>
                                                <div class="price-holder">
                                                    <span class="price">£4.80</span>
                                                    <a routerLink="/#." data-toggle="modal" data-target="#extras-0-1" class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>
                                                    <span id="add-menu-loader-8"></span>
                                                </div>
                                            </li> -->
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
              <div id="meal" class="tab-pane" role="tabpanel">
                <div class="menu-itam-holder">
                  <form [formGroup]="formmenu">
                    <br />
                    <div id="menu-item-list-6272" class="menu-itam-list">
                      <div *ngIf="Meallist" class="element-title" id="menu-category-2">
                        <h5 class="text-color">Meal Plan</h5>
                        <!-- <span>Choose your cut: Triangular, square, fingers or Un cut on any size pizza.</span> -->
                      </div>
                      <div *ngIf="!Meallist" class="element-title" id="menu-category-2">
                        <h5 class="text-color">
                          Meal Plan Not Available
                        </h5>
                        <!-- <span>Choose your cut: Triangular, square, fingers or Un cut on any size pizza.</span> -->
                      </div>

                      <ul>
                        <li *ngIf="Meallist">
                          <div class="image-holder">
                            <a><img src="{{ MenuNoImagebaseUrl }}" alt="#" /></a>
                          </div>
                          <div class="text-holder">
                            <h6>1 Meal Plan</h6>
                            

                            <!-- <span>{{menures.description}}</span> -->
                            <ul class="nutri-icons">
                              <li>
                                <div class="delivery-potions">
                                  <div class="post-time">
                                    <i class="icon-info"></i>
                                    <div class="time-tooltip">
                                      <div class="time-tooltip-holder">
                                       
                                        <b class="tooltip-info">
                                          {{Meallist.mealPlanDescription}}
                                          </b
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                                <!-- <a data-toggle="tooltip" title="Contain Bnana"><img src="assets/images/natural-item03.png" alt="#"></a> -->
                              </li>
                            </ul>
                          </div>
                          
                          <!-- price aed -->
                          <div class="price-holder">
                            <a data-target="#extras-0-1" (click)="addMealItem('1 Meal', 1)"
                              class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>

                            <span id="add-menu-loader-0"></span>

                            <!-- <div class="" style="width: 100px; margin-top: 5px">
                              <div class="input-group">
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-number btn-sm"
                                    data-type="minus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-minus"
                                    ></span>
                                  </button>
                                </span>
                                <input
                                  style="
                                    height: 30px;
                                    padding: 5px;
                                    text-align: center;
                                  "
                                  type="text"
                                  name="quant[2]"
                                  class="form-control input-number"
                                  value="10"
                                  min="1"
                                  max="100"
                                />
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-success btn-number btn-sm"
                                    data-type="plus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-plus"
                                    ></span>
                                  </button>
                                </span>
                              </div>
                            </div> -->
                          </div>
                        </li>

                        <li *ngIf="Meallist">
                          <div class="image-holder">
                            <a><img src="{{ MenuNoImagebaseUrl }}" alt="#" /></a>
                          </div>
                          <div class="text-holder">
                            <h6>2 Meal Plan</h6>

                            <!-- <span>{{menures.description}}</span> -->
                            <ul class="nutri-icons">
                              <li>
                                <div class="delivery-potions">
                                  <div class="post-time">
                                    <i class="icon-info"></i>
                                    <div class="time-tooltip">
                                      <div class="time-tooltip-holder">
                                       
                                        <b class="tooltip-info">
                                          {{Meallist.mealPlanDescription}}
                                          </b
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <a data-toggle="tooltip" title="Contain Bnana"><img src="assets/images/natural-item03.png" alt="#"></a> -->
                              </li>
                            </ul>
                          </div>
                          <!-- price aed -->
                          <div class="price-holder">
                            <a data-target="#extras-0-1" (click)="addMealItem('2 Meal', 2)"
                              class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>

                            <span id="add-menu-loader-0"></span>

                            <!-- <div class="" style="width: 100px; margin-top: 5px">
                              <div class="input-group">
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-number btn-sm"
                                    data-type="minus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-minus"
                                    ></span>
                                  </button>
                                </span>
                                <input
                                  style="
                                    height: 30px;
                                    padding: 5px;
                                    text-align: center;
                                  "
                                  type="text"
                                  name="quant[2]"
                                  class="form-control input-number"
                                  value="10"
                                  min="1"
                                  max="100"
                                />
                                <span class="input-group-btn">
                                  <button
                                    type="button"
                                    class="btn btn-success btn-number btn-sm"
                                    data-type="plus"
                                    data-field="quant[2]"
                                  >
                                    <span
                                      class="glyphicon glyphicon-plus"
                                    ></span>
                                  </button>
                                </span>
                              </div>
                            </div> -->
                          </div>
                        </li>

                        <li *ngIf="Meallist">
                          <div class="image-holder">
                            <a><img src="{{ MenuNoImagebaseUrl }}" alt="#" /></a>
                          </div>
                          <div class="text-holder">
                            <h6>3 Meal Plan</h6>

                            <!-- <span>{{menures.description}}</span> -->
                            <ul class="nutri-icons">
                              <li>
                                <div class="delivery-potions">
                                  <div class="post-time">
                                    <i class="icon-info"></i>
                                    <div class="time-tooltip">
                                      <div class="time-tooltip-holder">
                                       
                                        <b class="tooltip-info">
                                          {{Meallist.mealPlanDescription}}
                                          </b
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <a data-toggle="tooltip" title="Contain Bnana"><img src="assets/images/natural-item03.png" alt="#"></a> -->
                              </li>
                            </ul>
                          </div>
                          <!-- price aed -->
                          <div class="price-holder">
                            <!-- <span class="price">AED 25</span> -->
                            <a data-target="#extras-0-1" (click)="addMealItem('3 Meal', 3)"
                              class="dev-adding-menu-btn"><i class="icon-plus4 text-color"></i></a>

                            <span id="add-menu-loader-0"></span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
              <div id="menu2" class="tab-pane" role="tabpanel">...</div>
              <div id="menu3" class="tab-pane" role="tabpanel">
                <div class="contact-info-detail">
                  <h5 *ngIf="VendorInfoDet">
                    Overview {{ VendorInfoDet.vendor.vendorName }}
                  </h5>
                  <p class="restaurant-desc"></p>
                  <p *ngIf="VendorInfoDet">
                    {{ VendorInfoDet.vendor.description }}
                  </p>
                  <!-- <p>Base prepared fresh daily. Extra toppings are available in choose extraChoose you sauce: Go for BBQ sauce or piri piri sauce on your pizza base for no extra cost.Choose your cut: Triangular, square, fingers or Un cut
                                        on any size pizza
                                    </p> -->
                  <div class="map-sec-holder">
                    <div class="cs-map-section">
                      <div class="cs-map">
                        <div class="cs-map-content">
                          <div class="mapouter">
                            <div class="gmap_canvas">
                              <iframe width="100" height="180" id="gmap_canvas"
                                src="https://maps.google.com/maps?q=Dubai&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="contact-info">
                        <h5>Contact details</h5>
                        <!-- <p>Totnes, United Kingdom</p> -->
                        <ul>
                          <li class="cell" *ngIf="VendorInfoDet">
                            <i class="icon-phone"></i>{{ VendorInfoDet.vendor.contactNo }}
                          </li>
                          <li class="pizzaeast" *ngIf="VendorInfoDet">
                            <i class="icon-mail5"></i>{{ VendorInfoDet.vendor.emailId }}
                          </li>
                          <!-- <li class="email"><i class="icon-mail5"></i><a class="text-color" routerLink="/#">Send Enquiry By Email</a></li> -->
                        </ul>
                      </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div class="widget widget-timing">
                                                <h5>Opening Hours</h5>
                                                <ul>
                                                    <li><span>Monday</span>11:00 am - 11:00 pm</li>
                                                    <li><span>Tuesday</span>11:00 am - 11:00 pm</li>
                                                    <li><span>Wednesday</span>11:00 am - 11:00 pm</li>
                                                    <li><span>Thursday</span>11:00 am - 11:00 pm</li>
                                                    <li><span>Friday</span>11:00 am - 11:00 pm</li>
                                                    <li><span>Saturday</span>11:00 am - 11:00 pm</li>
                                                    <li><span>Sunday</span>Off</li>
                                                </ul>
                                            </div>
                                        </div> -->
                  </div>
                </div>
              </div>
              <div id="menu1" class="tab-pane fade" role="tabpanel">
                <form [formGroup]="formReviewGroup">
                  <div class="reviews-holder">
                    <div class="add-new-review-holder" style="display: none">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="elements-title">
                            <h3>Rate and Write a Review</h3>
                            <a href="#" class="close-post-new-reviews-btn">Close</a>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center">
                          Please login in order to post review.
                        </div>
                      </div>
                    </div>
                    <div class="reviwes-restaurant-holder">
                      <div class="over-all-rating-holder">
                        <div class="overall-ratings-container">
                          <div class="overall-rating">
                            <h6 *ngIf="VendorInfoDet">
                              Customer Reviews For
                              {{ VendorInfoDet.vendor.vendorName }}
                            </h6>
                            <div class="rating-starz">
                              <ul class="list-inline rating-list" *ngFor="let star of stars; let i = index">
                                <li (click)="countStar(star)" [ngClass]="{
                                    selected: star <= selectedValue
                                  }" (mouseover)="addClass(star)" (mouseout)="removeClass()">
                                  <span class="star" style="width: 20%"><i class="fa fa-star" aria-hidden="true"></i>
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <!-- <ul class="reviews-box">
                                                        <li>
                                                            <em>5.0 </em>
                                                            <div class="rating-star">
                                                                <span class="rating-box" style="width: 100%;"></span>
                                                            </div>
                                                            <span class="reviews-count">(based on 1
                                                                reviews)</span>
                                                        </li>
                                                        <li>
                                                            <div class="icon-holder"><i class="icon-smile"></i>
                                                            </div>
                                                            <p><span>100%</span> of diners recommend this
                                                                restaurant
                                                            </p>
                                                        </li>
                                                    </ul> -->
                          </div>
                        </div>
                        <div class="ratings-summary-container">
                          <div class="rating-summary">
                            <textarea placeholder="Tell about your experience or leave a tip for others"
                              class="textarea" formControlName="ReviewContent" cols="30" rows="10"
                              maxlength="500"></textarea>
                          </div>

                          <div class="form-element">
                            <input type="button" (click)="finish()" class="btn_review" value="Submit your Review" />
                          </div>
                        </div>
                        <!-- <div class="ratings-summary-container">
                                                <div class="rating-summary">
                                                    <h5>Rating summary</h5>
                                                    <ul>
                                                        <li>
                                                            <span class="review-category">Service</span>
                                                            <div class="rating-star">
                                                                <span class="rating-box" style="width:100%"></span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span class="review-category">Quality</span>
                                                            <div class="rating-star">
                                                                <span class="rating-box" style="width:100%"></span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span class="review-category">Value</span>
                                                            <div class="rating-star">
                                                                <span class="rating-box" style="width:100%"></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> -->
                      </div>
                      <div class="review-listing">
                        <div class="elements-title">
                          <h5 *ngIf="VendorInfoDet">
                            Customer Reviews For
                            {{ VendorInfoDet.vendor.vendorName }}
                            <span *ngIf="VendorReviewlist">{{
                              VendorReviewlist.length
                              }}</span>
                          </h5>
                          <div class="sort-by">
                            <span class="ajax-loader-sorty-by" style="display: none">
                              <img src="assets/images/ajax-loader.gif" alt="" /></span>
                            <ul class="reviews-sortby">
                              <li>
                                <span class="active-sort">Newest Reviews</span>
                                <div class="reviews-sort-dropdown">
                                  <form>
                                    <div class="input-reviews">
                                      <div class="radio-field">
                                        <input name="review" id="check-1" type="radio" value="newest"
                                          checked="checked" />
                                        <label for="check-1">Newest Reviews</label>
                                      </div>
                                      <div class="radio-field">
                                        <input name="review" id="check-2" type="radio" value="highest" />
                                        <label for="check-2">Highest Rating</label>
                                      </div>
                                      <div class="radio-field">
                                        <input name="review" id="check-3" type="radio" value="lowest" />
                                        <label for="check-3">Lowest Rating</label>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <ul class="review-restaurant" *ngIf="VendorReviewlist">
                          <li class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            *ngFor="let rev of VendorReviewlist; let i = index">
                            <div class="list-holder">
                              <div class="review-text">
                                <div class="review-title">
                                  <h6>: {{ rev.reviewContent }}</h6>
                                  <div class="rating-holder">
                                    <div class="rating-stars">
                                      <!-- <span style="width: 100%;" class="rating-box"></span> -->
                                      <ul class="list-inline rating-list" *ngFor="
                                          let star of stars;
                                          let i = index
                                        ">
                                        <li [ngClass]="{
                                            selected: star <= rev.mark
                                          }">
                                          <span class="star" style="width: 20%"><i class="fa fa-star"
                                              aria-hidden="true"></i>
                                          </span>
                                        </li>
                                      </ul>
                                      {{ rev.mark }}
                                    </div>
                                  </div>
                                </div>
                                <em class="review-date">{{
                                  rev.reviewDate | date: "dd-MM-yyyy HH:mm"
                                  }}</em>
                                <!-- <p>A wonderfull experience. </p> -->
                              </div>
                            </div>
                          </li>
                          <!-- <li class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                    <div class="list-holder ">
                                                        <div class="review-text">
                                                            <div class="review-title">
                                                                <h6>: Best food </h6>
                                                                <div class="rating-holder">
                                                                    <div class="rating-star">
                                                                        <span style="width: 100%;" class="rating-box"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <em class="review-date">25 Apr 2018</em>
                                                            <p>I had great food the other day </p>
                                                        </div>
                                                    </div>
                                                </li> -->
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="sticky-sidebar col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <!--order-->
          <div class="user-order-holder" *ngIf="ordernowvisibility">
            <div class="user-order">
              <h6><i class="icon-shopping-basket"></i>Your Order</h6>
              <div class="custom_tabs">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs" role="tablist">
                  <li role="presentation" class="active">
                    <a href="#regplan" role="tab" data-toggle="tab">Online Order</a>
                  </li>
                  <!-- <li role="presentation">
                    <a href="#suborder" role="tab" data-toggle="tab"
                      >Subscription Order</a
                    >
                  </li>
                  <li role="presentation">
                    <a href="#mealplan" role="tab" data-toggle="tab"
                      >Meal Plan</a
                    >
                  </li> -->
                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                  <div role="tabpanel" class="tab-pane active" id="regplan">
                    <!-- TPoints<label for="order-card-payment"
                    ><i class="icon-credit-card4"></i>  </label
                  > -->
                  </div>
                  <!-- <div role="tabpanel" class="tab-pane" id="suborder">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">Start Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">End Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="">
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                        />
                        Mon
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox2"
                          value="option2"
                        />
                        Tue
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox3"
                          value="option3"
                        />
                        Wed
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox4"
                          value="option4"
                        />
                        Thu
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox5"
                          value="option5"
                        />
                        Fri
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox6"
                          value="option6"
                        />
                        Sat
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox7"
                          value="option7"
                        />
                        Sun
                      </label>
                    </div>
                  </div>
                  <div role="tabpanel" class="tab-pane" id="mealplan">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">Start Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">End Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>

              <form [formGroup]="form">
                <div id="dev-menu-orders-list" *ngIf="cartArray.length > 0">
                  <ul class="categories-order">
                    <li class="menu-added clearfix" *ngFor="let lis of cartArray; let i = index">
                      <a class="btn-cross dev-remove-menu-item" (click)="removeItem(i, cartArray)"><i
                          class="fa fa-remove"></i></a>

                      <a>{{ lis.menuName }} x
                        <!-- <span style="color: #e91111 !important;"><b> X </b></span> -->
                        <span style="color: #39b44d !important;"><b>{{lis.qty}}</b></span>
                      </a>
                      <a *ngIf="lis.qty>1" class="btn-cross dev-remove-menu-item" (click)="Qty_decrese(i, cartArray)"><i
                          class="fa fa-minus-circle" style="color: #e91111 !important;"></i></a>
                      <span class="category-price">AED {{ lis.rate * lis.qty | number: "2.2" }}</span>
                      <ul *ngIf="lis.subItemName">
                        <li>{{ lis.subItemName }}</li>
                      </ul>
                      <ul></ul>
                      <!-- <div
                        class=""
                        style="width: 100px; margin-top: 0; float: right"
                      >
                        <div class="input-group">
                          <span class="input-group-btn">
                            <button
                              type="button"
                              class="btn btn-danger btn-number btn-sm"
                              data-type="minus"
                              data-field="quant[2]"
                              (click)="add_order(lis)"
                            >
                              <span class="glyphicon glyphicon-minus"></span>
                            </button>
                          </span>
                          <input
                            style="
                              height: 30px;
                              padding: 5px;
                              text-align: center;
                            "
                            type="text"
                            name="quant[2]"
                            class="form-control input-number"
                            value="1"
                            min="1"
                            max="100"
                          />
                          <span class="input-group-btn">
                            <button
                              type="button"
                              class="btn btn-success btn-number btn-sm"
                              data-type="plus"
                              data-field="quant[2]"
                            >
                              <span class="glyphicon glyphicon-plus"></span>
                            </button>
                          </span>
                        </div>
                      </div> -->
                    </li>
                    <!-- <li>
                                        <a routerLink="/#" class="btn-cross dev-remove-menu-item"><i class=" icon-cross3"></i></a>
                                        <a>Chicken Tandoori Special 12" Deep Pan</a>
                                        <span class="category-price">4.50</span>
                                    </li>
                                    <li>
                                        <a routerLink="/#" class="btn-cross dev-remove-menu-item"><i class=" icon-cross3"></i></a>
                                        <a>Chicken Tandoori Special 09" Deep Pan</a>
                                        <span class="category-price">6.50</span>
                                        <ul>
                                            <li>Drinks - chillies : <span class="category-price">3.50</span>
                                            </li>
                                        </ul>
                                        <a routerLink="/#" data-toggle="modal" data-target="#extras-0-1" class="update-menu dev-update-menu-btn">Edit</a>
                                    </li>
                                    <li>
                                        <a routerLink="/#" class="btn-cross dev-remove-menu-item"><i class=" icon-cross3"></i></a>
                                        <a>Margherita 12" Deep Pan</a>
                                        <span class="category-price">5.50</span>
                                    </li> -->
                  </ul>
                  <div class="price-area dev-menu-price-con" *ngIf="cartArray.length > 0">
                    <ul>
                      <li>
                        Subtotal
                        <span class="price"><em class="dev-menu-subtotal">AED {{ subtotal | number: "2.2" }}</em></span>
                      </li>
                      <!-- <li class="restaurant-fee-con">
                                            <span class="fee-title">Pick-Up</span>
                                            <span class="price"><em class="dev-menu-charges">15.00</em></span>
                                        </li> -->
                      <li>
                        Vat ({{vendor_vat}}%)
                        <span class="price"><em class="dev-menu-vtax">AED {{ tax | number: "2.2" }}</em></span>
                      </li>
                    </ul>
                    <p class="total-price">
                      Total
                      <span class="price"><em class="dev-menu-grtotal">
                          {{ total | currency: "AED ":"symbol":"1.2-2" }}</em></span>
                    </p>
                  </div>
                </div>
                <div id="dev-no-menu-orders-list">
                  <span class="success-message" *ngIf="cartArray.length > 0">There are {{ cartArray.length }} items in
                    your cart.</span>
                  <span class="success-message" *ngIf="cartArray.length == 0">There are no items in your cart.</span>
                </div>
                <!-- <div class="pay-option dev-order-pay-options">
                                
                                <div class="input-group date" (click)="selectAddress()">
                                    
                                    <input type="text" class="Adddress" value="Select Address" readonly >
                                    <span class="input-group-addon">
                                        <span class="icon-home"></span>
                                    </span>
                                </div>
                            </div> -->

                <div class="select-option dev-select-fee-option">
                  <ul>
                    <li *ngFor="let dellis of del_type_list; let i = index">
                      <input id="{{ dellis.lovName }}" type="radio" value="{{ dellis.lovName }}"
                        (click)="deltype('dellis.lovName')" formControlName="order_fee_type1" name="order_fee_type1" />
                      <label for="{{ dellis.lovName }}">{{
                        dellis.lovName
                        }}</label>
                      <span>Free</span>
                    </li>
                    <!-- <li>
                      <input
                        id="order-pick-up-fee"
                        type="radio"
                        value="1"
                        (click)="deltype(1)"
                        formControlName="order_fee_type1"
                        name="order_fee_type1"
                      />
                      <label for="order-pick-up-fee">Takeway</label>
                      <span>Free</span>
                    </li>
                    <li>
                      <input
                        id="order-cash-payment"
                        type="radio"
                        value="2"
                        checked="checked"
                        (click)="deltype(2)"
                        name="order_fee_type1"
                        formControlName="order_fee_type1"
                      />
                      <label for="order-cash-payment">Delivery</label>
                      <span>Free</span>
                    </li>
                    <li>
                      <input
                        id="order-road-pickup"
                        type="radio"
                        value="3"
                        (click)="deltype(3)"
                        name="order_fee_type1"
                        formControlName="order_fee_type1"
                      />
                      <label for="order-road-pickup">Curbside Pick Up</label>
                      <span>Free</span>
                    </li> -->
                  </ul>
                </div>

                <div class="pay-option dev-order-pay-options">
                  <ul>
                    <li>
                      <i class="icon-coins"></i>
                      <div>
                        <input id="order-cash-payment" type="radio" formControlName="PaymentType" name="PaymentType"
                          value="Cash" checked="checked" />
                        <label for="order-cash-payment">Cash</label>
                      </div>
                    </li>
                    <li>

                      <input
                        id="order-card-payment"
                        type="radio"
                        formControlName="PaymentType"
                        name="PaymentType"
                        value="TPoints"
                      />
                      <label for="order-card-payment"
                        ><i class="icon-credit-card4"></i>T-Points</label
                      >
                    </li>
                    <li>
                      <input
                        id="order-card-payment"
                        type="radio"
                        formControlName="PaymentType"
                        name="PaymentType"
                        value="Card"
                        checked="checked"
                      />
                      <label for="order-card-payment"
                        ><i class="icon-credit-card4"></i>Online Payment</label
                      >

                    </li>
                  </ul>
                </div>
                <div class="row" *ngIf="UserBal">
                  <div class="col-sm-12" *ngIf="UserBal.result.length > 0">
                    <div class="order-sort-results">
                      <ul>
                        <li>
                          <input type="checkbox" class="foodbakery_restaurant_category" formControlName="ewallet_chk"
                            value="1" style="display: none" />&nbsp; &nbsp;
                          <label for="order-card-payment" *ngIf="UserBal.result.length > 0"><i
                              class="icon-credit-card4"></i>&nbsp;
                            T-Points&nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {{ UserBal.result[0].credit }}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="input-group date">
                                            <input type="text" name="delivery_date"   id="datetimepicker1" class="form-control" formControlName="delivery_date" value="{{setdate}}" placeholder="Select Date and Time">
                                            <span class="input-group-addon">
                                                <span class="icon-event_available"></span>
                                            </span>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                <a (click)="selectAddress()" class="menu-order-confirm">Confirm Order</a>
                <span class="menu-loader"></span>
              </form>
            </div>
          </div>

          <!--sub-->
          <div class="user-order-holder" *ngIf="schedulevisibility">
            <div class="user-order">
              <h6><i class="icon-shopping-basket"></i>Your Order</h6>
              <div class="custom_tabs">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs" role="tablist">
                  <!-- <li role="presentation" class="active">
                    <a href="#regplan" role="tab" data-toggle="tab"
                      >Regular Order</a
                    >
                  </li> -->
                  <li role="presentation" class="active">
                    <a href="#suborder" role="tab" data-toggle="tab">Subscription Order</a>
                  </li>
                  <!-- <li role="presentation">
                    <a href="#mealplan" role="tab" data-toggle="tab"
                      >Meal Plan</a
                    >
                  </li> -->
                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                  <!-- <div role="tabpanel" class="tab-pane active" id="regplan">
                    Regular
                  </div> -->
                  <div role="tabpanel" class="tab-pane" id="suborder">
                    <!-- <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">Start Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">End Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="">
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                        />
                        Mon
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox2"
                          value="option2"
                        />
                        Tue
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox3"
                          value="option3"
                        />
                        Wed
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox4"
                          value="option4"
                        />
                        Thu
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox5"
                          value="option5"
                        />
                        Fri
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox6"
                          value="option6"
                        />
                        Sat
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox7"
                          value="option7"
                        />
                        Sun
                      </label>
                    </div> -->
                  </div>
                  <!-- <div role="tabpanel" class="tab-pane" id="mealplan">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">Start Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">End Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <br />
              <form [formGroup]="form">
                <div id="dev-menu-orders-list" *ngIf="ScheduleArray.length > 0">
                  <ul class="categories-order">
                    <li class="menu-added clearfix" *ngFor="let lis of ScheduleArray; let i = index">
                      <a class="btn-cross dev-remove-menu-item" (click)="removeScheduleItem(i, ScheduleArray)"><i
                          class="fa fa-remove"></i></a>
                      <a>{{ lis.menuName }} x
                        <span style="color: #39b44d !important;"><b>{{lis.qty}}</b></span>
                      </a>
                      <a class="btn-cross dev-remove-menu-item" (click)="Schedule_Qty_decrese(i, ScheduleArray)"><i
                          class="fa fa-minus" style="color: #e91111 !important;"></i></a>
                      <span class="category-price">AED {{ lis.rate * lis.qty | number: "2.2" }}</span>
                      <ul>
                        <li *ngIf="lis.mon == true">Monday</li>
                        <li *ngIf="lis.tue == true">Tuesday</li>
                        <li *ngIf="lis.wed == true">Wednesday</li>
                        <li *ngIf="lis.thu == true">Thursday</li>
                        <li *ngIf="lis.fri == true">Friday</li>
                        <li *ngIf="lis.sat == true">Saturday</li>
                        <li *ngIf="lis.sun == true">Sunday</li>
                      </ul>
                      <!-- <div
                        class=""
                        style="width: 100px; margin-top: 0; float: right"
                      >
                        <div class="input-group">
                          <span class="input-group-btn">
                            <button
                              type="button"
                              class="btn btn-danger btn-number btn-sm"
                              data-type="minus"
                              data-field="quant[2]"
                            >
                              <span class="glyphicon glyphicon-minus"></span>
                            </button>
                          </span>
                          <input
                            style="
                              height: 30px;
                              padding: 5px;
                              text-align: center;
                            "
                            type="text"
                            name="quant[2]"
                            class="form-control input-number"
                            value="10"
                            min="1"
                            max="100"
                          />
                          <span class="input-group-btn">
                            <button
                              type="button"
                              class="btn btn-success btn-number btn-sm"
                              data-type="plus"
                              data-field="quant[2]"
                            >
                              <span class="glyphicon glyphicon-plus"></span>
                            </button>
                          </span>
                        </div>
                      </div> -->
                    </li>
                    <!-- <li>
                                        <a routerLink="/#" class="btn-cross dev-remove-menu-item"><i class=" icon-cross3"></i></a>
                                        <a>Chicken Tandoori Special 12" Deep Pan</a>
                                        <span class="category-price">4.50</span>
                                    </li>
                                    <li>
                                        <a routerLink="/#" class="btn-cross dev-remove-menu-item"><i class=" icon-cross3"></i></a>
                                        <a>Chicken Tandoori Special 09" Deep Pan</a>
                                        <span class="category-price">6.50</span>
                                        <ul>
                                            <li>Drinks - chillies : <span class="category-price">3.50</span>
                                            </li>
                                        </ul>
                                        <a routerLink="/#" data-toggle="modal" data-target="#extras-0-1" class="update-menu dev-update-menu-btn">Edit</a>
                                    </li>
                                    <li>
                                        <a routerLink="/#" class="btn-cross dev-remove-menu-item"><i class=" icon-cross3"></i></a>
                                        <a>Margherita 12" Deep Pan</a>
                                        <span class="category-price">5.50</span>
                                    </li> -->
                  </ul>
                  <div class="price-area dev-menu-price-con" *ngIf="ScheduleArray.length > 0">
                    <ul>
                      <li>
                        Subtotal
                        <span class="price"><em class="dev-menu-subtotal">AED {{ scheduleordersubtotal | number: "2.2"
                            }}</em></span>
                      </li>
                      <!-- <li class="restaurant-fee-con">
                                            <span class="fee-title">Pick-Up</span>
                                            <span class="price"><em class="dev-menu-charges">15.00</em></span>
                                        </li> -->
                      <li>
                        Vat ({{ vendor_vat }}%)
                        <span class="price"><em class="dev-menu-vtax">AED {{ scheduleordertax | number: "2.2"
                            }}</em></span>
                      </li>
                    </ul>
                    <p class="total-price">
                      Total
                      <span class="price"><em class="dev-menu-grtotal">
                          {{
                          scheduleordertotal
                          | currency: "AED ":"symbol":"1.2-2"
                          }}</em></span>
                    </p>
                  </div>
                </div>
                <div id="dev-no-menu-orders-list">
                  <span class="success-message" *ngIf="ScheduleArray.length > 0">There are {{ ScheduleArray.length }}
                    items in your
                    cart.</span>
                  <span class="success-message" *ngIf="ScheduleArray.length == 0">There are no items in your
                    cart.</span>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="date">Time</label>
                      <input type="time" formControlName="delivery_time" class="form-control form-control-sm" />
                    </div>
                  </div>
                </div>

                <!-- <div class="pay-option dev-order-pay-options">
                                
                                <div class="input-group date" (click)="selectAddress()">
                                    
                                    <input type="text" class="Adddress" value="Select Address" readonly >
                                    <span class="input-group-addon">
                                        <span class="icon-home"></span>
                                    </span>
                                </div>
                            </div> -->

                <div class="select-option dev-select-fee-option">
                  <ul>
                    <li *ngFor="let dellis of del_type_list; let i = index">
                      <input id="{{ dellis.lovName }}" type="radio" value="{{ dellis.lovName }}"
                        (click)="deltype('dellis.lovName')" formControlName="order_fee_type1" name="order_fee_type1" />
                      <label for="{{ dellis.lovName }}">{{
                        dellis.lovName
                        }}</label>
                      <span>Free</span>
                    </li>
                    <!-- <li>
                      <input
                        id="order-pick-up-fee"
                        type="radio"
                        value="1"
                        (click)="deltype(1)"
                        formControlName="order_fee_type1"
                        name="order_fee_type1"
                      />
                      <label for="order-pick-up-fee">Takeway</label>
                      <span>Free</span>
                    </li>
                    <li>
                      <input
                        id="order-cash-payment"
                        type="radio"
                        value="2"
                        checked="checked"
                        (click)="deltype(2)"
                        name="order_fee_type1"
                        formControlName="order_fee_type1"
                      />
                      <label for="order-cash-payment">Delivery</label>
                      <span>Free</span>
                    </li>
                    <li>
                      <input
                        id="order-road-pickup"
                        type="radio"
                        value="3"
                        (click)="deltype(3)"
                        name="order_fee_type1"
                        formControlName="order_fee_type1"
                      />
                      <label for="order-road-pickup">Curbside Pick Up</label>
                      <span>Free</span>
                    </li> -->
                  </ul>
                </div>

                <div class="pay-option dev-order-pay-options">
                  <ul>
                    <li>

                      <input
                        id="order-card-payment"
                        type="radio"
                        formControlName="PaymentType"
                        name="PaymentType"
                        value="Card"
                        checked="checked"
                      />
                      <label for="order-card-payment"
                        ><i class="icon-credit-card4"></i>Online Payment</label
                      >

                    </li>
                    <!-- <li>
                      <input
                        id="order-cash-payment"
                        type="radio"
                        formControlName="PaymentType"
                        name="PaymentType"
                        value="Cash"
                        checked="checked"
                      />
                      <label for="order-cash-payment"
                        ><i class="icon-coins"></i>Cash</label
                      >
                    </li>
                    <li>
                      <input
                        id="order-card-payment"
                        type="radio"
                        formControlName="PaymentType"
                        name="PaymentType"
                        value="eWallet"
                      />
                      <label for="order-card-payment"
                        ><i class="icon-credit-card4"></i>TPoints</label
                      >
                    </li> -->
                  </ul>
                </div>
                <!-- <div class="row" *ngIf="UserBal">
                  <div class="col-sm-12" *ngIf="UserBal.result.length > 0">
                    <div class="order-sort-results">
                      <ul>
                        <li>
                          <input
                            type="checkbox"
                            class="foodbakery_restaurant_category"
                            formControlName="ewallet_chk"
                            value="1"
                          />&nbsp; &nbsp;
                          <label
                            for="order-card-payment"
                            *ngIf="UserBal.result.length > 0"
                            ><i class="icon-credit-card4"></i>&nbsp;
                            TPoints&nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {{ UserBal.result[0].credit }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="input-group date">
                                            <input type="text" name="delivery_date"   id="datetimepicker1" class="form-control" formControlName="delivery_date" value="{{setdate}}" placeholder="Select Date and Time">
                                            <span class="input-group-addon">
                                                <span class="icon-event_available"></span>
                                            </span>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                <a (click)="CheckoutSchedule()" class="menu-order-confirm">Confirm Order</a>
                <span class="menu-loader"></span>
              </form>
            </div>
          </div>
          <!--Meal-->
          <div class="user-order-holder" *ngIf="mealvisibility">
            <div class="user-order">
              <h6><i class="icon-shopping-basket"></i>Your Order</h6>
              <div class="custom_tabs">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs" role="tablist">
                  <!-- <li role="presentation" class="active">
                    <a href="#regplan" role="tab" data-toggle="tab"
                      >Regular Order</a
                    >
                  </li>
                  <li role="presentation">
                    <a href="#suborder" role="tab" data-toggle="tab"
                      >Subscription Order</a
                    >
                  </li> -->
                  <li role="presentation" class="active">
                    <a href="#mealplan" role="tab" data-toggle="tab">Meal Plan</a>
                  </li>
                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                  <!-- <div role="tabpanel" class="tab-pane active" id="regplan">
                    Regular
                  </div>
                  <div role="tabpanel" class="tab-pane" id="suborder">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">Start Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">End Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="">
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                        />
                        Mon
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox2"
                          value="option2"
                        />
                        Tue
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox3"
                          value="option3"
                        />
                        Wed
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox4"
                          value="option4"
                        />
                        Thu
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox5"
                          value="option5"
                        />
                        Fri
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox6"
                          value="option6"
                        />
                        Sat
                      </label>
                      <label class="checkbox-inline">
                        <input
                          type="checkbox"
                          id="inlineCheckbox7"
                          value="option7"
                        />
                        Sun
                      </label>
                    </div>
                  </div> -->
                  <div role="tabpanel" class="tab-pane active" id="mealplan">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <span class="discount-info" style="display: block">Meal Plan Start from Next Month.</span>
                        </div>
                      </div>
                      <!-- <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">Start Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label for="date">End Date</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <form [formGroup]="form">
                <div id="dev-menu-orders-list" *ngIf="MealArray.length > 0">
                  <ul class="categories-order">
                    <li class="menu-added clearfix" *ngFor="let lis of MealArray; let i = index">
                      <a class="btn-cross dev-remove-menu-item" (click)="removeMealItem(i, MealArray)"><i
                          class="fa fa-remove"></i></a>
                      <a>{{ lis.menuname }}</a>
                      <span class="category-price">AED {{ lis.mealrate | number: "2.2" }}</span>
                      <ul *ngIf="lis.type">
                        <li>{{ lis.type }}</li>
                        <li *ngIf="lis.breakfast == true">BreakFast</li>
                        <li *ngIf="lis.lunch == true">Lunch</li>
                        <li *ngIf="lis.dinner == true">Dinner</li>
                      </ul>
                      <!-- <div
                        class=""
                        style="width: 100px; margin-top: 0; float: right"
                      >
                        <div class="input-group">
                          <span class="input-group-btn">
                            <button
                              type="button"
                              class="btn btn-danger btn-number btn-sm"
                              data-type="minus"
                              data-field="quant[2]"
                            >
                              <span class="glyphicon glyphicon-minus"></span>
                            </button>
                          </span>
                          <input
                            style="
                              height: 30px;
                              padding: 5px;
                              text-align: center;
                            "
                            type="text"
                            name="quant[2]"
                            class="form-control input-number"
                            value="10"
                            min="1"
                            max="100"
                          />
                          <span class="input-group-btn">
                            <button
                              type="button"
                              class="btn btn-success btn-number btn-sm"
                              data-type="plus"
                              data-field="quant[2]"
                            >
                              <span class="glyphicon glyphicon-plus"></span>
                            </button>
                          </span>
                        </div>
                      </div> -->
                    </li>
                    <!-- <li>
                                        <a routerLink="/#" class="btn-cross dev-remove-menu-item"><i class=" icon-cross3"></i></a>
                                        <a>Chicken Tandoori Special 12" Deep Pan</a>
                                        <span class="category-price">4.50</span>
                                    </li>
                                    <li>
                                        <a routerLink="/#" class="btn-cross dev-remove-menu-item"><i class=" icon-cross3"></i></a>
                                        <a>Chicken Tandoori Special 09" Deep Pan</a>
                                        <span class="category-price">6.50</span>
                                        <ul>
                                            <li>Drinks - chillies : <span class="category-price">3.50</span>
                                            </li>
                                        </ul>
                                        <a routerLink="/#" data-toggle="modal" data-target="#extras-0-1" class="update-menu dev-update-menu-btn">Edit</a>
                                    </li>
                                    <li>
                                        <a routerLink="/#" class="btn-cross dev-remove-menu-item"><i class=" icon-cross3"></i></a>
                                        <a>Margherita 12" Deep Pan</a>
                                        <span class="category-price">5.50</span>
                                    </li> -->
                  </ul>
                  <div class="price-area dev-menu-price-con" *ngIf="MealArray.length > 0">
                    <ul>
                      <li>
                        Subtotal
                        <span class="price"><em class="dev-menu-subtotal">AED {{ mealplanrate | number: "2.2"
                            }}</em></span>
                      </li>
                      <!-- <li class="restaurant-fee-con">
                                            <span class="fee-title">Pick-Up</span>
                                            <span class="price"><em class="dev-menu-charges">15.00</em></span>
                                        </li> -->
                      <li>
                        Vat ({{ vendor_vat }}%)
                        <span class="price"><em class="dev-menu-vtax">AED {{ mealplantax | number: "2.2" }}</em></span>
                      </li>
                    </ul>
                    <p class="total-price">
                      Total
                      <span class="price"><em class="dev-menu-grtotal">
                          AED {{ mealplanttot | number: "2.2" }}</em></span>
                    </p>
                  </div>
                </div>
                <div id="dev-no-menu-orders-list">
                  <span class="success-message" *ngIf="MealArray.length > 0">There are {{ MealArray.length }} items in
                    your cart.</span>
                  <span class="success-message" *ngIf="MealArray.length == 0">There are no items in your cart.</span>
                </div>
                <!-- <div class="pay-option dev-order-pay-options">
                                
                                <div class="input-group date" (click)="selectAddress()">
                                    
                                    <input type="text" class="Adddress" value="Select Address" readonly >
                                    <span class="input-group-addon">
                                        <span class="icon-home"></span>
                                    </span>
                                </div>
                            </div> -->

                <div class="select-option dev-select-fee-option">
                  <ul>
                    <li *ngFor="let dellis of del_type_list; let i = index">
                      <input id="{{ dellis.lovName }}" type="radio" value="{{ dellis.lovName }}"
                        (click)="deltype('dellis.lovName')" formControlName="order_fee_type1" name="order_fee_type1" />
                      <label for="{{ dellis.lovName }}">{{
                        dellis.lovName
                        }}</label>
                      <span>Free</span>
                    </li>
                    <!-- <li>
                      <input
                        id="order-pick-up-fee"
                        type="radio"
                        value="1"
                        (click)="deltype(1)"
                        formControlName="order_fee_type1"
                        name="order_fee_type1"
                      />
                      <label for="order-pick-up-fee">Takeway</label>
                      <span>Free</span>
                    </li>
                    <li>
                      <input
                        id="order-cash-payment"
                        type="radio"
                        value="2"
                        checked="checked"
                        (click)="deltype(2)"
                        name="order_fee_type1"
                        formControlName="order_fee_type1"
                      />
                      <label for="order-cash-payment">Delivery</label>
                      <span>Free</span>
                    </li>
                    <li>
                      <input
                        id="order-road-pickup"
                        type="radio"
                        value="3"
                        (click)="deltype(3)"
                        name="order_fee_type1"
                        formControlName="order_fee_type1"
                      />
                      <label for="order-road-pickup">Curbside Pick Up</label>
                      <span>Free</span>
                    </li> -->
                  </ul>
                </div>

                <div class="pay-option dev-order-pay-options">
                  <ul>
                    <li>

                      <input
                        id="order-card-payment"
                        type="radio"
                        formControlName="PaymentType"
                        name="PaymentType"
                        value="Card"
                        checked="checked"
                      />
                      <label for="order-card-payment"
                        ><i class="icon-credit-card4"></i>Online Payment</label
                      >
                    </li>
                    <li>
                      <input
                        id="order-card-payment"
                        type="radio"
                        formControlName="PaymentType"
                        name="PaymentType"
                        value="TPoints"
                      />
                      <label for="order-card-payment"
                        ><i class="icon-coins"></i>T-Points</label
                      >

                    </li>
                  </ul>
                </div>
                <div class="row" *ngIf="UserBal">
                  <div class="col-sm-12" *ngIf="UserBal.result.length > 0">
                    <div class="order-sort-results">
                      <ul>
                        <li>
                          <i class="icon-credit-card4"></i>
                          <div>
                            <input type="checkbox" class="foodbakery_restaurant_category" formControlName="ewallet_chk"
                              style="display: none" value="1" />&nbsp; &nbsp;
                            <label for="order-card-payment" *ngIf="UserBal.result.length > 0"> &nbsp;
                              T-Points &nbsp; &nbsp; &nbsp; &nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {{ UserBal.result[0].credit }}</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="input-group date">
                                            <input type="text" name="delivery_date"   id="datetimepicker1" class="form-control" formControlName="delivery_date" value="{{setdate}}" placeholder="Select Date and Time">
                                            <span class="input-group-addon">
                                                <span class="icon-event_available"></span>
                                            </span>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                <a (click)="CheckoutMealPlan()" class="menu-order-confirm">Confirm Order</a>
                <span class="menu-loader"></span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>