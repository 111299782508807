<div class="main-section">
  <!-- Home Pages Elements Strat -->
  <section class="banner">
    <div class="mob-img"><img src="assets/images/banner-img2.png" /></div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>
            Your Place, Your Choice,
            <span> Where You Won't Miss Your Orders</span>
          </h2>
          <!-- <div class="searchform">
            <div class="restaurent">
              <div class="form-group">
                <input type="text" placeholder="Restaurent Name..." />
              </div>
            </div>
            <div class="find-food">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Enter Yout Delivery Location ..."
                />
              </div>
            </div>
            <div class="location">
              <button class="btn4">
                Find Food <i class="bi bi-arrow-right"></i>
              </button>
            </div>
          </div> -->

          <form>
            <div class="searchform">
              <div class="restaurent">
                <div class="form-group">
                  <!-- <input type="text" placeholder="Restaurent Name..." /> -->
                  <div class="ng-autocomplete">
                    <ng-autocomplete
                      [data]="data2"
                      [searchKeyword]="keyword2"
                      (selected)="selectEvent2($event)"
                      placeholder="Resturant Name"
                      (inputChanged)="onChangeSearch2($event)"
                      (inputFocused)="onFocused2($event)"
                      [itemTemplate]="itemTemplate2"
                      [notFoundTemplate]="notFoundTemplate2"
                    >
                    </ng-autocomplete>

                    <ng-template #itemTemplate2 let-item>
                      <a [innerHTML]="item.vendorName"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate2 let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="find-food">
                <div class="form-group">
                  <!-- <input
                    type="text"
                    placeholder="Enter Yout Delivery Location ..."
                  /> -->
                  <div
                    class="foodbakery-locations-fields-group foodbakery-focus-out"
                  >
                    <span
                      id="foodbakery_radius_location_open333"
                      class="foodbakery-radius-location"
                    ></span>
                    <div class="ng-autocomplete">
                      <ng-autocomplete
                        [data]="data"
                        [searchKeyword]="keyword"
                        (selected)="selectEvent($event)"
                        (inputChanged)="onChangeSearch($event)"
                        (inputFocused)="onFocused($event)"
                        [itemTemplate]="itemTemplate"
                        [notFoundTemplate]="notFoundTemplate"
                        placeholder="Enter Yout Delivery Location ..."
                      >
                      </ng-autocomplete>

                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.location"></a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="location">
                <button
                  class="btn4"
                  type="button"
                  (click)="getvalue()"
                  value="Search"
                >
                  Find Food <i class="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
          </form>
          <div class="numbers">
            <div class="boxes">
              <div class="icon"><img src="assets/images/merchant.svg" /></div>
              <div class="txt">
                <h4>50+ <span>Merchants</span></h4>
              </div>
            </div>
            <div class="boxes">
              <div class="icon"><img src="assets/images/serve.svg" /></div>
              <div class="txt">
                <h4>10K+ <span>People Served</span></h4>
              </div>
            </div>
            <div class="boxes">
              <div class="icon">
                <img src="assets/images/registered-user.svg" />
              </div>
              <div class="txt">
                <h4>5K+ <span>Registered Users</span></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="tkaff-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-12">
          <div class="tkaff">
            <h3>What would you like to do</h3>
            <h2>
              Restaurants and <br />
              Cafeteria
            </h2>
            <h4>Many more to come…</h4>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="app">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 mb-4">
          <h2>
            Simple way to <span>order</span> <br />
            Your <span> food </span> faster
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed eos et accusam et justo duo dolores et ea
            rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
            ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed
          </p>
          <div class="app-icon">
            <a href="#"><img src="assets/images/logo_playstore.svg" /> </a>
            <a href="#" class="disable-btn"
              ><img src="assets/images/logo_appstore.svg"
            /></a>
          </div>
        </div>
        <div class="col-md-6 mb-5">
          <img src="assets/images/app-img.png" class="img-responsive" />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <div class="better-than">
            <div class="row align-items-center">
              <div class="col-md-12 col-lg-4">
                <h3>Why we are Better than others</h3>
              </div>
              <div class="col-md-12 col-lg-8">
                <div class="better-icon">
                  <div class="boxes">
                    <div class="icons">
                      <img src="assets/images/fast-delivery.svg" />
                    </div>
                    <h5>Fast Delivery</h5>
                  </div>
                  <div class="boxes">
                    <div class="icons">
                      <img src="assets/images/easy-payment.svg" />
                    </div>
                    <h5>Easy Payment</h5>
                  </div>
                  <div class="boxes">
                    <div class="icons">
                      <img src="assets/images/maximum-quality.svg" />
                    </div>
                    <h5>Maximum Quality</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="locations">
    <div class="container">
      <h2>Popular Locations</h2>

      <div class="row justify-content-center seven-cols">
        <div class="col-4 col-sm-4 col-md-4 col-lg-1 mb-4 disabled">
          <div class="location-box">
            <a [routerLink]="['/listings', 'loc', 'adb']">
              <img
                src="assets/images/new-icon-image/abudhabi.png"
                class="img-responsive"
              />
              <h3>Abu Dhabi</h3>
            </a>
          </div>
        </div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-1 mb-4 disabled">
          <div class="location-box">
            <a [routerLink]="['/listings', 'loc', 'dxb']">
              <img
                src="assets/images/new-icon-image/dubai.png"
                class="img-responsive"
              />
              <h3>Dubai</h3>
            </a>
          </div>
        </div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-1 mb-4">
          <div class="location-box">
            <a [routerLink]="['/listings', 'loc', 'shj']">
              <img
                src="assets/images/new-icon-image/sharjah.png"
                class="img-responsive"
              />
              <h3>Sharjah</h3>
            </a>
          </div>
        </div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-1 mb-4">
          <div class="location-box">
            <a [routerLink]="['/listings', 'loc', 'ajm']">
              <img
                src="assets/images/new-icon-image/Ajman.png"
                class="img-responsive"
              />
              <h3>Ajman</h3>
            </a>
          </div>
        </div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-1 mb-4 disabled">
          <div class="location-box">
            <a [routerLink]="['/listings', 'loc', 'uaq']">
              <img
                src="assets/images/new-icon-image/Umm-Al-Qwain.png"
                class="img-responsive"
              />
              <h3>Umm Al Quwain</h3>
            </a>
          </div>
        </div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-1 mb-4 disabled">
          <div class="location-box">
            <a [routerLink]="['/listings', 'loc', 'fuj']">
              <img
                src="assets/images/new-icon-image/fujairah.png"
                class="img-responsive"
              />
              <h3>Fujairah</h3>
            </a>
          </div>
        </div>
        <div class="col-4 col-sm-4 col-md-4 col-lg-1 mb-4 disabled">
          <div class="location-box">
            <a [routerLink]="['/listings', 'loc', 'ras']">
              <img
                src="assets/images/new-icon-image/ras.png"
                class="img-responsive"
              />
              <h3>Ras Al Khaimah</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
