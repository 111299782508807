import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { ListingsComponent } from './pages/listings/listings.component';
import { ListingDetailComponent } from './pages/listing-detail/listing-detail.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { BuyerAccountSettingComponent } from './pages/buyer/buyer-account-setting/buyer-account-setting.component';
import { BuyerBookingsComponent } from './pages/buyer/buyer-bookings/buyer-bookings.component';
import { BuyerDashboardComponent } from './pages/buyer/buyer-dashboard/buyer-dashboard.component';
import { BuyerOrdersComponent } from './pages/buyer/buyer-orders/buyer-orders.component';
import { BuyerReviewsComponent } from './pages/buyer/buyer-reviews/buyer-reviews.component';
import { BuyerShortlistsComponent } from './pages/buyer/buyer-shortlists/buyer-shortlists.component';
import { BuyerStatementComponent } from './pages/buyer/buyer-statement/buyer-statement.component';
import { BuyerSidebarComponent } from './pages/buyer/buyer-sidebar/buyer-sidebar.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { RegisterRestaurantComponent } from './pages/register-restaurant/register-restaurant.component';
import { RegisterSelectPackageComponent } from './pages/register-select-package/register-select-package.component';
import { RegisterPaymentInfoComponent } from './pages/register-payment-info/register-payment-info.component';
import { RegisterActivationComponent } from './pages/register-activation/register-activation.component';
import { RestaurantDashboardComponent } from './pages/restaurant/restaurant-dashboard/restaurant-dashboard.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { BlogDetailComponent } from './pages/blog/blog-detail/blog-detail.component';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { HeaderUserComponent } from './components/header-user/header-user.component';
import { CoreModule } from './core/core.module';
import { AppService } from './app.service';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { LoginComponent } from './pages/login/login.component';
import { BuyerHeaderComponent } from './pages/buyer/buyer-header/buyer-header.component';
import { UserComponent } from './pages/user/user.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MenuExtraDialogComponent } from './pages/listing-detail/menuextra-dialog/menuextra-dialog.component';
import { SelectAddressDialogComponent } from './pages/listing-detail/selectaddress-dialog/menuextra-dialog/selectaddress-dialog.component';
import { MatStepperModule } from '@angular/material/stepper';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VendorReviewDialogComponent } from './pages/buyer/buyer-orders/vendor-review-dialog/vendor-review-dialog-dialog.component';
import { ConfirmAlertDialogComponent } from './pages/buyer/buyer-orders/confirm-alert-dialog/confirm-alert-dialog.component';
import { BuyerAddressComponent } from './pages/buyer/buyer-address/buyer-address.component';
import { StatementDialogComponent } from './pages/buyer/buyer-statement/statement-dialog/vendor-review-dialog/statement-dialog-dialog.component';
import { BuyerOrdersHistoryComponent } from './pages/buyer/buyer-orders-history/buyer-orders-history.component';
import { BuyerCarInfoComponent } from './pages/buyer/buyer-carinfo/buyer-carinfo.component';
import { MealPlanDialogComponent } from './pages/listing-detail/mealplan-dialog/mealplan-dialog.component';
import { MealPlanCheckoutComponent } from './pages/listing-detail/mealplan-checkout/mealplan-checkout.component';
import { DatePipe } from '@angular/common';
import { ScheduleDialogComponent } from './pages/listing-detail/schedule-dialog/schedule-dialog.component';
import { ScheduleCheckboxComponent } from './pages/listing-detail/schedule-checkout/schedule-checkbox.component';
import { BuyerMealPlanOrdersHistoryComponent } from './pages/buyer/buyer-mealplan-orders/buyer-mealplan-orders-history.component';
import { BuyerScheduleOrdersHistoryComponent } from './pages/buyer/buyer-schedule-orders/buyer-schedule-orders-history.component';
import { BuyerPartnerComponent } from './pages/buyer/buyer-partner/buyer-partner.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BuyerChangePasswordComponent } from './pages/buyer/buyer-changepassword/buyer-changepassword.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';
import { ResetPasswordComponent } from './pages/resetpassword/resetpassword.component';
import { AppInterceptor } from './core/util/app-interceptor';
import { PartnerWithUsComponent } from './pages/partner-with-us/partner-with-us.component';
import { SignupComponent } from './pages/signup/signup.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ListingsComponent,
    ListingDetailComponent,
    PagenotfoundComponent,
    BuyerAccountSettingComponent,
    BuyerBookingsComponent,
    BuyerDashboardComponent,
    BuyerOrdersComponent,
    BuyerReviewsComponent,
    BuyerShortlistsComponent,
    BuyerStatementComponent,
    BuyerSidebarComponent,
    BuyerHeaderComponent,
    RegistrationComponent,
    LoginComponent,
    RegisterRestaurantComponent,
    RegisterSelectPackageComponent,
    RegisterPaymentInfoComponent,
    RegisterActivationComponent,
    RestaurantDashboardComponent,
    SearchResultComponent,
    CheckoutComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    FaqComponent,
    ContactUsComponent,
    HowItWorksComponent,
    BlogDetailComponent,
    OrderDetailComponent,
    HeaderUserComponent,
    MenuExtraDialogComponent,
    MealPlanDialogComponent,
    MealPlanCheckoutComponent,
    ScheduleDialogComponent,
    ScheduleCheckboxComponent,
    SelectAddressDialogComponent,
    VendorReviewDialogComponent,
    ConfirmAlertDialogComponent,
    BuyerAddressComponent,
    StatementDialogComponent,
    BuyerOrdersHistoryComponent,
    BuyerMealPlanOrdersHistoryComponent,
    BuyerScheduleOrdersHistoryComponent,
    BuyerCarInfoComponent,
    BuyerPartnerComponent,
    BuyerChangePasswordComponent,
    ResetPasswordComponent,
    PartnerWithUsComponent,
    SignupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule, 
    NgxPaginationModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatStepperModule,
    AppRoutingModule,
    CoreModule,
    AutocompleteLibModule,
    //NgbModule,
  ],
  providers: [AppService,DatePipe,{ provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
