<div class="main-section">
    <app-buyer-header></app-buyer-header>
    <div class="page-section account-header buyer-logged-in">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <app-buyer-sidebar></app-buyer-sidebar>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div class="user-dashboard loader-holder">
                        <div class="user-holder">
                            <div class="user-profile">
                                
                                <div class="element-title has-border">
                                    <h5>Change Password</h5>
                                </div>
                                <form [formGroup]="formAddressGroup" >
                                  <div class="row">
                                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          <div class="payment-summary-fields">
                                            <div class="row">
                                              
                                             
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                  <label>Current Password</label>
                                                  <!-- <textarea class="foodbakery-dev-req-field" name="trans_address"></textarea> -->
                                                  <input type="password" class="foodbakery-dev-req-field foodbakery-number-field"
                                                  formControlName="Password" name="Password" placeholder="Current Password" value="">
                                                </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <!-- <div class="field-holder">
                                                  <label>Car manufacturer</label>
                                                   <input type="text" class="foodbakery-dev-req-field foodbakery-number-field"
                                                    name="CarMake" formControlName="CarMake" placeholder="Car manufacturer" value=""> 
                                                </div> -->
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                  <label>New Password</label>
                                                  <input type="password" class="foodbakery-dev-req-field foodbakery-number-field"
                                                    formControlName="Newpassword" name="Newpassword" placeholder="New Password" value="">
                                                </div>
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <!-- <div class="field-holder">
                                                  <label>Car Color</label>
                                                  <input type="text" class="foodbakery-dev-req-field foodbakery-number-field"
                                                    formControlName="CarColor" name="CarColor" placeholder="Car Color" value="">
                                                </div> -->
                                              </div>
                                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                  <label>Confirm Password</label>
                                                  <input type="password" class="foodbakery-dev-req-field foodbakery-number-field"
                                                    formControlName="confirmNewPassword" name="confirmNewPassword" placeholder="Confirm Password" value="">
                                                </div>
                                              </div>
                                            </div>
                                            
                                          </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          <div class="field-holder">
                                            <div class="payment-holder">
                                              <div class="payment-section">
                          
                                              </div><button id="register-restaurant-order" class="submit btn-submit" 
                                                type="submit" (click)="onSubmit()">Save</button>
                                            </div>
                                          </div>
                                        </div>
                                  </div>
                              </form>
                              
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
