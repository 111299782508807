<div class="container pt-5">
    <div class="row justify-content-sm-center pt-5">
        <div class="col-sm-6 shadow round pb-3">
            <h1 class="text-center pt-3 text-secondary">Example Form</h1>
            <form [formGroup]="exform">
                <div class="form-group">
                    <label class="col-form-label">Name:</label>
                    <input formControlName="name" type="text" class="form-control">
                    <small *ngIf="name?.invalid && name?.touched" class="text-danger">Name is Required</small>
                </div>
                <div class="form-group">
                    <label class="col-form-label">Email:</label>
                    <input formControlName="email" type="text" class="form-control">
                    <small *ngIf="email?.invalid && email?.touched" class="text-danger">Email is Required</small>
                </div>
                <div class="form-group">
                    <label class="col-form-label">Phone:</label>
                    <input formControlName="phone" type="text" class="form-control">
                    <small *ngIf="phone?.invalid && phone?.touched" class="text-danger">Phone no is Required(minimum
                        length 10,numbers are only allowed)</small>
                </div>
                <div class="form-group">
                    <label class="col-form-label">Message:</label>
                    <textarea formControlName="message" class="form-control"></textarea>
                    <small *ngIf="message?.invalid && message?.touched" class="text-danger"> Message is Required(minimum
                        length 10)</small>
                </div>
                <button (click)="clicksub()" [disabled]="exform.invalid" type="button" class="btn btn-primary">Send
                    message</button>
            </form>
        </div>
    </div>
</div>