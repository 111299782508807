import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.css']
})
export class ListingsComponent implements OnInit {
  public param_id: string = '';
  public param_type: string = '';
  public vendorlist: any
  public vendorlist2: any

  data: any;
  errorMsg: string = '';
  isLoadingResult: boolean = false;
  keyword = 'location';

  form = new FormGroup({
    search: new FormControl()
  })
  vendorTypelist: any;
  userdata: any;
  VendorImagebaseUrl: any =  environment.BASE_API_URL + 'vendor/';


  constructor(public appservice: AppService,
    public fb: FormBuilder,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    // this.activatedRoute.params.subscribe(params => {
    //    console.log( params['type']); 
    //    console.log( params['loc']); 
    //    this.param_id= params['loc']; 
    //    this.param_type= params['type']; 
    //    this.getVendorList(this.param_id,this.param_type);
    //   });
    if (localStorage.getItem("temp_loc")) {
      this.getVendorList(localStorage.getItem("temp_loc"), '', '')
    }
    else if (localStorage.getItem("temp_type")) {
      this.getVendorList('Dubai', localStorage.getItem("temp_type"), '')
    }
    else {
      this.getVendorList('Dubai', '', '')
    }
    this.searchItem();
    this.getAllLocation();
    this.getVendorType();
    this.getProfile();
  }

  public getProfile() {
    if (localStorage.getItem("token")) {
      this.appservice.getUserProfile().subscribe((userdata: any) => {
        console.log(userdata)
        this.userdata = userdata
        this.getVendorList('Dubai', '', userdata.customer.id)
      });
    }
  }


  public getAllLocation() {
    this.appservice.getGeoVendor().subscribe(loc => {
      this.appservice.Data.geovendorLocation = loc;
      //console.log(loc)
      this.data = loc;
    });
  }

  public getVendorList(loc: any, type: any, cusid: any) {
    console.log(type, loc)
    if (localStorage.getItem("token")) {
      let passdata = {
        "VendorType": type, "VendorNameSearch": "", "Location": loc, "CustomerId": cusid
      }
      console.log(passdata)

      this.appservice.getVendor(passdata).subscribe(vendata => {
        console.log(vendata)
        this.vendorlist = vendata
        this.vendorlist2 = vendata
        localStorage.removeItem("temp_loc")
        localStorage.removeItem("temp_type")
      });
    }
    else {
      let passdata = {
        "VendorType": type, "VendorNameSearch": "", "Location": loc, "CustomerId": 0
      }
      console.log(passdata)
      this.appservice.getVendorWithoutToken(passdata).subscribe(vendata => {
        console.log(vendata)
        this.vendorlist = vendata
        this.vendorlist2 = vendata
        localStorage.removeItem("temp_loc")
        localStorage.removeItem("temp_type")

      });
    }
  }

  public getVendorType() {
    if (localStorage.getItem("token")) {
      this.appservice.getVendorType().subscribe(vend_type => {
        console.log(vend_type)
        this.vendorTypelist = vend_type;
      });
    } else {
      this.appservice.getVendorTypeWithoutToke().subscribe(vend_type => {
        console.log(vend_type)
        this.vendorTypelist = vend_type;
      });
    }
  }


  private searchItem() {
    this.form.controls['search'].valueChanges.subscribe(value => {
      //console.log(value);
      if (value != "") {
        this.vendorlist = this.vendorlist2
        this.vendorlist = this.vendorlist.filter((option: { vendorName: string; }) => option?.vendorName?.toLowerCase().includes(value))
      }
      else {
        this.vendorlist = this.vendorlist2
      }

      //   let passdata = {
      //     "VendorType": '', "VendorNameSearch": value, "Location": '', "CustomerId": 0
      //   }
      //   this.appservice.getVendorWithoutToken(passdata).subscribe(vendata => {
      //     console.log(vendata)
      //     this.vendorlist = vendata
      // //    this.vendorlist2 = vendata


      //   });

    });
  }


  selectEvent(item: any) {
    // do something with selected item

    console.log(item)
    console.log(item.location)
    if (item.location != null) {
      console.log(item.location)
      this.vendorlist = this.vendorlist.filter((option: { location: string; }) => option?.location?.includes(item.location))
      console.log()
    }
  }

  onChangeSearch(val: string) {
    //console.log(val)
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e: any) {
    // console.log(e)

    // do something when input is focused
  }

  onCleared() {
    this.vendorlist = this.vendorlist2
  }

  type(event: any) {
    console.log(event)
    if (event != null) {
      if (event == 'All') {
        this.getVendorList('Dubai', '', '');
      }
      else {
        this.getVendorList('Dubai', event.lovName, '');
      }
    }
  }

  ClickFav(id: number) {
    if (localStorage.getItem("token")) {
      let fav =
      {
        "CustomerId": this.userdata.customer.id,
        "VendorId": id,
        "IsPartner": 0

      }

      this.appservice.myFavourite(fav).subscribe((tets: any) => {
        console.log(tets)
        this.getVendorList('Dubai', '', this.userdata.customer.id);
        if (tets.result == true) {
          this.snackBar.open(' Added as Favourite', '×', {
            panelClass: 'success',
            verticalPosition: 'top',
            duration: 3000,
          });
        }
      })
    }
    else {
      this.snackBar.open('Please Login to continue !!! ', '×', {  panelClass: 'danger',  verticalPosition: 'top',  duration: 3000, });
    }
  }
  removefav(res: any) {
    if (localStorage.getItem("token")) {
      console.log(res)
      this.appservice.myRemoveFavourite(res).subscribe((re: any) => {
        console.log(re)
        this.getVendorList('Dubai', '', this.userdata.customer.id);
        if (re.result == true)
          this.snackBar.open(' Removed Favourite', '×', {
            panelClass: 'danger',
            verticalPosition: 'top',
            duration: 3000,
          });
      })
    }
    else {
      this.snackBar.open('Please Login to continue !!! ', '×', {  panelClass: 'danger',  verticalPosition: 'top',  duration: 3000, });
    }
  }

}
