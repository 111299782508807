<header class="cbp-af-header">
  <div class="main_header_area animated">
    <div class="container">
      <nav id="navigation1" class="navigation">
        <!-- Logo Area Start -->
        <div class="nav-header">
          <a class="logo" href="/"></a>

          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <i class="bi bi-list"></i>
          </button>
        </div>
        <div class="nav-menus-wrapper">
          <!-- Main Menus Wrapper -->
          <div class="nav-menus-wrapper">
            <div
              class="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <div class="nav-button align-to-right">
                <div class="login-option">
                  <div class="user-dashboard-menu">
                    <ul>
                      <li class="user-dashboard-menu-children">
                        <a href="javascript:void(0);" class="">
                          <figure class="profile-image">
                            <img src="{{ imageURL }}" alt="" />
                          </figure>
                          <span *ngIf="user">{{
                            user.customer.customerName
                          }}</span>
                        </a>
                        <ul>
                          <li>
                            <a routerLink="/buyer-account-setting"
                              ><i class="icon-build"></i>Account Settings</a
                            >
                          </li>
                          <li>
                            <a (click)="logout()"
                              ><i class="icon-log-out"></i>Sign out</a
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ul class="nav-menu align-to-left">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li><a [routerLink]="['/about-us']">About Us</a></li>
                <li><a [routerLink]="['/partner-with-us']">Partner with us</a></li>
                <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</header>

<!-- <header id="header">
  <div class="main-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <div class="logo">
            <figure>
              <a
                [routerLink]="['/home']"
                class="light-logo"
                style="font-size: 18px"
              >
                <img
                  src="assets/images/tkaff-logo.svg"
                  alt="Tkaff"
                  height="30"
                />
              </a>
            </figure>
          </div>
          <div class="main-nav">
            <nav id="site-navigation" class="main-navigation">
              <ul>
                <li><a [routerLink]="['/home']">Home</a></li>
                <li><a [routerLink]="['/about-us']">About Us</a></li>
                <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="login-option">
            <div class="user-dashboard-menu">
              <ul>
                <li class="user-dashboard-menu-children">
                  <a href="javascript:void(0);" class="">
                    <figure class="profile-image">
                      <img src="{{ imageURL }}" alt="" />
                    </figure>
                    <span *ngIf="user">{{ user.customer.customerName }}</span>
                  </a>
                  <ul>
                    <li>
                      <a routerLink="/buyer-account-setting"
                        ><i class="icon-build"></i>Account Settings</a
                      >
                    </li>
                    <li>
                      <a (click)="logout()"
                        ><i class="icon-log-out"></i>Sign out</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header> -->
