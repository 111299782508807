<section class="footer">
  <div class="container">
    <div class="row">
      <section class="get-app">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-8 mb-4">
              <h4>Tkaff In Your Mobile! Get Our App</h4>
              <p>Get our app, it's the fastest way to order food on the go.</p>
              <div class="app-icon">
                <a href="#"><img src="assets/images/play-store.png" /> </a>
                <a href="#" class="disable-btn"
                  ><img src="assets/images/app-store.png"
                /></a>
              </div>

              <form
                [formGroup]="sendLinkForm"
                (ngSubmit)="onsendLinkFormSubmit()"
                class="wp-user-form webkit"
              >
                <div class="form-group-link">
                  <div class="form-group">
                    <input
                      formControlName="txtSendLink"
                      type="text"
                      size="20"
                      class="form-control"
                      placeholder="Enter Your Email"
                    />

                    <button class="btn3">Send Link</button>
                  </div>

                  <div
                    *ngIf="ff.txtSendLink.touched && ff.txtSendLink.invalid"
                    class="text-danger"
                    style="float: left !important"
                  >
                    <div *ngIf="ff.txtSendLink.errors?.required">
                      Email Id is required
                    </div>
                    <div *ngIf="ff.txtSendLink.errors?.pattern">
                      Please enter a valid Email Id.
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-4 mob-img">
              <img src="assets/images/app-mob.png" class="img-responsive" />
            </div>
          </div>
        </div>
      </section>

      <section class="news-letter">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10">
              <h2>Subscribe Our Newsletter!</h2>
              <div class="subscribe_form">
                <form
                  [formGroup]="subscribeForm"
                  (ngSubmit)="onsubscribeFormSubmit()"
                  class="wp-user-form webkit"
                >
                  <div class="input-group input-group-lg">
                    <input
                      formControlName="txtSubscribe"
                      type="text "
                      class="form-control input-lg"
                      placeholder="Enter your email"
                    />
                    <span class="input-group-btn">
                      <button
                        type="submit "
                        class="btn c-theme-btn c-btn-uppercase btn-lg c-btn-bold c-btn-square"
                      >
                        SUBSCRIBE
                      </button>
                    </span>
                  </div>

                  <div
                    *ngIf="fs.txtSubscribe.touched && fs.txtSubscribe.invalid"
                    class="text-danger"
                    style="float: left !important"
                  >
                    <div *ngIf="fs.txtSubscribe.errors?.required">
                      Email Id is required
                    </div>
                    <div *ngIf="fs.txtSubscribe.errors?.pattern">
                      Please enter a valid Email Id.
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="row">
      <div class="col-md-4 mb-4">
        <div class="logo">
          <a href="/"><img src="assets/images/logo.svg" /></a>
        </div>
        <h5>Follow us on</h5>
        <div class="social-icons">
          <li>
            <a href="" target="blank"><i class="uil uil-facebook"></i></a>
          </li>
          <li>
            <a href="" target="blank"><i class="uil uil-instagram"></i> </a>
          </li>
          <li>
            <a href="" target="blank"><i class="uil uil-linkedin"></i> </a>
          </li>
          <li>
            <a href="" target="blank"><i class="uil uil-twitter"></i> </a>
          </li>
          <li>
            <a href="" target="blank"><i class="uil uil-youtube"></i> </a>
          </li>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <h4>Quick Links</h4>
        <div class="block-list">
          <ul>
            <li><a [routerLink]="['/home']">Home</a></li>
            <li><a [routerLink]="['/about-us']">About Us</a></li>
            <!-- <li><a href="offers.html">Offers</a></li> -->
            <li><a [routerLink]="['/partner-with-us']">Partner with us</a></li>
            <!-- <li><a href="careers.html">Careers </a></li> -->
            <li><a [routerLink]="['/faq']">FAQ </a></li>
            <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
            <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
            <li>
              <a [routerLink]="['/terms-condition']">Terms and Conditions</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <h4>Get In Touch</h4>
        <div class="ftrlist-2">
          <li>
            <i class="bi bi-geo-alt"></i> <span>Locate Us</span>
            <a href="https://goo.gl/maps/wLUQnJ7tJY8UGTE17" target="_blank">
              SIT Towers, Dubai Silicon Oasis <br />
              (DSO) Dubai, UAE.</a
            >
          </li>

          <li>
            <i class="bi bi-telephone"></i> <span>Call Us</span
            ><a href="tel:+9714 10254"> +9714 10254</a>
          </li>
          <li>
            <i class="bi bi-envelope"></i> <span>E-mail Us</span>
            <a href="mailto:support@TKAFF.ae" i>support@TKAFF.ae </a> <br />
            <a href="mailto: info@TKAFF.ae" i> info@TKAFF.ae</a>
          </li>
        </div>
      </div>
      <div class="col-md-12">
        <div class="powered">
          <p>© {{ currentYear }} Tkaff. All Rights Reserved</p>
        </div>
      </div>
    </div>
  </div>
</section>
