<div class="menuextracontainer"  tabindex="-1" role="dialog">
  <div class="" role="document">
    <div class="">
      <div class="modal-header">
        <button type="button" class="close"  mat-dialog-close (click)="onNoClick()"><span aria-hidden="true">×</span></button>
        <h2><a>{{data.passdata.menuName}}</a></h2>
      </div>
      <div class="modal-body">
        <div class="menu-selection-container">
          <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="extras-detail-main" id="menu_idd_0">
            <input type="hidden" name="required_count" value="1">
            <h3>Choose Type Portion<span class="required_extras"></span>
            </h3>
            <div class="extras-detail-options"  >
              <div class="extras-detail-att"*ngFor="let res of submenulist; let i= index">
                <input type="radio" id="extra-{{i}}-0-0" data-ind="0" formControlName="selectportionId"  value="{{res.id}}"  name="selectportionId">
                <label for="extra-{{i}}-0-0">
                  <span  class="extra-title">{{res.subItemName}}</span>
                  <span class="extra-price">AED {{res.rate | number :'2.2'}}</span>
                </label>
              </div>
              <!-- <div class="extras-detail-att">
                <input type="radio" id="extra-1-0-0" data-ind="1" data-menucat-id="" data-menu-id="0" name="extra-0-0">
                <label for="extra-1-0-0">
                  <span class="extra-title">Coke / Diet Coke / Pepsi</span>
                  <span class="extra-price">£4.00</span>
                </label>
              </div>
              <div class="extras-detail-att">
                <input type="radio" id="extra-2-0-0" data-ind="2" data-menucat-id="" data-menu-id="0" name="extra-0-0">
                <label for="extra-2-0-0">
                  <span class="extra-title">Sangria</span>
                  <span class="extra-price">£2.00</span>
                </label>
              </div>
              <div class="extras-detail-att">
                <input type="radio" id="extra-3-0-0" data-ind="3" data-menucat-id="" data-menu-id="0" name="extra-0-0">
                <label for="extra-3-0-0">
                  <span class="extra-title">Red Bull</span>
                  <span class="extra-price">£4.00</span>
                </label>
              </div>
              <div class="extras-detail-att">
                <input type="radio" id="extra-4-0-0" data-ind="4" data-menucat-id="" data-menu-id="0" name="extra-0-0">
                <label for="extra-4-0-0">
                  <span class="extra-title">Sprite / 7up / Fresh Lime</span>
                  <span class="extra-price">£0.00</span>
                </label>
              </div> -->
            </div>
            <div class="extras-detail-selected"></div>
          </div>
          <!-- <div class="extras-detail-main">
            <h3>Sausages<span class="required_extras">Required 2</span></h3>
            <div class="extras-detail-options">
              <div class="extras-detail-att">
                <input type="checkbox" id="extra-0-0-11" data-ind="0" data-menucat-id="0" data-menu-id="1" name="extra-0-15">
                <label for="extra-0-0-11">
                  <span class="extra-title">Spicy Mayonnaise</span>
                  <span class="extra-price">£1.50</span>
                </label>
              </div>
              <div class="extras-detail-att">
                <input type="checkbox" id="extra-1-0-12" data-ind="1" data-menucat-id="0" data-menu-id="1" name="extra-0-15">
                <label for="extra-1-0-12">
                  <span class="extra-title">Tequila Lime Sauce</span>
                  <span class="extra-price">£2.50</span>
                </label>
              </div>
              <div class="extras-detail-att">
                <input type="checkbox" id="extra-2-0-13" data-ind="2" data-menucat-id="0" data-menu-id="1" name="extra-0-15">
                <label for="extra-2-0-13">
                  <span class="extra-title">Soy Sauce</span>
                  <span class="extra-price">£3.50</span>
                </label>
              </div>
              <div class="extras-detail-att">
                <input type="checkbox" id="extra-2-0-14" data-ind="2" data-menucat-id="0" data-menu-id="1" name="extra-0-15">
                <label for="extra-2-0-14">
                  <span class="extra-title">Garlic Sauce</span>
                  <span class="extra-price">£2.50</span>
                </label>
              </div>
            </div>
          </div> -->
          <div class="extras-btns-holderss">
            <button  class="add-extra-menu-btn input-button-loader editing-menu">Submit</button>
            <!-- <a href="#" class="reset-menu-fields btn">Reset Fields</a> -->
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>
</div>