import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { order } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { VendorReviewDialogComponent } from './vendor-review-dialog/vendor-review-dialog-dialog.component';
import { ConfirmAlertDialogComponent } from './confirm-alert-dialog/confirm-alert-dialog.component';

@Component({
  selector: 'app-buyer-orders',
  templateUrl: './buyer-orders.component.html',
  styleUrls: ['./buyer-orders.component.css']
})
export class BuyerOrdersComponent implements OnInit {
   Orderlist:any
   Orderlistdet:any
   Orderdetailss:any
   stars: number[] = [1, 2, 3, 4, 5];
selectedValue: number = 0;
isMouseover = true;
  constructor(public appservice: AppService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.getOrderList();
  }

  getOrderList(){
    this.appservice.getOrderList().subscribe((resorder)=>{
      console.log(resorder)
        this.Orderlist=resorder;
    })
  }

  orderdetail(det:any){
    console.log(det)
    this.Orderlistdet=det;
    this.Orderdetailss="";
    this.appservice.getOrderInfo(det.id).subscribe((detail)=>{
      console.log(detail)
      this.Orderdetailss=detail;
    })
  }

  OpenVendorReview(res:any){
    const dialogRef = this.dialog.open(VendorReviewDialogComponent
      , {
      data: { passdata: res }
    });
    dialogRef.afterClosed().subscribe(cat => {
      console.log(cat)
    });
  }


  Confirmalert(res:any){
    const dialogRef = this.dialog.open(ConfirmAlertDialogComponent
      , {
      data: { passdata: res }
    });
    dialogRef.afterClosed().subscribe(cat => {
      console.log(cat)
    });
  }

  countStar(star: number) {
    this.isMouseover = false;
    this.selectedValue = star;
  }
  
   addClass(star: number) {
    if (this.isMouseover) {
      this.selectedValue = star;
    }
   }
  
   removeClass() {
     if (this.isMouseover) {
        this.selectedValue = 0;
     }
   }

}
