<div class="sub-header align-center">
    <div class="subheader-holder"
        style=" background:url(assets/images/subheader-img2-1.jpg)  no-repeat ; background-size: cover; padding-top: 60px; padding-bottom: 30px; margin-top: 0px; margin-bottom: 0px; min-height: 186px !important;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="text-holder">
                        <div class="page-title ">
                            <h1 style="color:#ffffff !important;">Order Detail</h1>
                        </div>
                        <p style="color:#ffffff !important;">A great restaurant website</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumbs align-left">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ul>
                        <li><a routerLink="/index.html">Home</a></li>
                        <li class="active">Order Detail</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-section">

    <div class="page-section">
        <div class="container">
            <div class="row">

                <div class="section-content col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div class="reservation-form packages-form">
                        <form class="foodbakery-dev-payment-form" data-id="25607861" method="post">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="payment-summary-fields">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                    <label>First Name</label>
                                                    <input type="text" class="foodbakery-dev-req-field"
                                                        name="trans_first_name" placeholder="First Name" value="Buyer">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                    <label>Last Name</label>
                                                    <input type="text" class="foodbakery-dev-req-field"
                                                        name="trans_last_name" value="Demo pro" placeholder="Last Name">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                    <label>Email</label>
                                                    <input type="text"
                                                        class="foodbakery-dev-req-field foodbakery-email-field"
                                                        name="trans_email" value="dum4@chimpgroup.com"
                                                        placeholder="Email">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                    <label>Phone Number</label>
                                                    <input type="text"
                                                        class="foodbakery-dev-req-field foodbakery-number-field"
                                                        name="trans_phone_number" placeholder="Phone Number"
                                                        value="012 3456 789">
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="field-holder">
                                                    <label>Address</label>
                                                    <textarea class="foodbakery-dev-req-field"
                                                        name="trans_address"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-holder">
                                        <div class="payment-holder">
                                            <div class="payment-section">
                                                <ul class="payment-list row">
                                                    <li class="col-lg-6 col-xs-12 hidden">
                                                        <div class="payment-box">
                                                            <input type="radio" id="FOODBAKERY_WOOCOMMERCE_GATEWAY"
                                                                checked="checked" name="foodbakery_restaurant_gateway"
                                                                value="FOODBAKERY_WOOCOMMERCE_GATEWAY">
                                                            <label for="FOODBAKERY_WOOCOMMERCE_GATEWAY"></label>
                                                            <span>Pay with Woocommerce</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div><button id="register-restaurant-order" class="submit btn-submit"
                                                type="submit">Submit Order</button>
                                            <input type="hidden" name="foodbakery_buy_order_flag" value="1">
                                            <input type="hidden" name="trans_id" value="45637">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="section-sidebar col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="user-order-holder">
                        <div class="user-order">
                            <h6><i class="icon-shopping-basket"></i>Your Order </h6>
                            <ul class="categories-order">
                                <li>
                                    <a>California-Style Pizza</a>
                                    <span class="category-price">£39.00</span>
                                    <ul>
                                        <li>Extra Topping - Sweet corn : <span class="category-price">£0.00</span></li>
                                        <li>Extra Topping - Asparagus : <span class="category-price">£1.00</span></li>
                                        <li>Sausages - Spicy Mayonnaise : <span class="category-price">£0.00</span></li>
                                    </ul>
                                </li>
                            </ul>
                            <div class="price-area">
                                <ul>
                                    <li>Subtotal <span class="price">£40.00</span></li>
                                    <li>Delivery fee <span class="price">£10.00</span></li>
                                    <li>VAT (13%) <span class="price">£6.50</span></li>
                                </ul>
                                <p class="total-price">Total <span class="price">£56.50</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>