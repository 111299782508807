import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-buyer-dashboard',
  templateUrl: './buyer-dashboard.component.html',
  styleUrls: ['./buyer-dashboard.component.css']
})
export class BuyerDashboardComponent implements OnInit {

  name :any;
  mobileno :any='';
  email :any='';
  vendorlist:any;

  constructor(public appservice: AppService,
    public router: Router,
    public snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
    this.name=localStorage.getItem("name");
    this.mobileno=localStorage.getItem("mobileNo");
    this.email=localStorage.getItem("email");

    this.getVendorList();
  }

  public getVendorList() {
      let passdata= {
        "VendorType" : "Restaurant",   "VendorNameSearch" : "",  "Location" : "",   "CustomerId" : 0 
      }
      this.appservice.getVendorWithoutToken(passdata).subscribe(vendata => {
        console.log(vendata)
        this.vendorlist=vendata
      });
  }

}
