<div class="modal-header">
  <button type="button" class="close" mat-dialog-close><span aria-hidden="true">×</span></button>
  <h2 mat-dialog-title>Confirm your Order</h2>
</div>
<mat-dialog-content>
  <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom">
    <mat-step [stepControl]="formNameGroup" label="Delivery Details" editable="false" *ngIf="matSteppervisibility">
      <!-- <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="payment-holder">
              <div class="payment-section">
                <ul class="payment-list row">
                  <li class="col-lg-6 col-xs-12">
                    
                      <input id="order-pick-up-fee" type="radio" name="order_fee_type">
                      <label for="order-pick-up-fee"class="Address_text">Pick-Up 10.00</label>

                   
                  </li>
                  <li class="col-lg-6 col-xs-12">
                 
                      <input id="order-delivery-fee" checked="checked" type="radio" name="order_fee_type">
                      <label for="order-delivery-fee" class="Address_text">Delivery 15.00</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->
      <div><button id="register-restaurant-order" class="submit btn-submit" *ngIf="fieldvisibility"
          (click)="showAddress()">Add New
          Adress</button></div>
      <div class="menu-selection-container" *ngIf="visiblity">
        <form [formGroup]="formNameGroup">

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="payment-summary-fields">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Name</label>
                      <input type="text" class="foodbakery-dev-req-field" name="CustomerName"
                        formControlName="CustomerName" placeholder="Name" value="">
                    </div>
                  </div>
                  <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Company Name</label>
                      <input type="text" class="foodbakery-dev-req-field" name="CompanyName" value=""
                        formControlName="CompanyName" placeholder="Company Name">
                    </div>
                  </div> -->

                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Phone Number</label>
                      <input type="text" class="foodbakery-dev-req-field foodbakery-number-field" name="ContactNo"
                        formControlName="ContactNo" placeholder="Phone Number" value="">
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Building Name</label>
                      <!-- <textarea class="foodbakery-dev-req-field" name="trans_address"></textarea> -->
                      <input type="text" class="foodbakery-dev-req-field foodbakery-number-field"
                        formControlName="BuildingName" name="BuildingName" placeholder="Building Name" value="">
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Street Name</label>
                      <input type="text" class="foodbakery-dev-req-field foodbakery-email-field" name="StreetName"
                        formControlName="StreetName" value="" placeholder="Street Name">
                    </div>
                  </div>
                  
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Location</label>
                      <!-- <textarea class="foodbakery-dev-req-field" name="trans_address"></textarea> -->
                      <!-- <input type="text" class="foodbakery-dev-req-field foodbakery-number-field"
                          name="trans_phone_number" placeholder="Location" value=""> -->
                      <select (change)="Changecity($event)" name="GeoLocation" formControlName="GeoLocation">
                        <option selected="selected" value="">Select Location
                        </option>
                        <option [value]="option.id" *ngFor="let option of geocitylist">{{option.geoName}}</option>

                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Country</label>
                      <!-- <textarea class="foodbakery-dev-req-field" name="trans_address"></textarea> -->
                      <input type="text" class="foodbakery-dev-req-field foodbakery-number-field"
                        formControlName="Country" name="Country" placeholder="Country" value="">
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="field-holder">
                <div class="payment-holder">
                  <div class="payment-section">

                  </div><button id="register-restaurant-order" class="submit btn-submit" (click)="submit(stepper)"
                    type="submit">Save</button>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
      <br>
      <div class="menu-selection-container" *ngIf="!fieldvisibility">
        <form [formGroup]="formPickupGroup">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="payment-summary-fields">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>VechicleNo</label>
                      <input type="text" class="foodbakery-dev-req-field" name="CarNumber" formControlName="CarNumber"
                        placeholder="Vechicle No" readonly>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="display: none;">
                    <div class="field-holder">
                      <label>Driver Namee</label>
                      <input type="text" class="foodbakery-dev-req-field" name="DriverName" value=""
                        formControlName="DriverName" placeholder="Driver Name">
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="display: none;">
                    <div class="field-holder">
                      <label>Driver Phone</label>
                      <input type="text" class="foodbakery-dev-req-field foodbakery-number-field" name="DriverPhone"
                        formControlName="DriverPhone" placeholder="Driver Phone" value="">
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Car Make</label>
                      <input type="text" class="foodbakery-dev-req-field foodbakery-number-field" name="CarMake"
                        formControlName="CarMake" placeholder="Car Make" value="" readonly>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Car Model</label>
                      <input type="text" class="foodbakery-dev-req-field foodbakery-number-field" name="CarModel"
                        formControlName="CarModel" placeholder="Car Model" value="" readonly>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="field-holder">
                      <label>Car Color</label>
                      <input type="text" class="foodbakery-dev-req-field foodbakery-number-field" name="CarColor"
                        formControlName="CarColor" placeholder="Car Color" value="" readonly>
                    </div>
                  </div>


                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="field-holder">
                <div class="payment-holder">
                  <div class="payment-section">

                  </div><button id="register-restaurant-order" class="submit btn-submit" (click)="submit(stepper)"
                    type="submit">Next</button>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
      <br>
      <div *ngIf="fieldvisibility">
        <div class="menu-selection-container" *ngIf="data.userdata.address.length>0">
          <div class="row">
            <form [formGroup]="formaddress">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="element-title">
                  <h4>Choose Address to Delivery</h4>
                  <span class="element-slogan">(Click one of
                    the
                    options
                    below)</span>
                </div>
              </div>
              <div class="section-sidebar col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <!-- <div class="table-wrapper">

                <table class="shop_table woocommerce-checkout-review-order-table">
                  <thead>
                    <tr>
                      <th class="product-name">Street Name</th>
                      <th class="product-total">Location</th>
                      <th class="product-total">Country</th>
                      <th class="product-total"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="cart_item">
                      <td class="product-name">
                        12b, Street name </td>
                      <td class="product-total">
                        Location 1
                      </td>
                      <td>12546</td>
                      <td><i class="icon-chevron-with-circle-right"></i></td>
                    </tr>
                  </tbody>

                </table>
              </div> -->

                <div class="field-holder">

                  <div class="payment-holder">
                    <div class="payment-section">
                      <ul class="payment-list row">
                        <li class="col-lg-6 col-xs-12" *ngFor="let lis of data.userdata.address; let i=index;">
                          <div class="payment-box">
                            <span>{{lis.addType}}</span>
                            <input type="radio" id="foodbakery_paypal_gateway_{{lis.id}}" (click)="changetype()"
                              formControlName="Address" value="{{lis.id}}" checked="checked" name="Address">
                            <label for="foodbakery_paypal_gateway_{{lis.id}}" class="Address_text">{{lis.buildingName}},
                              {{lis.streetName}} <br>
                              {{lis.location}} <br> {{lis.country}}
                            </label>
                            <label>Contact no: {{lis.contactNumber}}</label>
                            <!-- <span>Pay with
                            Paypal</span> -->
                          </div>
                        </li>

                        <!-- <li class="col-lg-6 col-xs-12">
                        <div class="payment-box">
                          <input type="radio" id="foodbakery_pre_bank_transfer_9499784"
                            name="foodbakery_restaurant_gateway" value="FOODBAKERY_PRE_BANK_TRANSFER">
                          <label for="foodbakery_pre_bank_transfer_9499784" class="Address_text">#SB Street <br>
                            Sectionn 7 <br> Dubai</label>
                         <span>Pay with Pre Bank
                            Transfer</span>
                        </div>
                      </li> -->


                      </ul>
                    </div>

                  </div>
                </div>

              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="field-holder">
                  <div class="payment-holder">
                    <div class="payment-section">

                    </div><button id="register-restaurant-order" class="submit btn-submit" (click)="submit(stepper)"
                      type="submit">Next</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </mat-step>
    <mat-step [stepControl]="formPasswordGroup" label="Payment Details" editable="false">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="element-title">
          <h4>Payment Methods</h4>
          <ng-container *ngIf="data.formdata.PaymentType==='Cash' &&  !data.formdata.ewallet_chk">
            <h2 *ngIf="!cardvisiblity">{{data.formdata.PaymentType}} (Cash on Delivery)</h2>
            <h2 style="color: #047a06!important">Your Pay Amount is AED {{totalss | number:'2.2'}}</h2>

          </ng-container>
          <ng-container *ngIf="data.formdata.PaymentType==='Cash' &&  data.formdata.ewallet_chk">
            <h2 *ngIf="!cardvisiblity">{{data.formdata.PaymentType}} (Cash on Delivery)</h2>
            <h2 *ngIf="!cardvisiblity">E-Wallet - AED {{data.formdata.eBal}} </h2>
            <h2 *ngIf="!cardvisiblity">Total Amount - AED {{totalss | number:'2.2'}} </h2>
            <h2 style="color: #047a06!important">Your Pay Amount is AED {{(totalss2) | number:'2.2'}}</h2>

          </ng-container>


            <ng-container *ngIf="data.formdata.PaymentType==='TPoints'">
              <h2 *ngIf="!cardvisiblity">{{data.formdata.PaymentType}} - AED {{data.formdata.eBal}}</h2>
              <h2 style="color: #047a06!important">Your Pay Amount is AED {{totalss | number:'2.2'}}</h2>

            </ng-container>
            <ng-container *ngIf="data.formdata.PaymentType==='Card' ">
              <h2 *ngIf="!cardvisiblity">Online Payment</h2>
              <h2 style="color: #047a06!important">Your Pay Amount is AED {{totalss | number:'2.2'}}</h2>


          </ng-container>


          <span *ngIf="cardvisiblity" class="element-slogan">(Click one of
            the
            options
            below)</span>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="field-holder">
          <div class="payment-holder">
            <!-- <div class="payment-section">
                <ul class="payment-list row">
                  <li class="col-lg-6 col-xs-12">

                    <input id="order-cash-payment" type="radio" (click)="showCard('cash')" checked
                      name="order_payment_method">
                    <label for="order-cash-payment" class="payment_mod"><i class="icon-coins"></i>Cash</label>

                  </li>
                  <li class="col-lg-6 col-xs-12">

                    <input id="order-card-payment" type="radio" (click)="showCard('card')" name="order_payment_method">
                    <label for="order-card-payment"><i class="icon-credit-card4"></i>Card</label>

                  </li>
                </ul>
              </div> -->
            <div class="payment-section" *ngIf="cardvisiblity">
              <ul class="payment-list row">
                <li class="col-lg-6 col-xs-12">
                  <div class="payment-box">
                    <input type="radio" id="foodbakery_paypal_gateway_7284823" checked="checked"
                      name="foodbakery_restaurant_gateway" value="FOODBAKERY_PAYPAL_GATEWAY">
                    <label for="foodbakery_paypal_gateway_7284823"><img alt="" src="assets/images/Paypal.png"></label>
                    <span>Pay with
                      Paypal</span>
                  </div>
                </li>
                <li class="col-lg-6 col-xs-12">
                  <div class="payment-box">
                    <input type="radio" id="foodbakery_authorizedotnet_gateway_8257993"
                      name="foodbakery_restaurant_gateway" value="FOODBAKERY_AUTHORIZEDOTNET_GATEWAY">
                    <label for="foodbakery_authorizedotnet_gateway_8257993"><img alt=""
                        src="assets/images/Authorize.net_.png"></label>
                    <span>Pay with
                      Authorize.net</span>
                  </div>
                </li>
                <li class="col-lg-6 col-xs-12">
                  <div class="payment-box">
                    <input type="radio" id="foodbakery_pre_bank_transfer_9499784" name="foodbakery_restaurant_gateway"
                      value="FOODBAKERY_PRE_BANK_TRANSFER">
                    <label for="foodbakery_pre_bank_transfer_9499784"><img alt=""
                        src="assets/images/Bank-Transfer.png"></label>
                    <span>Pay with Pre Bank
                      Transfer</span>
                  </div>
                </li>
                <li class="col-lg-6 col-xs-12">
                  <div class="payment-box">
                    <input type="radio" id="foodbakery_skrill_gateway_8930496" name="foodbakery_restaurant_gateway"
                      value="FOODBAKERY_SKRILL_GATEWAY">
                    <label for="foodbakery_skrill_gateway_8930496"><img alt="" src="assets/images/Skrill.png"></label>
                    <span>Pay with
                      Skrill-MoneyBooker</span>
                  </div>
                </li>
              </ul>
            </div>

            <a class=" bgcolor btn-submit " (click)="paymentsubmit(stepper)">Confirm Order</a>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="formEmailGroup" label="Order Confirmation" editable="false">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="payment-summary-fields">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="field-holder">
                  <i class="icon-check"></i>
                  <label>Order placed Successfully...Please wait</label>
                </div>
              </div>

            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="field-holder">
              <!-- <a class=" bgcolor btn-submit " (click)="finish()">order Status</a> -->
            </div>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- <button mat-button [mat-dialog-close]="true" tabindex="1">Install</button>
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button> -->
</mat-dialog-actions>