import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-buyer-reviews',
  templateUrl: './buyer-reviews.component.html',
  styleUrls: ['./buyer-reviews.component.css']
})
export class BuyerReviewsComponent implements OnInit {

  VendorReviewlist:any;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;


  constructor(public appservice: AppService,
    public router: Router) { }

  ngOnInit(): void {
    this.appservice.getUserProfile().subscribe((res:any)=>{
      console.log(res)
      this.getVendorReview(res.customer.id)
    })
  }

  public getVendorReview(id:any){
    this.appservice.getCustomerOrderReview(id).subscribe((review:any)=>{
      console.log(review)
      this.VendorReviewlist=review;
    })
  }

}
