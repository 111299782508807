import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { order } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
// import { VendorReviewDialogComponent } from './vendor-review-dialog/vendor-review-dialog-dialog.component';
// import { ConfirmAlertDialogComponent } from './confirm-alert-dialog/confirm-alert-dialog.component';

@Component({
  selector: 'app-buyer-orders-history',
  templateUrl: './buyer-orders-history.component.html',
  styleUrls: ['./buyer-orders-history.component.css']
})
export class BuyerOrdersHistoryComponent implements OnInit {
  p: number = 0;
  Orderlist: any
  Orderlistdet: any
  Orderdetailss: any
  Orderdetailss1: any
  Orderdetailss2: any
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  isMouseover = true;
  constructor(public appservice: AppService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getOrderList();
  }

  getOrderList() {
    this.appservice.getOrderList().subscribe((resorder:any) => {
      console.log(resorder)
      this.Orderlist = resorder;
     // this.Orderlist = resorder.filter((item: { orderType: any; }) => item.orderType == "RegularOrder");
    })
  }

  orderdetail(det: any) {
    console.log(det)
    console.log(det.orderType)
    if(det.orderType=="RegularOrder"){
      this.Orderlistdet = det;
      this.Orderdetailss = "";
      this.appservice.getOrderInfo(det.id).subscribe((detail) => {
        console.log(detail)
        this.Orderdetailss = detail;
      })
    }
    else if(det.orderType=="MealPlan"){
      this.Orderlistdet = det;
        console.log(this.Orderlistdet)
        this.Orderdetailss1 = "";
        this.appservice.getVendorMealOrdersTypeInfo(det.id).subscribe((detail) => {
          console.log(detail)
          this.Orderdetailss1 = detail;
        })
        // this.appservice.getOrderInfo(det.id).subscribe((detail) => {
        //   console.log(detail)
        //   this.Orderdetailss = detail;
        // })
    }
    else if(det.orderType=="Subscription"){
      this.Orderlistdet = det;
      this.Orderdetailss2 = "";
      this.appservice.getVendorSubcriptionOrdersTypeInfo(det.id).subscribe((detail) => {
        console.log(detail)
        this.Orderdetailss2 = detail;
      })
    }
   
  }

  // OpenVendorReview(res:any){
  //   const dialogRef = this.dialog.open(VendorReviewDialogComponent
  //     , {
  //     data: { passdata: res }
  //   });
  //   dialogRef.afterClosed().subscribe(cat => {
  //     console.log(cat)
  //   });
  // }


  // Confirmalert(res:any){
  //   const dialogRef = this.dialog.open(ConfirmAlertDialogComponent
  //     , {
  //     data: { passdata: res }
  //   });
  //   dialogRef.afterClosed().subscribe(cat => {
  //     console.log(cat)
  //   });
  // }

  countStar(star: number) {
    this.isMouseover = false;
    this.selectedValue = star;
  }

  addClass(star: number) {
    if (this.isMouseover) {
      this.selectedValue = star;
    }
  }

  removeClass() {
    if (this.isMouseover) {
      this.selectedValue = 0;
    }
  }

  reorder(event: any) {
    console.log(event)

    if (event) {
      console.log(event)
      localStorage.setItem('temp_ord', event.id);
      this.router.navigate(['/listing-details/', event.vendorId])
    }






  }

}
