<header class="cbp-af-header">
  <div class="main_header_area animated">
    <div class="container">
      <nav id="navigation1" class="navigation">
        <!-- Logo Area Start -->
        <div class="nav-header">
          <a class="logo" href="index.html"></a>

          <button
            type="button"
            class="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <i class="bi bi-list"></i>
          </button>
        </div>
        <div class="nav-menus-wrapper">
          <!-- Main Menus Wrapper -->
          <div class="nav-menus-wrapper">
            <div
              class="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <div class="nav-button align-to-right">
                <button class="btn2" routerLink="/login">Login</button>
                <button class="btn3" routerLink="/registration">Sign Up</button>
              </div>
              <ul class="nav-menu align-to-left">
                <li><a [routerLink]="['/home']">Home</a></li>
                <li><a [routerLink]="['/about-us']">About Us</a></li>
                <li><a [routerLink]="['/partner-with-us']">Partner with us</a></li>
                <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</header>
