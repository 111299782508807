<div class="main-section">
    <app-buyer-header></app-buyer-header>
    <div class="page-section account-header buyer-logged-in">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="user-account-nav user-account-sidebar">
                        <app-buyer-sidebar></app-buyer-sidebar>
                    </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div class="user-dashboard loader-holder">
                        <div class="user-holder">
                            <div class="user-shortlist-list listing simple">
                                <div class="element-title has-border">
                                    <h5>Partner</h5>
                                </div>
                                <ul class="shortlists-list" *ngIf="userdata">
                                    <li *ngFor="let res of userdata; let i=index;">
                                        <div class="suggest-list-holder">
                                            <div class="img-holder">
                                                <figure>
                                                    <a [routerLink]="['/listing-details/',res.vendorId]">
                                                        <img *ngIf="!res.vendorImage" src="assets/images/listing-logo18.png" alt="#">
                                                        <img *ngIf="res.vendorImage" src="{{VendorImagebaseUrl+res.identifier+'/Logo/'+res.vendorImage}}"  alt="#">
                                                    </a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h5><a [routerLink]="['/listing-details/',res.vendorId]">{{res.vendorName}}</a></h5>
                                                </div>
                                                <!-- <div class="delivery-potions">
                                                    <span>Totnes, United Kingdom</span>
                                                </div>
                                                <span>Apple Juice</span> -->
                                                <div class="list-option">
                                                    <a [routerLink]="['/listing-details/',res.vendorId]" class="viewmenu-btn">View Menu</a>
                                                    <a (click)="remove(res)" class="short-icon delete-shortlist"><i class="icon-close2"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li>
                                        <div class="suggest-list-holder">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/listings">
                                                        <img src="assets/images/fb-restaurant-01.jpg" alt="">
                                                    </a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h5><a routerLink="/listings">Jet’s Kitchen</a></h5>
                                                </div>
                                                <div class="delivery-potions">
                                                    <span>12 Hume St, Montrose DD10 8JD, UK</span>
                                                </div>
                                                <span>Chines Soup</span> , <span>Egg Fry</span> , <span>Fish Fry</span>,
                                                <div class="list-option">
                                                    <a routerLink="/listings" class="viewmenu-btn">View Menu</a>
                                                    <a routerLink="/#" class="short-icon delete-shortlist"><i class="icon-close2"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="suggest-list-holder">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/listings">
                                                        <img src="assets/images/fb-restaurant-04.jpg" alt="">
                                                    </a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h5><a routerLink="/listings">Golden Chick Fries</a></h5>
                                                </div>
                                                <div class="delivery-potions">
                                                    <span>Restaurant Associates, Martin Lane, London, United Kingdom</span>
                                                </div>
                                                <span>Doughnut</span> , <span>Hot Dogs</span> , <span>Ice Cream</span> ,
                                                <div class="list-option">
                                                    <a routerLink="/listings" class="viewmenu-btn">View Menu</a>
                                                    <a routerLink="/#" class="short-icon delete-shortlist" data-id="1702"><i class="icon-close2"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="suggest-list-holder">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/listings">
                                                        <img src="assets/images/fb-restaurant-06.jpg" alt="">
                                                    </a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h5><a routerLink="/listings">Dragon Express</a></h5>
                                                </div>
                                                <div class="delivery-potions">
                                                    <span>10-11 Bethel Square, Brecon LD3 7JP, UK</span>
                                                </div>
                                                <span>Apple Juice</span> , <span>Carrot Juice</span> , <span>Ice Cream</span> ,
                                                <div class="list-option">
                                                    <a routerLink="/listings" class="viewmenu-btn">View Menu</a>
                                                    <a routerLink="/#" class="short-icon delete-shortlist" data-id="1703"><i class="icon-close2"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="suggest-list-holder">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/listings">
                                                        <img src="assets/images/xlisting-logo03.png.pagespeed.ic_.aT-o9LinEJ.png" alt="">
                                                    </a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h5><a routerLink="/listings">Nature Healthy Food</a></h5>
                                                </div>
                                                <div class="delivery-potions">
                                                    <span>Pizza Express Restaurants, Bristol, United Kingdom</span>
                                                </div>
                                                <span>Apple Juice</span> , <span>Chicken Roast</span> , <span>Chines Soup</span> , <span>Magi</span> ,
                                                <div class="list-option">
                                                    <a routerLink="/listings" class="viewmenu-btn">View Menu</a>
                                                    <a routerLink="/#" class="short-icon delete-shortlist"><i class="icon-close2"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="suggest-list-holder">
                                            <div class="img-holder">
                                                <figure>
                                                    <a routerLink="/listings">
                                                        <img src="assets/images/fb-restaurant-12.png" alt="">
                                                    </a>
                                                </figure>
                                            </div>
                                            <div class="text-holder">
                                                <div class="post-title">
                                                    <h5><a routerLink="/listings">Pizza Hut</a></h5>
                                                </div>
                                                <div class="delivery-potions">
                                                    <span>504 Stillwater Ave, Bangor, ME 04401, USA</span>
                                                </div>
                                                <span>Cold Coffee</span> , <span>Sham pane</span> , <span>Soda water</span> ,
                                                <div class="list-option">
                                                    <a routerLink="/listings" class="viewmenu-btn">View Menu</a>
                                                    <a routerLink="/#" class="short-icon delete-shortlist"><i class="icon-close2"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                            <!-- <ul class="pagination">
                                <li class="active"><a>1</a></li>
                                <li><a routerLink="/#">2</a></li>
                                <li><a routerLink="/#">3</a></li>
                                <li><span class="page-numbers dots">…</span></li>
                                <li><a routerLink="/#">24</a></li>
                                <li><a routerLink="/#">Next </a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Popup Start -->
<div class="modal fade menu-order-detail menu-order-info" id="booking-detail-22603" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                <h2><a>Booking Detail</a></h2>
            </div>
            <div class="modal-body booking-modal-body">
                <div class="order-detail-inner">
                    <h3>Restaurant Demo </h3>
                    <ul class="order-detail-options">
                        <li>
                            <strong>Booking ID :</strong>
                            <span>22603</span>
                        </li>
                        <li>
                            <strong>Booking Date :</strong>
                            <span>Apr 9,2019 6:40 AM </span>
                        </li>
                    </ul>
                    <h3>Customer Deatil</h3>
                    <ul class="order-detail-options">
                        <li>
                            <strong>First Name:</strong>
                            <span>Mark</span>
                        </li>
                        <li>
                            <strong>Last Name:</strong>
                            <span>Jose</span>
                        </li>
                        <li>
                            <strong>Email:</strong>
                            <span>markjose@gmail.com</span>
                        </li>
                        <li>
                            <span>4-guest</span>
                        </li>
                        <li>
                            <span>Apr 30,2019 11:00 AM</span>
                        </li>
                        <li class="order-detail-message">
                            <strong>Instructions:</strong>
                            <span>I need to book a special table for my friends. Make it some special.!</span>
                        </li>
                    </ul>
                    <div class="booking-status-holder">
                        <div class="booking-status-process booking-status">
                            <p style="background:#1e73be;">Your booking is Processing.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

