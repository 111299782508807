<div class="sub-header align-center">
    <div class="subheader-holder" style=" background:url(assets/images/subheader-img2-1.jpg)  no-repeat ; background-size: cover; padding-top: 60px; padding-bottom: 30px; margin-top: 0px; margin-bottom: 0px; min-height: 186px !important;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="text-holder">
                        <div class="page-title ">
                            <h1 style="color:#ffffff !important;">Checkout</h1>
                        </div>
                        <p style="color:#ffffff !important;">A great restaurant website</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumbs align-left">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li class="active">Checkout</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-section">

    <div class="page-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="cs-rich-editor">
                        <div class="woocommerce">
                            <div class="woocommerce-notices-wrapper"></div>
                            <div class="woocommerce-notices-wrapper"></div>
                            <form name="checkout" method="post" class="checkout woocommerce-checkout" action="" enctype="multipart/form-data" novalidate="novalidate">



                                <h3 id="order_review_heading">Your order</h3>


                                <div id="order_review" class="woocommerce-checkout-review-order">
                                    <table class="shop_table woocommerce-checkout-review-order-table">
                                        <thead>
                                            <tr>
                                                <th class="product-name">Product</th>
                                                <th class="product-total">Subtotal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="cart_item">
                                                <td class="product-name">
                                                    Hardees&nbsp; <strong class="product-quantity">×&nbsp;1</strong> </td>
                                                <td class="product-total">
                                                    <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">£</span>119.00</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>

                                            <tr class="cart-subtotal">
                                                <th>Subtotal</th>
                                                <td><span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">£</span>119.00</span>
                                                </td>
                                            </tr>



                                            <tr class="fee">
                                                <th>VAT (13%)</th>
                                                <td><span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">£</span>16.77</span>
                                                </td>
                                            </tr>
                                            <tr class="fee">
                                                <th>Delivery</th>
                                                <td><span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">£</span>10.00</span>
                                                </td>
                                            </tr>



                                            <tr class="order-total">
                                                <th>Total</th>
                                                <td><strong><span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">£</span>145.77</span></strong> </td>
                                            </tr>


                                        </tfoot>
                                    </table>

                                    <div id="payment" class="woocommerce-checkout-payment">
                                        <ul class="wc_payment_methods payment_methods methods">
                                            <li class="wc_payment_method payment_method_bacs">
                                                <input id="payment_method_bacs" type="radio" class="input-radio" name="payment_method" value="bacs" checked="checked" data-order_button_text="">

                                                <label for="payment_method_bacs">
Direct bank transfer 	</label>
                                                <div class="payment_box payment_method_bacs">
                                                    <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.</p>
                                                </div>
                                            </li>
                                            <li class="wc_payment_method payment_method_cheque">
                                                <input id="payment_method_cheque" type="radio" class="input-radio" name="payment_method" value="cheque" data-order_button_text="">

                                                <label for="payment_method_cheque">
Check payments 	</label>
                                                <div class="payment_box payment_method_cheque" style="display:none;">
                                                    <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                                                </div>
                                            </li>
                                            <li class="wc_payment_method payment_method_cod">
                                                <input id="payment_method_cod" type="radio" class="input-radio" name="payment_method" value="cod" data-order_button_text="">

                                                <label for="payment_method_cod">
Cash on delivery 	</label>
                                                <div class="payment_box payment_method_cod" style="display:none;">
                                                    <p>Pay with cash upon delivery.</p>
                                                </div>
                                            </li>
                                            <li class="wc_payment_method payment_method_paypal">
                                                <input id="payment_method_paypal" type="radio" class="input-radio" name="payment_method" value="paypal" data-order_button_text="Proceed to PayPal">

                                                <label for="payment_method_paypal">
PayPal <img src="https://www.paypalobjects.com/webstatic/mktg/Logo/AM_mc_vs_ms_ae_UK.png" alt="PayPal acceptance mark">	</label>
                                                <div class="payment_box payment_method_paypal" style="display:none;">
                                                    <p>Pay via PayPal; you can pay with your credit card if you don’t have a PayPal account. SANDBOX ENABLED. You can use sandbox testing accounts only. See the <a routerLink="/#">PayPal Sandbox Testing Guide</a>                                                        for more details.</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="form-row place-order">
                                            <noscript>
Since your browser does not support JavaScript, or it is disabled, please ensure you click the <em>Update Totals</em> button before placing your order. You may be charged more than the amount stated above if you fail to do so.			<br/><button type="submit" class="button alt" name="woocommerce_checkout_update_totals" value="Update totals">Update totals</button>
</noscript>

                                            <div class="woocommerce-terms-and-conditions-wrapper">
                                                <div class="woocommerce-privacy-policy-text">
                                                    <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a routerLink="/privacy-policy" class="woocommerce-privacy-policy-link">privacy policy</a>.</p>
                                                </div>
                                                <div class="woocommerce-terms-and-conditions" style="display: none; max-height: 200px; overflow: auto;">
                                                    <h2>Who we are</h2>
                                                    <p>Our website address is: http://foodbakery.chimpgroup.com.</p>
                                                    <h2>What personal data we collect and why we collect it</h2>
                                                    <h3>Comments</h3>
                                                    <p>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.</p>
                                                    <p>An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/.
                                                        After approval of your comment, your profile picture is visible to the public in the context of your comment.</p>
                                                    <h3>Media</h3>
                                                    <p>If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images
                                                        on the website.</p>
                                                    <h3>Contact forms</h3>
                                                    <h3>Cookies</h3>
                                                    <p>If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when
                                                        you leave another comment. These cookies will last for one year.</p>
                                                    <p>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</p>
                                                    <p>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you
                                                        select "Remember Me", your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</p>
                                                    <p>If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires
                                                        after 1 day.</p>
                                                    <h3>Embedded content from other websites</h3>
                                                    <p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
                                                    <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded
                                                        content if you have an account and are logged in to that website.</p>
                                                    <h3>Analytics</h3>
                                                    <h2>Who we share your data with</h2>
                                                    <h2>How long we retain your data</h2>
                                                    <p>If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.
                                                    </p>
                                                    <p>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except
                                                        they cannot change their username). Website administrators can also see and edit that information.</p>
                                                    <h2>What rights you have over your data</h2>
                                                    <p>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also
                                                        request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>
                                                    <h2>Where we send your data</h2>
                                                    <p>Visitor comments may be checked through an automated spam detection service.</p>
                                                    <h2>Your contact information</h2>
                                                    <h2>Additional information</h2>
                                                    <h3>How we protect your data</h3>
                                                    <h3>What data breach procedures we have in place</h3>
                                                    <h3>What third parties we receive data from</h3>
                                                    <h3>What automated decision making and/or profiling we do with user data</h3>
                                                    <h3>Industry regulatory disclosure requirements</h3>
                                                </div>
                                                <p class="form-row validate-required">
                                                    <label class="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox">
  <input type="checkbox" class="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox" name="terms" id="terms">
    <span class="woocommerce-terms-and-conditions-checkbox-text">I have read and agree to the website <a routerLink="/privacy-policy" class="woocommerce-terms-and-conditions-link" target="_blank">terms and conditions</a></span>&nbsp;<span class="required">*</span>
  </label>
                                                    <input type="hidden" name="terms-field" value="1">
                                                </p>
                                            </div>


                                            <button type="submit" class="button alt" name="woocommerce_checkout_place_order" id="place_order" value="Place order" data-value="Place order">Place order</button>

                                            <input type="hidden" id="woocommerce-process-checkout-nonce" name="woocommerce-process-checkout-nonce" value="15aae0cc7d"><input type="hidden" name="_wp_http_referer" value="/"> </div>
                                    </div>

                                </div>


                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
